import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import Select from 'react-select';
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from 'react-hook-form';
import { usePlatformApi } from '../../_common/hooks/api/PlatformApiHook';
import { useAppLoader } from '../../_common/hooks/common/appLoaderHook';
import { toast } from 'react-hot-toast';
import { handleKeyPressNumber } from '../../_common/functions';
import { countriesData, salutationData } from '../../_common/staticData';
import InputMask from 'react-input-mask';

interface FormValues {
	salutation: any;
	firstName: string;
	middleName: string;
	lastName: string;
	suffix: string;
	accntName: any;
	title: string;
	email: string;
	phone: string;
	mobile: string;
	contactOwner: string;
	reportsTo: string;
	department: string;
	fax: string;
	mailingStreet: string;
	mailingCity: string;
	mailingState: string;
	mailingZip: string;
	mailingCountry: any;
}

const validationSchema = Yup.object().shape({
	// accntName: Yup.object().required("Account Name is required"),
	lastName: Yup.string().required("Last Name is required"),
});

interface IncomingProps {
	show: boolean;
	close: () => void;
	AccountId: string;
	contactListCallbackFunc: any;
}

const AddContactModal = ({ show, close, AccountId, contactListCallbackFunc }: IncomingProps) => {

	const platformApi = usePlatformApi();

	const { showLoader, hideLoader } = useAppLoader();
	const [salutationsOptions, setSalutaionOptions] = useState<any>(salutationData)
	const [countriesOptions, setCountriesOptions] = useState<any>(countriesData)
	const [accountOptions, setAccountOptions] = useState<any>(null)

	const { control, formState: { errors }, handleSubmit, setValue, reset, watch } = useForm<FormValues>({
		resolver: yupResolver(validationSchema),
		defaultValues: {
			salutation: "",
			firstName: "",
			middleName: "",
			lastName: "",
			suffix: "",
			accntName: "",
			title: "",
			email: undefined,
			phone: undefined,
			mobile: undefined,
			contactOwner: "",
			reportsTo: "",
			department: "",
			fax: "",
			mailingStreet: "",
			mailingCity: "",
			mailingState: "",
			mailingZip: "",
			mailingCountry: "",
		},
	});

	const getAccountList = () => {
		showLoader();
		let params: any = {};
		platformApi.getAllAccount(params,
			(message: string, resp: any) => {
				// console.log(resp);
				const tempData = resp.data.list.data
				// const tempData = resp.data.list
				if (tempData && tempData.length > 0) {
					setAccountOptions(tempData.map((item: any) => {
						item.value = item.AccountId
						item.label = item.Name
						return item
					}))
				}
				hideLoader();
			}, (message: string) => {
				hideLoader();
			})
	}

	const onSubmit = (data: any) => {
		console.log(data);
		let params: any = {
			"AccountId": AccountId,
			"FirstName": data?.firstName,
			"LastName": data?.lastName,
			"Email": data?.email,
			"Fax": data?.fax,
			"Phone": data?.phone,
			// "Department": data?.department,
			// "MailingCity": data?.mailingCity,
			// "MailingCountry": data?.mailingCountry?.value,
			// "MailingPostalCode": data?.mailingZip,
			// "MailingState": data?.mailingState,
			// "MailingStreet": data?.mailingStreet,
			// "MobilePhone": data?.mobile,
			// "ReportsToId": data?.reportsTo,
			// "Salutation": data?.salutation?.value,
			// "Title": data?.title
		}
		showLoader()
		platformApi.manageContact(params, (message: string, resp: any) => {
			// console.log(message, resp)
			toast.success(message)
			handleClose()
			hideLoader()
			contactListCallbackFunc(AccountId)
		}, (message: string) => {
			// console.log(message)
			toast.error(message)
			hideLoader()
		})

	}

	const handleClose = () => {
		close();
		reset();
	};

	// useEffect(() => {
	// 	if (show && AccountId) {
	// 		getAccountList()
	// 	}
	// }, [show, AccountId])


	return (
		<React.Fragment>
			<Modal
				className='salesforce-modal modal-lndscp'
				show={show}
			// onHide={handleClose}
			>
				<div className="modal-header custom-modal-header">
					<button
						type="button"
						className="close"
						data-dismiss="modal"
						aria-label="Close"
						onClick={handleClose}
					>
						<span aria-hidden="true">×</span>
					</button>
				</div>
				<Modal.Body>
					<div className="row">
						<form onSubmit={handleSubmit(onSubmit)} className="w-100" >
							<div className="col-12">
								<div className="card">
									<div className="card-body">
										<div className="d-flex flex-wrap">
											<h5 className="card-title mr-2">Contact Information</h5>
										</div>
										<div className="row">
											{/* <div className="form-group col-6">
												<label htmlFor="accntName">Salutation</label>
												<Controller
													name={"salutation"}
													control={control}
													render={({ field: { value, onChange, onBlur, name } }) => (
														<>
															<Select
																className={"select-box " + (errors?.salutation ? ' border-danger' : '')}
																id="salutation"
																options={salutationsOptions ? salutationsOptions : []}
																placeholder={"Select salutation"}
																onChange={onChange}
																value={value}
																defaultValue={value}
															/>
														</>
													)}
												/>
											</div> */}
											{/* <div className="form-group col-6">
												<label htmlFor="contactOwner">Contact Owner</label>
												<Controller
													name={"contactOwner"}
													control={control}
													render={({ field: { value, onChange, onBlur, name } }) => (
														<>
															<input
																type="text"
																className={"form-control " + (errors?.contactOwner ? ' border-danger' : '')}
																id="contactOwner"
																name={name}
																onChange={onChange}
																value={value}
																onBlur={onBlur}
																placeholder="Enter contact owner name"
																readOnly
															/>
														</>
													)}
												/>
												{errors && errors.contactOwner &&
													<span className='text-danger'>{errors?.contactOwner?.message}</span>
												}
											</div> */}
											<div className="form-group col-6">
												<label htmlFor="firstName">First Name</label>
												<Controller
													name={"firstName"}
													control={control}
													render={({ field: { value, onChange, onBlur, name } }) => (
														<>
															<input
																type="text"
																className={"form-control " + (errors?.firstName ? ' border-danger' : '')}
																id="firstName"
																name={name}
																onChange={onChange}
																value={value}
																onBlur={onBlur}
																placeholder="Enter contact owner name"
															/>
														</>
													)}
												/>
												{errors && errors.firstName &&
													<span className='text-danger'>{errors?.firstName?.message}</span>
												}
											</div>
											{/* <div className="form-group col-6">
												<label htmlFor="reportsTo">Reports To</label>
												<Controller
													name="reportsTo"
													control={control}
													render={({ field: { value, onChange, onBlur, name } }) => (
														<>
															<input
																type="text"
																className={"form-control " + (errors?.reportsTo ? ' border-danger' : '')}
																id="reportsTo"
																name={name}
																onChange={onChange}
																value={value}
																onBlur={onBlur}
																placeholder="Enter reportsTo"
															/>
														</>
													)}
												/>
												{errors && errors.reportsTo &&
													<span className='text-danger'>{errors?.reportsTo?.message}</span>
												}
											</div> */}
											{/* <div className="form-group col-6">
												<label htmlFor="middleName">Middle Name</label>
												<Controller
													name={"middleName"}
													control={control}
													render={({ field: { value, onChange, onBlur, name } }) => (
														<>
															<input
																type="text"
																className={"form-control " + (errors?.middleName ? ' border-danger' : '')}
																id="middleName"
																name={name}
																onChange={onChange}
																value={value}
																onBlur={onBlur}
																placeholder="Enter middle name"
															/>
														</>
													)}
												/>
												{errors && errors.middleName &&
													<span className='text-danger'>{errors?.middleName?.message}</span>
												}
											</div> */}
											{/* <div className="form-group col-6">
												<label htmlFor="department">Department</label>
												<Controller
													name={"department"}
													control={control}
													render={({ field: { value, onChange, onBlur, name } }) => (
														<>
															<input
																type="text"
																className={"form-control " + (errors?.department ? ' border-danger' : '')}
																id="department"
																name={name}
																onChange={onChange}
																value={value}
																onBlur={onBlur}
																placeholder="Enter descriptiom"
															/>
														</>
													)}
												/>
												{errors && errors.department &&
													<span className='text-danger'>{errors?.department?.message}</span>
												}
											</div> */}
											<div className="form-group col-6">
												<label htmlFor="lastName">Last Name <span className='text-danger'>*</span></label>
												<Controller
													name={"lastName"}
													control={control}
													render={({ field: { value, onChange, onBlur, name, ref } }) => (
														<>
															<input
																type="text"
																className={"form-control " + (errors?.lastName ? ' border-danger' : '')}
																id="lastName"
																name={name}
																onChange={onChange}
																value={value}
																onBlur={onBlur}
																ref={ref}
																placeholder="Enter last name"
															/>
														</>
													)}
												/>
												{errors && errors.lastName &&
													<span className='text-danger'>{errors?.lastName?.message}</span>
												}
											</div>
											{/* <div className="form-group col-6">
												<label htmlFor="accntName">Account Name <span className='text-danger'>*</span></label>
												<Controller
													name={"accntName"}
													control={control}
													render={({ field: { value, onChange, onBlur, name, ref } }) => (
														<>
															<Select
																className={"select-box " + (errors?.accntName ? ' border-danger' : '')}
																id="accntName"
																options={accountOptions ? accountOptions : []}
																placeholder={"Select account"}
																onChange={onChange}
																value={value}
																defaultValue={value}
																isDisabled
																ref={ref}
															/>
														</>
													)}
												/>
												{errors && errors.accntName &&
													<span className='text-danger'>Account is required</span>
												}
											</div> */}
											{/* <div className="form-group col-6">
												<label htmlFor="suffix">Suffix</label>
												<Controller
													name={"suffix"}
													control={control}
													render={({ field: { value, onChange, onBlur, name } }) => (
														<>
															<input
																type="text"
																className={"form-control " + (errors?.suffix ? ' border-danger' : '')}
																id="suffix"
																name={name}
																onChange={onChange}
																value={value}
																onBlur={onBlur}
																placeholder="Enter suffix"
															/>
														</>
													)}
												/>
												{errors && errors.suffix &&
													<span className='text-danger'>{errors?.suffix?.message}</span>
												}
											</div> */}
											{/* <div className="form-group col-6">
												<label htmlFor="title">Title</label>
												<Controller
													name={"title"}
													control={control}
													render={({ field: { value, onChange, onBlur, name } }) => (
														<>
															<input
																type="text"
																className={"form-control " + (errors?.title ? ' border-danger' : '')}
																id="title"
																name={name}
																onChange={onChange}
																value={value}
																onBlur={onBlur}
																placeholder="Enter title"
															/>
														</>
													)}
												/>
												{errors && errors.title &&
													<span className='text-danger'>{errors?.title?.message}</span>
												}
											</div> */}
											<div className="form-group col-6">
												<label htmlFor="phone">Phone</label>
												<Controller
													name={"phone"}
													control={control}
													render={({ field: { value, onChange, onBlur, name } }) => (
														<>
															<InputMask
																mask="(999) 999-9999"
																maskChar="_"
																name={name}
																value={value}
																onBlur={onBlur}
																onChange={onChange}
																className={
																	"form-control " + (errors?.phone ? "border-danger" : "")
																}
																id="phone"
																placeholder="Enter phone"
															/>
														</>
													)}
												/>
												{errors && errors.phone &&
													<span className='text-danger'>{errors?.phone?.message}</span>
												}
											</div>
											<div className="form-group col-6">
												<label htmlFor="email">Email</label>
												<Controller
													name={"email"}
													control={control}
													render={({ field: { value, onChange, onBlur, name } }) => (
														<>
															<input
																type="email"
																className={"form-control " + (errors?.email ? ' border-danger' : '')}
																id="email"
																name={name}
																onChange={onChange}
																value={value}
																onBlur={onBlur}
																placeholder="Enter email"
															/>
														</>
													)}
												/>
												{errors && errors.email &&
													<span className='text-danger'>{errors?.email?.message}</span>
												}
											</div>
											{/* <div className="form-group col-6">
												<label htmlFor="mobile">Mobile</label>
												<Controller
													name={"mobile"}
													control={control}
													render={({ field: { value, onChange, onBlur, name } }) => (
														<>
															<InputMask
																mask="(999) 999-9999"
																maskChar="_"
																className={"form-control " + (errors?.mobile ? ' border-danger' : '')}
																id="mobile"
																name={name}
																onChange={onChange}
																value={value}
																onBlur={onBlur}
																placeholder="Enter mobile"
																onKeyPress={(event: any) => handleKeyPressNumber(event)}
															/>
														</>
													)}
												/>
												{errors && errors.mobile &&
													<span className='text-danger'>{errors?.mobile?.message}</span>
												}
											</div> */}
											<div className="form-group col-6">
												<label htmlFor="fax">Fax</label>
												<Controller
													name={"fax"}
													control={control}
													render={({ field: { value, onChange, onBlur, name } }) => (
														<>
															<input
																type="text"
																className={"form-control " + (errors?.fax ? ' border-danger' : '')}
																id="fax"
																name={name}
																onChange={onChange}
																value={value}
																onBlur={onBlur}
																placeholder="Enter fax"
																onKeyPress={(event: any) => handleKeyPressNumber(event)}
															/>
														</>
													)}
												/>
												{errors && errors.fax &&
													<span className='text-danger'>{errors?.fax?.message}</span>
												}
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="col-12 d-none">
								<div className="card">
									<div className="card-body">
										<div className="d-flex flex-wrap justify-content-between">
											<h5 className="card-title mr-2">Address Information</h5>
										</div>
										<div className="row">
											<div className="form-group col-6">
												<label htmlFor="mailingStreet">Mailling Street</label>
												<Controller
													name={"mailingStreet"}
													control={control}
													render={({ field: { value, onChange, onBlur, name } }) => (
														<>
															<input
																type="text"
																className={"form-control " + (errors?.mailingStreet ? ' border-danger' : '')}
																id="mailingStreet"
																name={name}
																onChange={onChange}
																value={value}
																onBlur={onBlur}
																placeholder="Enter mailling street"
															/>
														</>
													)}
												/>
												{errors && errors.mailingStreet &&
													<span className='text-danger'>{errors?.mailingStreet?.message}</span>
												}
											</div>
											<div className="form-group col-6">
												<label htmlFor="mailingCity">Mailling City</label>
												<Controller
													name={"mailingCity"}
													control={control}
													render={({ field: { value, onChange, onBlur, name } }) => (
														<>
															<input
																type="text"
																className={"form-control " + (errors?.mailingCity ? ' border-danger' : '')}
																id="mailingCity"
																name={name}
																onChange={onChange}
																value={value}
																onBlur={onBlur}
																placeholder="Enter billing city"
															/>
														</>
													)}
												/>
												{errors && errors.mailingCity &&
													<span className='text-danger'>{errors?.mailingCity?.message}</span>
												}
											</div>
											<div className="form-group col-6">
												<label htmlFor="mailingState">Mailling State/Province</label>
												<Controller
													name={"mailingState"}
													control={control}
													render={({ field: { value, onChange, onBlur, name } }) => (
														<>
															<input
																type="text"
																className={"form-control " + (errors?.mailingState ? ' border-danger' : '')}
																id="mailingState"
																name={name}
																onChange={onChange}
																value={value}
																onBlur={onBlur}
																placeholder="Enter billing state"
															/>
														</>
													)}
												/>
												{errors && errors.mailingState &&
													<span className='text-danger'>{errors?.mailingState?.message}</span>
												}
											</div>
											<div className="form-group col-6">
												<label htmlFor="mailingZip">Mailling Zip/Postal Code</label>
												<Controller
													name={"mailingZip"}
													control={control}
													render={({ field: { value, onChange, onBlur, name } }) => (
														<>
															<input
																type="text"
																className={"form-control " + (errors?.mailingZip ? ' border-danger' : '')}
																id="mailingZip"
																name={name}
																onChange={onChange}
																value={value}
																onBlur={onBlur}
																placeholder="Enter billing zip"
															/>
														</>
													)}
												/>
												{errors && errors.mailingZip &&
													<span className='text-danger'>{errors?.mailingZip?.message}</span>
												}
											</div>
											<div className="form-group col-6">
												<label htmlFor="mailingCountry">Mailling Country</label>
												<Controller
													name={"mailingCountry"}
													control={control}
													render={({ field: { value, onChange, onBlur, name } }) => (
														<>
															<Select
																className={"select-box " + (errors?.mailingCountry ? ' border-danger' : '')}
																id="mailingCountry"
																options={countriesOptions ? countriesOptions : []}
																placeholder={"Select country"}
																onChange={onChange}
																value={value}
																defaultValue={value}
															/>
														</>
													)}
												/>
												{/* {errors && errors.mailingCountry &&
                                                            <span className='text-danger'>{errors?.mailingCountry?.message}</span>
                                                        } */}
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="col-12">
								<div className='align-items-center d-flex gap-10 justify-content-center'>
									<button type='submit' className='btn btn-primary'>Save</button>
									<button
										type='button'
										className='btn btn-outline-danger'
										onClick={handleClose}
									>
										Cancel
									</button>
								</div>
							</div>
						</form>
					</div>
				</Modal.Body>
			</Modal>
		</React.Fragment>
	)
}

export default AddContactModal