import React, { useEffect, useState } from 'react'
import { usePlatformApi } from '../../_common/hooks/api/PlatformApiHook';
import { useAppLoader } from '../../_common/hooks/common/appLoaderHook';
import { useUserDetailsSelector } from '../../_common/hooks/selectors/userSelector';
import { generateYearList } from '../../_common/functions';
import Select from 'react-select';
import { useCommonApi } from '../../_common/hooks/api/common/commonApiHook';
import { monthQuarterOptions, openCloseFilterData, quoteStatusData } from '../../_common/staticData';
import { accntListFilterStausData } from '../../_common/staticData';

// interface SelectedYear {
//     accntName: string;
// }

interface IncomingProps {
    type: string;
    filterDetails: any;
    setFilterDetails: any;
    setDoFilter?: any;
    callbackFunctions?: any[];
    defaultYear?: boolean;
}

const FilterRow = ({ type, setDoFilter, filterDetails, setFilterDetails, callbackFunctions, defaultYear = false }: IncomingProps) => {

    const platformApi = usePlatformApi();
    const commonApi = useCommonApi();
    const { showLoader, hideLoader } = useAppLoader();
    const userDetails: any = useUserDetailsSelector();

    const [arrUserOption, setArruserOption] = useState<any>(null)
    const [regionOption, setRegionOption] = useState<any>(null)
    const [yearOptions, setYearOptions] = useState<any>(null)
    const [openCloseOptions, setOpenCloseOptions] = useState<any | []>([null])
    const [selectedRepValue, setSelectedRepValue] = useState<any | []>([])
    const [selectedRegionValue, setSelectedRegionValue] = useState<any | []>([])

    const getArrUserData = () => {
        showLoader();
        platformApi.getAllArrUsers({},
            (message: string, resp: any) => {
                const tempList = resp.data.list.data;
                if (tempList.length > 0) {
                    setArruserOption(tempList.map((item: any) => {
                        item.label = item.name
                        item.value = item.id
                        return item
                    }));
                }
                hideLoader();
            }, (message: string) => {
                // setLoading(false);
                hideLoader();
            })
    }

    const getRegionsData = () => {
        showLoader();
        commonApi.getAllRegions({},
            (message: string, resp: any) => {
                const tempList = resp.data.list.data;
                if (tempList.length > 0) {
                    setRegionOption(tempList.map((item: any) => {
                        item.label = item.region_name
                        item.value = item.Region
                        return item
                    }));
                }
                hideLoader();
            }, (message: string) => {
                // setLoading(false);
                hideLoader();
            })
    }

    const handleFilterChange = (key: any, data: any) => {
        let params: any = filterDetails;
        if (params.hasOwnProperty(`${key}`)) {
            if (key == 'rep' || key == 'region') {
                let tempData = "";
                data?.forEach((item: any, idx: number) => tempData += (key == 'rep' ? item.id : item.Region) + (data?.length - 1 != idx ? "," : ""))
                params[key] = tempData
                setFilterDetails(params)
                if (type == 'upside') {
                    setDoFilter(true)
                } else if (type == 'forecast' || type == 'quoting-management' || type == 'account-list') {
                    callbackFunctions?.forEach((func: any) => {
                        func();
                    });
                } else if (type == 'revenue' || type == 'contract-page') {
                    setDoFilter(true)
                    callbackFunctions?.forEach((func: any) => {
                        func();
                    });
                }
            } else {
                params[key] = data;
                setFilterDetails(params)
                if (type == 'upside') {
                    setDoFilter(true)
                } else if (type == 'forecast' || type == 'quoting-management' || type == 'account-list') {
                    callbackFunctions?.forEach((func: any) => {
                        func();
                    });
                } else if (type == 'revenue' || type == 'contract-page') {
                    setDoFilter(true)
                    callbackFunctions?.forEach((func: any) => {
                        func();
                    });
                }
            }
        } else {
            if (key == 'rep' || key == 'region') {
                let tempData = "";
                data?.forEach((item: any, idx: number) => tempData += (key == 'rep' ? item.id : item.Region) + (data?.length - 1 != idx ? "," : ""))
                params[key] = tempData
                setFilterDetails(params)
                if (type == 'upside') {
                    setDoFilter(true)
                } else if (type == 'forecast' || type == 'quoting-management' || type == 'account-list') {
                    callbackFunctions?.forEach((func: any) => {
                        func();
                    });
                } else if (type == 'revenue' || type == 'contract-page') {
                    setDoFilter(true)
                    callbackFunctions?.forEach((func: any) => {
                        func();
                    });
                }
            } else {
                params[key] = data;
                setFilterDetails(params)
                if (type == 'upside') {
                    setDoFilter(true)
                } else if (type == 'forecast' || type == 'quoting-management' || type == 'account-list') {
                    callbackFunctions?.forEach((func: any) => {
                        func();
                    });
                } else if (type == 'revenue' || type == 'contract-page') {
                    setDoFilter(true)
                    callbackFunctions?.forEach((func: any) => {
                        func();
                    });
                }
            }
        }
    }

    const handleSelectedValue = (key: any, data: any) => {
        if (key == 'rep') {
            setSelectedRepValue(data)
        } else {
            setSelectedRegionValue(data)
        }
    }

    const onRepMenuClose = () => {
        handleFilterChange('rep', selectedRepValue)
        // if (selectedRepValue.length > 0) {
        // }
    }

    const onRegionMenuClose = () => {
        handleFilterChange('region', selectedRegionValue)
        // if (selectedRegionValue.length > 0) {
        // }
    }

    const renderOption = ({ innerProps, label, isSelected }: any) => {
        return (
            <div>
                <div {...innerProps}
                    className=''
                >
                    <input
                        type="checkbox"
                        checked={isSelected}
                        onChange={(e) => { }}
                        className='ml-2 mr-2'
                    />
                    <label>{label}</label>
                </div>
            </div>
        );
    }

    useEffect(() => {
        getArrUserData()
        getRegionsData()
        setYearOptions(generateYearList())
    }, [])

    useEffect(() => {
        if (type && type == 'account-list') {
            setOpenCloseOptions(accntListFilterStausData)
        } else if (type && type == 'quoting-management') {
            setOpenCloseOptions(quoteStatusData)
        }
        else {
            setOpenCloseOptions(openCloseFilterData)
        }
    }, [type])

    useEffect(() => {
        if (defaultYear && yearOptions && yearOptions.length > 0) {
            const currentYear = new Date().getFullYear();
            handleFilterChange("year", currentYear)
        }
    }, [defaultYear, yearOptions])

    return (
        <div className="row">
            {
                userDetails && userDetails?.userType == "primary" ?
                    <div className="col">
                        <div className="form-group">
                            <label htmlFor="formrow-inputOwner">
                                {type && type == 'forecast' ? 'Rep' : 'Account Owner'}
                            </label>
                            <Select
                                className="select-box"
                                options={arrUserOption ? arrUserOption : []}
                                placeholder={"account owner"}
                                onChange={(e: any) => handleSelectedValue("rep", e)}
                                isClearable={true}
                                isMulti={true}
                                components={{ Option: renderOption }}
                                closeMenuOnSelect={false}
                                hideSelectedOptions={false}
                                onMenuClose={onRepMenuClose}
                            />
                        </div>
                    </div>
                    : null
            }
            <div className="col">
                <div className="form-group">
                    <label htmlFor="formrow-inputRegion">Region</label>
                    <Select
                        className="select-box"
                        options={regionOption ? regionOption : []}
                        placeholder={"region"}
                        isClearable={true}
                        isMulti={true}
                        components={{ Option: renderOption }}
                        onChange={(e: any) => handleSelectedValue("region", e)}
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        onMenuClose={onRegionMenuClose}
                    />
                </div>
            </div>
            <div className="col-lg-5 col-12">
                <div className="form-group">
                    <label htmlFor="formrow-inputDate">
                        {type && type == 'forecast' ? 'Fiscal period' : 'Available To Renew Date'}
                    </label>
                    <div className="row">
                        <Select
                            className="select-box col-6"
                            options={yearOptions ? yearOptions : []}
                            placeholder={"year"}
                            onChange={(e: any) => handleFilterChange("year", e?.value)}
                            isClearable={false}
                            defaultValue={defaultYear ? { label: new Date().getFullYear(), value: new Date().getFullYear() } : null}
                        />
                        <Select
                            className="select-box col-6"
                            options={monthQuarterOptions ? monthQuarterOptions : []}
                            placeholder={"quarter / month "}
                            onChange={(e: any) => handleFilterChange("quarter", e?.value)}
                            isClearable={true}
                        />
                    </div>
                </div>
            </div>
            <div className="col">
                <div className="form-group">
                    <label htmlFor="formrow-inputRegion">
                        {type == 'quoting-management' ? 'Status' : 'Active/ Expired'}
                    </label>
                    <Select
                        className="select-box"
                        options={openCloseOptions ? openCloseOptions : []}
                        placeholder={type == 'quoting-management' ? 'status' : 'active / expired'}
                        onChange={(e: any) => handleFilterChange("Status", e?.value)}
                        isClearable={true}
                    />
                </div>
            </div>
        </div>
    )
}

export default FilterRow