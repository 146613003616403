import { useCommonApi } from "../api/common/commonApiHook";

export function useCommonFunction() {
    const commonApi = useCommonApi()

    const deleteFile = (id: any) => {
        return new Promise((resolve: any, reject: any) => {
            commonApi.deleteSla({
                id
            }, (message: string, resp: any) => {
                resolve(message)
            }, (message: string) => {
                reject(message);
            });
        })
    }

    return {
        deleteFile
    }
}