import React, { useEffect, useMemo, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Footer from '../components/commonComponents/Footer'
import Header from '../components/commonComponents/Header'
import LeftPanel from '../components/commonComponents/LeftPanel'
import { usePlatformApi } from '../_common/hooks/api/PlatformApiHook'
import { useAppLoader } from '../_common/hooks/common/appLoaderHook'
import { formatNumber } from '../_common/functions'
import TableContainer from '../components/commonComponents/TableContainer'
import DeleteButtonCell from '../components/actionsBtns/DeleteButtonCell'
import moment from 'moment'
import AddLineitemModal from '../components/modals/addLineitem.modal'
import { AiOutlineEdit } from 'react-icons/ai'
import { toast } from 'react-hot-toast'
import { changeString } from '../_common/functions'
import QuoteStatusEditModal from '../components/modals/quoteStatusEdit.modal'

const QuoteDetails = () => {

    const urlParams: any = useParams()
    const platformApi = usePlatformApi()
    const navigate = useNavigate()

    const { showLoader, hideLoader } = useAppLoader()

    const [data, setData] = useState<any>(null)
    const [lineItemData, setLineItemData] = useState<any>([])
    const [rawData, setRawData] = useState<any>(null)
    const [loading, setLoading] = useState<boolean>(false)
    const [showLineitemModel, setShowLineitemModel] = useState<boolean>(false)
    const [lineitemId, setLineitemId] = useState<any>(null)
    const [pricebookId, setPricebookId] = useState<any>(null)
    const [isEditable, setIsEditable] = useState<boolean>(false)
    const [showEditStatusModal, setShowEditStatusModal] = useState<boolean>(false)

    const getQuoteDetails = () => {
        showLoader()
        platformApi.getAllQuote({
            QuoteId: urlParams?.id
        }, (message: string, resp: any) => {
            // console.log(message, resp)
            setData(resp.data.list.data[0])
            hideLoader()
        }, (message: string) => {
            // console.log(message)
            hideLoader()
        })
    }

    const getAllQuoteLineItem = (page = 1) => {
        showLoader();
        setLoading(true);
        platformApi.getAllQuoteLineItem({
            page: page,
            QuoteId: urlParams?.id
        },
            (message: string, resp: any) => {
                // console.log(resp);
                setLoading(false);
                setRawData(resp.data.list);
                setLineItemData(resp.data.list.data);
                hideLoader();
            }, (message: string) => {
                setLoading(false);
                hideLoader();
            })
    }

    const deleteLineItems = (id: any) => {
        return new Promise((resolve: any, reject: any) => {
            platformApi.quoteLineitemDestroy({
                id
            }, (message: string, resp: any) => {
                resolve(message)
            }, (message: string) => {
                reject(message);
            });
        })
    }

    const getQuotePdf = (id: any) => {
        showLoader();
        setLoading(true);
        platformApi.getQuotePdf({ id },
            (message: string, resp: any) => {
                // console.log(resp);
                window.open(resp.data.link, '_blank');
                setLoading(false);
                hideLoader();
            }, (message: string) => {
                setLoading(false);
                hideLoader();
            })
    }

    const handleShowLineitemModel = (isEdit: boolean, lineItemId?: any) => {
        setShowLineitemModel(true)
        setLineitemId(lineItemId)
        setIsEditable(isEdit)
    }

    const handleCloseLineitemModal = () => {
        setShowLineitemModel(false)
        setLineitemId(null)
        setPricebookId(null)
        setIsEditable(false)
    }

    const submitforApproval = () => {
        showLoader()
        let params: any = {
            "quoteid": data.id,
        }
        console.log(params)
        platformApi.addApprovalForQuote(params, (message: string, resp: any) => {
            console.log(message, resp)
            // toast.success(message)
            if (resp.data.details && resp.data.details.list && resp.data.details.list.length > 0) {
                toast((t) => (
                    <span
                        style={{
                            minWidth: "280px"
                        }}
                    >
                        <div className='align-items-center d-flex font-size-20 gap-10 justify-content-center text-center text-success'>
                            <i className='bx bxs-check-circle font-size-22' />
                            {message}
                        </div>
                        {resp.data.details.list?.map((item: any) =>
                            <div className='bg-light mb-2 px-3 py-2'>
                                <h4>{item.approverobject && item.approverobject.user ? item.approverobject.user?.full_name : ""}</h4>
                                <p className='mb-0'>{item.approverobject && item.approverobject.user && item.approverobject.user.designation ? item?.approverobject?.user?.designation?.name : ""}</p>
                            </div>
                        )}
                        <button
                            className='btn btn-success d-flex ml-auto mr-auto mt-2'
                            onClick={() => toast.dismiss(t.id)}
                        >
                            OK
                        </button>
                    </span>
                ), { duration: 5000 });
            }
            hideLoader()
            getQuoteDetails()
        }, (message: string) => {
            // console.log(message)
            toast.error(message)
            hideLoader()
        })
    }

    const EditBtn = ({ column, value, quoteId }: any) => {
        return (
            <AiOutlineEdit
                onClick={() => handleShowLineitemModel(true, value)}
                className="cursor-pointer mr-2"
                data-toggle="tooltip" data-placement="top" title="Edit"
            />
        )
    }

    const confirmQuoteStatusEvent = (status: string, params?: any) => {
        // console.log('change data', data);
        toast(
            t => (
                <span>
                    Are you sure you want to change quote status to {status} ?
                    <br />
                    <hr />
                    <div className="d-flex align-items-center justify-content-end">
                        <button
                            className="btn btn-danger btn-sm m-1"
                            onClick={() => {
                                toast.dismiss(t.id);
                                let payload = params ? params : { id: data?.id, Status: status };
                                showLoader()
                                platformApi.manageQuote(
                                    payload,
                                    (message: string, resp: any) => {
                                        // console.log(message, resp)
                                        toast.success(message);
                                        getQuoteDetails();
                                        getAllQuoteLineItem();
                                        hideLoader();
                                    },
                                    (message: string) => {
                                        // console.log(message)
                                        toast.error(message);
                                        hideLoader();
                                    },
                                );
                            }}>
                            Yes
                        </button>

                        <button
                            className="btn btn-secondary btn-sm m-1"
                            onClick={() => toast.dismiss(t.id)}>
                            No
                        </button>
                    </div>
                </span>
            ),
            { duration: 15000, className: 'withdraw-toast' },
        );
    };

    const columns = useMemo(
        () => [
            {
                Header: 'Actions',
                accessor: "QuoteLineItemId",
                Cell: ({ column, value, row }: any) =>
                    <>
                        <DeleteButtonCell
                            column={column}
                            value={row.original.id}
                            deleteFunc={deleteLineItems}
                            callbackFunc={[getAllQuoteLineItem, getQuoteDetails]}
                            type={"Lineitem"}
                        />
                        <EditBtn column={column} value={value} quoteId={urlParams?.id} />
                    </>,
            },
            {
                Header: "Product Name",
                accessor: "product.Name",
            },
            {
                Header: "List Price ($)",
                accessor: "ListPrice",
                Cell: ({ column, value, row }: any) => (
                    <div>
                        {value ? formatNumber(value) : '0.00'}
                    </div>
                )
            },
            {
                Header: "Quantity",
                accessor: "Quantity",
            },
            {
                Header: "Sales Price ($)",
                accessor: "UnitPrice",
                Cell: ({ column, value, row }: any) => (
                    <div>
                        {value ? formatNumber(value) : '0.00'}
                    </div>
                )
            },
            {
                Header: "Discount (%)",
                accessor: "Discount",
                Cell: ({ column, value, row }: any) => (
                    <div>
                        {value ? formatNumber(value) : '0.00'}
                    </div>
                )
            },
            {
                Header: "Tax (%)",
                accessor: "taxper",
                Cell: ({ column, value, row }: any) => (
                    <div>
                        {value ? formatNumber(value) : '0.00'}
                    </div>
                )
            },
            {
                Header: "Total ($)",
                accessor: "TotalPrice",
                Cell: ({ column, value, row }: any) => (
                    <div>
                        {value ? formatNumber(value) : '0.00'}
                    </div>
                )
            },
        ],
        []
    )

    const closeEditStatusModal = () => {
        setShowEditStatusModal(false)
    }

    useEffect(() => {
        if (urlParams && urlParams.id) {
            getQuoteDetails()
            getAllQuoteLineItem()
        }
    }, [urlParams])

    useEffect(() => {
        if (!showLineitemModel && lineItemData && lineItemData.length > 0) {
            setPricebookId(lineItemData[0]?.pricebookentity?.Pricebook2Id)
        } else if (!showLineitemModel && lineItemData && lineItemData.length == 0) {
            setPricebookId(null)
        }
    }, [lineItemData, showLineitemModel])

    return (
        <React.Fragment>
            <AddLineitemModal
                show={showLineitemModel}
                close={handleCloseLineitemModal}
                quoteId={urlParams?.id}
                lineItemId={lineitemId}
                isEdit={isEditable}
                pricebookId={pricebookId}
                getQuoteDetails={getQuoteDetails}
                getAllQuoteLineItem={getAllQuoteLineItem}
            />
            <QuoteStatusEditModal
                show={showEditStatusModal}
                close={closeEditStatusModal}
                quoteData={data}
                confirmQuoteStatusEvent={confirmQuoteStatusEvent}
            />
            <div id="layout-wrapper">
                <Header />
                <LeftPanel />
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            {/* start page title */}
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-flex align-items-center justify-content-between">
                                        <div>
                                            <h4 className="mb-0 font-size-18">Quote details</h4>
                                        </div>
                                        <div className="page-title-right">
                                            <ol className="breadcrumb m-0">
                                                <li className="breadcrumb-item">
                                                    <Link to={`/account-view/${data?.AccountId}`}>Account Details</Link>
                                                </li>
                                                <li className="breadcrumb-item active">Quote details</li>
                                            </ol>
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-end mb-3 gap-10'>
                                        {/* <button
                                            type='button'
                                            className="btn btn-success"
                                            onClick={() => setShowQuoteSignatureModal(true)}
                                        >
                                            Add Signature
                                        </button> */}
                                        <Link
                                            to={`/account-view/${data?.AccountId}`}
                                            className="btn btn-success">
                                            Go To Account
                                        </Link>
                                        {data && data.approval && data.approval.length === 0 ?
                                            <button
                                                type='button'
                                                className="btn btn-success"
                                                onClick={submitforApproval}
                                            // onClick={() => setShowQuoteApprovalModal(true)}
                                            >
                                                Submit for Approval
                                            </button>
                                            :
                                            null
                                        }
                                        <Link
                                            to={`/add-quote/${data?.AccountId}?type=AccountId&id=${urlParams?.id}`}
                                            className="btn btn-success">
                                            Edit Quote
                                        </Link>
                                        <button
                                            type='button'
                                            className="btn btn-success"
                                            onClick={() => getQuotePdf(data?.id)}
                                        >
                                            Export to pdf
                                        </button>
                                    </div>
                                </div>
                            </div>
                            {/* end page title */}
                            <div className="row">

                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <h5 className="card-title text-center mb-3">Quote Status</h5>
                                            <div className="">
                                                <div className="progress-container">
                                                    <div className='renewal-evnt'>
                                                        <div
                                                            className={'progress-circle ' + (
                                                                ["Draft", "Needs Review",
                                                                    "In Review", "Approved",
                                                                    "Rejected", "Presented",
                                                                    "Accepted", "Denied"].includes(data?.Status) ? 'active' : 'cursor-pointer'
                                                            )}
                                                            onClick={() =>
                                                                !["Draft", "Needs Review",
                                                                    "In Review", "Approved",
                                                                    "Rejected", "Presented",
                                                                    "Accepted", "Denied"].includes(data?.Status) ?
                                                                    confirmQuoteStatusEvent('Draft')
                                                                    : null
                                                            }
                                                            data-toggle="tooltip"
                                                            data-placement="bottom"
                                                            title="Draft">
                                                            1
                                                        </div>
                                                    </div>
                                                    <div className='renewal-evnt'>
                                                        <div
                                                            className={'progress-circle ' + (
                                                                ["Needs Review",
                                                                    "In Review", "Approved",
                                                                    "Rejected", "Presented",
                                                                    "Accepted", "Denied"].includes(data?.Status) ? 'active' : 'cursor-pointer'
                                                            )}
                                                            onClick={() =>
                                                                !["Needs Review",
                                                                    "In Review", "Approved",
                                                                    "Rejected", "Presented",
                                                                    "Accepted", "Denied"].includes(data?.Status) ?
                                                                    confirmQuoteStatusEvent('Needs Review')
                                                                    : null
                                                            }
                                                            data-toggle="tooltip"
                                                            data-placement="top"
                                                            title="Needs Review">
                                                            2
                                                        </div>
                                                    </div>
                                                    <div className='renewal-evnt'>
                                                        <div
                                                            className={'progress-circle ' + (
                                                                ["In Review", "Approved",
                                                                    "Rejected", "Presented",
                                                                    "Accepted", "Denied"].includes(data?.Status) ? 'active' : 'cursor-pointer'
                                                            )}
                                                            onClick={() =>
                                                                !["In Review", "Approved",
                                                                    "Rejected", "Presented",
                                                                    "Accepted", "Denied"].includes(data?.Status) ?
                                                                    confirmQuoteStatusEvent('In Review')
                                                                    : null
                                                            }
                                                            data-toggle="tooltip"
                                                            data-placement="top"
                                                            title="In Review">
                                                            3
                                                        </div>
                                                    </div>
                                                    <div className='renewal-evnt'>
                                                        <div
                                                            className={'progress-circle ' + (
                                                                ["Approved",
                                                                    "Rejected", "Presented",
                                                                    "Accepted", "Denied"].includes(data?.Status) ? 'active' : 'cursor-pointer'
                                                            )}
                                                            onClick={() =>
                                                                !["Approved",
                                                                    "Rejected", "Presented",
                                                                    "Accepted", "Denied"].includes(data?.Status) ?
                                                                    confirmQuoteStatusEvent('Approved')
                                                                    : null
                                                            }
                                                            data-toggle="tooltip"
                                                            data-placement="top"
                                                            title="Approved">
                                                            4
                                                        </div>
                                                    </div>
                                                    <div className='renewal-evnt'>
                                                        <div
                                                            className={'progress-circle ' + (
                                                                ["Rejected", "Presented",
                                                                    "Accepted", "Denied"].includes(data?.Status) ? 'active' : 'cursor-pointer'
                                                            )}
                                                            onClick={() =>
                                                                !["Rejected", "Presented",
                                                                    "Accepted", "Denied"].includes(data?.Status) ?
                                                                    confirmQuoteStatusEvent('Rejected')
                                                                    : null
                                                            }
                                                            data-toggle="tooltip"
                                                            data-placement="top"
                                                            title="Rejected">
                                                            5
                                                        </div>
                                                    </div>
                                                    <div className='renewal-evnt'>
                                                        <div
                                                            className={'progress-circle ' + (
                                                                ["Presented",
                                                                    "Accepted", "Denied"].includes(data?.Status) ? 'active' : 'cursor-pointer'
                                                            )}
                                                            onClick={() =>
                                                                !["Presented",
                                                                    "Accepted", "Denied"].includes(data?.Status) ?
                                                                    confirmQuoteStatusEvent('Presented')
                                                                    : null
                                                            }
                                                            data-toggle="tooltip"
                                                            data-placement="top"
                                                            title="Presented">
                                                            6
                                                        </div>
                                                    </div>
                                                    <div className='renewal-evnt'>
                                                        <div
                                                            className={'progress-circle ' + (
                                                                ["Accepted", "Denied"].includes(data?.Status) ? 'active' : 'cursor-pointer'
                                                            )}
                                                            onClick={() =>
                                                                !["Accepted", "Denied"].includes(data?.Status) ?
                                                                    setShowEditStatusModal(true)
                                                                    : null
                                                            }
                                                            // onClick={() =>
                                                            //     !["Accepted", "Denied"].includes(data?.Status) ?
                                                            //         confirmQuoteStatusEvent('Accepted')
                                                            //         : null
                                                            // }
                                                            data-toggle="tooltip"
                                                            data-placement="top"
                                                            title="Accepted">
                                                            7
                                                        </div>
                                                    </div>
                                                    <div className='renewal-evnt'>
                                                        <div
                                                            className={'progress-circle ' + (
                                                                ["Denied"].includes(data?.Status) ? 'active' : 'cursor-pointer'
                                                            )}
                                                            onClick={() =>
                                                                !["Denied"].includes(data?.Status) ?
                                                                    confirmQuoteStatusEvent('Denied')
                                                                    : null
                                                            }
                                                            data-toggle="tooltip"
                                                            data-placement="top"
                                                            title="Denied">
                                                            8
                                                        </div>
                                                    </div>
                                                </div>
                                            </div >
                                        </div >
                                    </div >
                                </div >

                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="d-flex flex-wrap">
                                                <h5 className="card-title mr-2">Quote Information</h5>
                                            </div>
                                            <div className="row">
                                                <div className="col-6">
                                                    <label className='mr-2' htmlFor="quoteName">Quote name :</label>
                                                    <strong>{data?.Name ? data?.Name : ""}</strong>
                                                </div>
                                                <div className="col-6">
                                                    <label className='mr-2' htmlFor="expDate">Expiration Date :</label>
                                                    <strong>{data?.ExpirationDate ? moment(data?.ExpirationDate).format("MM/DD/YY") : ""}</strong>
                                                </div>
                                                <div className="col-6">
                                                    <label className='mr-2' htmlFor="accntName">Account Name :</label>
                                                    {data?.account ?
                                                        <strong
                                                            className='cursor-pointer'
                                                            onClick={() => navigate(`/account-view/${data?.account?.AccountId}`)}
                                                        >
                                                            {data?.account ? data?.account?.Name : ""}
                                                        </strong>
                                                        :
                                                        ""
                                                    }
                                                </div>
                                                <div className="col-6">
                                                    <label className='mr-2' htmlFor="ContractName">Contract Number :</label>
                                                    {data?.contract ?
                                                        <strong
                                                            className='cursor-pointer'
                                                            onClick={() => navigate(`/contract-details/${data?.contract?.ContractId}`)}
                                                        >
                                                            {data?.contract ? data?.contract?.ContractNumber : ""}
                                                        </strong>
                                                        : ""
                                                    }
                                                </div>
                                                <div className="col-6">
                                                    <label className='mr-2' htmlFor="ContractName">Contract Start Date :</label>
                                                    <strong>{data?.contract && data?.contract?.StartDate ? moment(data?.contract?.StartDate).format('MM/DD/YY') : ""}</strong>
                                                </div>
                                                <div className="col-6">
                                                    <label className='mr-2' htmlFor="ContractName">Contract End Date :</label>
                                                    <strong>{data?.contract && data?.contract?.EndDate ? moment(data?.contract?.EndDate).format('MM/DD/YY') : ""}</strong>
                                                </div>
                                                <div className="col-6">
                                                    <label className='mr-2' htmlFor="ContractName">Partner Name :</label>
                                                    <strong>{data?.partner ? data?.partner?.name : ""}</strong>
                                                </div>
                                                <div className="col-6">
                                                    <label className='mr-2' htmlFor="status">Status :</label>
                                                    <strong>{data?.Status ? data?.Status : ""}</strong>
                                                </div>
                                                {/* <div className="col-6">
                                                    <label className='mr-2' htmlFor="status">Approval Status :</label>
                                                    <strong>{data?.approval ? data?.approval[0]?.status : ""}</strong>
                                                </div>
                                                <div className="col-6">
                                                    <label className='mr-2' htmlFor="description">Approval Date :</label>
                                                    <strong>{data?.approval && data.approval.length > 0 ? moment(data?.approval[0]?.updated_at).format('MM/DD/YY') : ""}</strong>
                                                </div>
                                                <div className="col-6">
                                                    <label className='mr-2' htmlFor="description">Approver Name :</label>
                                                    <strong>{data?.approval && data.approval.length > 0 && data.approval[0]?.approverobject ? data?.approval[0]?.approverobject?.approvername : ""}</strong>
                                                </div> */}
                                                <div className="col-6">
                                                    <label className='mr-2' htmlFor="description">Description :</label>
                                                    <strong>{data?.Description ? data?.Description : ""}</strong>
                                                </div>
                                                <div className="col-6">
                                                    <label className='mr-2' htmlFor="description">Pdf :</label>
                                                    <a
                                                        href={data?.sla?.basepath}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        {data?.sladoc?.file_name_original}
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {data && data.approval && data.approval.length > 0 ?
                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="d-flex flex-wrap">
                                                    <h5 className="card-title mr-2">Approver List</h5>
                                                </div>
                                                <div className="row">
                                                    <div className="col-3">
                                                        <label className='mr-2' htmlFor="subTotal">Approver Name </label>
                                                    </div>
                                                    <div className="col-3">
                                                        <label className='mr-2' htmlFor="subTotal">Status </label>
                                                    </div>
                                                    <div className="col-3">
                                                        <label className='mr-2' htmlFor="subTotal">Comment </label>
                                                    </div>
                                                    <div className="col-3">
                                                        <label className='mr-2' htmlFor="subTotal">Updated At </label>
                                                    </div>
                                                </div>
                                                {data && data.approval && data.approval.length > 0 ?
                                                    data.approval?.map((item: any) =>
                                                        <div className="row mt-2">
                                                            <div className="col-3">
                                                                <div className='mr-2'>
                                                                    <strong>
                                                                        {item.user ? item.user?.full_name : ""}
                                                                    </strong>
                                                                </div>
                                                            </div>
                                                            <div className="col-3">
                                                                <div className='mr-2'>
                                                                    <strong>
                                                                        {item.status ? item?.status : ""}
                                                                    </strong>
                                                                </div>
                                                            </div>
                                                            <div className="col-3">
                                                                <div className='mr-2'>
                                                                    <strong
                                                                        data-toggle="tooltip"
                                                                        data-placement="top"
                                                                        title={item?.comment}
                                                                    >
                                                                        {item.comment ? changeString(item?.comment, 25) + " ..." : ""}
                                                                    </strong>
                                                                </div>
                                                            </div>
                                                            <div className="col-3">
                                                                <div className='mr-2'>
                                                                    <strong>
                                                                        {item.updated_at ? moment(item.updated_at).format('MM/DD/YY') : ""}
                                                                    </strong>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                    :
                                                    null}
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    null
                                }

                                <div className="col-12 d-none">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="d-flex flex-wrap">
                                                <h5 className="card-title mr-2">Totals</h5>
                                            </div>
                                            <div className="row">
                                                <div className="col-6">
                                                    <label className='mr-2' htmlFor="subTotal">Sub Total ($) :</label>
                                                    <strong>{data?.Subtotal ? formatNumber(data?.Subtotal) : ""}</strong>
                                                </div>
                                                <div className="col-6">
                                                    <label className='mr-2' htmlFor="tax">Tax (%) :</label>
                                                    <strong>{data?.Tax ? data?.Tax : ""}</strong>
                                                </div>
                                                <div className="col-6">
                                                    <label className='mr-2' htmlFor="discount">Discount (%) :</label>
                                                    <strong>{data?.Discount ? data?.Discount : ""}</strong>
                                                </div>
                                                <div className="col-6">
                                                    <label className='mr-2' htmlFor="shipping">Shipping and Handling ($) :</label>
                                                    <strong>{data?.ShippingCharge ? formatNumber(data?.ShippingCharge) : ""}</strong>
                                                </div>
                                                <div className="col-6">
                                                    <label className='mr-2' htmlFor="totalPrice">Total Price ($) :</label>
                                                    <strong>{data?.TotalPrice ? formatNumber(data?.TotalPrice) : ""}</strong>
                                                </div>
                                                <div className="col-6">
                                                    <label className='mr-2' htmlFor="grandTotal">Grand Total ($) :</label>
                                                    <strong>{data?.GrandTotal ? formatNumber(data?.GrandTotal) : ""}</strong>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="d-flex flex-wrap">
                                                <h5 className="card-title mr-2">Prepared For</h5>
                                            </div>
                                            <div className="row">
                                                <div className="col-6">
                                                    <label className='mr-2' htmlFor="contactName">Contact Name :</label>
                                                    <strong>{data?.contact ? data?.contact?.FirstName + " " + data?.contact?.LastName : ""}</strong>
                                                </div>
                                                <div className="col-6">
                                                    <label className='mr-2' htmlFor="phone">Phone :</label>
                                                    <strong>{data?.Phone ? data?.Phone : ""}</strong>
                                                </div>
                                                <div className="col-6">
                                                    <label className='mr-2' htmlFor="email">Email :</label>
                                                    <strong>{data?.Email ? data?.Email : ""}</strong>
                                                </div>
                                                <div className="col-6">
                                                    <label className='mr-2' htmlFor="fax">Fax :</label>
                                                    <strong>{data?.Fax ? data?.Fax : ""}</strong>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="d-flex flex-wrap">
                                                <h5 className="card-title mr-2">Address Information</h5>
                                            </div>
                                            <div className="row">
                                                <div className="col-6">
                                                    <label className='mr-2' htmlFor="billingName">Bill To Name :</label>
                                                    <strong>{data?.BillingName ? data?.BillingName : ""}</strong>
                                                </div>
                                                <div className="col-6">
                                                    <label className='mr-2' htmlFor="shippingName">Ship To Name :</label>
                                                    <strong>{data?.ShippingName ? data?.ShippingName : ""}</strong>
                                                </div>
                                                <div className="col-6">
                                                    <label className='mr-2' htmlFor="billingStreet">Bill To Street :</label>
                                                    <strong>{data?.BillingStreet ? data?.BillingStreet : ""}</strong>
                                                </div>
                                                <div className="col-6">
                                                    <label className='mr-2' htmlFor="shippingStreet">Ship To Street :</label>
                                                    <strong>{data?.ShippingStreet ? data?.ShippingStreet : ""}</strong>
                                                </div>
                                                <div className="col-6">
                                                    <label className='mr-2' htmlFor="billingCity">Bill To City :</label>
                                                    <strong>{data?.BillingCity ? data?.BillingCity : ""}</strong>
                                                </div>
                                                <div className="col-6">
                                                    <label className='mr-2' htmlFor="shippingCity">Ship To City :</label>
                                                    <strong>{data?.ShippingCity ? data?.ShippingCity : ""}</strong>
                                                </div>
                                                <div className="col-6">
                                                    <label className='mr-2' htmlFor="billingState">Bill To State/Province :</label>
                                                    <strong>{data?.BillingState ? data?.BillingState : ""}</strong>
                                                </div>
                                                <div className="col-6">
                                                    <label className='mr-2' htmlFor="shippingState">Ship To State/Province :</label>
                                                    <strong>{data?.ShippingState ? data?.ShippingState : ""}</strong>
                                                </div>
                                                <div className="col-6">
                                                    <label className='mr-2' htmlFor="billingZip">Bil To Zip/Postal Code :</label>
                                                    <strong>{data?.BillingPostalCode ? data?.BillingPostalCode : ""}</strong>
                                                </div>
                                                <div className="col-6">
                                                    <label className='mr-2' htmlFor="shippingZip">Ship To Zip/Postal Code :</label>
                                                    <strong>{data?.ShippingPostalCode ? data?.ShippingPostalCode : ""}</strong>
                                                </div>
                                                <div className="col-6">
                                                    <label className='mr-2' htmlFor="billingCountry">Bill To Country :</label>
                                                    <strong>{data?.BillingCountry ? data?.BillingCountry : ""}</strong>
                                                </div>
                                                <div className="col-6">
                                                    <label className='mr-2' htmlFor="shippingCountry">Ship To Country :</label>
                                                    <strong>{data?.ShippingCountry ? data?.ShippingCountry : ""}</strong>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="d-flex flex-wrap justify-content-between">
                                                <h5 className="card-title mr-2">Line Items</h5>
                                                <button
                                                    onClick={() => handleShowLineitemModel(false)}
                                                    className="btn btn-success"
                                                >
                                                    Add Line Items
                                                </button>
                                            </div>
                                            <div className="table-responsive">
                                                <TableContainer
                                                    columns={columns}
                                                    data={lineItemData}
                                                    fetchData={getAllQuoteLineItem}
                                                    loading={loading}
                                                    rawData={rawData}
                                                />
                                            </div>
                                            <div className="align-items-end d-flex flex-column">
                                                {/* <div className="row"> */}
                                                <div className="">
                                                    {/* <div className="col-12"> */}
                                                    <label className='mr-2' htmlFor="subTotal">Sub Total :</label>
                                                    <strong>${data?.Subtotal ? formatNumber(data?.Subtotal) : "0.00"}</strong>
                                                </div>
                                                <div className="">
                                                    {/* <div className="col-12"> */}
                                                    <label className='mr-2' htmlFor="discount">Discount :</label>
                                                    <strong>${data?.Discount ? formatNumber(+data?.Subtotal * +data?.Discount / 100) : "0.00"}</strong>
                                                </div>
                                                <div className="">
                                                    {/* <div className="col-12"> */}
                                                    <label className='mr-2' htmlFor="totalPrice">Total :</label>
                                                    <strong>${data?.TotalPrice ? formatNumber(data?.TotalPrice) : "0.00"}</strong>
                                                </div>
                                                <div className="">
                                                    {/* <div className="col-12"> */}
                                                    <label className='mr-2' htmlFor="tax">Tax :</label>
                                                    <strong>${data?.Tax ? formatNumber(data?.TotalPrice * +data?.Tax / 100) : "0.00"}</strong>
                                                </div>
                                                <div className="">
                                                    {/* <div className="col-12"> */}
                                                    <label className='mr-2' htmlFor="shipping">Shipping and Handling :</label>
                                                    <strong>${data?.ShippingCharge ? formatNumber(data?.ShippingCharge) : "0.00"}</strong>
                                                </div>
                                                <div className="">
                                                    {/* <div className="col-12"> */}
                                                    <label className='mr-2' htmlFor="grandTotal">Grand Total :</label>
                                                    <strong>${data?.GrandTotal ? formatNumber(data?.GrandTotal) : "0.00"}</strong>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <label className='mr-2' htmlFor="shippingCountry">Terms & Conditions:</label>
                                            <div
                                                style={{
                                                    maxHeight: '150px',
                                                    overflowY: 'auto'
                                                }}
                                            >
                                                <strong>{data?.tandc ? data?.tandc : ""}</strong>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="d-flex flex-wrap justify-content-around">
                                                <h5 className="mb-auto mt-auto">Authorized Signature</h5>

                                                <div>
                                                    {data?.signature && data?.signature?.basepath != '' && data.signatureimg ?
                                                        <div>
                                                            <img
                                                                src={data?.signature?.basepath}
                                                                alt=""
                                                                className='bg-soft-secondary'
                                                                style={{ maxHeight: '80px' }}
                                                            />
                                                            <div className='text-center mt-2'>({data?.signature_fullname})</div>
                                                        </div>
                                                        : null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </React.Fragment>
    )
}

export default QuoteDetails