import { type } from 'os';
import { ActionExtended } from '../../../_common/interfaces/ActionExtended';
import { ACTIONS } from '../../../_config'

export interface PlatformReducer {
  salesforceData: any;
}

const initialState: PlatformReducer = {
  salesforceData: null,
};

const platformReducer = (state = initialState, action: ActionExtended) => {
  switch (action.type) {
    case ACTIONS.SALESFORCE.SAVE_DATA:
      let x = state.salesforceData
      if(x)
      {
        Object.assign(x,action.payload)
      }
      else
      {
        x = action.payload
      }
      return {
        ...state,
        salesforceData: x
      };
    case ACTIONS.SALESFORCE.RESET_DATA:
      return {
        ...state,
        salesforceData: null
      };
    default:
      return state;
  }
};

export default platformReducer;
