import React, { useEffect, useMemo, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import moment from 'moment'
import { AiOutlineEdit } from 'react-icons/ai'
import { useAppLoader } from '../../_common/hooks/common/appLoaderHook'
import { useCommonApi } from '../../_common/hooks/api/common/commonApiHook'
import DeleteButtonCell from '../../components/actionsBtns/DeleteButtonCell'
import Header from '../../components/commonComponents/Header'
import LeftPanel from '../../components/commonComponents/LeftPanel'
import { URLS } from '../../_config'
import TableContainer from '../../components/commonComponents/TableContainer'
import Footer from '../../components/commonComponents/Footer'
import AddApproverRuleConditionModal from '../../components/modals/addApproverRuleCondition.modal'
import AddApproverRuleModal from '../../components/modals/addApproverRule.modal'

const ApprovalRulesDetails = () => {

    const urlParams: any = useParams()
    const commonApi = useCommonApi()

    const { showLoader, hideLoader } = useAppLoader()

    const [data, setData] = useState<any>(null)
    const [approverRuleConditionData, setApproverRuleConditionData] = useState<any>([])
    const [rawData, setRawData] = useState<any>(null)
    const [loading, setLoading] = useState<boolean>(false)
    const [showRuleConditionModel, setRuleConditionModel] = useState<boolean>(false)
    const [ruleConditionId, setRuleConditionId] = useState<any>(null)
    const [isEditable, setIsEditable] = useState<boolean>(false)
    const [showAddApproverRuleModal, setShowAddApproverRuleModal] = useState<boolean>(false);
    const [isRuleEdit, setIsRuleEdit] = useState<boolean>(false)
    const [approverRuleId, setApproverRuleId] = useState<any>(null)

    const getApprovalRulesDetails = () => {
        showLoader()
        commonApi.getAllApproverRule({
            id: urlParams?.id
        }, (message: string, resp: any) => {
            // console.log(message, resp)
            setData(resp.data.list)
            hideLoader()
        }, (message: string) => {
            // console.log(message)
            hideLoader()
        })
    }

    const getAllApproverRuleCondition = (page = 1) => {
        showLoader();
        setLoading(true);
        commonApi.getAllApproverRuleCondition({
            page: page,
            rules: urlParams?.id
        },
            (message: string, resp: any) => {
                // console.log(resp);
                setLoading(false);
                setRawData(resp.data.list);
                setApproverRuleConditionData(resp.data.list.data);
                hideLoader();
            }, (message: string) => {
                setLoading(false);
                hideLoader();
            })
    }

    const deleteApproverRuleCondition = (id: any) => {
        return new Promise((resolve: any, reject: any) => {
            commonApi.deleteApproverRuleCondition({
                id
            }, (message: string, resp: any) => {
                resolve(message)
            }, (message: string) => {
                reject(message);
            });
        })
    }

    const handleShowRulesConditionModel = (isEdit: boolean, conditionId?: any) => {
        setRuleConditionModel(true)
        setRuleConditionId(conditionId)
        setIsEditable(isEdit)
    }

    const handleCloseRulesConditionModal = () => {
        setRuleConditionModel(false)
        setRuleConditionId(null)
        setIsEditable(false)
    }

    const openAddApproverRuleModal = () => {
        setShowAddApproverRuleModal(true)
        setIsRuleEdit(true)
        setApproverRuleId(urlParams?.id)
    }

    const closeAddApproverRuleModal = () => {
        setShowAddApproverRuleModal(false)
        setIsRuleEdit(false)
        setApproverRuleId(null)
        getApprovalRulesDetails()
    }

    const EditBtn = ({ column, value }: any) => {
        return (
            <AiOutlineEdit
                onClick={() => handleShowRulesConditionModel(true, value)}
                className="cursor-pointer mr-2"
                data-toggle="tooltip" data-placement="top" title="Edit"
            />
        )
    }

    const columns = useMemo(
        () => [
            {
                Header: 'Actions',
                accessor: "id",
                Cell: ({ column, value, row }: any) =>
                    <>
                        <DeleteButtonCell
                            column={column}
                            value={row.original.id}
                            deleteFunc={deleteApproverRuleCondition}
                            callbackFunc={[getAllApproverRuleCondition]}
                            type={"Condition"}
                        />
                        <EditBtn column={column} value={value} />
                    </>,
            },
            {
                Header: "Condition Name",
                accessor: "conditionname",
            },
            {
                Header: "Target Field",
                accessor: "targetfield",
            },
            {
                Header: "Field Type",
                accessor: "fieldtype",
            },
            {
                Header: "Operator",
                accessor: "operator",
            },
            {
                Header: "Rule",
                accessor: "ruleobject.rulename",
            },
        ],
        []
    )

    useEffect(() => {
        if (urlParams && urlParams.id && !showRuleConditionModel) {
            getApprovalRulesDetails()
            getAllApproverRuleCondition()
        }
    }, [urlParams, showRuleConditionModel])

    return (
        <React.Fragment>
            <AddApproverRuleConditionModal
                show={showRuleConditionModel}
                close={handleCloseRulesConditionModal}
                approverRuleId={urlParams?.id}
                id={ruleConditionId}
                isEdit={isEditable}
            />
            <AddApproverRuleModal
                show={showAddApproverRuleModal}
                close={closeAddApproverRuleModal}
                id={approverRuleId}
                isEdit={isRuleEdit}
            />
            <div id="layout-wrapper">
                <Header />
                <LeftPanel />
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-flex align-items-center justify-content-between">
                                        <div>
                                            <h4 className="mb-0 font-size-18">Rule details</h4>
                                        </div>
                                        <div className="page-title-right">
                                            <ol className="breadcrumb m-0">
                                                <li className="breadcrumb-item">
                                                    <Link to={URLS.MANAGE_APPROVER_RULE}>Rules</Link>
                                                </li>
                                                <li className="breadcrumb-item active">Rule details</li>
                                            </ol>
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-end mb-3'>
                                        <button
                                            className='btn btn-success'
                                            type='button'
                                            onClick={openAddApproverRuleModal}
                                        >
                                            Edit Rule
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="d-flex flex-wrap">
                                                <h5 className="card-title mr-2">Rule Information</h5>
                                            </div>
                                            <div className="row">
                                                <div className="col-6">
                                                    <label className='mr-2' htmlFor="quoteName">Rule Name :</label>
                                                    <strong>{data?.rulename ? data?.rulename : ""}</strong>
                                                </div>
                                                <div className="col-6">
                                                    <label className='mr-2' htmlFor="expDate">Target Object :</label>
                                                    <strong>{data?.targetobject ? data?.targetobject : ""}</strong>
                                                </div>
                                                <div className="col-6">
                                                    <label className='mr-2' htmlFor="accntName">Approver :</label>
                                                    <strong>{data?.approverobject ? data?.approverobject?.approvername : ""}</strong>
                                                </div>
                                                <div className="col-6">
                                                    <label className='mr-2' htmlFor="ContractName">Step :</label>
                                                    <strong>{data?.step ? data?.step : ""}</strong>
                                                </div>
                                                <div className="col-6">
                                                    <label className='mr-2' htmlFor="ContractName">Status :</label>
                                                    <strong>{data?.status && data?.status == '1' ? 'Active' : 'Inactive'}</strong>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="d-flex flex-wrap justify-content-between">
                                                    <h5 className="card-title mr-2">Condition</h5>
                                                    {approverRuleConditionData && approverRuleConditionData.length === 0 ?
                                                        <button
                                                            onClick={() => handleShowRulesConditionModel(false)}
                                                            className="btn btn-success"
                                                        >
                                                            Add Condition
                                                        </button>
                                                        :
                                                        null
                                                    }
                                                </div>
                                                <div className="table-responsive">
                                                    <TableContainer
                                                        columns={columns}
                                                        data={approverRuleConditionData}
                                                        fetchData={getAllApproverRuleCondition}
                                                        loading={loading}
                                                        rawData={rawData}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer />
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default ApprovalRulesDetails