
let apiBaseUrl = 'https://arrdev-api.dreamztesting.com/api/v1'
let appStage = process.env.REACT_APP_STAGE
if (appStage == 'dev') {
  apiBaseUrl = 'https://arrdev-api.dreamztesting.com/api/v1'
} else if (appStage == 'uat') {
  apiBaseUrl = 'https://arruat-api.dreamztesting.com/api/v1'
} else if (appStage == 'prod') {
  //   apiBaseUrl = ''
}

export const API_BASE_URL = apiBaseUrl;