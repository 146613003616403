import React, { useState } from "react";
import { useAppLoader } from '../_common/hooks/common/appLoaderHook';
import { useUserApi } from "../_common/hooks/api/UserApiHook";
import { Controller, useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { URLS } from "../_config";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

interface LoginFormValues {
	email: string;
	password: string;
}

const validationSchema = Yup.object().shape({
	email: Yup.string().required("Email is required").email("Email is invalid"),
	password: Yup.string().required("Password is required")
});

export default function Login() {

	const { showLoader, hideLoader } = useAppLoader();
	const userApi = useUserApi();
	const navigate = useNavigate();

	const [showPassword, setShowPassword] = useState<boolean>(false)

	const { control, formState: { errors }, handleSubmit, } = useForm<LoginFormValues>({
		resolver: yupResolver(validationSchema),
		defaultValues: {
			email: "",
			password: "",
		},
	});

	const onSubmit = (data: any) => {
		showLoader();
		userApi.login(data, (message: string, resp: any) => {
			// console.log({ resp })
			hideLoader();
			if (resp.data.userType == 'primary') {
				if (resp.data.user.doneregionsetting == '0') {
					navigate(URLS.REGION_SETTINGS)
				} else if (resp.data.user.donebasicsetting == '0') {
					navigate(URLS.BUSINESS_DETAILS)
				} else if (resp.data.connectedPlatform.length > 0 && resp.data.user.doneregionsetting == '1' && resp.data.user.donebasicsetting == '1') {
					navigate(URLS.REVENUE)
				}
			} else if (resp.data.userType == 'secondary') {
				navigate(URLS.REVENUE)
			}
		}, (message: string) => {
			toast.error(message)
			hideLoader();
		});
	}

	return (
		<React.Fragment>
			<div className="account-pages my-5 pt-sm-5 arr-login">
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-md-8 col-lg-6 col-xl-5">
							<div className="card overflow-hidden">
								<div className="bg-soft-primary">
									<div className="row">
										<div className="col-7">
											<div className=" p-4">
												<h5>Log In!</h5>
												<p>Continue to ARR Management</p>
											</div>
										</div>
										<div className="col-5 align-self-end">
											<img
												src="assets/images/profile-img.png"
												alt=""
												className="img-fluid"
											/>
										</div>
									</div>
								</div>
								<div className="card-body pt-0">
									<div>
										<div className="avatar-md profile-user-wid mb-4">
											<span className="avatar-title rounded-circle ">
												<img
													src="assets/images/logo-light.png"
													alt=""
													height={34}
													className="rounded-circle"
												/>
											</span>
										</div>
									</div>
									<div className="p-2">
										<form className="form-horizontal" >
											<div className="form-group">
												<label htmlFor="email">Email
													<span className='text-danger'>*</span>
												</label>
												<Controller
													name={"email"}
													control={control}
													render={({ field: { value, onChange, onBlur, name } }) => (
														<>
															<input
																type="email"
																className={"form-control " + (errors?.email ? ' border-danger' : '')}
																id="email"
																name={name}
																onChange={onChange}
																value={value}
																onBlur={onBlur}
																placeholder="Enter email"
															/>
														</>
													)}
												/>
												{errors && errors.email &&
													<span className='text-danger'>{errors?.email?.message}</span>
												}
											</div>
											<div className="form-group">
												<label htmlFor="password">Password
													<span className='text-danger'>*</span>
												</label>
												<div className="cstm-password">
													<Controller
														name="password"
														control={control}
														render={({ field: { value, onChange, onBlur, name } }) => (
															<>
																<input
																	type={!showPassword ? "password" : "text"}
																	className={"form-control " + (errors?.password ? ' border-danger' : '')}
																	id="password"
																	autoComplete="new-password"
																	name={name}
																	onChange={onChange}
																	value={value}
																	onBlur={onBlur}
																	placeholder="Enter password"
																/>
															</>
														)}
													/>
													{!showPassword ?
														<AiOutlineEyeInvisible onClick={() => setShowPassword(!showPassword)} />
														:
														<AiOutlineEye onClick={() => setShowPassword(!showPassword)} />
													}
												</div>
												{errors && errors.password &&
													<span className='text-danger'>{errors?.password?.message}</span>
												}
											</div>
											<div className="custom-control custom-checkbox d-none">
												<input
													type="checkbox"
													className="custom-control-input"
													id="customControlInline"
												/>
												<label
													className="custom-control-label"
													htmlFor="customControlInline"
												>
													Remember me
												</label>
											</div>
											<div className="mt-3">
												<button
													type="submit"
													className="btn btn-primary btn-block waves-effect waves-light"
													onClick={handleSubmit(onSubmit)}
												>
													Log In
												</button>
											</div>
										</form>
										<div className="mt-4 text-center d-none">
											<h5 className="font-size-14 mb-3">Sign in with</h5>
											<ul className="list-inline">
												<li className="list-inline-item">
													<a
														href="/login"
														className="social-list-item bg-primary text-white border-primary"
													>
														<i className="mdi mdi-facebook" />
													</a>
												</li>
												<li className="list-inline-item">
													<a
														href="/login"
														className="social-list-item bg-info text-white border-info"
													>
														<i className="mdi mdi-twitter" />
													</a>
												</li>
												<li className="list-inline-item">
													<a
														href="/login"
														className="social-list-item bg-danger text-white border-danger"
													>
														<i className="mdi mdi-google" />
													</a>
												</li>
											</ul>
										</div>
										<div className="mt-4 text-center">
											<Link to={URLS.FORGOT_PASSWORD} className="text-muted">
												<i className="mdi mdi-lock mr-1" /> Forgot your password?
											</Link>
										</div>
									</div>
								</div>
							</div>
							<div className="mt-5 text-center">
								<div>
									<p>
										Don't have an account ?
										<Link
											to={URLS.SIGNUP}
											className="font-weight-medium text-primary"
										>{" "}
											Signup now
										</Link>
									</p>
									<p>© ARR Management</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
}