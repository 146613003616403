import React, { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import EditButtonCell from '../components/actionsBtns/EditButtonCell'
import ViewButtonCell from '../components/actionsBtns/ViewButtonCell'
import Footer from '../components/commonComponents/Footer'
import Header from '../components/commonComponents/Header'
import LeftPanel from '../components/commonComponents/LeftPanel'
import TableContainer from '../components/commonComponents/TableContainer'
import { usePlatformApi } from '../_common/hooks/api/PlatformApiHook'
import { useAppLoader } from '../_common/hooks/common/appLoaderHook'
import { URLS } from '../_config'
import DeleteButtonCell from '../components/actionsBtns/DeleteButtonCell'
import { toast } from 'react-hot-toast'

const PriceBook = () => {

    const platformApi = usePlatformApi();
    const { showLoader, hideLoader } = useAppLoader();

    const [priceBookData, setPriceBookData] = useState<any>(null)
    const [data, setData] = useState<any>([])
    const [loading, setLoading] = useState<boolean>(false)

    const getPriceBookData = (page = 1) => {
        showLoader();
        setLoading(true);
        platformApi.getAllPriceBook({ page: page },
            (message: string, resp: any) => {
                // console.log(resp);
                setLoading(false);
                setPriceBookData(resp.data.list);
                setData(resp.data.list.data);
                hideLoader();
            }, (message: string) => {
                setLoading(false);
                hideLoader();
            })
    }

    const deletePricebook = (id: any) => {
        return new Promise((resolve: any, reject: any) => {
            platformApi.pricebookDestroy({
                id
            }, (message: string, resp: any) => {
                resolve(message)
            }, (message: string) => {
                reject(message);
            });
        })
    }

    const onTogglePricebook = (data: any, toggle: boolean) => {
        // console.log(data);
        let params: any = data;
        params["IsActive"] = toggle;
        showLoader()
        platformApi.managePriceBook(params, (message: string, resp: any) => {
            // console.log(message, resp)
            toast.success(message)
            getPriceBookData()
            hideLoader()
        }, (message: string) => {
            // console.log(message)
            toast.error(message)
            hideLoader()
        })
    }

    const columns = useMemo(
        () => [
            {
                Header: 'Actions',
                accessor: "PriceBookId",
                Cell: ({ column, value, row }: any) =>
                    <>
                        <DeleteButtonCell
                            column={column}
                            value={row.original.id}
                            deleteFunc={deletePricebook}
                            callbackFunc={[getPriceBookData]}
                            type={"Pricebook"}
                        />
                        <ViewButtonCell column={column} value={value} link={'pricebook-details'} />
                        <EditButtonCell column={column} value={value} link={'add-pricebook'} />
                    </>,
            },
            {
                Header: "Name",
                // accessor: "Name",
                // className: "nowrap",
                accessor: (row: { Name: any; IsStandard: any; }) => {
                    return { Name: row.Name, IsStandard: row.IsStandard };
                },
                Cell: ({ value }: any) => {
                    return (
                        <div>
                            <span>{value.Name}</span>
                            {value?.IsStandard == '1' ?
                                <span className='badge badge-pill badge-soft-success font-size-12 ml-2'>Standard</span>
                                :
                                null}
                        </div>
                    );
                },
            },
            {
                Header: "Active",
                accessor: "IsActive",
                className: "nowrap",
                Cell: ({ column, value, row }: any) => (
                    <div className='cstm-switch'>
                        <input
                            type="checkbox"
                            id={`switch${row.id}`}
                            defaultChecked={value === "1"}
                            onChange={(e: any) => { onTogglePricebook(row.original, e.target.checked) }}
                        />
                        <label htmlFor={`switch${row.id}`} data-on-label="on" data-off-label="off"></label>
                    </div>
                )
            },
        ],
        []
    )

    useEffect(() => {
        getPriceBookData();
    }, [])

    return (
        <React.Fragment>
            <div id="layout-wrapper">
                <Header />
                <LeftPanel />
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            {/* start page title */}
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-flex align-items-center justify-content-between">
                                        <h4 className="mb-0 font-size-18">Price Book</h4>
                                        <div className="page-title-right">
                                            <ol className="breadcrumb m-0">
                                                <li className="breadcrumb-item">
                                                    <Link to={URLS.REVENUE}>Revenue</Link>
                                                </li>
                                                <li className="breadcrumb-item active">Price Book</li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* end page title */}
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="d-flex flex-wrap justify-content-between">
                                                <h5 className="card-title mr-2">Price Book</h5>
                                                <Link to={URLS.ADD_PRICEBOOK} className="btn btn-success">Add Price Book</Link>
                                            </div>
                                            <div className="table-responsive">
                                                <TableContainer
                                                    columns={columns}
                                                    data={data}
                                                    fetchData={getPriceBookData}
                                                    loading={loading}
                                                    rawData={priceBookData}
                                                    clickFunc={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </React.Fragment>
    )
}

export default PriceBook