import React, { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom';
import { store } from '../store';
import { ACTIONS } from '../_config';

const CallbackPage = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const code = searchParams.get("code")
    // console.log({ code })

    const redirectToOldTab = () => {
        if (window.opener) {
            window.opener.location.href = `${window.location.origin}/`;
            window.close();
        }
    }

    useEffect(() => {
        if (code) {
            const params = { client_code: code }
            store.dispatch({
                type: ACTIONS.SALESFORCE.SAVE_DATA,
                payload: params
            });
        }
    }, [code])

    return (
        <React.Fragment>
            <div style={{minHeight: '100vh'}}  className="align-items-center d-flex flex-column justify-content-center">
                <h5 className='text-dark' style={{fontSize: '36px'}}>Thank You</h5>
                <h4 className='font-size-18 font-weight-normal text-black-50'>asif please click the button to proceed</h4>
                <button
                    className='btn btn-primary font-size-18 mt-3 px-5 py-2 rounded-sm text-white'
                    type='button'
                    onClick={redirectToOldTab}
                >
                    Click
                </button>
            </div>
        </React.Fragment>
    )
}

export default CallbackPage