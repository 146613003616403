import React, { useEffect, useRef, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import Select from 'react-select';
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from 'react-hook-form';
import { usePlatformApi } from '../../_common/hooks/api/PlatformApiHook';
import { useAppLoader } from '../../_common/hooks/common/appLoaderHook';
import { toast } from 'react-hot-toast';
import { handleBlur, handleFocus, handleKeyPressDecimalNumber, handleKeyPressNumber, handleKeyUpDown, handleWheel } from '../../_common/functions';
import moment from 'moment';
import { AiOutlinePlusSquare } from 'react-icons/ai';
import { useCommonApi } from '../../_common/hooks/api/common/commonApiHook';
import { useCommonFunction } from '../../_common/hooks/common/appCommonFunctionHook';
import DatePicker from 'react-datepicker';
import AddRegionModal from './addRegion.modal';
import { contractStatusData, yesNoOptions } from '../../_common/staticData';
import AddContractManagerModal from './addContractManager.modal';

interface FormValues {
	accountName: any;
	status: any;
	contractStartDate: string;
	contractTerm: string;
	file: any;
	region: any;
	bestCase: string;
	nextStep: string;
	closePlan: string;
	paymentTerm: string;
	autoRenewal: any;
	customerTerm: any;
	mostLikely: any;
	poc: any;
}

const validationSchema = Yup.object().shape({
	accountName: Yup.object().required("Account name is required"),
	status: Yup.object().required("Status is required"),
	contractStartDate: Yup.date().required("Contract Start is required"),
	contractTerm: Yup.string().required("Contract Term is required"),
	region: Yup.object().required("Region is required"),
	bestCase: Yup.string().required("Best Case is required"),
	nextStep: Yup.string().required("Nest Step is required"),
	closePlan: Yup.string().required("Close Plan is required"),
	paymentTerm: Yup.string().required("Payment term is required"),
	autoRenewal: Yup.object().required("Auto renewal is required"),
	customerTerm: Yup.object().required("Custom term is required"),
	mostLikely: Yup.object().required("Most likely is required"),
	poc: Yup.object().required("Contract manager is required"),
});

interface IncomingProps {
	show: boolean;
	close: () => void;
	isEdit: boolean;
	AccountId: string;
	ContractId?: string;
}

const AddContractModal = ({ show, close, AccountId, ContractId, isEdit = false }: IncomingProps) => {

	const platformApi = usePlatformApi();
	const commonApi = useCommonApi();
	const commonFunctions = useCommonFunction();
	const datePickerRef = useRef<any>()

	const { showLoader, hideLoader } = useAppLoader();
	const [accountOptions, setAccountOptions] = useState<any>(null)
	const [statusOptions, setStatusOptions] = useState<any>(contractStatusData)
	const [contractData, setContractData] = useState<any>(null)
	const [loading, setLoading] = useState<boolean>(false)
	const [fileValue, setFileValue] = useState<any>()
	const [regionOptions, setRegionOption] = useState<any>(null)
	const [contractManagerOptions, setContractManagerOption] = useState<any>(null)
	const [showAddRegionModal, setShowAddRegionModal] = useState<boolean>(false)
	const [showContractManagerModal, setShowContractManagerModal] = useState<boolean>(false)

	const { control, formState: { errors }, handleSubmit, setValue, reset } = useForm<FormValues>({
		resolver: yupResolver(validationSchema),
		defaultValues: {
			accountName: "",
			status: "",
			contractStartDate: "",
			contractTerm: "",
			file: null,
			region: "",
			bestCase: "",
			nextStep: "",
			closePlan: "",
			paymentTerm: "",
			autoRenewal: "",
			customerTerm: "",
			mostLikely: "",
			poc: "",
		},
	});

	const getAccountList = () => {
		showLoader();
		platformApi.getAllAccount({
			AccountId: AccountId
		},
			(message: string, resp: any) => {
				// console.log(resp);
				const tempData = resp.data.list.data
				// const tempData = resp.data.list
				if (tempData && tempData.length > 0) {
					setAccountOptions(tempData.map((item: any) => {
						item.value = item.AccountId
						item.label = item.Name
						return item
					}))
				}
				hideLoader();
			}, (message: string) => {
				hideLoader();
			})
	}

	const getContractData = () => {
		showLoader();
		setLoading(true)
		platformApi.getAllContract({
			ContractId: ContractId
		},
			(message: string, resp: any) => {
				// console.log(resp);
				setContractData(resp.data.list.data[0]);
				setLoading(false)
				hideLoader();
			}, (message: string) => {
				setLoading(false)
				hideLoader();
			})
	}

	const getRegionList = () => {
		showLoader();
		commonApi.getAllRegions({},
			(message: string, resp: any) => {
				// console.log(resp);
				let tempArr = resp.data.list.data
				if (tempArr && tempArr.length > 0) {
					setRegionOption(tempArr.map((item: any) => {
						item.label = item.region_name;
						item.value = item.Region;
						return item
					}));
				}
				hideLoader();
			}, (message: string) => {
				hideLoader();
			})
	}

	const getContractManagerList = () => {
		showLoader();
		platformApi.getAccountTeam({
			AccountId: AccountId
		},
			(message: string, resp: any) => {
				// console.log(resp);
				let tempArr = resp.data.list.data
				if (tempArr && tempArr.length > 0) {
					setContractManagerOption(tempArr.map((item: any) => {
						item.label = item.name;
						item.value = item.id;
						return item
					}));
				}
				hideLoader();
			}, (message: string) => {
				hideLoader();
			})
	}

	const onSubmit = (data: any) => {
		// console.log(data);
		let formData: any = new FormData()
		if (data?.accountName?.value) {
			formData.append("AccountId", data?.accountName?.value)
		}
		if (data?.contractStartDate) {
			formData.append("StartDate", data?.contractStartDate ? moment(data.contractStartDate).format("MM/DD/YY") : "")
		}
		if (data?.contractTerm) {
			formData.append("ContractTerm", data?.contractTerm)
		}
		if (data?.status?.value) {
			formData.append("Status", data?.status?.value)
		}
		if (data?.region?.value) {
			formData.append("Region", data?.region?.value)
		}
		if (data?.bestCase) {
			formData.append("bestcase", String(data?.bestCase))
		}
		if (data?.nextStep) {
			formData.append("nextsteps", data?.nextStep)
		}
		if (data?.closePlan) {
			formData.append("closeplan", data?.closePlan)
		}
		if (data?.paymentTerm) {
			formData.append("paymentterm", data?.paymentTerm)
		}
		if (data?.autoRenewal?.value) {
			formData.append("autorenewal", data?.autoRenewal?.value)
		}
		if (data?.customerTerm?.value) {
			formData.append("cuatomerterm", data?.customerTerm?.value)
		}
		if (data?.mostLikely?.value) {
			formData.append("mostlikely", data?.mostLikely?.value)
		}
		if (data?.poc?.value) {
			formData.append("poc", data?.poc?.value)
		}
		if (isEdit) {
			if (contractData && contractData?.id) {
				// params["id"] = contractData?.id
				formData.append("id", contractData?.id)
			}
		}
		if (fileValue) {
			formData.append('sla', fileValue)
		}
		// console.log(params)
		showLoader()
		platformApi.manageContract(formData, (message: string, resp: any) => {
			// console.log(message, resp)
			toast.success(message)
			reset()
			close()
			hideLoader()
		}, (message: string) => {
			// console.log(message)
			toast.error(message)
			hideLoader()
		})
	}

	const handleClose = () => {
		close();
		setContractData(null);
		reset();
	};

	const closeAddRegionModal = () => {
		setShowAddRegionModal(false)
	}

	const closeContractManagerModal = () => {
		setShowContractManagerModal(false)
		// getContractManagerList()
	}

	useEffect(() => {
		if (show && AccountId) {
			getAccountList()
			getContractManagerList()
		}
	}, [show, AccountId])

	useEffect(() => {
		if (show) {
			getRegionList()
		}
	}, [show])

	useEffect(() => {
		if (isEdit && ContractId) {
			getContractData()
		}
	}, [ContractId, isEdit])

	useEffect(() => {
		if (contractData) {
			// setValue("contractStartDate", contractData?.StartDate)
			setValue("contractStartDate", contractData?.StartDate && new Date(contractData?.StartDate))
			setValue("contractTerm", contractData?.ContractTerm || "")
			setValue("status", contractStatusData?.find((item: any) => item?.value == contractData?.Status))
			setValue("region", regionOptions?.find((item: any) => item?.value == contractData?.Region))
			setValue("bestCase", contractData?.bestcase || "")
			setValue("nextStep", contractData?.nextsteps || "")
			setValue("closePlan", contractData?.closeplan || "")
			setValue("paymentTerm", contractData?.paymentterm || "")
			setValue("autoRenewal", yesNoOptions?.find((item: any) => item?.value == contractData?.autorenewal))
			setValue("customerTerm", yesNoOptions?.find((item: any) => item?.value == contractData?.cuatomerterm))
			setValue("mostLikely", yesNoOptions?.find((item: any) => item?.value == contractData?.mostlikely))
			setValue("poc", contractManagerOptions?.find((item: any) => item?.value == contractData?.poc))
		}
	}, [contractData])

	useEffect(() => {
		if (accountOptions && accountOptions.length > 0) {
			setValue('accountName', accountOptions?.[0])
		}
	}, [accountOptions])

	useEffect(() => {
		if (errors?.contractStartDate) {
			datePickerRef.current.setOpen(true);
		}
	}, [errors])

	return (
		<React.Fragment>
			<AddRegionModal
				show={showAddRegionModal}
				close={closeAddRegionModal}
				isEdit={false}
				regionListCallbackFunc={getRegionList}
			/>
			<AddContractManagerModal
				close={closeContractManagerModal}
				show={showContractManagerModal}
				AccountId={AccountId}
				callbackFunc={getContractManagerList}
			/>
			<Modal
				className={'salesforce-modal modal-lndscp ' + (showAddRegionModal || showContractManagerModal ? 'opec-5' : '')}
				show={show}
			// onHide={handleClose}
			>
				<div className="modal-header custom-modal-header">
					<button
						type="button"
						className="close"
						data-dismiss="modal"
						aria-label="Close"
						onClick={handleClose}
					>
						<span aria-hidden="true">×</span>
					</button>
				</div>
				<Modal.Body>
					{loading ?
						<div>
							Loading......
						</div>
						:
						<div className="row">
							<form onSubmit={handleSubmit(onSubmit)} className="w-100" >
								<div className="col-12">
									<div className="card">
										<div className="card-body">
											<div className="d-flex flex-wrap">
												<h5 className="card-title mr-2">Contract Information</h5>
											</div>
											<div className="row">
												<div className="form-group col-6">
													<label htmlFor="accountName">Account name <span className='text-danger'>*</span></label>
													<Controller
														name={"accountName"}
														control={control}
														render={({ field: { value, onChange, onBlur, name, ref } }) => (
															<>
																<Select
																	className={"select-box " + (errors?.accountName ? ' border-danger' : '')}
																	options={accountOptions ? accountOptions : []}
																	placeholder={"Select account"}
																	onChange={onChange}
																	value={value}
																	defaultValue={value}
																	ref={ref}
																	isDisabled
																/>
															</>
														)}
													/>
													{errors && errors.accountName &&
														<span className='text-danger'>Account Name is required</span>
													}
												</div>
												<div className="form-group col-6">
													<label htmlFor="status">Status <span className='text-danger'>*</span></label>
													<Controller
														name={"status"}
														control={control}
														render={({ field: { value, onChange, onBlur, name, ref } }) => (
															<>
																<Select
																	className={"select-box " + (errors?.status ? ' border-danger' : '')}
																	options={statusOptions ? statusOptions : []}
																	placeholder={"Select status"}
																	onChange={onChange}
																	value={value}
																	defaultValue={value}
																	ref={ref}
																/>
															</>
														)}
													/>
													{errors && errors.status &&
														<span className='text-danger'>Status is required</span>
													}
												</div>
												<div className="form-group col-6">
													<label htmlFor="contractStartDate">Contract Start Date <span className='text-danger'>*</span></label>
													<Controller
														name={"contractStartDate"}
														control={control}
														render={({ field: { value, onChange, onBlur, name, ref } }) => (
															<>
																<DatePicker
																	selected={value ? new Date(value) : null}
																	onChange={onChange}
																	dateFormat="MM/dd/yy"
																	className={"form-control " + (errors?.contractStartDate ? ' border-danger' : '')}
																	id="contractStartDate"
																	name={name}
																	onBlur={onBlur}
																	ref={datePickerRef}
																	value={value}
																	placeholderText="Enter contract start date"
																/>
															</>
														)}
													/>
													{errors && errors.contractStartDate &&
														<span className='text-danger'>Contract Start Date is required</span>
													}
												</div>
												<div className="form-group col-6">
													<label htmlFor="contractTerm">Contract Term (month) <span className='text-danger'>*</span></label>
													<Controller
														name={"contractTerm"}
														control={control}
														render={({ field: { value, onChange, onBlur, name, ref } }) => (
															<>
																<input
																	type="number"
																	className={"form-control " + (errors?.contractTerm ? ' border-danger' : '')}
																	id="contractTerm"
																	name={name}
																	value={value}
																	onChange={onChange}
																	onBlur={(e) => { onBlur(); handleBlur(e) }}
																	onFocus={(e) => handleFocus(e)}
																	onKeyDown={(event: any) => { handleKeyPressNumber(event); handleKeyUpDown(event) }}
																	onWheel={handleWheel}
																	step={"1"}
																	min={0}
																	ref={ref}
																	placeholder="Enter contract term"
																/>
															</>
														)}
													/>
													{errors && errors.contractTerm &&
														<span className='text-danger'>{errors?.contractTerm?.message}</span>
													}
												</div>
												<div className="form-group col-6">
													<div className='d-flex justify-content-between'>
														<label htmlFor="region">Region <span className='text-danger'>*</span></label>
													</div>
													<div className='d-flex align-items-center gap-10 add-new-fld-row'>
														<Controller
															name={"region"}
															control={control}
															render={({ field: { value, onChange, onBlur, name, ref } }) => (
																<>
																	<Select
																		className={"select-box " + (errors?.region ? ' border-danger' : '')}
																		options={regionOptions ? regionOptions : []}
																		placeholder={"Select region"}
																		onChange={onChange}
																		value={value}
																		defaultValue={value}
																		ref={ref}
																		isClearable={true}
																	// isDisabled={isCreateLoading}
																	// isLoading={isCreateLoading}
																	// onCreateOption={handleCreate}
																	/>
																</>
															)}
														/>
														<span
															data-toggle="tooltip"
															data-placement="top"
															title={"Add Region"}
															onClick={() => setShowAddRegionModal(true)}
														>
															<AiOutlinePlusSquare className='cursor-pointer' />
														</span>
													</div>
													{errors && errors.region &&
														<span className='text-danger'>Region is required</span>
													}
												</div>
												<div className="form-group col-6">
													<label htmlFor="bestCase">Best Case ($)<span className='text-danger'>*</span></label>
													<Controller
														name={"bestCase"}
														control={control}
														render={({ field: { value, onChange, onBlur, name, ref } }) => (
															<>
																<input
																	type="number"
																	className={"form-control " + (errors?.bestCase ? ' border-danger' : '')}
																	id="bestCase"
																	name={name}
																	value={value}
																	onChange={onChange}
																	onBlur={(e) => { onBlur(); handleBlur(e) }}
																	onFocus={(e) => handleFocus(e)}
																	onKeyDown={(event: any) => { handleKeyPressDecimalNumber(event); handleKeyUpDown(event) }}
																	onWheel={handleWheel}
																	step={"0.01"}
																	min={0}
																	ref={ref}
																	placeholder="Enter best case"
																/>
															</>
														)}
													/>
													{errors && errors.bestCase &&
														<span className='text-danger'>{errors?.bestCase?.message}</span>
													}
												</div>
												<div className="form-group col-6">
													<label htmlFor="nextStep">Next Step <span className='text-danger'>*</span></label>
													<Controller
														name={"nextStep"}
														control={control}
														render={({ field: { value, onChange, onBlur, name, ref } }) => (
															<>
																<input
																	type="text"
																	className={"form-control " + (errors?.nextStep ? ' border-danger' : '')}
																	id="nextStep"
																	name={name}
																	onChange={onChange}
																	value={value}
																	onBlur={onBlur}
																	ref={ref}
																	placeholder="Enter next step"
																/>
															</>
														)}
													/>
													{errors && errors.nextStep &&
														<span className='text-danger'>{errors?.nextStep?.message}</span>
													}
												</div>
												<div className="form-group col-6">
													<label htmlFor="nextStep">Close Plan <span className='text-danger'>*</span></label>
													<Controller
														name={"closePlan"}
														control={control}
														render={({ field: { value, onChange, onBlur, name, ref } }) => (
															<>
																<input
																	type="text"
																	className={"form-control " + (errors?.closePlan ? ' border-danger' : '')}
																	id="closePlan"
																	name={name}
																	onChange={onChange}
																	value={value}
																	onBlur={onBlur}
																	ref={ref}
																	placeholder="Enter close plan"
																/>
															</>
														)}
													/>
													{errors && errors.closePlan &&
														<span className='text-danger'>{errors?.closePlan?.message}</span>
													}
												</div>
												<div className="form-group col-6">
													<label htmlFor="paymentTerm">Payment Term (month) <span className='text-danger'>*</span></label>
													<Controller
														name={"paymentTerm"}
														control={control}
														render={({ field: { value, onChange, onBlur, name, ref } }) => (
															<>
																<input
																	type="number"
																	className={"form-control " + (errors?.paymentTerm ? ' border-danger' : '')}
																	id="paymentTerm"
																	name={name}
																	value={value}
																	onChange={onChange}
																	onBlur={(e) => { onBlur(); handleBlur(e) }}
																	onFocus={(e) => handleFocus(e)}
																	onKeyDown={(event: any) => { handleKeyPressNumber(event); handleKeyUpDown(event) }}
																	onWheel={handleWheel}
																	step={"1"}
																	min={0}
																	ref={ref}
																	placeholder="Enter payment term"
																/>
															</>
														)}
													/>
													{errors && errors.paymentTerm &&
														<span className='text-danger'>{errors?.paymentTerm?.message}</span>
													}
												</div><div className="form-group col-6">
													<label htmlFor="autoRenewal">Auto renewal <span className='text-danger'>*</span></label>
													<Controller
														name={"autoRenewal"}
														control={control}
														render={({ field: { value, onChange, onBlur, name, ref } }) => (
															<>
																<Select
																	className={"select-box " + (errors?.autoRenewal ? ' border-danger' : '')}
																	options={yesNoOptions ? yesNoOptions : []}
																	placeholder={"Select auto renewal"}
																	onChange={onChange}
																	value={value}
																	defaultValue={value}
																	ref={ref}
																/>
															</>
														)}
													/>
													{errors && errors.autoRenewal &&
														<span className='text-danger'>Auto renewal is required</span>
													}
												</div>
												<div className="form-group col-6">
													<label htmlFor="customerTerm">Custom term <span className='text-danger'>*</span></label>
													<Controller
														name={"customerTerm"}
														control={control}
														render={({ field: { value, onChange, onBlur, name, ref } }) => (
															<>
																<Select
																	className={"select-box " + (errors?.customerTerm ? ' border-danger' : '')}
																	options={yesNoOptions ? yesNoOptions : []}
																	placeholder={"Select customer term"}
																	onChange={onChange}
																	value={value}
																	defaultValue={value}
																	ref={ref}
																/>
															</>
														)}
													/>
													{errors && errors.customerTerm &&
														<span className='text-danger'>Custom term is required</span>
													}
												</div>
												<div className="form-group col-6">
													<label htmlFor="mostLikely">Most likely <span className='text-danger'>*</span></label>
													<Controller
														name={"mostLikely"}
														control={control}
														render={({ field: { value, onChange, onBlur, name, ref } }) => (
															<>
																<Select
																	className={"select-box " + (errors?.mostLikely ? ' border-danger' : '')}
																	options={yesNoOptions ? yesNoOptions : []}
																	placeholder={"Select most likely"}
																	onChange={onChange}
																	value={value}
																	defaultValue={value}
																	ref={ref}
																/>
															</>
														)}
													/>
													{errors && errors.mostLikely &&
														<span className='text-danger'>Most likely is required</span>
													}
												</div>
												<div className="form-group col-6">
													<label htmlFor="poc">Contract manager
														<span className='text-danger'>*</span>
													</label>
													<div className='d-flex align-items-center gap-10 add-new-fld-row'>
														<Controller
															name={"poc"}
															control={control}
															render={({ field: { value, onChange, onBlur, name, ref } }) => (
																<>
																	<Select
																		className={"select-box" + (errors?.poc ? ' border-danger' : '')}
																		id="poc"
																		options={contractManagerOptions ? contractManagerOptions : []}
																		placeholder={"Select contract manager"}
																		onChange={onChange}
																		value={value}
																		defaultValue={value}
																	/>
																</>
															)}
														/>
														<span
															data-toggle="tooltip"
															data-placement="top"
															title={"Add Manager"}
															onClick={() => setShowContractManagerModal(true)}
														>
															<AiOutlinePlusSquare className='cursor-pointer' />
														</span>
													</div>
													{errors && errors.poc &&
														<span className='text-danger'>Contract manager is required</span>
													}
												</div>
											</div>
										</div>
									</div>
								</div>

								<div className="col-12">
									<div className='align-items-center d-flex gap-10 justify-content-center'>
										<button type='submit' className='btn btn-primary'>Save</button>
										<button
											type='button'
											className='btn btn-outline-danger'
											onClick={handleClose}
										>
											Cancel
										</button>
									</div>
								</div>
							</form>
						</div>
					}
				</Modal.Body>
			</Modal>
		</React.Fragment>
	)
}

export default AddContractModal