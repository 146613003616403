import { SAGA_ACTIONS } from '../../../_config';
import { useApiCall } from '../common/appApiCallHook';


export function useZendeskApi() {

  const callApi = useApiCall()

  const getZendeskOpenTicket = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.ZENDESK.GET_ZENDESK_OPEN_TICKET, data, onSuccess, onError);
  }

  const getZendeskTicketByAccount = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.ZENDESK.GET_ZENDESK_TICKET_BY_ACCOUNT, data, onSuccess, onError);
  }

  return {
    getZendeskOpenTicket: getZendeskOpenTicket,
    getZendeskTicketByAccount: getZendeskTicketByAccount,
  }
}