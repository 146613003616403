import React, { useEffect, useMemo, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Footer from '../components/commonComponents/Footer'
import Header from '../components/commonComponents/Header'
import LeftPanel from '../components/commonComponents/LeftPanel'
import { URLS } from '../_config'
import { usePlatformApi } from '../_common/hooks/api/PlatformApiHook'
import { useAppLoader } from '../_common/hooks/common/appLoaderHook'
import TableContainer from '../components/commonComponents/TableContainer'
import DeleteButtonCell from '../components/actionsBtns/DeleteButtonCell'
import AddPricbookEntriesModal from '../components/modals/addPricbookEntries.modal'
import { AiOutlineEdit } from 'react-icons/ai'
import { toast } from 'react-hot-toast'
import { formatNumber } from '../_common/functions'

const PriceBookDetails = () => {

    const urlParams: any = useParams()
    const platformApi = usePlatformApi()
    const navigate = useNavigate()

    const { showLoader, hideLoader } = useAppLoader()

    const [data, setData] = useState<any>(null)
    const [priceBookEntries, setPriceBookEntries] = useState<any>(null)
    const [loading, setLoading] = useState<boolean>(false)
    const [pricebookLoading, setPricebookLoading] = useState<boolean>(false)
    const [priceBookData, setPriceBookData] = useState<any>([])
    const [showModal, setShowModal] = useState<boolean>(false)
    const [isEdit, setIsEdit] = useState<boolean>(false)
    const [pricebookEntryId, setPricebookEntryId] = useState<any>(null)

    const getPricebookDetails = () => {
        showLoader()
        setPricebookLoading(true)
        platformApi.getAllPriceBook({
            PriceBookId: urlParams?.id
        }, (message: string, resp: any) => {
            // console.log(message, resp)
            setData(resp.data.list.data[0])
            setPricebookLoading(false)
            hideLoader()
        }, (message: string) => {
            // console.log(message)
            hideLoader()
            setPricebookLoading(false)
        })
    }

    const getPriceBookEntries = (page = 1) => {
        showLoader();
        setLoading(true);
        platformApi.getAllPriceBookEntities({
            page: page,
            Pricebook2Id: urlParams?.id
        },
            (message: string, resp: any) => {
                // console.log(resp);
                setLoading(false);
                setPriceBookEntries(resp.data.list);
                setPriceBookData(resp.data.list.data);
                hideLoader();
            }, (message: string) => {
                setLoading(false);
                hideLoader();
            })
    }

    const deletePricebookEntities = (id: any) => {
        return new Promise((resolve: any, reject: any) => {
            platformApi.pricebookEntityDestroy({
                id
            }, (message: string, resp: any) => {
                resolve(message)
            }, (message: string) => {
                reject(message);
            });
        })
    }

    const onTogglePricebookEntries = (data: any, toggle: boolean) => {
        // console.log(data);
        let params: any = data;
        params["IsActive"] = toggle;
        showLoader()
        platformApi.managePriceBookEntities(params, (message: string, resp: any) => {
            // console.log(message, resp)
            toast.success(message)
            getPriceBookEntries()
            hideLoader()
        }, (message: string) => {
            // console.log(message)
            toast.error(message)
            hideLoader()
        })
    }

    const closeModal = () => {
        setShowModal(false)
        setIsEdit(false)
        // getPriceBookEntries()
        setPricebookEntryId(null)
    }

    const EditBtn = ({ column, value }: any) => {
        const handleClick = () => {
            console.log(value)
            setShowModal(true)
            setPricebookEntryId(value)
            setIsEdit(true)
        }
        return (
            <AiOutlineEdit
                onClick={handleClick}
                className="cursor-pointer mr-2"
                data-toggle="tooltip" data-placement="top" title="Edit"
            />
        )
    }

    const columns = useMemo(
        () => [
            {
                Header: 'Actions',
                accessor: "PricebookEntryId",
                Cell: ({ column, value, row }: any) =>
                    <>
                        <DeleteButtonCell
                            column={column}
                            value={row.original.id}
                            deleteFunc={deletePricebookEntities}
                            callbackFunc={[getPriceBookEntries]}
                            type={"Pricebook Entry"}
                        />
                        <EditBtn column={column} value={value} />
                        {/* <EditButtonCell column={column} value={value} link={`add-pricebook-entities/${urlParams?.id}`} /> */}
                    </>,
            },
            {
                Header: "Product Name",
                accessor: "Name",
            },
            {
                Header: "List Price ($)",
                accessor: "UnitPrice",
                Cell: ({ column, value, row }: any) => (
                    <div>
                        {value ? formatNumber(value) : ''}
                    </div>
                )
            },
            {
                Header: "Active",
                accessor: "IsActive",
                className: "nowrap",
                Cell: ({ column, value, row }: any) => (
                    <div className='cstm-switch'>
                        <input
                            type="checkbox"
                            id={`switch${row.id}`}
                            defaultChecked={value === "1"}
                            onChange={(e: any) => { onTogglePricebookEntries(row.original, e.target.checked) }}
                        />
                        <label htmlFor={`switch${row.id}`} data-on-label="on" data-off-label="off"></label>
                    </div>
                )
            },
        ],
        []
    )

    useEffect(() => {
        if (urlParams && urlParams.id) {
            getPricebookDetails()
            getPriceBookEntries()
        }
    }, [urlParams])

    return (
        <React.Fragment>
            <AddPricbookEntriesModal
                show={showModal}
                close={closeModal}
                isEdit={isEdit}
                Pricebook2Id={urlParams?.id}
                PricebookEntryId={pricebookEntryId}
                callbackFunction={getPriceBookEntries}
            />
            <div id="layout-wrapper">
                <Header />
                <LeftPanel />
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            {/* start page title */}
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-flex align-items-center justify-content-between">
                                        <h4 className="mb-0 font-size-18">PriceBook Details</h4>
                                        <div className="page-title-right">
                                            <ol className="breadcrumb m-0">
                                                <li className="breadcrumb-item">
                                                    <Link to={URLS.PRICE_BOOK}>Pricebook List</Link>
                                                </li>
                                                <li className="breadcrumb-item active">Pricebook Details</li>
                                            </ol>
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-end mb-3'>
                                        <Link
                                            to={`/add-pricebook?id=${urlParams?.id}`}
                                            className="btn btn-success">
                                            Edit Pricebook
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            {/* end page title */}
                            {pricebookLoading ?
                                <div>
                                    Loading......
                                </div>
                                :
                                <div className="row">
                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="d-flex flex-wrap">
                                                    <h5 className="card-title mr-2">Price Book Information</h5>
                                                </div>
                                                <div className="row">
                                                    <div className="col-6">
                                                        <label htmlFor="priceBookName" className='mr-2'>Price Book name :</label>
                                                        <strong>{" " + data?.Name ? data?.Name : ""}</strong>
                                                    </div>
                                                    {/* <div className="align-items-center col-6 d-flex gap-10">
                                                        <label htmlFor="active" className='mb-0'>Active : </label>
                                                        <input
                                                            type="checkbox"
                                                            disabled
                                                            checked={data?.IsActive == '1'}
                                                        />
                                                    </div> */}
                                                    <div className="col-6">
                                                        <label htmlFor="description" className='mr-2'>Description : </label>
                                                        <strong>{" " + data?.Description ? data?.Description : ""}</strong>
                                                    </div>
                                                    <div className="align-items-center col-6 d-flex gap-10">
                                                        <label htmlFor="isStndPriceBook" className='mb-0 mr-2'>Is Standard Price Book : </label>
                                                        <input
                                                            type="checkbox"
                                                            readOnly
                                                            checked={data?.IsStandard == '1'}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="d-flex flex-wrap justify-content-between">
                                                    <h5 className="card-title mr-2">Pricebook Entries</h5>
                                                    {/* <Link
                                                        to={`/add-pricebook-entities/${urlParams?.id}`}
                                                        className="btn btn-success"
                                                    >
                                                        Add Price Book Entries
                                                    </Link> */}
                                                    <button
                                                        onClick={() => setShowModal(true)}
                                                        className="btn btn-success"
                                                    >
                                                        Add Pricebook Entries
                                                    </button>
                                                </div>
                                                <div className="table-responsive">
                                                    <TableContainer
                                                        columns={columns}
                                                        data={priceBookData}
                                                        fetchData={getPriceBookEntries}
                                                        loading={loading}
                                                        rawData={priceBookEntries}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </React.Fragment>
    )
}

export default PriceBookDetails