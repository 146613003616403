import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import Footer from '../components/commonComponents/Footer'
import Header from '../components/commonComponents/Header'
import LeftPanel from '../components/commonComponents/LeftPanel'
import { URLS } from '../_config'
import * as Yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { usePlatformApi } from '../_common/hooks/api/PlatformApiHook'
import { useAppLoader } from '../_common/hooks/common/appLoaderHook'
import { toast } from 'react-hot-toast'

interface AddPriceBookFormValues {
    priceBookName: string;
    description: string;
    isStndPriceBook: boolean;
    // active: boolean;
}

const validationSchema = Yup.object().shape({
    priceBookName: Yup.string().required("Price Book Name is required")
});


const AddPriceBook = () => {

    const platformApi = usePlatformApi()
    const navigate = useNavigate()
    const [searchParams] = useSearchParams();
    const id: any = searchParams.get("id")

    const { showLoader, hideLoader } = useAppLoader()
    const [priceBookData, setPriceBookData] = useState<any>(null)
    const [loading, setLoading] = useState<boolean>(false)

    const { control, formState: { errors }, handleSubmit, setValue, reset } = useForm<AddPriceBookFormValues>({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            priceBookName: "",
            description: "",
            isStndPriceBook: false,
            // active: false
        },
    });

    const getPriceBookData = () => {
        showLoader();
        setLoading(true)
        platformApi.getAllPriceBook({ PriceBookId: id },
            (message: string, resp: any) => {
                // console.log(resp);
                setPriceBookData(resp.data.list.data[0]);
                setLoading(false)
                hideLoader();
            }, (message: string) => {
                setLoading(false)
                hideLoader();
            })
    }

    const onSubmit = (data: any) => {
        // console.log(data);
        let params: any = {
            "Description": data?.description,
            // "IsActive": data?.active,
            "IsStandard": data?.isStndPriceBook,
            "Name": data?.priceBookName
        }
        if (priceBookData && priceBookData?.id) {
            params["id"] = priceBookData?.id
        }
        // console.log(params)
        showLoader()
        platformApi.managePriceBook(params, (message: string, resp: any) => {
            // console.log(message, resp)
            toast.success(message)
            reset()
            navigate(`/pricebook-details/${resp?.data?.details?.PriceBookId}`)
            hideLoader()
        }, (message: string) => {
            // console.log(message)
            toast.error(message)
            hideLoader()
        })
    }

    useEffect(() => {
        if (id) {
            getPriceBookData()
        }
    }, [id])

    useEffect(() => {
        if (priceBookData) {
            setValue("description", priceBookData?.Description)
            // setValue("active", priceBookData?.IsActive == '1' ? true : false)
            setValue("isStndPriceBook", priceBookData?.IsStandard == '1' ? true : false)
            setValue("priceBookName", priceBookData?.Name)
        }
    }, [priceBookData])

    return (
        <React.Fragment>
            <div id="layout-wrapper">
                <Header />
                <LeftPanel />
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            {/* start page title */}
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-flex align-items-center justify-content-between">
                                        <h4 className="mb-0 font-size-18">New Price Book</h4>
                                        <div className="page-title-right">
                                            <ol className="breadcrumb m-0">
                                                <li className="breadcrumb-item">
                                                    <Link to={URLS.PRICE_BOOK}>Price Book List</Link>
                                                </li>
                                                <li className="breadcrumb-item active">Add Price Book</li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* end page title */}
                            {loading ?
                                <div>
                                    Loading......
                                </div>
                                :
                                <div className="row">
                                    <form onSubmit={handleSubmit(onSubmit)} className="w-100" >
                                        <div className="col-12">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="d-flex flex-wrap">
                                                        <h5 className="card-title mr-2">Price Book Information</h5>
                                                    </div>
                                                    <div className="row">
                                                        <div className="form-group col-6">
                                                            <label htmlFor="priceBookName">Price Book name <span className='text-danger'>*</span></label>
                                                            <Controller
                                                                name={"priceBookName"}
                                                                control={control}
                                                                render={({ field: { value, onChange, onBlur, name, ref } }) => (
                                                                    <>
                                                                        <input
                                                                            type="text"
                                                                            className={"form-control " + (errors?.priceBookName ? ' border-danger' : '')}
                                                                            id="priceBookName"
                                                                            name={name}
                                                                            onChange={onChange}
                                                                            value={value}
                                                                            onBlur={onBlur}
                                                                            ref={ref}
                                                                            defaultChecked={priceBookData?.IsActive == '1' ? true : false}
                                                                            placeholder="Enter price book name"
                                                                        />
                                                                    </>
                                                                )}
                                                            />
                                                            {errors && errors.priceBookName &&
                                                                <span className='text-danger'>{errors?.priceBookName?.message}</span>
                                                            }
                                                        </div>
                                                        {/* <div className="align-items-center col-6 d-flex form-group gap-10">
                                                            <label htmlFor="active" className='mb-0' >Active</label>
                                                            <Controller
                                                                name={"active"}
                                                                control={control}
                                                                render={({ field: { value, onChange, onBlur, name } }) => (
                                                                    <>
                                                                        <input
                                                                            type="checkbox"
                                                                            className={(errors?.active ? ' border-danger' : '')}
                                                                            id="active"
                                                                            name={name}
                                                                            onChange={onChange}
                                                                            // value={value}
                                                                            onBlur={onBlur}
                                                                            defaultChecked={priceBookData?.IsActive == '1' ? true : false}
                                                                            placeholder="Enter active"
                                                                        />
                                                                    </>
                                                                )}
                                                            />
                                                            {errors && errors.active &&
                                                                <span className='text-danger'>{errors?.active?.message}</span>
                                                            }
                                                        </div> */}
                                                        <div className="align-items-center col-6 d-flex form-group gap-10">
                                                        <Controller
                                                                name={"isStndPriceBook"}
                                                                control={control}
                                                                render={({ field: { value, onChange, onBlur, name } }) => (
                                                                    <>
                                                                        <input
                                                                            type="checkbox"
                                                                            className={(errors?.isStndPriceBook ? ' border-danger' : '')}
                                                                            id="isStndPriceBook"
                                                                            name={name}
                                                                            onChange={onChange}
                                                                            // value={value}
                                                                            onBlur={onBlur}
                                                                            defaultChecked={priceBookData?.IsStandard == '1' ? true : false}
                                                                            placeholder="Enter isStndPriceBook"
                                                                        />
                                                                    </>
                                                                )}
                                                            />
                                                            <label htmlFor="isStndPriceBook" className='mb-0' >Is Standard Price Book</label>
                                                            
                                                            {errors && errors.isStndPriceBook &&
                                                                <span className='text-danger'>{errors?.isStndPriceBook?.message}</span>
                                                            }
                                                        </div>
                                                        <div className="form-group col-6">
                                                            <label htmlFor="description">Description</label>
                                                            <Controller
                                                                name={"description"}
                                                                control={control}
                                                                render={({ field: { value, onChange, onBlur, name } }) => (
                                                                    <>
                                                                        <textarea
                                                                            // type="text"
                                                                            className={"form-control " + (errors?.description ? ' border-danger' : '')}
                                                                            id="description"
                                                                            name={name}
                                                                            onChange={onChange}
                                                                            value={value}
                                                                            onBlur={onBlur}
                                                                            placeholder="Enter description"
                                                                        />
                                                                    </>
                                                                )}
                                                            />
                                                            {errors && errors.description &&
                                                                <span className='text-danger'>{errors?.description?.message}</span>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-12">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className='align-items-center d-flex gap-10 justify-content-center'>
                                                        <button type='submit' className='btn btn-primary'>Save</button>
                                                        <button
                                                            type='button'
                                                            className='btn btn-outline-danger'
                                                            onClick={() => navigate(URLS.PRICE_BOOK)}
                                                        >
                                                            Cancel
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            }
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </React.Fragment>
    )
}

export default AddPriceBook