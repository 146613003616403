import React from "react";
import { useAppLoader } from '../_common/hooks/common/appLoaderHook';
import { useUserApi } from "../_common/hooks/api/UserApiHook";
import { Controller, useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { URLS } from "../_config";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

interface LoginFormValues {
	email: string;
}

const validationSchema = Yup.object().shape({
	email: Yup.string().required("Email is required").email("Email is invalid")
});

export default function ForgotPassword() {

	const { showLoader, hideLoader } = useAppLoader();
	const userApi = useUserApi();
	const navigate = useNavigate()

	const { control, formState: { errors }, handleSubmit, } = useForm<LoginFormValues>({
		resolver: yupResolver(validationSchema),
		defaultValues: {
			email: ""
		},
	});

	const onSubmit = (data: any) => {
		showLoader();
		userApi.forgotPassword(data, (message: string, resp: any) => {
			// console.log({ resp })
			toast.success(message, { duration: 5000 })
			hideLoader();
			navigate(URLS.LOGIN)
		}, (message: string) => {
			toast.error(message)
			hideLoader();
		});
	}

	return (
		<React.Fragment>
			<div className="account-pages my-5 pt-sm-5 arr-login">
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-md-8 col-lg-6 col-xl-5">
							<div className="card overflow-hidden">
								<div className="bg-soft-primary">
									<div className="row">
										<div className="col-7">
											<div className=" p-4">
												<h5>Forgot Password!</h5>
												<p>Continue to ARR Management</p>
											</div>
										</div>
										<div className="col-5 align-self-end">
											<img
												src="assets/images/profile-img.png"
												alt=""
												className="img-fluid"
											/>
										</div>
									</div>
								</div>
								<div className="card-body pt-0">
									<div>
										<div className="avatar-md profile-user-wid mb-4">
											<span className="avatar-title rounded-circle ">
												<img
													src="assets/images/logo-light.png"
													alt=""
													height={34}
													className="rounded-circle"
												/>
											</span>
										</div>
									</div>
									<div className="p-2">
										<form className="form-horizontal" >
											<div className="form-group">
												<label htmlFor="email">Email
													<span className='text-danger'>*</span>
												</label>
												<Controller
													name={"email"}
													control={control}
													render={({ field: { value, onChange, onBlur, name } }) => (
														<>
															<input
																type="email"
																className={"form-control " + (errors?.email ? ' border-danger' : '')}
																id="email"
																name={name}
																onChange={onChange}
																value={value}
																onBlur={onBlur}
																placeholder="Enter email"
															/>
														</>
													)}
												/>
												{errors && errors.email &&
													<span className='text-danger'>{errors?.email?.message}</span>
												}
											</div>
											<div className="mt-3">
												<button
													type="submit"
													className="btn btn-primary btn-block waves-effect waves-light"
													onClick={handleSubmit(onSubmit)}
												>
													Submit
												</button>
											</div>
										</form>
										<div className="mt-4 text-center">
											<Link to={URLS.LOGIN} className="text-muted">
												<i className="mr-1" /> Back to Login
											</Link>
										</div>
									</div>
								</div>
							</div>
							<div className="mt-5 text-center">
								<div>
									<p>
										Don't have an account ?
										<Link
											to={URLS.SIGNUP}
											className="font-weight-medium text-primary"
										>{" "}
											Signup now
										</Link>
									</p>
									<p>© ARR Management</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
}