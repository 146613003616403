import React, { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useUserApi } from '../_common/hooks/api/UserApiHook';
import { useAppLoader } from '../_common/hooks/common/appLoaderHook';
import { toast } from 'react-hot-toast';
import { URLS } from '../_config';

const Authtentication = () => {

  const [searchParams] = useSearchParams();
  const code: any = searchParams.get('code');
  const userApi = useUserApi();
  const navigate = useNavigate();

  const { showLoader, hideLoader } = useAppLoader();

  useEffect(() => {
    // console.log(code)
    if (code) {
      showLoader();
      userApi.verifyEmail({
        token: code
      }, (message: string, resp: any) => {
        toast.success(message);
        navigate(URLS.LOGIN);
        hideLoader();
      }, (message: string) => {
        toast.error(message);
        navigate(URLS.LOGIN);
        hideLoader();
      })
    }
  }, [code])

  return (
    <React.Fragment>
      <div
        className='d-flex justify-content-center align-items-center font-size-24 min-vh-100'
      >
        Authtenticating
      </div>
    </React.Fragment>
  )
}

export default Authtentication