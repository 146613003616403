import React, { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { Link, useNavigate } from 'react-router-dom'
import Footer from '../../components/commonComponents/Footer'
import Header from '../../components/commonComponents/Header'
import LeftPanel from '../../components/commonComponents/LeftPanel'
import SalesforceConnectModal from '../../components/modals/salesforceConnect.modal'
import { store } from '../../store'
import { useCommonApi } from '../../_common/hooks/api/common/commonApiHook'
import { useUserApi } from '../../_common/hooks/api/UserApiHook'
import { useAppLoader } from '../../_common/hooks/common/appLoaderHook'
import { useConnentedPlatformsSelector, useSalesforceDataSelector } from '../../_common/hooks/selectors/platformSelector'
import { useUserSelector } from '../../_common/hooks/selectors/userSelector'
import { ACTIONS, URLS } from '../../_config'
import ZendeskConnectModal from '../../components/modals/zendeskConnection.modal'

// const credentials = {
//     client_id: "3MVG9n_HvETGhr3BMIEB2fZ2X3PGl_lpvNZoccYl.bZVhhy1z3qUOvBEoho.Gp5i093Sz7_soWVmHIiPd0VqO",
//     client_secret: "FBA09B59C5CB1CAF57BDA8879D9C319D91B29388849F308A75718DA3CF82C05A",
// }

const ExternalApplication = () => {

    const userApi = useUserApi();
    const commonApi = useCommonApi();
    const user: any = useUserSelector(); console.log({ user })
    const salesforceData: any = useSalesforceDataSelector();
    const connectedPlatforms: any = useConnentedPlatformsSelector(); console.log({ connectedPlatforms })
    const navigate = useNavigate()

    const { showLoader, hideLoader } = useAppLoader();
    const [showSalesforceModal, setShowSalesforceModal] = useState<boolean>(false);
    const [showZendeskModal, setShowZendeskModal] = useState<boolean>(false);
    const [salesforceCredentials, setSalesforceCredentials] = useState<object>({});
    const [platforms, setPlatforms] = useState<any>([]);

    const closeSalesforceModal = () => {
        setShowSalesforceModal(false)
    }
    const closeZendeskModal = () => {
        setShowZendeskModal(false)
    }

    const getPlatforms = () => {
        showLoader()
        commonApi.getPlatforms({},
            (message: string, resp: any) => {
                // console.log({ resp })
                setPlatforms(resp?.data)
                hideLoader()
            }, (message: string) => {
                hideLoader()
            })
    }

    const updateUserPlatform = (data: any) => {
        showLoader()
        userApi.updateUserPlatform(data,
            (message: string, resp: any) => {
                // console.log({ resp })
                store.dispatch({
                    type: ACTIONS.SALESFORCE.RESET_DATA,
                });
                toast.success(message)
                navigate(URLS.REVENUE)
                hideLoader()
            }, (message: string) => {
                toast.error(message)
                hideLoader()
            })
    }

    const showExternalApplication = (applicationName: string) => {
        const data = platforms.find((item: any) => item.name === applicationName);
        if (data && data.status === '1') {
            return true;
        }
        return false;
    }

    useEffect(() => {
        getPlatforms()
    }, [])

    useEffect(() => {
        if (salesforceData) {
            let data: any = {}
            if (salesforceData.client_id) {
                data["client_id"] = salesforceData.client_id
            }
            if (salesforceData.client_secret) {
                data["client_secret"] = salesforceData.client_secret
            }
            if (salesforceData.client_code) {
                data["client_code"] = salesforceData.client_code
            }
            data["platform_id"] = 1
            // console.log("salesdata===>", data)
            updateUserPlatform(data)
        }
    }, [salesforceData])

    useEffect(() => {
        if (connectedPlatforms && connectedPlatforms.length > 0) {
            setSalesforceCredentials({
                client_id: connectedPlatforms[0]?.client_id,
                client_secret: connectedPlatforms[0]?.client_secret
            })
        }
    }, [connectedPlatforms])

    return (
        <React.Fragment>
            <SalesforceConnectModal
                show={showSalesforceModal}
                close={closeSalesforceModal}
                credentials={salesforceCredentials}
            />
            <ZendeskConnectModal
                show={showZendeskModal}
                close={closeZendeskModal}
            />
            <div id="layout-wrapper">
                <Header />
                <LeftPanel />
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            {/* start page title */}
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-flex align-items-center justify-content-between title-mob-fix">
                                        <h4 className="mb-0 font-size-18">Connects External Application </h4>
                                        <div className="page-title-right">
                                            <ol className="breadcrumb m-0">
                                                <li className="breadcrumb-item">
                                                    <Link to="#">Revenue</Link>
                                                </li>
                                                <li className="breadcrumb-item active">
                                                    Connects External Application
                                                </li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="d-flex flex-wrap mb-3">
                                                <h5 className="card-title mr-2">Connects External Application</h5>
                                            </div>
                                            <div className="row">
                                                <div className={"col-lg-4 col-md-6 col-12 " + (showExternalApplication("Salesforce") ? "d-block" : "d-none")}>
                                                    <div className="application-box">
                                                        <div className="application-box-img">
                                                            <img src="assets/images/sales-force.png" alt="#" />
                                                        </div>
                                                        <div className="application-box-btn">
                                                            <button
                                                                type="button"
                                                                className="btn btn-primary"
                                                                data-toggle="modal"
                                                                onClick={() => setShowSalesforceModal(true)}
                                                            >
                                                                Manage Connections
                                                            </button>
                                                            <button
                                                                type="button"
                                                                className="btn btn-outline-primary"
                                                                onClick={() => navigate(URLS.SF_CONNECTION)}
                                                            >
                                                                Manage Mapping
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={"col-lg-4 col-md-6 col-12 " + (showExternalApplication("Zendesk") ? "d-block" : "d-none")}>
                                                    <div className="application-box">
                                                        <div className="application-box-img">
                                                            <img src="assets/images/zendesk.png" alt="#" />
                                                        </div>
                                                        <div className="application-box-btn">
                                                            <button
                                                                type="button"
                                                                className="btn btn-primary"
                                                                onClick={() => setShowZendeskModal(true)}
                                                            >
                                                                Manage Connections
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={"col-lg-4 col-md-6 col-12 " + (showExternalApplication("ZohoCRM") ? "d-block" : "d-none")}>
                                                    <div className="application-box">
                                                        <div className="application-box-img">
                                                            <img src="assets/images/zoho.png" alt="#" />
                                                        </div>
                                                        <div className="application-box-btn">
                                                            <button type="button" className="btn btn-primary">
                                                                Manage Connections
                                                            </button>
                                                            <button type="button" className="btn btn-primary">
                                                                Manage Mapping
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={"col-lg-4 col-md-6 col-12 " + (showExternalApplication("Hobspot") ? "d-block" : "d-none")}>
                                                    <div className="application-box">
                                                        <div className="application-box-img">
                                                            <img src="assets/images/hubspot.png" alt="#" />
                                                        </div>
                                                        <div className="application-box-btn">
                                                            <button type="button" className="btn btn-primary">
                                                                Manage Connections
                                                            </button>
                                                            <button type="button" className="btn btn-primary">
                                                                Manage Mapping
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={"col-lg-4 col-md-6 col-12 " + (showExternalApplication("Microsoft Dynamics") ? "d-block" : "d-none")}>
                                                    <div className="application-box">
                                                        <div className="application-box-img">
                                                            <img src="assets/images/microsoft.png" alt="#" />
                                                        </div>
                                                        <div className="application-box-btn">
                                                            <button type="button" className="btn btn-primary">
                                                                Manage Connections
                                                            </button>
                                                            <button type="button" className="btn btn-primary">
                                                                Manage Mapping
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>{" "}
                                {/* end col */}
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </React.Fragment>
    )
}

export default ExternalApplication