import React, { useEffect, useState } from "react";
import { useAppLoader } from '../_common/hooks/common/appLoaderHook';
import { useUserApi } from "../_common/hooks/api/UserApiHook";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { URLS } from "../_config";
import * as Yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { handleKeyPressNumber } from "../_common/functions";
import { useCommonApi } from "../_common/hooks/api/common/commonApiHook";
import Select from "react-select";
import InputMask from "react-input-mask";
import { countriesData, employeesData } from "../_common/staticData";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

interface SignUpFormValues {
	firstName: string;
	lastName: string;
	workEmail: string;
	jobTitle: string;
	company: string;
	employees: string;
	phone: string;
	country: string;
	state: string;
	password: string;
	confirmPassword: string;
}

const validationSchema = Yup.object().shape({
	firstName: Yup.string().max(20, "First Name can be of maximum 20 characters").required("First Name is required"),
	lastName: Yup.string().max(20, "Last Name can be of maximum 20 characters").required("Last Name is required"),
	workEmail: Yup.string().required("Work Email is required").email("Work Email is invalid"),
	jobTitle: Yup.object().required("Job Title is required"),
	company: Yup.string().required("Company is required"),
	employees: Yup.object().required("Employees is required"),
	phone: Yup.string().min(14, "Phone must be of 10 characters").max(14, "Phone must be of 10 characters").required("Phone is required"),
	country: Yup.object().required("Country is required"),
	state: Yup.string().required("State is required"),
	password: Yup.string().required("Password is required").min(6, "Password must be at least 6 characters"),
	confirmPassword: Yup.string().required("Confirm Password is required").min(6).oneOf([Yup.ref('password'), ''], 'Passwords must match'),
});


export default function Signup() {

	const { showLoader, hideLoader } = useAppLoader();
	const userApi = useUserApi();
	const commonApi = useCommonApi();
	const navigate = useNavigate();
	const [jobTitleOptions, setJobTitleOptions] = useState<any>([])
	const [employeesOptions, setEmployeesOptions] = useState<any>(employeesData)
	const [countriesOptions, setCountriesOptions] = useState<any>(countriesData)
	const [showPassword, setShowPassword] = useState<boolean>(false)
	const [showCPassword, setShowCPassword] = useState<boolean>(false)

	const { control, formState: { errors }, handleSubmit } = useForm<SignUpFormValues>({
		resolver: yupResolver(validationSchema),
		defaultValues: {
			firstName: "",
			lastName: "",
			workEmail: "",
			jobTitle: "",
			company: "",
			employees: "",
			phone: "",
			country: "",
			state: "",
			password: "",
			confirmPassword: "",
		},
	});

	const onSubmit = (data: any) => {
		console.log({ data })
		let params: any = {
			first_name: data.firstName,
			last_name: data.lastName,
			email: data.workEmail,
			phone: data.phone,
			company: data.company,
			employee_number: data.employees.value,
			jobtitle: data.jobTitle.value,
			country: data.country.value,
			state: data.state,
			password: data.password,
		}
		showLoader();
		userApi.signup(params, (message: string, resp: any) => {
			// console.log({ resp })
			toast.success(resp?.message, { duration: 5000 })
			navigate(URLS.LOGIN)
			hideLoader();
		}, (message: string) => {
			toast.error(message)
			hideLoader();
		});
	}
	// console.log({ errors })

	const getJobTitle = () => {
		commonApi.getJobTitle({},
			(message: string, resp: any) => {
				console.log(resp.data.list)
				if (resp.data && resp.data.list.length > 0) {
					setJobTitleOptions(resp.data.list.map((item: any) => {
						item.label = item.jobtitle;
						item.value = item.id;
						return item;
					}))
				}
			}, (message: string) => { })
	}

	useEffect(() => {
		getJobTitle()
	}, [])

	return (
		<React.Fragment>
			<div className="account-pages my-5 pt-sm-5 arr-login">
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-md-8 col-lg-6 col-xl-5">
							<div className="card overflow-hidden">
								<div className="bg-soft-primary">
									<div className="row">
										<div className="col-7">
											<div className=" p-4">
												<h5>Sign In!</h5>
												<p>Continue to ARR Management</p>
											</div>
										</div>
										<div className="col-5 align-self-end">
											<img
												src="assets/images/profile-img.png"
												alt=""
												className="img-fluid"
											/>
										</div>
									</div>
								</div>
								<div className="card-body pt-0">
									<div>
										<div className="avatar-md profile-user-wid mb-4">
											<span className="avatar-title rounded-circle ">
												<img
													src="assets/images/logo-light.png"
													alt=""
													height={34}
													className="rounded-circle"
												/>
											</span>
										</div>
									</div>
									<div className="p-2">
										<form className="form-horizontal" onSubmit={handleSubmit(onSubmit)}>
											<div className="row">
												<div className="form-group col-6">
													<label htmlFor="firstName">First name <span className='text-danger'>*</span></label>
													<Controller
														name={"firstName"}
														control={control}
														render={({ field: { value, onChange, onBlur, name } }) => (
															<>
																<input
																	type="text"
																	className={"form-control " + (errors?.firstName ? ' border-danger' : '')}
																	id="firstName"
																	name={name}
																	onChange={onChange}
																	value={value}
																	onBlur={onBlur}
																	placeholder="Enter first name"
																/>
															</>
														)}
													/>
													{errors && errors.firstName &&
														<span className='text-danger'>{errors?.firstName?.message}</span>
													}
												</div>
												<div className="form-group col-6">
													<label htmlFor="lastName">Last Name <span className='text-danger'>*</span></label>
													<Controller
														name={"lastName"}
														control={control}
														render={({ field: { value, onChange, onBlur, name } }) => (
															<>
																<input
																	type="text"
																	className={"form-control " + (errors?.lastName ? ' border-danger' : '')}
																	id="lastName"
																	name={name}
																	onChange={onChange}
																	value={value}
																	onBlur={onBlur}
																	placeholder="Enter last name"
																/>
															</>
														)}
													/>
													{errors && errors.lastName &&
														<span className='text-danger'>{errors?.lastName?.message}</span>
													}
												</div>
											</div>
											<div className="row">
												<div className="form-group col-6">
													<label htmlFor="workEmail">Work Email <span className='text-danger'>*</span></label>
													<Controller
														name={"workEmail"}
														control={control}
														render={({ field: { value, onChange, onBlur, name } }) => (
															<>
																<input
																	type="email"
																	className={"form-control " + (errors?.workEmail ? ' border-danger' : '')}
																	id="workEmail"
																	name={name}
																	onChange={onChange}
																	value={value}
																	onBlur={onBlur}
																	placeholder="Enter work email"
																/>
															</>
														)}
													/>
													{errors && errors.workEmail &&
														<span className='text-danger'>{errors?.workEmail?.message}</span>
													}
												</div>
												<div className="form-group col-6">
													<label htmlFor="jobTitle">Job Title <span className='text-danger'>*</span></label>
													<Controller
														name={"jobTitle"}
														control={control}
														render={({ field: { value, onChange, onBlur, name } }) => (
															<>
																<Select
																	className={"select-box " + (errors?.jobTitle ? ' border-danger' : '')}
																	options={jobTitleOptions ? jobTitleOptions : []}
																	placeholder={"Select job title"}
																	onChange={onChange}
																	value={value}
																	defaultValue={value}
																/>
															</>
														)}
													/>
													{errors && errors.jobTitle &&
														<span className='text-danger'>Job Title is required</span>
													}
												</div>
											</div>
											<div className="row">
												<div className="form-group col-6">
													<label htmlFor="company">Company <span className='text-danger'>*</span></label>
													<Controller
														name={"company"}
														control={control}
														render={({ field: { value, onChange, onBlur, name } }) => (
															<>
																<input
																	type="text"
																	className={"form-control " + (errors?.company ? ' border-danger' : '')}
																	id="company"
																	name={name}
																	onChange={onChange}
																	value={value}
																	onBlur={onBlur}
																	placeholder="Enter company"
																/>
															</>
														)}
													/>
													{errors && errors.company &&
														<span className='text-danger'>{errors?.company?.message}</span>
													}
												</div>
												<div className="form-group col-6">
													<label htmlFor="employees">Employees <span className='text-danger'>*</span></label>
													<Controller
														name="employees"
														control={control}
														render={({ field: { value, onChange, onBlur, name } }) => (
															<>
																<Select
																	className={"select-box " + (errors?.employees ? ' border-danger' : '')}
																	options={employeesOptions ? employeesOptions : []}
																	placeholder={"Select employees"}
																	onChange={onChange}
																	value={value}
																	defaultValue={value}
																/>
															</>
														)}
													/>
													{errors && errors.employees &&
														<span className='text-danger'>Employees is required</span>
													}
												</div>
											</div>
											<div className="row">
												<div className="form-group col-6">
													<label htmlFor="phone">Phone <span className='text-danger'>*</span></label>
													<Controller
														name="phone"
														control={control}
														render={({ field: { value, onChange, onBlur, name } }) => (
															<>
																<InputMask
																	mask="(999) 999-9999"
																	maskChar="_"
																	className={"form-control " + (errors?.phone ? ' border-danger' : '')}
																	id="phone"
																	name={name}
																	onChange={onChange}
																	value={value}
																	onBlur={onBlur}
																	placeholder="Enter phone"
																	onKeyPress={(event: any) => handleKeyPressNumber(event)}
																/>
															</>
														)}
													/>
													{errors && errors.phone &&
														<span className='text-danger'>{errors?.phone?.message}</span>
													}
												</div>
												<div className="form-group col-6">
													<label htmlFor="country">Country <span className='text-danger'>*</span></label>
													<Controller
														name="country"
														control={control}
														render={({ field: { value, onChange, onBlur, name } }) => (
															<>
																<Select
																	className={"select-box " + (errors?.country ? ' border-danger' : '')}
																	options={countriesOptions ? countriesOptions : []}
																	placeholder={"Select country"}
																	onChange={onChange}
																	value={value}
																	defaultValue={value}
																/>
															</>
														)}
													/>
													{errors && errors.country &&
														<span className='text-danger'>Country is required</span>
													}
												</div>
											</div>
											<div className="row">
												<div className="form-group col-6">
													<label htmlFor="state">State <span className='text-danger'>*</span></label>
													<Controller
														name="state"
														control={control}
														render={({ field: { value, onChange, onBlur, name } }) => (
															<>
																<input
																	type="text"
																	className={"form-control " + (errors?.state ? ' border-danger' : '')}
																	id="state"
																	name={name}
																	onChange={onChange}
																	value={value}
																	onBlur={onBlur}
																	placeholder="Enter state"
																/>
															</>
														)}
													/>
													{errors && errors.state &&
														<span className='text-danger'>{errors?.state?.message}</span>
													}
												</div>
												<div className="form-group col-6">
													<label htmlFor="password">Password
														<span className='text-danger'>*</span>
													</label>
													<div className="cstm-password">
														<Controller
															name="password"
															control={control}
															render={({ field: { value, onChange, onBlur, name } }) => (
																<>
																	<input
																		type={!showPassword ? "password" : "text"}
																		className={"form-control " + (errors?.password ? ' border-danger' : '')}
																		id="password"
																		autoComplete="new-password"
																		name={name}
																		onChange={onChange}
																		value={value}
																		onBlur={onBlur}
																		placeholder="Enter password"
																	/>
																</>
															)}
														/>
														{!showPassword ?
															<AiOutlineEyeInvisible onClick={() => setShowPassword(!showPassword)} />
															:
															<AiOutlineEye onClick={() => setShowPassword(!showPassword)} />
														}
													</div>
													{errors && errors.password &&
														<span className='text-danger'>{errors?.password?.message}</span>
													}
												</div>
											</div>
											<div className="row">
												<div className="form-group col-6">
													<label htmlFor="confirmPassword">Confirm Password
														<span className='text-danger'>*</span>
													</label>
													<div className="cstm-password">
														<Controller
															name="confirmPassword"
															control={control}
															render={({ field: { value, onChange, onBlur, name } }) => (
																<>
																	<input
																		type={!showCPassword ? "password" : "text"}
																		className={"form-control " + (errors?.confirmPassword ? ' border-danger' : '')}
																		id="confirmPassword"
																		autoComplete="new-password"
																		name={name}
																		onChange={onChange}
																		value={value}
																		onBlur={onBlur}
																		placeholder="Re-Enter password"
																	/>
																</>
															)}
														/>
														{!showCPassword ?
															<AiOutlineEyeInvisible onClick={() => setShowCPassword(!showCPassword)} />
															:
															<AiOutlineEye onClick={() => setShowCPassword(!showCPassword)} />
														}
													</div>
													{errors && errors.confirmPassword &&
														<span className='text-danger'>{errors?.confirmPassword?.message}</span>
													}
												</div>
											</div>
											<div className="mt-3">
												<button
													type="submit"
													className="btn btn-primary btn-block waves-effect waves-light"
												>
													SignUp
												</button>
											</div>
										</form>
									</div>
								</div>
							</div>
							<div className="mt-5 text-center">
								<div>
									<p>
										Already have an account ?
										<Link
											to={URLS.LOGIN}
											className="font-weight-medium text-primary"
										>{" "}
											Login now
										</Link>
									</p>
									<p>© ARR Management</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
}