import React, { useEffect, useMemo, useState } from 'react'
import { usePlatformApi } from '../../_common/hooks/api/PlatformApiHook'
import { useAppLoader } from '../../_common/hooks/common/appLoaderHook';
import TableContainer from '../commonComponents/TableContainer'
import DeleteButtonCell from '../actionsBtns/DeleteButtonCell';

interface IncomingProps {
    AccountId: string;
    show: boolean;
}

const AccountPartnerListing = ({ AccountId, show }: IncomingProps) => {

    const platformApi = usePlatformApi();
    const { showLoader, hideLoader } = useAppLoader();

    const [accountPartnerData, setAccountPartnerData] = useState<any>(null)
    const [data, setData] = useState<any>([])
    const [loading, setLoading] = useState<boolean>(false)

    const getAccountPartnerData = (page = 1) => {
        showLoader();
        setLoading(true);
        platformApi.getAccountPartner({
            page: page,
            AccountId: AccountId,
            limit: 4
        },
            (message: string, resp: any) => {
                // console.log(resp);
                setLoading(false);
                setAccountPartnerData(resp.data.list);
                setData(resp.data.list.data);
                hideLoader();
            }, (message: string) => {
                setLoading(false);
                hideLoader();
            })
    }

    const deleteAccountTeam = (id: any) => {
        return new Promise((resolve: any, reject: any) => {
            platformApi.deleteAccountPartner({
                id
            }, (message: string, resp: any) => {
                resolve(message)
            }, (message: string) => {
                reject(message);
            });
        })
    }

    const columns = useMemo(
        () => [
            {
                Header: 'Actions',
                accessor: "id",
                Cell: ({ column, value }: any) =>
                    <>
                        <DeleteButtonCell
                            column={column}
                            value={value}
                            deleteFunc={deleteAccountTeam}
                            callbackFunc={[getAccountPartnerData]}
                            type={"Partner Team"}
                        />
                    </>,
            },
            {
                Header: "Name",
                accessor: "partner.name",
            },
            {
                Header: "Email",
                accessor: "partner.email",
            },
        ],
        []
    )

    useEffect(() => {
        if (!show) {
            getAccountPartnerData();
        }
    }, [show])


    return (
        <React.Fragment>
            <div className="table-responsive">
                <TableContainer
                    columns={columns}
                    data={data}
                    fetchData={getAccountPartnerData}
                    loading={loading}
                    rawData={accountPartnerData}
                    small={true}
                    cstmClassName={"table-scroll-overflow"}
                />
            </div>
        </React.Fragment>
    )
}

export default AccountPartnerListing