import React from 'react'

const CommingSoon = () => {
    return (
        <div className="row">
            <div className="col-12">
                <div className="card">
                    <div className="card-body">
                        <div className="comming-soon-text">
                            <h1>Coming Soon</h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CommingSoon