import React, { useEffect, useRef, useState } from "react"
import { useAsyncDebounce, useTable, useGlobalFilter, useSortBy, usePagination } from "react-table";
import { BiDownArrowAlt, BiSort, BiUpArrowAlt } from "react-icons/bi"
import { useNavigate } from "react-router-dom";

interface IncomingProps {
    columns: any;
    data: any;
    fetchData: any;
    loading: boolean;
    rawData: any;
    clickFunc?: boolean;
    small?: boolean;
    extraLarge?: boolean;
    cstmClassName?: string;
}

function GlobalFilter({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
}: any) {

    const count = preGlobalFilteredRows.length
    const [value, setValue] = useState(globalFilter)

    const onChange = useAsyncDebounce(value => {
        setGlobalFilter(value || undefined)
    }, 200)

    return (
        <div className="dataTables_filter d-flex m-2 justify-content-end">
            <label
                className="d-flex align-items-center gap-5"
            >
                Search:
                <input
                    value={value || ""}
                    onChange={e => {
                        setValue(e.target.value);
                        onChange(e.target.value);
                    }}
                    placeholder={`${count} records...`}
                    className="form-control form-control-sm"
                />
            </label>
        </div>
    )
}

const TableContainer = ({
    columns,
    data,
    fetchData,
    loading,
    rawData,
    clickFunc = false,
    small = false,
    extraLarge = false,
    cstmClassName = "",
}: IncomingProps) => {

    const navigate = useNavigate();

    const [totalPages, setTotalPages] = useState(rawData?.last_page);
    const [currentPage, setCurrentPage] = useState(rawData?.current_page | 1);
    const isFirstRenderRef = useRef(true);
    const clickableFields = ['Account Number', 'Opportunity Id', 'Quote Id', 'Quote Name', 'PriceBook Id', 'Account Name', 'Account', 'Contract Number', 'Contract', 'Rule']

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        state: { globalFilter },
        preGlobalFilteredRows,
        setGlobalFilter,
        prepareRow,
    } = useTable({
        columns,
        data,
        manual: true
    },
        useGlobalFilter,
        useSortBy,
        usePagination
    )

    const handlePageChange = (newPage: number) => {
        setCurrentPage(newPage);
    };

    const handleCellClick = (data: any) => {
        // console.log(data?.row?.original?.QuoteId)
        if (clickFunc) {
            if (data?.column?.Header == 'Account Number') {
                // console.log(data)
                navigate(`/account-view/${data?.value}`)
            }
            if (data?.column?.Header == 'Opportunity Id') {
                // console.log(data)
                navigate(`/opportunity-details/${data?.value}`)
            }
            if (data?.column?.Header == 'Quote Id' || data?.column?.Header == 'Quote Name') {
                // console.log(data)
                // navigate(`/quote-details/${data?.value}`)
                navigate(`/quote-details/${data?.row?.original?.QuoteId && data?.row?.original?.QuoteId != 'undefined' ? data?.row?.original?.QuoteId : data?.row?.original?.quoteobject?.QuoteId}`)
            }
            if (data?.column?.Header == 'PriceBook Id') {
                // console.log(data)
                navigate(`/pricebook-details/${data?.value}`)
            }
            // console.log(data?.row?.original, data?.column?.Header)
            if (data?.column?.Header == 'Account Name' || data?.column?.Header == 'Account') {
                // console.log({data})
                navigate(`/account-view/${data?.row?.original?.AccountId && data?.row?.original?.AccountId != 'undefined' ? data?.row?.original?.AccountId : data?.row?.original?.quoteobject?.account?.AccountId}`)
            }
            if (data?.column?.Header == 'Contract Number' || data?.column?.Header == 'Contract') {
                // console.log({data})
                navigate(`/contract-details/${data?.row?.original?.ContractId}`)
            }
            if (data?.column?.Header == 'Rule') {
                // console.log({data})
                navigate(`/approver-rule-details/${data?.row?.original?.rules}`)
            }
        }
    }

    useEffect(() => {
        if (!isFirstRenderRef.current) {
            fetchData(currentPage);
        } else {
            isFirstRenderRef.current = false;
        }
    }, [currentPage])

    useEffect(() => {
        // console.log({rawData})
        setTotalPages(rawData?.last_page)
    }, [rawData])

    return (
        <React.Fragment>
            <GlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
            />
            <div className={cstmClassName}>
                <table
                    id="datatable"
                    className={"table table-striped dt-responsive" + (small ? "nowrap" : extraLarge ? "dataTable no-footer general-table large-table" : "dataTable no-footer general-table")}
                    style={{
                        borderCollapse: "collapse",
                        borderSpacing: 0,
                        width: "100%"
                    }}
                    {...getTableProps()}
                >
                    <thead>

                        {headerGroups.map((headerGroup: any) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {/* <th>Actions</th> */}
                                {headerGroup.headers.map((column: any) => (
                                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                        <div className="d-flex justify-content-between gap-10">
                                            {column.render("Header")}
                                            <span>
                                                {column.isSorted
                                                    ? column.isSortedDesc
                                                        ? <BiDownArrowAlt />
                                                        : <BiUpArrowAlt />
                                                    : <BiSort />}
                                            </span>
                                        </div>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>

                    <tbody {...getTableBodyProps()}>
                        {rows.map((row: any) => {
                            prepareRow(row)
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map((cell: any) => {
                                        return <td {...cell.getCellProps()}
                                            onClick={() => handleCellClick(cell)}
                                            className={cell?.column?.Header ? clickableFields.includes(cell?.column?.Header) ? "c-pointer" : "" : ""}
                                        >
                                            {cell.render("Cell")}
                                        </td>
                                    })}
                                </tr>
                            )
                        })}
                        {loading ?
                            <tr>
                                <td colSpan={10000}>Loading...</td>
                            </tr>
                            :
                            rows.length == 0 ?
                                <tr>
                                    <td colSpan={10000} className="text-center">No Data Found</td>
                                </tr>
                                :
                                null
                        }
                    </tbody>
                </table>
            </div>

            {rawData?.last_page > 1 ?
                <div className="d-flex justify-content-between pagination pt-3">
                    <div>
                        Showing {data.length} of ~ {rawData?.total}{' '}
                        results
                    </div>
                    <div className="cstm-page-nav d-flex gap-5">
                        <button onClick={() => handlePageChange(1)} disabled={currentPage === 1}>
                            {'<<'}
                        </button>
                        <button
                            onClick={() => handlePageChange(currentPage - 1)}
                            disabled={currentPage === 1}
                        >
                            {'<'}
                        </button>
                        <button
                            type="button"
                            className="btn-crnt-pg"
                        >
                            {currentPage}
                        </button>
                        <button
                            onClick={() => handlePageChange(currentPage + 1)}
                            disabled={rawData?.to >= rawData?.total}
                        >
                            {'>'}
                        </button>
                        <button
                            onClick={() => handlePageChange(totalPages)}
                            disabled={rawData?.to >= rawData?.total}
                        >
                            {'>>'}
                        </button>
                        {/* <span className="ml-2">
                            Page{' '}
                            <strong>
                                {currentPage} of {totalPages}
                            </strong>{' '}
                        </span> */}
                    </div>
                </div>
                :
                null
            }
        </React.Fragment>
    )
}

export default TableContainer