import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Footer from '../components/commonComponents/Footer'
import Header from '../components/commonComponents/Header'
import LeftPanel from '../components/commonComponents/LeftPanel'
import { URLS } from '../_config'
import { useAppLoader } from '../_common/hooks/common/appLoaderHook'
import { usePlatformApi } from '../_common/hooks/api/PlatformApiHook'
import { formatNumber } from '../_common/functions'
import ChartContainer from '../components/commonComponents/ChartContainer'
import CommonBoxDetails from '../components/commonComponents/CommonBoxDetails'
import { useUserApi } from '../_common/hooks/api/UserApiHook'
import FilterRow from '../components/commonComponents/FilterRow'
import GeneralTableListingModal from '../components/modals/generalTableListing.modal'
import moment from 'moment'

const Revenue = () => {

    const platformApi = usePlatformApi();
    const userApi = useUserApi();
    const navigate = useNavigate();

    const { showLoader, hideLoader } = useAppLoader()
    const [revenueDetails, setRevenueDetails] = useState<any>(null)
    const [filterDetails, setFilterDetails] = useState<any>({})
    const [doFilter, setDoFilter] = useState<boolean>(false)
    const [lateRenewalsData, setLateRenewalData] = useState<any>([])
    const [showListingModal, setShowListingModal] = useState<boolean>(false)

    const getRevenueDetails = () => {
        showLoader();
        platformApi.getRevenueDetails(filterDetails,
            (message: string, resp: any) => {
                if (resp?.data?.list) {
                    setRevenueDetails(resp?.data?.list)
                }
                hideLoader();
            }, (message: string) => {
                hideLoader();
            })
    }

    const getLateRenewalsList = (page = 1) => {
        showLoader();
        let params: any = { page: page }
        if (filterDetails && Object.keys(filterDetails).length > 0) {
            Object.assign(params, filterDetails)
        }
        platformApi.getLateRenewals(params,
            (message: string, resp: any) => {
                // console.log(resp);
                setLateRenewalData(resp.data.list.data);
                hideLoader();
                setDoFilter(false)
            }, (message: string) => {
                hideLoader();
                setDoFilter(false)
            })
    };

    const gotToContractPage = (quarter: any, type: any) => {
        if (filterDetails && Object.keys(filterDetails).length > 0) {
            navigate(`/contract-page?quarter=${quarter}&type=${type}&year=${filterDetails?.year}&rep=${filterDetails?.rep}&region=${filterDetails?.region}&Status=${filterDetails?.Status}`)
        } else {
            navigate(`/contract-page?quarter=${quarter}&type=${type}`)
        }
    }

    const gotToAccountListPage = (quarter: any, type: any) => {
        if (filterDetails && Object.keys(filterDetails).length > 0) {
            navigate(`/account-list?quarter=${quarter}&type=${type}&year=${filterDetails?.year}&rep=${filterDetails?.rep}&region=${filterDetails?.region}`)
        } else {
            navigate(`/account-list?quarter=${quarter}&type=${type}`)
        }
    }

    const getLoginUserDetails = () => {
        showLoader()
        userApi.getLoginUser({},
            (message: string, resp: any) => {
                if (resp.data.userType == 'primary' && resp.data.user.donebasicsetting == '0') {
                    navigate(URLS.BUSINESS_SETTINGS)
                }
                hideLoader()
            }, (message: string) => {
                hideLoader()
            })
    }

    const handleCloseListingModal = () => {
        setShowListingModal(false)
    }

    useEffect(() => {
        getRevenueDetails()
        getLoginUserDetails()
        getLateRenewalsList()
    }, [])


    return (
        <React.Fragment>
            <GeneralTableListingModal
                show={showListingModal}
                close={handleCloseListingModal}
                filterDetails={filterDetails}
                doFilter={doFilter}
                setDoFilter={setDoFilter}
            />
            <div id="layout-wrapper">
                <Header />
                <LeftPanel />
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-flex align-items-center justify-content-between">
                                        <h4 className="mb-0 font-size-18">Revenue</h4>
                                        <div className="page-title-right">
                                            <ol className="breadcrumb m-0">
                                                <li className="breadcrumb-item">
                                                    <Link to={URLS.REVENUE}>Revenue</Link>
                                                </li>
                                                <li className="breadcrumb-item active">Revenue</li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-12">
                                    <CommonBoxDetails
                                        filterDetails={filterDetails}
                                        doFilter={doFilter}
                                        setDoFilter={setDoFilter}
                                        toUpside={true}
                                    />
                                </div>
                            </div>

                            <div className="card">
                                <div className="card-body">
                                    <FilterRow
                                        type="revenue"
                                        filterDetails={filterDetails}
                                        setFilterDetails={setFilterDetails}
                                        setDoFilter={setDoFilter}
                                        defaultYear={true}
                                        callbackFunctions={[
                                            getRevenueDetails,
                                            getLateRenewalsList
                                        ]}
                                    />
                                </div>
                            </div>


                            <div className="row">
                                <div className="col-lg-12 col-sm-12 col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="d-flex flex-wrap mb-4 resp-mt-4">
                                                <h4 className="card-title ">Fiscal year</h4>
                                                <div className="ml-auto d-flex">
                                                </div>
                                            </div>
                                            <div className="table-responsive">
                                                <table
                                                    id="datatable"
                                                    className="table table-striped dt-responsive"
                                                    style={{
                                                        borderCollapse: "collapse",
                                                        borderSpacing: 0,
                                                        width: "100%"
                                                    }}
                                                >
                                                    <thead>
                                                        <tr>
                                                            <th>Quarter of availble to renew</th>
                                                            <th>Availble to renew ($)</th>
                                                            <th>Open availble to renew ($)</th>
                                                            <th>Forecast Renewal Rate (%)</th>
                                                            <th>Actual C/C ($)</th>
                                                            <th>Actual Base Renewed ($)</th>
                                                            <th>Net Retention Rate (%)</th>
                                                            <th>Actual Renew ($)</th>
                                                            <th>Gross Renewal Rate (%)</th>
                                                            <th>Renewal Forecast ($)</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>FY</td>
                                                            <td onClick={() => gotToContractPage('FY', 'available_to_renew')} className='link-clr'>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.fullyear?.availabletorenew)}</td>
                                                            <td onClick={() => gotToContractPage('FY', 'open_available_to_renew')} className='link-clr'>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.fullyear?.openavailabletorenew)}</td>
                                                            <td>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.fullyear?.forcastedrenewalrate)}</td>
                                                            <td>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.fullyear?.actualcc)}</td>
                                                            <td onClick={() => gotToContractPage('FY', 'actual_base_renewed')} className='link-clr'>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.fullyear?.actualbaserenewed)}</td>
                                                            <td>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.fullyear?.netrelationrate)}</td>
                                                            <td onClick={() => gotToContractPage('FY', 'actual_renewed')} className='link-clr'>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.fullyear?.actualrenew)}</td>
                                                            <td>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.fullyear?.grossrenewalrate)}</td>
                                                            <td onClick={() => gotToContractPage('FY', 'renewal_forecast')} className='link-clr'>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.fullyear?.renewalforcast)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>{revenueDetails?.currentfy} Q1</td>
                                                            <td onClick={() => gotToContractPage('Q1', 'available_to_renew')} className='link-clr'>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.first?.availabletorenew)}</td>
                                                            <td onClick={() => gotToContractPage('Q1', 'open_available_to_renew')} className='link-clr'>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.first?.openavailabletorenew)}</td>
                                                            <td>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.first?.forcastedrenewalrate)}</td>
                                                            <td>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.first?.actualcc)}</td>
                                                            <td onClick={() => gotToContractPage('Q1', 'actual_base_renewed')} className='link-clr'>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.first?.actualbaserenewed)}</td>
                                                            <td>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.first?.netrelationrate)}</td>
                                                            <td onClick={() => gotToContractPage('Q1', 'actual_renewed')} className='link-clr'>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.first?.actualrenew)}</td>
                                                            <td>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.first?.grossrenewalrate)}</td>
                                                            <td onClick={() => gotToContractPage('Q1', 'renewal_forecast')} className='link-clr'>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.first?.renewalforcast)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>{revenueDetails?.currentfy} Q2</td>
                                                            <td onClick={() => gotToContractPage('Q2', 'available_to_renew')} className='link-clr'>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.second?.availabletorenew)}</td>
                                                            <td onClick={() => gotToContractPage('Q2', 'open_available_to_renew')} className='link-clr'>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.second?.openavailabletorenew)}</td>
                                                            <td>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.second?.forcastedrenewalrate)}</td>
                                                            <td>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.second?.actualcc)}</td>
                                                            <td onClick={() => gotToContractPage('Q2', 'actual_base_renewed')} className='link-clr'>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.second?.actualbaserenewed)}</td>
                                                            <td>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.second?.netrelationrate)}</td>
                                                            <td onClick={() => gotToContractPage('Q2', 'actual_renewed')} className='link-clr'>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.second?.actualrenew)}</td>
                                                            <td>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.second?.grossrenewalrate)}</td>
                                                            <td onClick={() => gotToContractPage('Q2', 'renewal_forecast')} className='link-clr'>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.second?.renewalforcast)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>{revenueDetails?.currentfy} Q3</td>
                                                            <td onClick={() => gotToContractPage('Q3', 'available_to_renew')} className='link-clr'>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.third?.availabletorenew)}</td>
                                                            <td onClick={() => gotToContractPage('Q3', 'open_available_to_renew')} className='link-clr'>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.third?.openavailabletorenew)}</td>
                                                            <td>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.third?.forcastedrenewalrate)}</td>
                                                            <td>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.third?.actualcc)}</td>
                                                            <td onClick={() => gotToContractPage('Q3', 'actual_base_renewed')} className='link-clr'>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.third?.actualbaserenewed)}</td>
                                                            <td>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.third?.netrelationrate)}</td>
                                                            <td onClick={() => gotToContractPage('Q3', 'actual_renewed')} className='link-clr'>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.third?.actualrenew)}</td>
                                                            <td>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.third?.grossrenewalrate)}</td>
                                                            <td onClick={() => gotToContractPage('Q3', 'renewal_forecast')} className='link-clr'>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.third?.renewalforcast)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>{revenueDetails?.currentfy} Q4</td>
                                                            <td onClick={() => gotToContractPage('Q4', 'available_to_renew')} className='link-clr'>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.forth?.availabletorenew)}</td>
                                                            <td onClick={() => gotToContractPage('Q4', 'open_available_to_renew')} className='link-clr'>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.forth?.openavailabletorenew)}</td>
                                                            <td>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.forth?.forcastedrenewalrate)}</td>
                                                            <td>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.forth?.actualcc)}</td>
                                                            <td onClick={() => gotToContractPage('Q4', 'actual_base_renewed')} className='link-clr'>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.forth?.actualbaserenewed)}</td>
                                                            <td>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.forth?.netrelationrate)}</td>
                                                            <td onClick={() => gotToContractPage('Q4', 'actual_renewed')} className='link-clr'>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.forth?.actualrenew)}</td>
                                                            <td>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.forth?.grossrenewalrate)}</td>
                                                            <td onClick={() => gotToContractPage('Q4', 'renewal_forecast')} className='link-clr'>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.forth?.renewalforcast)}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className="row">
                                <div className="col-lg-6 col-sm-12 col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="d-flex flex-wrap mb-4 resp-mt-4">
                                                <h4 className="card-title">
                                                    Committed Monthly Recurring Revenue
                                                </h4>
                                            </div>
                                            <div className='w-100'>
                                                <ChartContainer
                                                    filterDetails={filterDetails}
                                                    doFilter={doFilter}
                                                    setDoFilter={setDoFilter}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-sm-12 col-12">
                                    <div className="row-flex flex-wrap gap-10 dashboard-top-panel">
                                        <div className="custom-col w-49">
                                            <div className="card mini-stats-wid card-border-bottom">
                                                <div className="card-body" style={{ cursor: "pointer" }} onClick={() => gotToContractPage('FY', 'customer_retention_rate')}>
                                                    <div className="d-flex flex-wrap gr-fix">
                                                        <div className="mr-3">
                                                            <p className="text-muted mb-2">
                                                                Customer Retention Rate
                                                            </p>
                                                            <h5 className="mb-2">{revenueDetails?.prevfydata?.customerretaintionrate}%</h5>
                                                        </div>
                                                        <div className="avatar-sm ml-auto">
                                                            <div className="avatar-title-sm bg-light rounded-circle text-primary font-size-20">
                                                                <i className="mdi mdi-file-document-outline" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="custom-col w-49">
                                            <div className="card blog-stats-wid card-border-bottom">
                                                <div className="card-body" style={{ cursor: "pointer" }} onClick={() => gotToContractPage('FY', 'churn')}>
                                                    <div className="d-flex flex-wrap gr-fix">
                                                        <div className="mr-3">
                                                            <p className="text-muted mb-2">Churn</p>
                                                            <h5 className="mb-0">${revenueDetails?.prevfydata?.churn}</h5>
                                                            {/* <p className="mb-1">
                                                                FY :<strong>{revenueDetails?.prevfy}</strong>
                                                            </p> */}
                                                        </div>
                                                        <div className="avatar-sm ml-auto">
                                                            <div className="avatar-title-sm bg-light rounded-circle text-primary font-size-20">
                                                                <i className="mdi mdi-chart-bell-curve-cumulative" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="custom-col w-49">
                                            <div className="card blog-stats-wid card-border-bottom">
                                                <div className="card-body" style={{ cursor: "pointer" }} onClick={() => gotToContractPage('FY', 'contraction')}>
                                                    <div className="d-flex flex-wrap gr-fix">
                                                        <div className="mr-3">
                                                            <p className="text-muted mb-2">Contraction</p>
                                                            <h5 className="mb-0">{revenueDetails?.prevfydata?.contraction}</h5>
                                                            {/* <p className="mb-1">
                                                                FY :<strong>{revenueDetails?.prevfy}</strong>
                                                            </p> */}
                                                        </div>
                                                        <div className="avatar-sm ml-auto">
                                                            <div className="avatar-title-sm bg-light rounded-circle text-primary font-size-20">
                                                                <i className="mdi mdi-autorenew" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="custom-col w-49">
                                            <div className="card blog-stats-wid card-border-bottom">
                                                <div className="card-body" style={{ cursor: "pointer" }} onClick={() => gotToContractPage('FY', 'customer_churn_rate')}>
                                                    <div className="d-flex flex-wrap gr-fix">
                                                        <div className="mr-3">
                                                            <p className="text-muted mb-2">Customer Churn Rate</p>
                                                            <h5 className="mb-0">{revenueDetails?.prevfydata?.customerchurnrate}%</h5>
                                                        </div>
                                                        <div className="avatar-sm ml-auto">
                                                            <div className="avatar-title-sm bg-light rounded-circle text-primary font-size-20">
                                                                <i className="mdi mdi-file-multiple-outline" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="custom-col w-49">
                                            <div className="card mini-stats-wid card-border-bottom">
                                                <div className="card-body" style={{ cursor: "pointer" }} onClick={() => gotToContractPage('FY', 'expansion')}>
                                                    <div className="d-flex flex-wrap gr-fix">
                                                        <div className="mr-3">
                                                            <p className="text-muted mb-2">Expansion</p>
                                                            <h5 className="mb-2">${revenueDetails?.prevfydata?.expansion}</h5>
                                                        </div>
                                                        <div className="avatar-sm ml-auto">
                                                            <div className="avatar-title-sm bg-light rounded-circle text-primary font-size-20">
                                                                <i className="mdi mdi-arrow-expand-all" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="custom-col w-49">
                                            <div className="card blog-stats-wid card-border-bottom">
                                                <div className="card-body" style={{ cursor: "pointer" }} onClick={() => gotToContractPage('FY', 'uplift')}>
                                                    <div className="d-flex flex-wrap gr-fix">
                                                        <div className="mr-3">
                                                            <p className="text-muted mb-2">Uplift</p>
                                                            <h5 className="mb-0">${revenueDetails?.prevfydata?.uplift}</h5>
                                                        </div>
                                                        <div className="avatar-sm ml-auto">
                                                            <div className="avatar-title-sm bg-light rounded-circle text-primary font-size-20">
                                                                <i className="bx bx-trending-up" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="custom-col w-49">
                                            <div className="card blog-stats-wid card-border-bottom">
                                                <div className="card-body" style={{ cursor: "pointer" }} onClick={() => gotToContractPage('FY', 'cross_sale')}>
                                                    <div className="d-flex flex-wrap gr-fix">
                                                        <div className="mr-3">
                                                            <p className="text-muted mb-2">Cross Sale</p>
                                                            <h5 className="mb-0">${revenueDetails?.prevfydata?.crosssale}</h5>
                                                        </div>
                                                        <div className="avatar-sm ml-auto">
                                                            <div className="avatar-title-sm bg-light rounded-circle text-primary font-size-20">
                                                                <i className="dripicons-media-shuffle" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-lg-6 col-md-12 col-12">
                                    <div className="card">
                                        <div className="card-body" style={{ maxHeight: "450px", minHeight: "450px" }}>
                                            <div className="d-flex flex-wrap mb-4">
                                                <h4 className="card-title">Late Renewals</h4>
                                            </div>
                                            <div>
                                                {/* <LateRenewalsList
                                                    filterDetails={filterDetails}
                                                    doFilter={doFilter}
                                                    setDoFilter={setDoFilter}
                                                    lateRenewalsData={lateRenewalsData}
                                                    setLateRenewalData={setLateRenewalData}
                                                /> */}
                                                <div className="table-responsive">
                                                    <table id="datatable2" className="table table-striped dt-responsive nowrap"
                                                        style={{ borderCollapse: "collapse", borderSpacing: "0", width: "100%" }}>
                                                        <thead>
                                                            <tr>
                                                                <th>Account</th>
                                                                <th>Contract</th>
                                                                <th>Due Date</th>
                                                                <th>Renew Date</th>
                                                                <th>Amount ($)</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {lateRenewalsData && lateRenewalsData.length > 0 ?
                                                                lateRenewalsData.map((data: any, index: number) => {
                                                                    if (index <= 4) {
                                                                        return (
                                                                            <tr key={Math.random()}>
                                                                                <td
                                                                                    className='cursor-pointer'
                                                                                    onClick={() => navigate(`/account-view/${data?.account?.AccountId}`)}
                                                                                >
                                                                                    {data?.account?.Name}
                                                                                </td>
                                                                                <td
                                                                                    className='cursor-pointer'
                                                                                    onClick={() => navigate(`/contract-details/${data?.ContractId}`)}
                                                                                >
                                                                                    {data?.ContractNumber}
                                                                                </td>
                                                                                <td>{data?.EndDate ? moment(data?.EndDate).format("MM/DD/YY") : ""}</td>
                                                                                <td>{data?.RenewDate ? moment(data?.RenewDate).format("MM/DD/YY") : ""}</td>
                                                                                <td>{data?.ContractValue ? formatNumber(data?.ContractValue) : "0.00"}</td>
                                                                            </tr>
                                                                        )
                                                                    }
                                                                })
                                                                :
                                                                null
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                                {lateRenewalsData && lateRenewalsData.length > 4 ?
                                                    <div className='d-flex justify-content-center mt-3'>
                                                        <button
                                                            type='button'
                                                            className='btn btn-success'
                                                            onClick={() => setShowListingModal(true)}
                                                        >
                                                            view more
                                                        </button>
                                                    </div>
                                                    :
                                                    null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-12 col-12">
                                    <div className="card">
                                        <div className="card-body" style={{ maxHeight: "450px", minHeight: "450px" }}>
                                            <div className="d-flex flex-wrap mb-4">
                                                <h4 className="card-title">Key Indicators</h4>
                                            </div>
                                            <div>
                                                <div className="row">
                                                    <div className="col-lg-3 col-md-6 col-12" style={{ cursor: "pointer" }} onClick={() => gotToAccountListPage('FY', 'base_customers')}>
                                                        <div className="indicate-box mb-4">
                                                            <h4>Base Customers</h4>
                                                            <Link to="#" className="key-indicater">
                                                                {revenueDetails?.keyindicator?.basecustomer}
                                                            </Link>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3 col-md-6 col-12" style={{ cursor: "pointer" }} onClick={() => gotToAccountListPage('FY', 'total_customers')}>
                                                        <div className="indicate-box mb-4">
                                                            <h4>Total Customers</h4>
                                                            <Link to="#" className="key-indicater">
                                                                {revenueDetails?.keyindicator?.totalcustomers}
                                                            </Link>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3 col-md-6 col-12" style={{ cursor: "pointer" }} onClick={() => gotToContractPage('FY', 'base_contracts')}>
                                                        <div className="indicate-box mb-4">
                                                            <h4>Base Contracts</h4>
                                                            <Link to="#" className="key-indicater">
                                                                {revenueDetails?.keyindicator?.basecontracts}
                                                            </Link>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3 col-md-6 col-12" style={{ cursor: "pointer" }} onClick={() => gotToContractPage('FY', 'total_contracts')}>
                                                        <div className="indicate-box mb-4">
                                                            <h4>Total Contracts</h4>
                                                            <Link to="#" className="key-indicater">
                                                                {revenueDetails?.keyindicator?.totalcontracts}
                                                            </Link>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3 col-md-6 col-12" style={{ cursor: "pointer" }} onClick={() => gotToContractPage('FY', 'base_arr')}>
                                                        <div className="indicate-box mb-4">
                                                            <h4>Base ARR </h4>
                                                            <Link to="#" className="key-indicater">
                                                                ${formatNumber(revenueDetails?.keyindicator?.basearr)}
                                                            </Link>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3 col-md-6 col-12" style={{ cursor: "pointer" }} onClick={() => gotToContractPage('FY', 'current_arr')}>
                                                        <div className="indicate-box mb-4">
                                                            <h4>Current ARR</h4>
                                                            <Link to="#" className="key-indicater">
                                                                ${formatNumber(revenueDetails?.keyindicator?.currentarr)}
                                                            </Link>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3 col-md-6 col-12" style={{ cursor: "pointer" }} onClick={() => gotToContractPage('FY', 'multi_year_contracts')}>
                                                        <div className="indicate-box mb-4">
                                                            <h4>Multi-year Contracts</h4>
                                                            <Link to="#" className="key-indicater">
                                                                {revenueDetails?.keyindicator?.multiyearcontacts}
                                                            </Link>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3 col-md-6 col-12" style={{ cursor: "pointer" }} onClick={() => gotToContractPage('FY', 'late_renewal')}>
                                                        <div className="indicate-box mb-4">
                                                            <h4>Late Renewals</h4>
                                                            <Link to="#" className="key-indicater">
                                                                {revenueDetails?.keyindicator?.laterenewals}
                                                            </Link>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3 col-md-6 col-12" style={{ cursor: "pointer" }} onClick={() => gotToContractPage('FY', 'late_renewal_arr')}>
                                                        <div className="indicate-box">
                                                            <h4>Late Renewal ARR</h4>
                                                            <Link to="#" className="key-indicater" >
                                                                ${formatNumber(revenueDetails?.keyindicator?.laterenewalarr)}
                                                            </Link>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3 col-md-6 col-12" style={{ cursor: "pointer" }} onClick={() => gotToContractPage('FY', 'at_risk')}>
                                                        <div className="indicate-box">
                                                            <h4>At Risk</h4>
                                                            <Link to="#" className="key-indicater">
                                                                {revenueDetails?.keyindicator?.atrisk}
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>

        </React.Fragment>
    )
}

export default Revenue