import React, { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import Header from '../../components/commonComponents/Header';
import LeftPanel from '../../components/commonComponents/LeftPanel';
import TableContainer from '../../components/commonComponents/TableContainer';
import { URLS } from '../../_config';
import { AiOutlineEdit } from 'react-icons/ai'
import Footer from '../../components/commonComponents/Footer';
import { useAppLoader } from '../../_common/hooks/common/appLoaderHook';
import DeleteButtonCell from '../../components/actionsBtns/DeleteButtonCell';
import { useCommonApi } from '../../_common/hooks/api/common/commonApiHook';
import AddApproverModal from '../../components/modals/addApprover.modal';
import AddViewConditionModal from '../../components/modals/addViewCondition.modal';


const ManageApprover = () => {
    const commonApi = useCommonApi();
    const { showLoader, hideLoader } = useAppLoader();

    const [approverData, setApproverData] = useState<any>(null)
    const [data, setData] = useState<any>([])
    const [showAddApproverModal, setShowAddApproverModal] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false)
    const [isEdit, setIsEdit] = useState<boolean>(false)
    const [approverId, setApproverId] = useState<any>(null)

    const getApproverData = (page = 1) => {
        showLoader();
        setLoading(true);
        commonApi.getAllApproverRule({ page: page },
            (message: string, resp: any) => {
                // console.log(resp);
                setLoading(false);
                setApproverData(resp.data.list);
                setData(resp.data.list.data);
                hideLoader();
            }, (message: string) => {
                setLoading(false);
                hideLoader();
            })
    }

    const closeAddApproverModal = () => {
        setShowAddApproverModal(false)
        setIsEdit(false)
        setApproverId(null)
    }

    const EditBtn = ({ column, value }: any) => {
        const handleClick = () => {
            // console.log(value)
            setShowAddApproverModal(true)
            setApproverId(value)
            // setIsEdit(true)
        }
        return (
            <AiOutlineEdit
                onClick={handleClick}
                className="cursor-pointer mr-2"
                data-toggle="tooltip" data-placement="top" title="Edit"
            />
        )
    }


    const deleteApprover = (id: any) => {
        return new Promise((resolve: any, reject: any) => {
            commonApi.deleteApproverRule({
                id
            }, (message: string, resp: any) => {
                resolve(message)
            }, (message: string) => {
                reject(message);
            });
        })
    }

    const columns = useMemo(
        () => [
            {
                Header: 'Actions',
                accessor: "id",
                Cell: ({ column, value }: any) =>
                    <>
                        <DeleteButtonCell
                            column={column}
                            value={value}
                            deleteFunc={deleteApprover}
                            callbackFunc={[getApproverData]}
                            type={"Approver"}
                        />
                        {/* <EditBtn column={column} value={value} /> */}
                    </>,
            },
            {
                Header: "Approver Name",
                accessor: "approverobject.user.full_name",
            },
            {
                Header: "Designation",
                accessor: "approverobject.user.designation.name",
            }
        ],
        []
    )

    useEffect(() => {
        getApproverData()
    }, [])

    return (
        <React.Fragment>
            <AddApproverModal
                show={showAddApproverModal}
                close={closeAddApproverModal}
                callbackFunc={getApproverData}
            />
            <div id="layout-wrapper">
                <Header />
                <LeftPanel />
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-flex align-items-center justify-content-between">
                                        <h4 className="mb-0 font-size-18">Approver</h4>
                                        <div className="page-title-right">
                                            <ol className="breadcrumb m-0">
                                                <li className="breadcrumb-item">
                                                    <Link to={URLS.REVENUE}>Revenue</Link>
                                                </li>
                                                <li className="breadcrumb-item active">Approver</li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-body">
                                    <div className="d-flex flex-wrap justify-content-between">
                                        <h5 className="card-title mr-2">Approver</h5>
                                        <button
                                            className="btn btn-success"
                                            onClick={() => setShowAddApproverModal(true)}
                                        >
                                            Add Approver
                                        </button>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="table-responsive manage-user-table">
                                                <TableContainer
                                                    columns={columns}
                                                    data={data}
                                                    fetchData={getApproverData}
                                                    loading={loading}
                                                    rawData={approverData}
                                                    clickFunc={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </React.Fragment >
    )
}

export default ManageApprover