import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Controller, useForm } from 'react-hook-form';
import { useAppLoader } from '../../_common/hooks/common/appLoaderHook';
import { toast } from 'react-hot-toast';
import { useCommonApi } from '../../_common/hooks/api/common/commonApiHook';
import Select from 'react-select';
import ReactInputMask from 'react-input-mask';
import { countriesData } from '../../_common/staticData';

interface FormValues {
	name: string,
	description: string,
	contactname: string,
	email: string,
	phone: string,
	address: string,
	city: string,
	state: string,
	country: any,
	postal_code: string,
}

const validationSchema = Yup.object().shape({
	name: Yup.string().required("Name is required"),
	contactname: Yup.string().required("Contact name is required"),
	email: Yup.string().email('invalid email').required("Email is required"),
	phone: Yup.string().required("Phone is required"),
	address: Yup.string().required("Address is required"),
	city: Yup.string().required("City is required"),
	state: Yup.string().required("City is required"),
	postal_code: Yup.string().required("Postal Code is required"),
	country: Yup.object().required("Country is required"),
});

interface IncomingProps {
	show: boolean;
	close: () => void;
	id?: any
	isEdit: boolean;
	AccountId?: string;
	partnerListCallbackFunc: any;
}

const AddPartnerModal = ({ show, close, id, isEdit = false, AccountId = '', partnerListCallbackFunc }: IncomingProps) => {

	const commonApi = useCommonApi();
	const { showLoader, hideLoader } = useAppLoader();

	const [partnerData, setPartnerData] = useState<any>(null)
	const [loading, setLoading] = useState<boolean>(false)

	const { control, formState: { errors }, handleSubmit, setValue, reset } = useForm<FormValues>({
		resolver: yupResolver(validationSchema),
		defaultValues: {
			name: "",
			description: "",
			contactname: "",
			email: "",
			phone: "",
			address: "",
			city: "",
			state: "",
			country: "",
			postal_code: "",
		},
	});

	const onSubmit = (data: any) => {
		// console.log({ data })
		let params: any = {
			name: data?.name,
			description: data?.description,
			contactname: data?.contactname,
			email: data?.email,
			phone: data?.phone,
			address: data?.address,
			city: data?.city,
			state: data?.state,
			country: data?.country?.value,
			postal_code: data?.postal_code,
		}
		if (AccountId != '') {
			params['AccountId'] = AccountId
		}
		if (isEdit) {
			if (partnerData && partnerData.id) {
				params["id"] = partnerData.id
			}
		}
		// console.log(params)
		showLoader()
		commonApi.managePartner(params, (message: string, resp: any) => {
			// console.log(message, resp)
			toast.success(message)
			reset()
			close()
			hideLoader()
			partnerListCallbackFunc(AccountId)
		}, (message: string) => {
			// console.log(message)
			toast.error(message)
			hideLoader()
		})
	}

	const getPartnerData = () => {
		showLoader();
		setLoading(true);
		commonApi.getAllPartner({ id },
			(message: string, resp: any) => {
				// console.log(resp);
				setLoading(false);
				setPartnerData(resp.data.list);
				hideLoader();
			}, (message: string) => {
				setLoading(false);
				hideLoader();
			})
	}

	useEffect(() => {
		// console.log(partnerData)
		if (partnerData) {
			setValue('name', partnerData?.name)
			setValue('contactname', partnerData?.contactname)
			setValue('email', partnerData?.email)
			setValue('phone', partnerData?.phone)
			setValue('address', partnerData?.address)
			setValue('city', partnerData?.city)
			setValue('state', partnerData?.state)
			setValue('postal_code', partnerData?.postal_code)
			setValue('country', countriesData?.find((item: any) => item.value == partnerData?.country))
			setValue('description', partnerData?.description)
		}
	}, [partnerData, countriesData])

	useEffect(() => {
		if (isEdit && id) {
			getPartnerData()
		}
	}, [isEdit, id])

	return (
		<React.Fragment>
			<Modal
				className='salesforce-modal modal-lndscp'
				show={show}
			// onHide={() => { close(); reset() }}
			>
				<div className="modal-header custom-modal-header">
					<button
						type="button"
						className="close"
						data-dismiss="modal"
						aria-label="Close"
						onClick={() => { close(); reset() }}
					>
						<span aria-hidden="true">×</span>
					</button>
				</div>
				<Modal.Body>
					{loading ?
						<div>
							Loading......
						</div>
						:
						<div className="row">
							<form onSubmit={handleSubmit(onSubmit)} className="w-100" >
								<div className="col-12">
									<div className="card">
										<div className="card-body">
											<div className="d-flex flex-wrap">
												<h5 className="card-title mr-2">Partner Information</h5>
											</div>
											<div className="row">
												<div className="form-group col-6">
													<label htmlFor="name">Name <span className='text-danger'>*</span></label>
													<Controller
														name={"name"}
														control={control}
														render={({ field: { value, onChange, onBlur, name, ref } }) => (
															<>
																<input
																	type="text"
																	className={"form-control " + (errors?.name ? ' border-danger' : '')}
																	id="name"
																	name={name}
																	onChange={onChange}
																	value={value}
																	onBlur={onBlur}
																	ref={ref}
																	placeholder="Enter name"
																/>
															</>
														)}
													/>
													{errors && errors.name &&
														<span className='text-danger'>{errors?.name?.message}</span>
													}
												</div>
												<div className="form-group col-6">
													<label htmlFor="contactname">Contact Name <span className='text-danger'>*</span></label>
													<Controller
														name={"contactname"}
														control={control}
														render={({ field: { value, onChange, onBlur, name, ref } }) => (
															<>
																<input
																	type="text"
																	className={"form-control " + (errors?.contactname ? ' border-danger' : '')}
																	id="contactname"
																	name={name}
																	onChange={onChange}
																	value={value}
																	onBlur={onBlur}
																	ref={ref}
																	placeholder="Enter contact name"
																/>
															</>
														)}
													/>
													{errors && errors.contactname &&
														<span className='text-danger'>{errors?.contactname?.message}</span>
													}
												</div>
												<div className="form-group col-6">
													<label htmlFor="email">Email <span className='text-danger'>*</span></label>
													<Controller
														name={"email"}
														control={control}
														render={({ field: { value, onChange, onBlur, name, ref } }) => (
															<>
																<input
																	type="text"
																	className={"form-control " + (errors?.email ? ' border-danger' : '')}
																	id="email"
																	name={name}
																	onChange={onChange}
																	value={value}
																	onBlur={onBlur}
																	ref={ref}
																	placeholder="Enter email"
																/>
															</>
														)}
													/>
													{errors && errors.email &&
														<span className='text-danger'>{errors?.email?.message}</span>
													}
												</div>
												<div className="form-group col-6">
													<label htmlFor="phone">Phone <span className='text-danger'>*</span></label>
													<Controller
														name={"phone"}
														control={control}
														render={({ field: { value, onChange, onBlur, name, ref } }) => (
															<>
																<ReactInputMask
																	mask="(999) 999-9999"
																	maskChar="_"
																	className={"form-control " + (errors?.phone ? ' border-danger' : '')}
																	id="phone"
																	name={name}
																	onChange={onChange}
																	value={value}
																	onBlur={onBlur}
																	placeholder="Enter phone"
																/>
															</>
														)}
													/>
													{errors && errors.phone &&
														<span className='text-danger'>{errors?.phone?.message}</span>
													}
												</div>
												<div className="form-group col-6">
													<label htmlFor="address">Address <span className='text-danger'>*</span></label>
													<Controller
														name={"address"}
														control={control}
														render={({ field: { value, onChange, onBlur, name, ref } }) => (
															<>
																<input
																	type="text"
																	className={"form-control " + (errors?.address ? ' border-danger' : '')}
																	id="address"
																	name={name}
																	onChange={onChange}
																	value={value}
																	onBlur={onBlur}
																	ref={ref}
																	placeholder="Enter address"
																/>
															</>
														)}
													/>
													{errors && errors.address &&
														<span className='text-danger'>{errors?.address?.message}</span>
													}
												</div>
												<div className="form-group col-6">
													<label htmlFor="city">City <span className='text-danger'>*</span></label>
													<Controller
														name={"city"}
														control={control}
														render={({ field: { value, onChange, onBlur, name, ref } }) => (
															<>
																<input
																	type="text"
																	className={"form-control " + (errors?.city ? ' border-danger' : '')}
																	id="city"
																	name={name}
																	onChange={onChange}
																	value={value}
																	onBlur={onBlur}
																	ref={ref}
																	placeholder="Enter city"
																/>
															</>
														)}
													/>
													{errors && errors.city &&
														<span className='text-danger'>{errors?.city?.message}</span>
													}
												</div>
												<div className="form-group col-6">
													<label htmlFor="state">State <span className='text-danger'>*</span></label>
													<Controller
														name={"state"}
														control={control}
														render={({ field: { value, onChange, onBlur, name, ref } }) => (
															<>
																<input
																	type="text"
																	className={"form-control " + (errors?.state ? ' border-danger' : '')}
																	id="state"
																	name={name}
																	onChange={onChange}
																	value={value}
																	onBlur={onBlur}
																	ref={ref}
																	placeholder="Enter state"
																/>
															</>
														)}
													/>
													{errors && errors.state &&
														<span className='text-danger'>{errors?.state?.message}</span>
													}
												</div>
												<div className="form-group col-6">
													<label htmlFor="postal_code">Postal Code <span className='text-danger'>*</span></label>
													<Controller
														name={"postal_code"}
														control={control}
														render={({ field: { value, onChange, onBlur, name, ref } }) => (
															<>
																<input
																	type="text"
																	className={"form-control " + (errors?.postal_code ? ' border-danger' : '')}
																	id="postal_code"
																	name={name}
																	onChange={onChange}
																	value={value}
																	onBlur={onBlur}
																	ref={ref}
																	placeholder="Enter postal code"
																/>
															</>
														)}
													/>
													{errors && errors.postal_code &&
														<span className='text-danger'>{errors?.postal_code?.message}</span>
													}
												</div>
												<div className="form-group col-6">
													<label htmlFor="country">Country <span className='text-danger'>*</span></label>
													<Controller
														name={"country"}
														control={control}
														render={({ field: { value, onChange, onBlur, name, ref } }) => (
															<>
																<Select
																	className={"select-box" + (errors?.country ? ' border-danger' : '')}
																	id="country"
																	options={countriesData ? countriesData : []}
																	placeholder={""}
																	onChange={onChange}
																	value={value}
																	defaultValue={value}
																/>
															</>
														)}
													/>
													{errors && errors.country &&
														<span className='text-danger'>Country is required</span>
													}
												</div>
												<div className="form-group col-6">
													<label htmlFor="description">Description</label>
													<Controller
														name={"description"}
														control={control}
														render={({ field: { value, onChange, onBlur, name, ref } }) => (
															<>
																<textarea
																	className={"form-control " + (errors?.description ? ' border-danger' : '')}
																	id="description"
																	name={name}
																	placeholder={""}
																	onChange={onChange}
																	value={value}
																	defaultValue={value}
																/>
															</>
														)}
													/>
													{errors && errors.description &&
														<span className='text-danger'>Country is required</span>
													}
												</div>
											</div>
										</div>
									</div>
								</div>

								<div className="col-12">
									<div className='align-items-center d-flex gap-10 justify-content-center'>
										<button type='submit' className='btn btn-primary'>Save</button>
										<button
											type='button'
											className='btn btn-outline-danger'
											onClick={() => { close(); reset() }}
										>
											Cancel
										</button>
									</div>
								</div>
							</form>
						</div>
					}
				</Modal.Body>
			</Modal>
		</React.Fragment>
	)
}

export default AddPartnerModal