import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Controller, useForm } from 'react-hook-form';
import { usePlatformApi } from '../../_common/hooks/api/PlatformApiHook';
import { useAppLoader } from '../../_common/hooks/common/appLoaderHook';
import { toast } from 'react-hot-toast';
import { useCommonApi } from '../../_common/hooks/api/common/commonApiHook';
import SelectTaxModal from './selectTax.modal';
import { formatNumber, handleBlur, handleFocus, handleKeyPressDecimalNumber, handleKeyPressNumber, handleKeyUpDown, handleWheel } from '../../_common/functions';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';

interface IncomingProps {
    show: boolean;
    close: () => void;
    quoteId: any;
    lineItemId?: any;
    pricebookId?: any;
    isEdit: boolean;
    getQuoteDetails: any;
    getAllQuoteLineItem: any;
}

interface AddLineItemsFormValues {
    pricebook: any;
    product: any;
    listPrice: string;
    salesPrice: string;
    quantity: string;
    subTotal: string;
    discount: string;
    totalPrice: string;
    description: string;
    taxVal: string;
    familyid: any;
}

const validationSchema = Yup.object().shape({
    pricebook: Yup.object().required("Pricebook is required"),
    salesPrice: Yup.string().when("familyid", {
        is: (val: any) => !val,
        then: Yup.string().required("Sales Price is required"),
        otherwise: Yup.string().optional().notRequired().nullable()
    }),
    quantity: Yup.string().when("familyid", {
        is: (val: any) => !val,
        then: Yup.string().required("Quantity is required"),
        otherwise: Yup.string().optional().notRequired().nullable()
    }),
    discount: Yup.string().max(100).optional().notRequired().nullable(),
    familyid: Yup.object().when("product", {
        is: (val: any) => !val,
        then: Yup.object().required("Product Family is required"),
        otherwise: Yup.object().optional().notRequired().nullable()
    }),
    product: Yup.object().when("familyid", {
        is: (val: any) => !val,
        then: Yup.object().required("Product is required"),
        otherwise: Yup.object().optional().notRequired().nullable()
    }),
    // familyid: Yup.object().required("Product Family is required"),
    // product: Yup.object().required("Product is required"),
},
    [["familyid", "product"]]
);

const AddLineitemModal = ({ show, close, quoteId, lineItemId, pricebookId, isEdit = false, getQuoteDetails, getAllQuoteLineItem }: IncomingProps) => {

    const platformApi = usePlatformApi()
    const commonApi = useCommonApi()
    const navigate = useNavigate()

    const { showLoader, hideLoader } = useAppLoader()
    const [priceBookOptions, setPriceBookOptions] = useState<any>([])
    const [productOptions, setProductOptions] = useState<any>([])
    const [lineItemData, setLineItemData] = useState<any>(null)
    const [showTaxModal, setShowTaxModal] = useState<boolean>(false)
    const [taxValue, setTaxValue] = useState<any>(null)
    const [productFamilyOptions, setProductFamilyOptions] = useState<any>([])

    const { control, formState: { errors }, handleSubmit, watch, setValue, reset } = useForm<AddLineItemsFormValues>({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            pricebook: "",
            product: "",
            listPrice: "",
            salesPrice: "",
            quantity: "1",
            subTotal: "",
            discount: "",
            totalPrice: "",
            description: "",
            taxVal: "0.00",
            familyid: ""
        },
    });

    const selectedPriceBook: any = watch('pricebook');
    const selectedProduct: any = watch('product');
    const selectedDiscount: any = watch('discount');
    const selectedSalesPrice: any = watch('salesPrice');
    const selectedQuantity: any = watch('quantity');
    const selectedTaxVal: any = watch('taxVal');
    const selectedProductFamily: any = watch('familyid');

    const getPriceBookData = () => {
        showLoader();
        platformApi.getAllPriceBook({},
            (message: string, resp: any) => {
                // console.log(resp);
                const datas = resp.data.list.data;
                if (datas && datas.length > 0) {
                    setPriceBookOptions(datas.map((item: any) => {
                        item.label = item.Name;
                        item.value = item.PriceBookId;
                        return item;
                    }));
                }
                hideLoader();
            }, (message: string) => {
                hideLoader();
            })
    }

    const getPriceBookEntries = (id: any) => {
        showLoader();
        platformApi.getAllPriceBookEntities({
            Pricebook2Id: id
        },
            (message: string, resp: any) => {
                // console.log(resp);
                const datas = resp.data.list.data;
                if (datas && datas.length > 0) {
                    setProductOptions(datas.map((item: any) => {
                        item.label = item.ProductCode;
                        item.value = item.Product2Id;
                        return item;
                    }));
                }
                hideLoader();
            }, (message: string) => {
                hideLoader();
            })
    }

    const getQuoteLineItemData = () => {
        showLoader();
        platformApi.getAllQuoteLineItem({
            QuoteLineItemId: lineItemId
        },
            (message: string, resp: any) => {
                // console.log(resp);
                setLineItemData(resp.data.list.data[0]);
                hideLoader();
            }, (message: string) => {
                hideLoader();
            })
    }

    const handleShowTaxModal = () => {
        setShowTaxModal(true)
    }

    const handleCloseTaxModal = () => {
        setShowTaxModal(false)
    }

    const onSubmit = (data: any) => {
        // console.log(data);
        let params: any = {
            "QuoteId": quoteId,
            "PricebookEntryId": data?.pricebook.value,
            "Description": data?.description
        }
        if (selectedProductFamily) {
            params["familyid"] = data?.familyid.value
        } else if (selectedProduct) {
            params["Product2Id"] = data?.product.value
            params["ListPrice"] = data?.listPrice
            params["UnitPrice"] = data?.salesPrice
            params["Quantity"] = data?.quantity
            params["Subtotal"] = data?.subTotal
            params["TotalPrice"] = data?.totalPrice
            if (data?.discount && data?.discount != '') {
                params["Discount"] = data?.discount
            }
            if (taxValue) {
                params["taxid"] = taxValue?.id;
                params["taxper"] = taxValue?.taxpercent;
            }
        }
        if (isEdit && lineItemData) {
            params["id"] = lineItemData?.id;
            if (!taxValue && selectedTaxVal != "0.00" && selectedTaxVal != "0") {
                params["taxid"] = lineItemData?.taxid;
                params["taxper"] = lineItemData?.taxper;
            }
        }
        // console.log(params)
        showLoader()
        platformApi.manageQuoteLineItem(params, (message: string, resp: any) => {
            // console.log(message, resp)
            toast.success(message)
            handleClose()
            hideLoader()
            getQuoteDetails()
            getAllQuoteLineItem()
        }, (message: string) => {
            // console.log(message)
            toast.error(message)
            hideLoader()
        })
    }

    const getProductFamilyData = (page = 1) => {
        showLoader();
        commonApi.getAllProductFamily({},
            (message: string, resp: any) => {
                const tempData = resp.data.list.data;
                if (tempData.length > 0) {
                    setProductFamilyOptions(tempData?.map((item: any) => {
                        item.label = item.name
                        item.value = item.id
                        return item
                    }));
                }
                hideLoader();
            }, (message: string) => {
                hideLoader();
            })
    }

    const handleClose = () => {
        close();
        reset();
        setLineItemData(null);
    }

    useEffect(() => {
        if (show) {
            getPriceBookData()
            getProductFamilyData()
        }
    }, [show])

    useEffect(() => {
        if (show && lineItemId) {
            getQuoteLineItemData()
        }
    }, [lineItemId, show])

    useEffect(() => {
        if (taxValue) {
            setValue("taxVal", taxValue?.taxpercent)
        }
    }, [taxValue])

    useEffect(() => {
        if (lineItemData) {
            setValue('pricebook', priceBookOptions?.find((item: any) => item.value == lineItemData?.pricebookentity?.Pricebook2Id))
            // setValue('product', productOptions?.find((item: any) => item.value == lineItemData?.Product2Id))
            setValue('salesPrice', lineItemData?.UnitPrice)
            setValue('listPrice', lineItemData?.ListPrice)
            setValue('quantity', lineItemData?.Quantity)
            setValue('subTotal', lineItemData?.Subtotal)
            setValue('discount', lineItemData?.Discount)
            setValue('totalPrice', lineItemData?.TotalPrice)
            setValue('description', lineItemData?.Description)
            setValue('taxVal', lineItemData?.taxper)
            setValue('familyid', productFamilyOptions?.find((item: any) => item.value == lineItemData?.familyid))
        } else if (!lineItemData && pricebookId) {
            setValue('pricebook', priceBookOptions?.find((item: any) => item.value == pricebookId))
        }
    }, [lineItemData, productFamilyOptions, priceBookOptions, pricebookId])

    useEffect(() => {
        if (lineItemData && productOptions.length > 0) {
            setValue('product', productOptions?.find((item: any) => item.value == lineItemData?.Product2Id))
        }
    }, [lineItemData, productOptions])

    useEffect(() => {
        if (selectedPriceBook) {
            getPriceBookEntries(selectedPriceBook?.PriceBookId)
        }
    }, [selectedPriceBook])

    useEffect(() => {
        if (selectedProduct && lineItemData === null) {
            setValue('listPrice', selectedProduct?.UnitPrice)
            setValue('salesPrice', selectedProduct?.UnitPrice)
        }
    }, [selectedProduct, lineItemData])

    useEffect(() => {
        if (selectedDiscount && +selectedDiscount > 100) {
            toast.error('discount must be less than 100')
        } else if (selectedDiscount && +selectedDiscount <= 100) {
            if (selectedSalesPrice && selectedQuantity) {
                const discountAmount = (+selectedSalesPrice * +selectedDiscount) / 100;
                const discountedPrice = +selectedSalesPrice - +discountAmount;
                const subTotalPrice = +selectedSalesPrice * +selectedQuantity;
                const tempTotalPrice = discountedPrice * +selectedQuantity;
                const totalPrice = tempTotalPrice + (tempTotalPrice * +selectedTaxVal / 100);
                setValue('subTotal', String(formatNumber(subTotalPrice)))
                setValue('totalPrice', String(formatNumber(totalPrice)))
            }
        } else {
            const subTotalPrice = +selectedSalesPrice * +selectedQuantity;
            const totalPrice = subTotalPrice + (subTotalPrice * +selectedTaxVal / 100);
            setValue('totalPrice', String(formatNumber(totalPrice)))
            setValue('subTotal', String(formatNumber(subTotalPrice)))
        }
    }, [selectedDiscount, selectedQuantity, selectedSalesPrice, selectedTaxVal])


    return (
        <React.Fragment>
            <SelectTaxModal
                show={showTaxModal}
                close={handleCloseTaxModal}
                status={"1"}
                taxfor={"Product"}
                setTaxValue={setTaxValue}
            />
            <Modal
                className={'salesforce-modal modal-lndscp ' + (showTaxModal ? 'opec-5' : '')}
                show={show}
            // onHide={handleClose}
            >
                <div className="modal-header custom-modal-header">
                    <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={handleClose}
                    >
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <Modal.Body>
                    <div className="row">
                        <form onSubmit={handleSubmit(onSubmit)} className='w-100'>
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="d-flex flex-wrap">
                                            <h5 className="card-title mr-2">Manage Line Item</h5>
                                        </div>
                                        <div className="row">
                                            <div className="form-group col-6">
                                                <label htmlFor="pricebook">Price book <span className='text-danger'>*</span></label>
                                                <Controller
                                                    name={"pricebook"}
                                                    control={control}
                                                    render={({ field: { value, onChange, onBlur, name, ref } }) => (
                                                        <>
                                                            <Select
                                                                className={"select-box " + (errors?.pricebook ? ' border-danger' : '')}
                                                                options={priceBookOptions ? priceBookOptions : []}
                                                                placeholder={"Select pricebook"}
                                                                onChange={onChange}
                                                                value={value}
                                                                defaultValue={value}
                                                                ref={ref}
                                                                isDisabled={isEdit || pricebookId != null}
                                                            />
                                                        </>
                                                    )}
                                                />
                                                {errors && errors.pricebook &&
                                                    <span className='text-danger'>Pricebook is required</span>
                                                }
                                            </div>
                                            <div className="form-group col-6">
                                                <label htmlFor="salesPrice">Sales Price $<span className='text-danger'>*</span></label>
                                                <Controller
                                                    name="salesPrice"
                                                    control={control}
                                                    render={({ field: { value, onChange, onBlur, name, ref } }) => (
                                                        <>
                                                            <input
                                                                type="number"
                                                                className={"form-control " + (errors?.salesPrice && !selectedProductFamily ? ' border-danger' : '')}
                                                                id="salesPrice"
                                                                name={name}
                                                                value={value}
                                                                onChange={onChange}
                                                                onBlur={(e) => { onBlur(); handleBlur(e) }}
                                                                onFocus={(e) => handleFocus(e)}
                                                                onKeyDown={(event: any) => { handleKeyPressDecimalNumber(event); handleKeyUpDown(event) }}
                                                                onWheel={handleWheel}
                                                                step={"0.01"}
                                                                min={0}
                                                                ref={ref}
                                                                disabled={selectedProductFamily}
                                                                placeholder="Enter sales price"
                                                            // onKeyPress={(event: any) => handleKeyPressDecimalNumber(event)}
                                                            />
                                                        </>
                                                    )}
                                                />
                                                {errors && errors.salesPrice && !selectedProductFamily &&
                                                    <span className='text-danger'>{errors?.salesPrice?.message}</span>
                                                }
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-group col-6">
                                                <label htmlFor="familyid">Product Family
                                                    <span className='text-danger'>*</span>
                                                </label>
                                                <Controller
                                                    name={"familyid"}
                                                    control={control}
                                                    render={({ field: { value, onChange, onBlur, name } }) => (
                                                        <>
                                                            <Select
                                                                className={"select-box " + (errors?.familyid && !selectedProduct ? ' border-danger' : '')}
                                                                options={productFamilyOptions ? productFamilyOptions : []}
                                                                placeholder={"Select product family"}
                                                                onChange={onChange}
                                                                value={value}
                                                                defaultValue={value}
                                                                isClearable={true}
                                                                isDisabled={selectedProduct || isEdit}
                                                            />
                                                        </>
                                                    )}
                                                />
                                                {errors && errors.familyid && !selectedProduct &&
                                                    <span className='text-danger'>Product family is required</span>
                                                }
                                            </div>
                                            <div className="form-group col-6">
                                                <label htmlFor="quantity">Quantity <span className='text-danger'>*</span></label>
                                                <Controller
                                                    name={"quantity"}
                                                    control={control}
                                                    render={({ field: { value, onChange, onBlur, name, ref } }) => (
                                                        <>
                                                            <input
                                                                type="text"
                                                                className={"form-control " + (errors?.quantity && !selectedProductFamily ? ' border-danger' : '')}
                                                                id="quantity"
                                                                name={name}
                                                                onChange={onChange}
                                                                value={value}
                                                                onBlur={onBlur}
                                                                ref={ref}
                                                                placeholder="Enter quantity"
                                                                disabled={selectedProductFamily}
                                                                onKeyDown={(event: any) => handleKeyPressNumber(event)}
                                                            />
                                                        </>
                                                    )}
                                                />
                                                {errors && errors.quantity && !selectedProductFamily &&
                                                    <span className='text-danger'>{errors?.quantity?.message}</span>
                                                }
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-group col-6">
                                                <label htmlFor="product">Product <span className='text-danger'>*</span></label>
                                                <Controller
                                                    name={"product"}
                                                    control={control}
                                                    render={({ field: { value, onChange, onBlur, name, ref } }) => (
                                                        <>
                                                            <Select
                                                                className={"select-box " + (errors?.product && !selectedProductFamily ? ' border-danger' : '')}
                                                                options={productOptions ? productOptions : []}
                                                                placeholder={"Select product"}
                                                                onChange={onChange}
                                                                value={value}
                                                                defaultValue={value}
                                                                ref={ref}
                                                                isClearable={true}
                                                                isDisabled={selectedProductFamily || isEdit}
                                                            />
                                                        </>
                                                    )}
                                                />
                                                {errors && errors.product && !selectedProductFamily &&
                                                    <span className='text-danger'>Product is required</span>
                                                }
                                            </div>
                                            <div className="form-group col-6">
                                                <label htmlFor="subTotal">Sub Total $</label>
                                                <Controller
                                                    name={"subTotal"}
                                                    control={control}
                                                    render={({ field: { value, onChange, onBlur, name } }) => (
                                                        <>
                                                            <input
                                                                type="text"
                                                                className={"form-control " + (errors?.subTotal ? ' border-danger' : '')}
                                                                id="subTotal"
                                                                name={name}
                                                                onChange={onChange}
                                                                value={value}
                                                                onBlur={onBlur}
                                                                disabled
                                                            />
                                                        </>
                                                    )}
                                                />
                                                {errors && errors.subTotal &&
                                                    <span className='text-danger'>{errors?.subTotal?.message}</span>
                                                }
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-group col-6">
                                                <label htmlFor="listPrice">List Price $</label>
                                                <Controller
                                                    name={"listPrice"}
                                                    control={control}
                                                    render={({ field: { value, onChange, onBlur, name } }) => (
                                                        <>
                                                            <input
                                                                type="number"
                                                                className={"form-control " + (errors?.listPrice ? ' border-danger' : '')}
                                                                id="listPrice"
                                                                name={name}
                                                                value={value}
                                                                onChange={onChange}
                                                                onBlur={(e) => { onBlur(); handleBlur(e) }}
                                                                onFocus={(e) => handleFocus(e)}
                                                                onKeyDown={(event: any) => { handleKeyPressDecimalNumber(event); handleKeyUpDown(event) }}
                                                                onWheel={handleWheel}
                                                                step={"0.01"}
                                                                min={0}
                                                                disabled
                                                            // placeholder="Enter listPrice"
                                                            // onKeyPress={(event: any) => handleKeyPressDecimalNumber(event)}
                                                            />
                                                        </>
                                                    )}
                                                />
                                                {errors && errors.listPrice &&
                                                    <span className='text-danger'>{errors?.listPrice?.message}</span>
                                                }
                                            </div>
                                            <div className="form-group col-6">
                                                <label htmlFor="discount">Discount %</label>
                                                <Controller
                                                    name={"discount"}
                                                    control={control}
                                                    render={({ field: { value, onChange, onBlur, name } }) => (
                                                        <>
                                                            <input
                                                                type="number"
                                                                className={"form-control " + (errors?.discount && !selectedProductFamily ? ' border-danger' : '')}
                                                                id="discount"
                                                                name={name}
                                                                value={value}
                                                                onChange={onChange}
                                                                onBlur={(e) => { onBlur(); handleBlur(e) }}
                                                                onFocus={(e) => handleFocus(e)}
                                                                onKeyDown={(event: any) => { handleKeyPressDecimalNumber(event); handleKeyUpDown(event) }}
                                                                onWheel={handleWheel}
                                                                step={"0.01"}
                                                                min={0}
                                                                placeholder="Enter discount"
                                                                disabled={selectedProductFamily}
                                                            />
                                                        </>
                                                    )}
                                                />
                                                {errors && errors.discount && !selectedProductFamily &&
                                                    <span className='text-danger'>{errors?.discount?.message}</span>
                                                }
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-group col-6 mb-0">
                                                <label htmlFor="description">Description</label>
                                                <Controller
                                                    name={"description"}
                                                    control={control}
                                                    render={({ field: { value, onChange, onBlur, name } }) => (
                                                        <>
                                                            <textarea
                                                                // type="text"
                                                                className={"form-control linitem-desc " + (errors?.description ? ' border-danger' : '')}
                                                                id="description"
                                                                name={name}
                                                                onChange={onChange}
                                                                value={value}
                                                                onBlur={onBlur}
                                                                disabled={selectedProductFamily}
                                                                placeholder="Enter descriptiom"
                                                            />
                                                        </>
                                                    )}
                                                />
                                                {errors && errors.description &&
                                                    <span className='text-danger'>{errors?.description?.message}</span>
                                                }
                                            </div>
                                            <div className='col-6'>
                                                <div className="form-group">
                                                    <div className='d-flex justify-content-between'>
                                                        <label htmlFor="taxVal">Tax (%)</label>
                                                    </div>
                                                    <div className='d-flex justify-content-between gap-10'>
                                                        <Controller
                                                            name={"taxVal"}
                                                            control={control}
                                                            render={({ field: { value, onChange, onBlur, name } }) => (
                                                                <>
                                                                    <input
                                                                        type="text"
                                                                        className={"form-control " + (errors?.taxVal ? ' border-danger' : '')}
                                                                        id="taxVal"
                                                                        name={name}
                                                                        onChange={onChange}
                                                                        value={value}
                                                                        onBlur={onBlur}
                                                                        disabled
                                                                        onKeyDown={(event: any) => handleKeyPressDecimalNumber(event)}
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                        {/* <>{console.log(taxValue, selectedTaxVal)}</> */}
                                                        <div className='d-flex gap-10 justify-content-between align-items-center'>
                                                            {!selectedProductFamily ?
                                                                taxValue || (selectedTaxVal && selectedTaxVal != "0.00" && selectedTaxVal != "0") ?
                                                                    <span
                                                                        className='cursor-pointer nowrap btn btn-outline-danger'
                                                                        onClick={() => { setValue("taxVal", "0.00"); setTaxValue(null) }}
                                                                    >
                                                                        Clear Tax
                                                                    </span>
                                                                    :
                                                                    <span
                                                                        className='cursor-pointer nowrap btn btn-outline-primary'
                                                                        onClick={handleShowTaxModal}
                                                                    >
                                                                        Select Tax
                                                                    </span>
                                                                :
                                                                null
                                                            }
                                                        </div>
                                                    </div>
                                                    {errors && errors.taxVal &&
                                                        <span className='text-danger'>{errors?.taxVal?.message}</span>
                                                    }
                                                </div>
                                                <div className="form-group mb-0">
                                                    <label htmlFor="totalPrice">Total Price $</label>
                                                    <Controller
                                                        name={"totalPrice"}
                                                        control={control}
                                                        render={({ field: { value, onChange, onBlur, name } }) => (
                                                            <>
                                                                <input
                                                                    type="text"
                                                                    className={"form-control " + (errors?.totalPrice ? ' border-danger' : '')}
                                                                    id="totalPrice"
                                                                    name={name}
                                                                    onChange={onChange}
                                                                    value={value}
                                                                    onBlur={onBlur}
                                                                    disabled
                                                                    // placeholder="Enter totalPrice"
                                                                    onKeyDown={(event: any) => handleKeyPressDecimalNumber(event)}
                                                                />
                                                            </>
                                                        )}
                                                    />
                                                    {errors && errors.totalPrice &&
                                                        <span className='text-danger'>{errors?.totalPrice?.message}</span>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-12">
                                <div className='align-items-center d-flex gap-10 justify-content-center'>
                                    <button type='submit' className='btn btn-primary'>Save</button>
                                    <button
                                        type='button'
                                        className='btn btn-outline-danger'
                                        onClick={handleClose}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    )
}

export default AddLineitemModal