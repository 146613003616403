import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Footer from '../components/commonComponents/Footer';
import Header from '../components/commonComponents/Header';
import LeftPanel from '../components/commonComponents/LeftPanel';
import TableContainer from '../components/commonComponents/TableContainer';
import { usePlatformApi } from '../_common/hooks/api/PlatformApiHook';
import { useAppLoader } from '../_common/hooks/common/appLoaderHook';
import { URLS } from '../_config';
import moment from 'moment';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import Modal from 'react-bootstrap/Modal';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { deleteNotification, extractContent, formatNumber, getNextDate, handleKeyPressNumber } from '../_common/functions';
import AccountTeamListing from '../components/dataListing/AccountTeamListing';
import AddAccountTeamModal from '../components/modals/addAccountTeam.modal';
import AddCustomerTeamModal from '../components/modals/addCustomerTeam.modal';
import CustomerTeamListing from '../components/dataListing/CustomerTeamListing';
import AddContractModal from '../components/modals/addContract.modal';
import { AiOutlineDelete, AiOutlineEdit } from 'react-icons/ai';
import { useCommonApi } from '../_common/hooks/api/common/commonApiHook';
import { useCommonFunction } from '../_common/hooks/common/appCommonFunctionHook';
import QuoteList from '../components/dataListing/QuoteList';
import EntitlementUpdateModal from '../components/modals/entitlementUpdate.modal';
import DatePicker from 'react-datepicker'

interface renewalProcessFormValues {
  renewalProcess: string;
}

const renewalProcessValidationSchema = Yup.object().shape({
  renewalProcess: Yup.string().required('Renewal Process is required'),
});

const ContractDetails = () => {

  const platformApi = usePlatformApi();
  const commonApi = useCommonApi();
  const commonFunctions = useCommonFunction();
  const { showLoader, hideLoader } = useAppLoader();
  const urlParams: any = useParams();
  const fileUploadRef = useRef<any>()

  const [contractData, setContractData] = useState<any>(null);
  const [contractProductData, setContractProductData] = useState<any>(null);
  const [showModal, setShowModal] = useState<any>();
  const [contractLevelList, setContractLevelList] = useState<any>();
  const [contractRiskLevelList, setContractRiskLevelList] = useState<any>();
  const [changeOptionsDatas, setChangeOptionsDatas] = useState<any>(undefined);
  const [contractReasonCOC, setContractReasonCOC] = useState<any>();
  const [contactReasonCOCVal, setContractReasonCOCVal] = useState<any>(undefined);
  const [otherReason, setOtherReason] = useState<any>(undefined);
  const [contractStartDate, setContractStartDate] = useState<any>(undefined);
  const [contractTerm, setContractTerm] = useState<any>("12");
  const [editRenewals, setEditRenewals] = useState<boolean>(false);
  const [renewals, setRenewals] = useState<any>(null);
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [showAccountModal, setShowAccountModal] = useState<boolean>(false);
  const [showCustomersModal, setShowCustomersModal] = useState<boolean>(false);
  const [divWidth, setDivWdith] = useState<string>('')
  const [showContractModal, setShowContractModal] = useState<boolean>(false)
  const [contractIsEdit, setContractIsEdit] = useState<boolean>(false)
  const [fileValue, setFileValue] = useState<any>(null)
  const [isNewAccount, setIsNewAccount] = useState<any>(null)
  const [quoteData, setQuoteData] = useState<any>(null)
  const [renewalQuoteId, setRenewalQuoteId] = useState<string>('')
  const [showEntitlementUpdateModal, setShowEntitlementUpdateModal] = useState<boolean>(false)
  const [selectedEntitlementProductId, setSelecedEntitlementProductId] = useState<any>(null)
  const [selectedEntitlementProductValue, setSelectedEntitlementProductvalue] = useState<any>(null)
  const [selectedEntitlementProductColumn, setSelectedEntitlementProductColumn] = useState<any>(null)
  const [errorsMessages, setErrorMessages] = useState<any>({})

  const {
    control: renewalControl,
    formState: { errors: renewalErrors },
    handleSubmit: renewalHandleSubmit,
    reset: renewalReset,
    setValue: renewalSetValue,
  } = useForm<renewalProcessFormValues>({
    resolver: yupResolver(renewalProcessValidationSchema),
    defaultValues: {
      renewalProcess: '',
    },
  });

  const getContractData = () => {
    showLoader();
    platformApi.getAllContract(
      { ContractId: urlParams?.id },
      (message: string, resp: any) => {
        // console.log(resp);
        setContractData(resp.data.list.data[0]);
        hideLoader();
      },
      (message: string) => {
        hideLoader();
      },
    );
  };

  const getContractProductData = () => {
    showLoader();
    setLoading(true);
    platformApi.getContractProduct(
      { ContractId: urlParams?.id },
      (message: string, resp: any) => {
        // console.log('prdd resp', resp);
        setLoading(false);
        setContractProductData(resp.data.list);
        setData(resp.data.list);
        hideLoader();
      },
      (message: string) => {
        setLoading(false);
        hideLoader();
      },
    );
  };

  const getRiskLevelData = () => {
    showLoader();
    platformApi.getContractLevelLIst(
      {},
      (message: string, resp: any) => {
        let tempArr = resp.data;
        setContractLevelList(tempArr);
        hideLoader();
      },
      (message: string) => {
        hideLoader();
      },
    );
  };

  const getRiskTimeLevelData = () => {
    showLoader();
    platformApi.getContractTimeLevelLIst(
      {},
      (message: string, resp: any) => {
        // console.log('LEVEL LIST 1', resp);
        let tempArr = resp.data;
        setContractRiskLevelList(tempArr);
        // console.log('tempArr', tempArr?.length);
        hideLoader();
      },
      (message: string) => {
        hideLoader();
      },
    );
  };

  const contractRiskLevelOption = (data: any) => {
    // console.log('change data', data);
    toast(
      t => (
        <span>
          Are you sure you want to change?
          <br />
          <hr />
          <div className="d-flex align-items-center justify-content-end">
            <button
              className="btn btn-danger btn-sm m-1"
              onClick={() => {
                toast.dismiss(t.id);
                let params = {
                  ContractId: urlParams?.id,
                  RenewalRiskLevel: data,
                };
                platformApi.manageContract(
                  params,
                  (message: string, resp: any) => {
                    // console.log(message, resp)
                    toast.success(message);
                    getContractData();
                    hideLoader();
                  },
                  (message: string) => {
                    // console.log(message)
                    toast.error(message);
                    hideLoader();
                  },
                );
              }}>
              Yes
            </button>

            <button
              className="btn btn-secondary btn-sm m-1"
              onClick={() => toast.dismiss(t.id)}>
              No
            </button>
          </div>
        </span>
      ),
      { duration: 15000, className: 'withdraw-toast' },
    );
  };

  const contractLevelOption = (data: any) => {
    // console.log('change data', data);
    toast(
      t => (
        <span>
          Are you sure you want to change?
          <br />
          <hr />
          <div className="d-flex align-items-center justify-content-end">
            <button
              className="btn btn-danger btn-sm m-1"
              onClick={() => {
                toast.dismiss(t.id);
                let params = {
                  ContractId: urlParams?.id,
                  RenewalTimeRiskLevel: data,
                };
                platformApi.manageContract(
                  params,
                  (message: string, resp: any) => {
                    // console.log(message, resp)
                    toast.success(message);
                    getContractData();
                    hideLoader();
                  },
                  (message: string) => {
                    // console.log(message)
                    toast.error(message);
                    hideLoader();
                  },
                );
              }}>
              Yes
            </button>

            <button
              className="btn btn-secondary btn-sm m-1"
              onClick={() => toast.dismiss(t.id)}>
              No
            </button>
          </div>
        </span>
      ),
      { duration: 15000, className: 'withdraw-toast' },
    );
  };

  const confirmRenewalEvent = (data: any, params?: any) => {
    // console.log('change data', data);
    if (data == 'done') {
      setShowModal(true);
    } else {
      setShowModal(false);
      toast(
        t => (
          <span>
            Are you sure you want to change?
            <br />
            <hr />
            <div className="d-flex align-items-center justify-content-end">
              <button
                className="btn btn-danger btn-sm m-1"
                onClick={() => {
                  toast.dismiss(t.id);
                  setShowModal(false);
                  let payload = params ? params : { ContractId: urlParams?.id, RenewalEvent: data };
                  platformApi.manageContract(
                    payload,
                    (message: string, resp: any) => {
                      // console.log(message, resp)
                      toast.success(message);
                      getContractData();
                      hideLoader();
                      close();
                    },
                    (message: string) => {
                      // console.log(message)
                      toast.error(message);
                      hideLoader();
                      close();
                    },
                  );
                }}>
                Yes
              </button>

              <button
                className="btn btn-secondary btn-sm m-1"
                onClick={() => { toast.dismiss(t.id); close() }}>
                No
              </button>
            </div>
          </span>
        ),
        { duration: 15000, className: 'withdraw-toast' },
      );
    }
  };

  const confirmCustomerEvent = (data: any) => {
    // console.log('change data', data);
    if (data == 'done') {
      setShowModal(true);
    } else {
      setShowModal(false);
      toast(
        t => (
          <span>
            Are you sure you want to change?
            <br />
            <hr />
            <div className="d-flex align-items-center justify-content-end">
              <button
                className="btn btn-danger btn-sm m-1"
                onClick={() => {
                  toast.dismiss(t.id);
                  setShowModal(false);
                  let params = { ContractId: urlParams?.id, CustomerEvent: data };
                  platformApi.manageContract(
                    params,
                    (message: string, resp: any) => {
                      // console.log(message, resp)
                      toast.success(message);
                      getContractData();
                      hideLoader();
                    },
                    (message: string) => {
                      // console.log(message)
                      toast.error(message);
                      hideLoader();
                    },
                  );
                }}>
                Yes
              </button>

              <button
                className="btn btn-secondary btn-sm m-1"
                onClick={() => toast.dismiss(t.id)}>
                No
              </button>
            </div>
          </span>
        ),
        { duration: 15000, className: 'withdraw-toast' },
      );
    }
  };

  const contractLevelOtherOptionChange = (data: any) => {
    setChangeOptionsDatas(data.target.value);
    setErrorMessages({
      renewalEvent: null,
    })
    // console.log('data', data.target.value);
  };

  const firstModalSubmit = () => {
    console.log({ changeOptionsDatas, contactReasonCOCVal, otherReason, contractStartDate, contractTerm });
    if (changeOptionsDatas === undefined || changeOptionsDatas === "") {
      setErrorMessages({
        renewalEvent: 'Required'
      })
    }
    else if (changeOptionsDatas === "Closed Lost" && (contactReasonCOCVal === undefined || contactReasonCOCVal === "")) {
      setErrorMessages({
        ...errorsMessages,
        contractReason: 'Required'
      })
    }
    else if (changeOptionsDatas === "Closed Lost" && contactReasonCOCVal === "Other" && (otherReason === undefined || otherReason === "")) {
      setErrorMessages({
        ...errorsMessages,
        otherReason: 'Required'
      })
    }
    else if (changeOptionsDatas === "Closed Won" && (contractTerm === undefined || contractTerm === "")) {
      setErrorMessages({
        ...errorsMessages,
        contractTerm: 'Required'
      })
    }
    else {
      let params: any = {
        ContractId: urlParams?.id,
        RenewalEvent: changeOptionsDatas
      };
      if (contractStartDate !== undefined && contractStartDate !== "") {
        params["contractstartdate"] = moment(data.contractStartDate).format("MM/DD/YY")
      }
      if (contractTerm !== undefined && contractTerm !== "") {
        params["contractterm"] = contractTerm
      }
      if (contactReasonCOCVal !== undefined && contactReasonCOCVal !== "") {
        params["ReasonCOC"] = contactReasonCOCVal
      }
      if (otherReason !== undefined || otherReason !== "") {
        params["OtherReasonCOC"] = otherReason
      }

      confirmRenewalEvent(changeOptionsDatas, params)
    }
  };

  const close = () => {
    if (contractData && contractData.EndDate) {
      let nextDate = getNextDate(contractData?.EndDate)
      setContractStartDate(nextDate)
    } else {
      setContractStartDate(undefined);
    }
    setShowModal(false);
    setChangeOptionsDatas(undefined);
    setContractReasonCOCVal(undefined);
    setOtherReason(undefined)
    setContractTerm("12");
    setErrorMessages({})
  };

  const getContractReasonCoc = () => {
    showLoader();
    platformApi.getContractReasonCOC(
      {},
      (message: string, resp: any) => {
        // console.log('cONTRACT REASON', resp);
        let tempArr = resp.data;
        setContractReasonCOC(tempArr);
        // console.log('tempArr', tempArr?.length);
        hideLoader();
      },
      (message: string) => {
        hideLoader();
      },
    );
  };

  const contactReasonCOCVals = (data: any) => {
    // console.log('contactReasonCOCVal', data);
    setContractReasonCOCVal(data);
  };

  const renewalSubmit = (data: any) => {
    // console.log(data)
    let params: any = {
      ContractId: urlParams?.id,
      RenewalNote: data?.renewalProcess,
    };
    showLoader();
    platformApi.manageContractRenewalNotes(
      params,
      (message: string, resp: any) => {
        // console.log(resp)
        toast.success(message);
        setEditRenewals(false);
        renewalReset();
        hideLoader();
        getRenewalProcess();
      },
      (message: string) => {
        toast.error(message);
        hideLoader();
      },
    );
  };

  const getRenewalProcess = () => {
    showLoader();
    platformApi.getRenewalNotes(
      {
        ContractId: urlParams?.id,
      },
      (message: string, resp: any) => {
        // console.log(resp)
        setRenewals(resp.data.list.RenewalNote);
        hideLoader();
      },
      (message: string) => {
        // toast.error(message)
        hideLoader();
      },
    );
  };

  const customerEvents = (n: any) => {
    let x = Array.from({ length: n }, (v, k) => k + 1)
    // console.log({ divWidth })
    let events = x.map((value, index) =>
      <div
        className="customer-evnt-grp"
        key={index}
        style={{ width: `${divWidth}px` }}
      >
        <div className={"progress-circle " + ((contractData?.CustomerEvent >= value) ? "active" : "")}
          onClick={() => confirmCustomerEvent(value)}>{value}
        </div>
      </div>
    )
    return events
  }

  const closeAccountModal = () => {
    setShowAccountModal(false);
  };

  const closeCustomersModal = () => {
    setShowCustomersModal(false);
  };

  const openEntitlementUpdateModalClose = (column: any, value: any, productId: any) => {
    // console.log(column, value, contractId)
    setShowEntitlementUpdateModal(true);
    setSelecedEntitlementProductId(productId);
    setSelectedEntitlementProductvalue(value);
    setSelectedEntitlementProductColumn(column);
  }

  const closeEntitlementUpdateModalClose = () => {
    setShowEntitlementUpdateModal(false);
    setSelecedEntitlementProductId(null);
    setSelectedEntitlementProductvalue(null);
    setSelectedEntitlementProductColumn(null);
  }

  const EditBtn = ({ column, value, id }: any) => {
    return (
      <span
        data-toggle="tooltip"
        data-placement="top"
        title="update/edit"
      >
        <AiOutlineEdit
          onClick={() => openEntitlementUpdateModalClose(column, value, id)}
          className='cursor-pointer ml-2'
        />
      </span >
    )
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Product Name',
        accessor: 'product.Name',
      },
      {
        Header: 'Quantity',
        accessor: 'Quantity',
      },
      {
        Header: 'Sales Price ($)',
        accessor: 'Subtotal',
        Cell: ({ column, value, row }: any) => (
          <div>
            {value ? formatNumber(value) : '0.00'}
          </div>
        )
      },
      {
        Header: 'Discount (%)',
        accessor: 'Discount',
        Cell: ({ column, value, row }: any) => (
          <div>
            {value ? formatNumber(value) : '0.00'}
          </div>
        )
      },
      {
        Header: 'Tax ($)',
        accessor: 'Taxval',
        Cell: ({ column, value, row }: any) => (
          <div>
            {value ? formatNumber(value) : '0.00'}
          </div>
        )
      },
      {
        Header: 'Total ($)',
        accessor: 'TotalPrice',
        Cell: ({ column, value, row }: any) => (
          <div>
            {value ? formatNumber(value) : '0.00'}
          </div>
        )
      },
      {
        Header: 'Renewal Forecast ($)',
        accessor: 'RenewalForecast',
        Cell: ({ column, value, row }: any) => (
          <div>
            {value ? formatNumber(value) : '0.00'}
            <EditBtn column={column} value={value} id={row.original.id} />
          </div>
        )
      },
    ],
    [contractLevelList],
  );

  const calculateDivWidth = () => {
    const divElement: any = document.getElementById('get-width');
    const computedWidth: any = window.getComputedStyle(divElement).width;
    setDivWdith(String((+computedWidth.split('px')[0] - 90) / 6));
    // console.log('Div width:', computedWidth, +computedWidth.split('px')[0] / 6);
  }

  const getContractPdf = (ContractId: any) => {
    showLoader();
    setLoading(true);
    platformApi.getContractPdf({ ContractId },
      (message: string, resp: any) => {
        // console.log(resp);
        window.open(resp.data.link, '_blank');
        setLoading(false);
        hideLoader();
      }, (message: string) => {
        setLoading(false);
        toast.error(message);
        hideLoader();
      })
  }

  const closeContractModal = () => {
    setShowContractModal(false)
    setContractIsEdit(false)
  }

  const openContractModal = () => {
    setShowContractModal(true)
    setContractIsEdit(true)
  }

  const fileOnChange = (data: any) => {
    if (data?.target?.files) {
      setFileValue(data.target.files[0])
    }
  }

  const saveFile = () => {
    let formData: any = new FormData()
    if (fileValue) {
      formData.append('id', contractData?.id)
      formData.append('sla', fileValue)
    } else {
      return toast.error('Please upload a pdf file')
    }

    showLoader()
    platformApi.uploadContractDoc(formData, (message: string, resp: any) => {
      toast.success(message)
      getContractData()
      fileUploadRef.current.value = ""
      setFileValue(null)
      hideLoader()
    }, (message: string) => {
      toast.error(message)
      hideLoader()
    })
  }

  const getNewAccntStatus = () => {
    commonApi.isNewAccount({
      AccountId: contractData?.AccountId
    },
      (message: string, resp: any) => {
        setIsNewAccount(resp.data)
      }, (message: string) => { })
  }

  const getQuoteDetails = () => {
    showLoader()
    platformApi.getAllQuote({
      QuoteId: renewalQuoteId
    }, (message: string, resp: any) => {
      // console.log(message, resp)
      setQuoteData(resp.data.list.data[0])
      hideLoader()
    }, (message: string) => {
      // console.log(message)
      hideLoader()
    })
  }

  useEffect(() => {
    calculateDivWidth()
    getContractReasonCoc();
  }, [])

  useEffect(() => {
    if (renewals) {
      renewalSetValue('renewalProcess', renewals);
    }
  }, [renewals]);

  useEffect(() => {
    if (urlParams && urlParams.id) {
      getContractData();
      getContractProductData();
      getRiskLevelData();
      getRiskTimeLevelData();
      getRenewalProcess();
    }
  }, [urlParams]);

  useEffect(() => {
    if (contractData) {
      getNewAccntStatus()
      if (contractData.RenewQuoteId && contractData.RenewQuoteId != "") {
        setRenewalQuoteId(contractData.RenewQuoteId)
      }
      if (contractData.EndDate) {
        let nextDate = getNextDate(contractData?.EndDate)
        setContractStartDate(nextDate)
      }
    }
  }, [contractData])

  useEffect(() => {
    if (renewalQuoteId && renewalQuoteId !== '') {
      getQuoteDetails()
    }
  }, [renewalQuoteId])

  return (
    <React.Fragment>
      <AddAccountTeamModal
        close={closeAccountModal}
        show={showAccountModal}
        AccountId={urlParams?.id}
        type="contract"
      />
      <AddCustomerTeamModal
        close={closeCustomersModal}
        show={showCustomersModal}
        AccountId={contractData?.AccountId}
        ContractId={urlParams?.id}
        type="contract"
      />
      <AddContractModal
        close={closeContractModal}
        show={showContractModal}
        AccountId={contractData?.AccountId}
        isEdit={contractIsEdit}
        ContractId={urlParams?.id}
      />
      <EntitlementUpdateModal
        show={showEntitlementUpdateModal}
        close={closeEntitlementUpdateModalClose}
        contractId={urlParams?.id}
        id={selectedEntitlementProductId}
        value={selectedEntitlementProductValue}
        fieldDetail={selectedEntitlementProductColumn}
        callbackFunctions={[
          getContractData,
          getContractProductData
        ]}
      />
      <div id="layout-wrapper">
        <Header />
        <LeftPanel />
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box d-flex align-items-center justify-content-between">
                    <h4 className="mb-0 font-size-18">Contract Details</h4>
                    <div className="page-title-right">
                      <ol className="breadcrumb m-0">
                        <li className="breadcrumb-item">
                          <Link to={URLS.CONTRACT_PAGE}>Contract List</Link>
                        </li>
                        <li className="breadcrumb-item active">
                          Contract Details
                        </li>
                      </ol>
                    </div>
                  </div>
                  <div className='d-flex justify-content-end mb-3 gap-10'>
                    {contractData?.Status && !["Activated", "Expired"].includes(contractData?.Status) ?
                      <button
                        type='button'
                        className="btn btn-success"
                        onClick={openContractModal}
                      >
                        Edit Contract
                      </button>
                      : null
                    }
                    <Link
                      to={`/account-view/${contractData?.AccountId}`}
                      className="btn btn-success">
                      Go To Account
                    </Link>
                    <button
                      type='button'
                      className="btn btn-success"
                      onClick={() => getContractPdf(urlParams?.id)}
                    >
                      Export to pdf
                    </button>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-4">
                  <div className="card">
                    <div className="card-body">
                      <div className="">
                        <h6 className="mb-3">
                          <strong>Contract:</strong>{' '}
                          {contractData?.ContractNumber
                            ? contractData?.ContractNumber
                            : ''}
                        </h6>
                        <h6 className="mb-3">
                          <strong>Customer:</strong>{' '}
                          <Link to={`/account-view/${contractData?.AccountId}`}>
                            {contractData?.account
                              ? contractData?.account?.Name
                              : 'N/A'}
                          </Link>
                        </h6>
                        <h6 className="mb-3">
                          <strong>Term:</strong>{' '}
                          {contractData?.ContractTerm
                            ? contractData?.ContractTerm
                            : 'N/A'}{' '}
                          Months
                        </h6>
                        <h6 className="mb-3">
                          <strong>Payment terms:</strong>{' '}
                          {contractData?.paymentterm
                            ? contractData?.paymentterm
                            : ''}
                        </h6>
                        <h6 className="mb-3">
                          <strong>Previous Contract Amount ($):</strong>{" "}
                          {formatNumber(contractData?.prevContractAmt)}
                        </h6>
                        <h6 className="mb-3">
                          <strong>Contract Value ($):</strong>{" "}
                          {formatNumber(contractData?.ContractValue)}
                        </h6>

                        <h6 className="mb-3">
                          <strong>ARR ($):</strong>{" "}
                          {formatNumber(contractData?.arr)}
                        </h6>
                        <h6 className="mb-3">
                          <strong>MRR ($):</strong>{" "}
                          {formatNumber(contractData?.mrr)}
                        </h6>
                        <h6 className="mb-3">
                          <strong>Uplift ($)</strong>{" "}
                          {contractData?.isRenewed == '1' && contractData?.Upliftval ? formatNumber(contractData?.Upliftval) : '0.00'}
                        </h6>
                        <h6 className="mb-3">
                          <strong>Cross Sell ($)</strong>{" "}
                          {contractData?.isRenewed == '1' && contractData?.Crosssellval ? formatNumber(contractData?.Crosssellval) : '0.00'}
                        </h6>
                        <h6 className="mb-3">
                          <strong>UpSell ($)</strong>{" "}
                          {contractData?.isRenewed == '1' && contractData?.Upsellval ? formatNumber(contractData?.Upsellval) : '0.00'}
                        </h6>
                        <h6 className="mb-3">
                          <strong>Contraction ($):</strong>{" "}
                          {contractData?.isRenewed == '1' && contractData?.Contractionval ? formatNumber(contractData?.Contractionval) : '0.00'}
                        </h6>
                        <h6 className="mb-3">
                          <strong>Best case ($):</strong>{" "}
                          {contractData?.bestcase ? formatNumber(contractData?.bestcase)
                            : ''}
                        </h6>

                        <h6 className="mb-3">
                          <strong>Most Likely Amount ($):</strong>{" "}
                          {formatNumber(contractData?.mostlikely_forecast)}
                        </h6>

                        <h6 className="mb-3">
                          <strong>New ARR ($):</strong>{" "}
                          {formatNumber(contractData?.newarr)}
                        </h6>


                        <h6 className="mb-3">
                          <strong>Start Date:</strong>{" "}
                          {contractData?.StartDate ? moment(contractData?.StartDate).format("MM/DD/YY")
                            : ''}
                        </h6>
                        <h6 className="mb-3">
                          <strong>End Date:</strong>{" "}
                          {contractData?.EndDate ? moment(contractData?.EndDate).format("MM/DD/YY")
                            : ''}
                        </h6>

                        <h6 className="mb-3">
                          <strong>Quotation name:</strong>{" "}
                          <Link to={`/quote-details/${contractData?.quote?.QuoteId}`}>
                            {contractData?.quote?.Name}
                          </Link>
                        </h6>

                        <h6 className="mb-3">
                          <strong>Region:</strong>{' '}
                          {contractData?.region
                            ? contractData?.region?.region_name
                            : ''}
                        </h6>


                        <h6 className="mb-3">
                          <strong>GEO:</strong>{' '}
                        </h6>
                        <h6 className="mb-3">
                          <strong>Quote End Date:</strong>{' '}
                          {contractData?.EndDate ? moment(contractData?.quote?.ExpirationDate).format("MM/DD/YY") : ''}
                        </h6>
                        <h6 className="mb-3">
                          <strong>Account Vertical:</strong>{' '}
                          {contractData?.account
                            ? contractData?.account?.Industry
                            : ''}
                        </h6>
                        <h6 className="mb-3">
                          <strong>POC:</strong>{' '}
                          {contractData?.account
                            ? contractData?.account?.account_managerobj?.name
                            : ''}
                        </h6>
                        {/* <h6 className="mb-3">
                          <strong>Contracted Uplift:</strong>{' '}
                          {contractData?.Upliftval
                            ? contractData?.Upliftval + " %"
                            : ''}
                        </h6> */}
                        <h6 className="mb-3">
                          <strong>Custom terms:</strong>{' '}
                          {contractData?.cuatomerterm && contractData?.cuatomerterm == "1"
                            ? 'Yes'
                            : 'No'}
                        </h6>
                        <h6 className="mb-3">
                          <strong>Most likely case:</strong>{' '}
                          {contractData?.mostlikely && contractData?.mostlikely == "1"
                            ? 'Yes'
                            : 'No'}
                        </h6>

                        <h6 className="mb-3">
                          <strong>Next steps:</strong>{" "}
                          {contractData?.nextsteps ? contractData?.nextsteps
                            : ''}
                        </h6>
                        <h6 className="mb-3">
                          <strong>Close plan:</strong>{" "}
                          {contractData?.closeplan ? contractData?.closeplan
                            : ''}
                        </h6>

                        <h6 className="mb-3">
                          <strong>Renewal Forecast ($):</strong>{' '}
                          {contractData?.RenewalForecast ? contractData?.RenewalForecast
                            : '0.00'}
                          {/* {contractData?.SBQQRenewalForecast
                            ? contractData?.SBQQRenewalForecast
                            : ''}
                          <div className="form-group">
                            <textarea
                              className="form-control"
                              placeholder="Enter renewal forecast"
                              defaultValue={contractData?.RenewalForecast
                                ? contractData?.RenewalForecast
                                : ''}
                              onBlur={(e) => debouncedChangeHandlerForSingleForecast(e?.target?.value, contractData?.ContractId)}
                            />
                          </div> */}
                        </h6>
                        <h6 className="mb-3">
                          <strong>Renewal Risk Level:</strong>{' '}
                          <select
                            value={
                              contractData?.RenewalRiskLevel
                                ? contractData?.RenewalRiskLevel
                                : ''
                            }
                            className="form-control mb-3"
                            onChange={e =>
                              contractRiskLevelOption(e.target.value)
                            }>
                            {contractLevelList &&
                              contractLevelList?.length &&
                              contractLevelList?.map((o: any, index: number) => (
                                <option value={o} key={index}>{o}</option>
                              ))}
                            {/* <option>abc</option> */}
                          </select>
                        </h6>
                        <h6 className="mb-3">
                          <strong>Renewal Timing Risk:</strong>{' '}
                          <select
                            value={
                              contractData?.RenewalTimeRiskLevel
                                ? contractData?.RenewalTimeRiskLevel
                                : ''
                            }
                            className="form-control mb-3"
                            onChange={e => contractLevelOption(e.target.value)}>
                            {contractRiskLevelList &&
                              contractRiskLevelList?.length &&
                              contractRiskLevelList?.map((o: any, index: number) => (
                                <option value={o} key={index}>{o}</option>
                              ))}
                            {/* <option>abc</option> */}
                          </select>
                        </h6>


                        <h6 className="mb-3">
                          <strong>Auto-renewal:</strong>{' '}
                          {contractData?.autorenewal && contractData?.autorenewal == "1"
                            ? 'Yes'
                            : 'No'}
                        </h6>

                        <h6 className="mb-3">
                          <strong>Uplift (%):</strong>{" "}
                          {contractData?.upliftpercent ? formatNumber(contractData?.upliftpercent)
                            : ''}
                        </h6>

                        <h6 className="mb-3">
                          <strong>Status:</strong>{" "}
                          {contractData?.newstatus}
                        </h6>

                        <h6 className="mb-3">
                          <strong>Submotion:</strong>{" "}
                          {contractData?.submotion}
                        </h6>


                        <h6 className="mb-3">
                          <strong>Upload PDF: </strong>{" "}
                          <div className="mb-2 mt-2">
                            {/* <input type="file" accept=".pdf" onChange={fileOnChange} ref={fileUploadRef} /> */}
                            <div className='d-flex align-items-center gap-10'>
                              <div className="custom-file">
                                <input type="file" className="custom-file-input" accept=".pdf" onChange={fileOnChange} ref={fileUploadRef} id="customFile" />
                                <label className="custom-file-label" htmlFor="customFile">{fileValue && fileValue != undefined ? fileValue?.name : "Choose file"}</label>
                              </div>
                              <button
                                className='btn btn-primary'
                                type='submit'
                                onClick={saveFile}
                              >
                                Upload
                              </button>
                            </div>
                          </div>
                          {
                            contractData?.sladoc && contractData?.sladoc?.length > 0 ?
                              contractData?.sladoc?.map((item: any, index: number) => (
                                <div className="mb-1" key={Math.random()}>
                                  <a
                                    href={contractData?.sla?.[index]?.basepath}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    {item?.file_name_original}
                                  </a>
                                  <AiOutlineDelete
                                    color='red'
                                    size={"18px"}
                                    className='cursor-pointer'
                                    style={{ paddingLeft: "5px" }}
                                    onClick={() =>
                                      deleteNotification(item?.id, "document", commonFunctions.deleteFile, getContractData)
                                    }
                                  />
                                </div>
                              ))
                              :
                              null
                          }
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-8">
                  <div className="card">
                    <div className="card-body">
                      <div className="">
                        <h5 className="card-title text-center mb-3">
                          Renewal Events
                        </h5>
                        <div className="progress-container mb-5">
                          <div className='renewal-evnt'>
                            <div
                              className={'progress-circle ' + (contractData?.RenewalEventPoint >= 10 ? 'active' : '')}
                              onClick={() =>
                                confirmRenewalEvent('Usage Reviewed')
                              }
                              data-toggle="tooltip"
                              data-placement="bottom"
                              title="Usage Reviewed">
                              1
                            </div>
                          </div>
                          <div className='renewal-evnt'>
                            <div
                              className={'progress-circle ' + (contractData?.RenewalEventPoint >= 20 ? 'active' : '')}
                              onClick={() =>
                                confirmRenewalEvent(
                                  'Success Review completed W/ Customer',
                                )
                              }
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Success Review completed W/ Customer">
                              2
                            </div>
                          </div>
                          <div className='renewal-evnt'>
                            <div
                              className={'progress-circle ' + (contractData?.RenewalEventPoint >= 30 ? 'active' : '')}
                              onClick={() =>
                                confirmRenewalEvent(
                                  'Renewal/ Partnership plan Agreed upon',
                                )
                              }
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Renewal/ Partnership plan Agreed upon">
                              3
                            </div>
                          </div>
                          <div className='renewal-evnt'>
                            <div
                              className={'progress-circle ' + (contractData?.RenewalEventPoint >= 40 ? 'active' : '')}
                              onClick={() =>
                                confirmRenewalEvent('Quote sent to customer')
                              }
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Quote sent to customer">
                              4
                            </div>
                          </div>
                          <div className='renewal-evnt'>
                            <div
                              className={'progress-circle ' + (contractData?.RenewalEventPoint >= 50 ? 'active' : '')}
                              data-toggle="tooltip"
                              onClick={() =>
                                confirmRenewalEvent('Intent Confirmed')
                              }
                              data-placement="top"
                              title="Intent Confirmed">
                              5
                            </div>
                          </div>
                          <div className='renewal-evnt'>
                            <div
                              className={'progress-circle ' + (contractData?.RenewalEventPoint >= 60 ? 'active' : '')}
                              onClick={() =>
                                confirmRenewalEvent('Renewal Process Confirmed')
                              }
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Renewal Process Confirmed">
                              6
                            </div>
                          </div>
                          <div className='renewal-evnt'>
                            <div
                              className={'progress-circle ' + (contractData?.RenewalEventPoint >= 70 ? 'active' : '')}
                              data-toggle="tooltip"
                              onClick={() => confirmRenewalEvent('ETA Confirmed')}
                              data-placement="top"
                              title="ETA Confirmed">
                              7
                            </div>
                          </div>
                          <div className='renewal-evnt'>
                            <div
                              className={'progress-circle ' + (contractData?.RenewalEventPoint >= 80 ? 'active' : '')}
                              data-toggle="tooltip"
                              onClick={() =>
                                confirmRenewalEvent('Pricing Confirmed')
                              }
                              data-placement="top"
                              title="Pricing Confirmed">
                              8
                            </div>
                          </div>
                          <div className='renewal-evnt'>
                            <div
                              aria-readonly={
                                contractData?.RenewalEvent != 'Pricing Confirmed'
                              }
                              className={'progress-circle ' + (contractData?.RenewalEventPoint >= 100 ? 'active' : '')}
                              data-toggle="tooltip"
                              onClick={() => confirmRenewalEvent('done')}>
                              Done
                            </div>
                          </div>
                        </div>

                        <h5 className="card-title text-center mb-3">
                          {isNewAccount && isNewAccount?.isnew == 1 ? "Sales Events" : "Customer Events"}
                        </h5>
                        <div className='relative'>
                          <div className={'customer-evnt-divider ' + (+contractData?.ContractTerm / 2 < 2 ? 'd-none' : '')}></div>
                          <div className="progress-container customer-envt">
                            {
                              contractData?.ContractTerm
                                ? (contractData?.ContractTerm % 2) > 0 ?
                                  customerEvents((Number(contractData?.ContractTerm) / 2) + 1)
                                  :
                                  customerEvents((Number(contractData?.ContractTerm) / 2))
                                : null
                            }
                          </div >
                        </div>
                      </div >
                    </div >
                  </div >

                  <div className="card">
                    <div className="card-body" id='get-width'>
                      <div className="">
                        <h5 className="card-title mb-3">Entitlements</h5>
                        <div className="table-responsive">
                          <TableContainer
                            columns={columns}
                            data={data}
                            fetchData={getContractProductData}
                            loading={loading}
                            rawData={contractProductData}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="card">
                            <div className="card-body" style={{ minHeight: 325 }}>
                              <div className="d-flex flex-wrap justify-content-between">
                                <h5 className="card-title mr-2">
                                  Account Team
                                </h5>
                                <button
                                  className="btn btn-success"
                                  onClick={() => setShowAccountModal(true)}>
                                  Add Account Team
                                  {/* <AiOutlinePlus /> */}
                                </button>
                              </div>
                              <AccountTeamListing
                                AccountId={urlParams?.id}
                                show={showAccountModal}
                                type={'contract'}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="card">
                            <div className="card-body" style={{ minHeight: 325 }}>
                              <div className="d-flex flex-wrap justify-content-between">
                                <h5 className="card-title mr-2">
                                  Customer Team
                                </h5>
                                <button
                                  className="btn btn-success"
                                  onClick={() => setShowCustomersModal(true)}>
                                  Add Customer Team
                                </button>
                              </div>
                              <CustomerTeamListing
                                AccountId={urlParams?.id}
                                show={showCustomersModal}
                                setShowCustomersModal={setShowCustomersModal}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>{' '}
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="card eql-hght-nts-crd"
                        style={{ minHeight: "212px" }}
                      >
                        <div className="card-body">
                          <div className="d-flex flex-wrap">
                            <h5 className="card-title mr-2">
                              Renewal Notes:
                            </h5>
                          </div>
                          {!editRenewals && renewals ? (
                            <>
                              <div className="note-cnt-overflow renewal-overflow">
                                {/* {renewals && changeString(extractContent(renewals), 180)} */}
                                {renewals && extractContent(renewals)}
                              </div>
                              <button
                                className="btn btn-primary mt-2"
                                type="button"
                                onClick={() => setEditRenewals(true)}>
                                edit
                              </button>
                            </>
                          ) : (
                            <form
                              onSubmit={renewalHandleSubmit(renewalSubmit)}>
                              <div className="form-group">
                                <Controller
                                  name={'renewalProcess'}
                                  control={renewalControl}
                                  render={({
                                    field: { value, onChange, onBlur, name },
                                  }) => (
                                    <>
                                      <textarea
                                        // type="text"
                                        className={
                                          'form-control ' +
                                          (renewalErrors?.renewalProcess
                                            ? ' border-danger'
                                            : '')
                                        }
                                        id="renewalProcess"
                                        name={name}
                                        onChange={onChange}
                                        value={value}
                                        onBlur={onBlur}
                                        placeholder="Renewal Notes ..."
                                        style={{
                                          height: "85px"
                                        }}
                                      />
                                    </>
                                  )}
                                />
                                {renewalErrors &&
                                  renewalErrors.renewalProcess && (
                                    <span className="text-danger">
                                      {renewalErrors?.renewalProcess?.message}
                                    </span>
                                  )}
                              </div>
                              <div className="d-flex justify-content-center gap-10">
                                <button
                                  className="btn btn-primary"
                                  type="submit">
                                  save
                                </button>
                                <button
                                  className="btn btn-outline-danger"
                                  type="button"
                                  onClick={() => setEditRenewals(false)}>
                                  cancel
                                </button>
                              </div>
                            </form>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div >

                <div className="col-12">
                  {quoteData ?
                    <div className="col-12">
                      <div className="card">
                        <div className="card-body">
                          <div className="d-flex flex-wrap justify-content-between">
                            <h5 className="card-title mr-2">Renewal Quotes</h5>
                          </div>
                          <QuoteList
                            id={contractData?.ContractId}
                            type="ContractId"
                          />
                        </div>
                      </div>
                    </div>
                    :
                    null
                  }
                </div>

              </div>
            </div >
          </div >
          <Footer />
        </div >
      </div >

      <Modal
        className="salesforce-modal"
        show={showModal}
        onHide={() => {
          close();
        }}>
        <Modal.Body>
          <div className="modal-body">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="form-group col-12">
                        <label htmlFor="ContactId">
                          Select Renewal Event{' '}
                          <span className="text-danger">
                            *
                          </span>
                        </label>

                        <select
                          className={"form-control " + (errorsMessages?.renewalEvent ? ' border-danger' : '')}
                          onChange={e =>
                            contractLevelOtherOptionChange(e)
                          }>
                          <option value="">
                            Please Select
                          </option>
                          <option value="Closed Won">
                            Closed Won
                          </option>
                          <option value="Closed Lost">
                            Closed Lost
                          </option>
                          <option value="Cotermed with other contract">
                            Cotermed with other contract
                          </option>
                        </select>
                        {errorsMessages?.renewalEvent &&
                          <span className='text-danger'>{errorsMessages?.renewalEvent}</span>
                        }
                      </div>

                      {changeOptionsDatas === 'Closed Won' ?
                        <div className="form-group col-12">
                          <div className="row">
                            <div className="form-group col-6">
                              <label htmlFor="contractstartdate">Contract Start Date</label>
                              <DatePicker
                                selected={contractStartDate && contractStartDate !== undefined ? new Date(contractStartDate) : null}
                                onChange={(event: any) => {
                                  setContractStartDate(event);
                                  setErrorMessages({ ...errorsMessages, contractStartDate: null })
                                }}
                                dateFormat="MM/dd/yy"
                                className={"form-control " + (errorsMessages?.contractStartDate ? ' border-danger' : '')}
                                id="contractstartdate"
                                name="contractstartdate"
                                placeholderText="Enter contract start date"
                              />
                              {errorsMessages?.contractStartDate &&
                                <span className='text-danger'>{errorsMessages?.contractStartDate}</span>
                              }
                            </div>
                            <div className="form-group col-6">
                              <label htmlFor="contractterm">Contract Term
                                <span className="text-danger">
                                  *
                                </span>
                              </label>
                              <input
                                type="text"
                                className={"form-control " + (errorsMessages?.contractTerm ? ' border-danger' : '')}
                                id="contractterm"
                                onChange={(event: any) => {
                                  setContractTerm(event.target.value);
                                  setErrorMessages({ ...errorsMessages, contractTerm: null })
                                }}
                                defaultValue={contractTerm}
                                placeholder="Enter contract term"
                                onKeyDown={handleKeyPressNumber}
                              />
                              {errorsMessages?.contractTerm &&
                                <span className='text-danger'>{errorsMessages?.contractTerm}</span>
                              }
                            </div>
                          </div>
                        </div>
                        :
                        null
                      }

                      {changeOptionsDatas === 'Closed Lost' ?
                        <div className="form-group col-12">
                          <label htmlFor="ContactId">
                            Select Contract Event
                            <span className="text-danger">
                              *
                            </span>
                          </label>
                          <select
                            className={"form-control " + (errorsMessages?.contractReason ? ' border-danger' : '')}
                            onChange={(event: any) => {
                              contactReasonCOCVals(event.target.value);
                              setErrorMessages({ ...errorsMessages, contractReason: null })
                            }}>
                            <option value="">Please Select</option>
                            {contractReasonCOC &&
                              contractReasonCOC?.length &&
                              contractReasonCOC?.map(
                                (optn: any, index: number) => (
                                  <option value={optn} key={index}>{optn}</option>
                                ),
                              )}
                          </select>
                          {errorsMessages?.contractReason &&
                            <span className='text-danger'>{errorsMessages?.contractReason}</span>
                          }
                        </div>
                        :
                        null}

                      {contactReasonCOCVal === 'Other' ?
                        <div className="form-group col-12">
                          <label htmlFor="ContactId">
                            Reason
                            <span className="text-danger">
                              *
                            </span>
                          </label>
                          <input
                            type="text"
                            className={'form-control ' + (errorsMessages?.otherReason ? ' border-danger' : '')}
                            id="otherReason"
                            name="otherReason"
                            onChange={(event: any) => {
                              setOtherReason(event?.target.value);
                              setErrorMessages({ ...errorsMessages, otherReason: null })
                            }}
                            placeholder="Enter Reason"
                          />
                          {errorsMessages?.otherReason &&
                            <span className='text-danger'>{errorsMessages?.otherReason}</span>
                          }
                        </div>
                        : null}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12">
                <div className="align-items-center d-flex gap-10 justify-content-center">
                  <button
                    type="button"
                    onClick={() => firstModalSubmit()}
                    className="btn btn-primary">
                    Save
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-danger"
                    onClick={() => {
                      close();
                    }}>
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal >

    </React.Fragment >
  );
};

export default ContractDetails;
