import React, { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import Footer from '../../components/commonComponents/Footer'
import Header from '../../components/commonComponents/Header'
import LeftPanel from '../../components/commonComponents/LeftPanel'
import TableContainer from '../../components/commonComponents/TableContainer'
import { useAppLoader } from '../../_common/hooks/common/appLoaderHook'
import { URLS } from '../../_config'
import { AiOutlineEdit } from 'react-icons/ai'
import DeleteButtonCell from '../../components/actionsBtns/DeleteButtonCell'
import { useCommonApi } from '../../_common/hooks/api/common/commonApiHook'
import AddDesignationModal from '../../components/modals/addDesignation.modal'
import { TiTickOutline } from 'react-icons/ti'


const DesignationSettings = () => {
    const commonApi = useCommonApi();
    const { showLoader, hideLoader } = useAppLoader();

    const [designationData, setDesignationData] = useState<any>(null)
    const [data, setData] = useState<any>([])
    const [showAddDesignationModal, setShowDesignationModal] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false)
    const [isEdit, setIsEdit] = useState<boolean>(false)
    const [designationId, setDesignationId] = useState<any>(null)

    const getDesignationData = (page = 1) => {
        showLoader();
        setLoading(true);
        commonApi.getAllDesignation({ page: page },
            (message: string, resp: any) => {
                // console.log(resp);
                setLoading(false);
                setDesignationData(resp.data.list);
                setData(resp.data.list.data);
                hideLoader();
            }, (message: string) => {
                setLoading(false);
                hideLoader();
            })
    }

    const closeAddDesignationModal = () => {
        setShowDesignationModal(false)
        setIsEdit(false)
        setDesignationId(null)
    }

    const EditBtn = ({ column, value }: any) => {
        const handleClick = () => {
            // console.log(value)
            setShowDesignationModal(true)
            setDesignationId(value)
            setIsEdit(true)
        }
        return (
            <AiOutlineEdit
                onClick={handleClick}
                className="cursor-pointer mr-2"
                data-toggle="tooltip" data-placement="top" title="Edit"
            />
        )
    }


    const deleteDesignation = (id: any) => {
        // console.log(id)
        return new Promise((resolve: any, reject: any) => {
            commonApi.deleteDesignation({
                id
            }, (message: string, resp: any) => {
                resolve(message)
            }, (message: string) => {
                reject(message);
            });
        })
    }

    const columns = useMemo(
        () => [
            {
                Header: 'Actions',
                accessor: "id",
                Cell: ({ column, value }: any) =>
                    <>
                        <DeleteButtonCell
                            column={column}
                            value={value}
                            deleteFunc={deleteDesignation}
                            callbackFunc={[getDesignationData]}
                            type={"Designation"}
                        />
                        <EditBtn column={column} value={value} />
                    </>,
            },
            {
                Header: "Name",
                accessor: "name",
            },
            // {
            //     Header: 'Approver',
            //     accessor: 'canapprove',
            //     Cell: ({ value }: any) => (
            //         <div className={value == "1" ? "d-flex justify-content-center" : ""}>
            //             {value == '1' ? <TiTickOutline color='green' size={24} /> : ""}
            //         </div>
            //     ),
            // },
        ],
        []
    )

    useEffect(() => {
        getDesignationData()
    }, [])

    return (
        <React.Fragment>
            <AddDesignationModal
                show={showAddDesignationModal}
                close={closeAddDesignationModal}
                id={designationId}
                isEdit={isEdit}
				designationListCallbackFunc={getDesignationData}
            />
            <div id="layout-wrapper">
                <Header />
                <LeftPanel />
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            {/* start page title */}
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-flex align-items-center justify-content-between">
                                        <h4 className="mb-0 font-size-18">Designation</h4>
                                        <div className="page-title-right">
                                            <ol className="breadcrumb m-0">
                                                <li className="breadcrumb-item">
                                                    <Link to={URLS.REVENUE}>Revenue</Link>
                                                </li>
                                                <li className="breadcrumb-item active">Designation</li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-body">
                                    <div className="d-flex flex-wrap justify-content-between">
                                        <h5 className="card-title mr-2">Designation</h5>
                                        <button
                                            className="btn btn-success"
                                            onClick={() => setShowDesignationModal(true)}
                                        >
                                            Add Designation
                                        </button>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="table-responsive manage-user-table">
                                                <TableContainer
                                                    columns={columns}
                                                    data={data}
                                                    fetchData={getDesignationData}
                                                    loading={loading}
                                                    rawData={designationData}
                                                    clickFunc={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </React.Fragment >
    )
}

export default DesignationSettings