//import { getSubdomain } from './functions';
// export const API_BASE_URL = process.env.REACT_APP_API_URL;

import { API_BASE_URL } from "./site_urls";

export const STORAGE = 'arr';
// export const UPDATED_USER = 'updatedUser';
export const APP_URL = window.location.origin;

export const URLS = {
  HOME: '/',
  DASHBOARD: '/dashboard',
  LOGIN: '/login',
  SIGNUP: '/signup',
  REVENUE: '/revenue',
  UPSIDE: '/upside',
  ATRISK: '/atrisk',
  CHURN_FORCAST: '/churn-forecast',
  FORECAST: '/forecast',
  CONTRACT_PAGE: '/contract-page',
  ACCOUNT_LIST: '/account-list',
  PRODUCT_LIST: '/product-list',
  OTHER_REPORTS: '/other-reports',
  CONNECTS_EXTERNAL_APPLICATION: '/connects-external-application',
  MANAGE_USERS: '/manage-users',
  PERMISSION: '/permission',
  PRICE_BOOK: '/price-book',
  ADVANCE_APPROVALS: '/advanced-approvals',
  QUOTING_MANAGEMENT: '/quoting-management',
  ACCOUNT_VIEW: '/account-view/:id',
  SF_CALLBACK: '/sfcallback',
  SF_CONNECTION: '/sfconnection',
  PLATFORM_OBJECT: '/platform-object/:module',
  ADD_ACCOUNT: '/add-account',
  ADD_PRODUCT: '/add-product',
  ADD_PRICEBOOK: '/add-pricebook',
  ADD_OPPORTUNITY: '/add-opportunity/:id',
  ADD_QUOTE: '/add-quote/:id',
  OPPORTUNITY_DETAILS: '/opportunity-details/:id',
  QUOTE_DETAILS: '/quote-details/:id',
  PRICEBOOK_DETAILS: '/pricebook-details/:id',
  ADD_PRICEBOOK_ENTITIES: '/add-pricebook-entities/:id',
  ADD_LINE_ITEMS: '/add-lineitems/:id',
  CONTRACT_DETAILS: '/contract-details/:id',
  ADD_CONTACT: '/add-contact/:id',
  AUTHENTICATION: '/authentication',
  MANAGE_REGIONS: '/manage-regions',
  BUSINESS_SETTINGS: '/business-settings',
  ADD_BUSINESS: '/add-business',
  MANAGE_ZONE: '/manage-zone',
  NOTIFICATION_SETTINGS: '/notification-settings',
  MANAGE_TAX: '/manage-tax',
  MANAGE_PRODUCT_FAMILY: '/manage-product-family',
  REGION_SETTINGS: '/region-settings',
  PARTNER_SETTINGS: '/partner-settings',
  DESIGNATION_SETTINGS: '/designation-settings',
  MANAGE_APPROVER: '/mangae-approver',
  MANAGE_APPROVER_RULE: '/mangae-approver-rule',
  APPROVER_RULE_DETAILS: '/approver-rule-details/:id',
  MANAGE_APPROVALS: '/manage-approvals',
  APPROVAL_DETAILS: '/approval-details/:id',
  BUSINESS_DETAILS: '/business-details',
  CONDITION_LIST: '/condition-list',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password',
}


export const API_URL = {
  COMMON: {
    GET_JOB_TITLE: `${API_BASE_URL}/getjobtitle`,
    GET_PLATFORMS: `${API_BASE_URL}/getPlatforms`,
    MANAGE_BUSINESS: `${API_BASE_URL}/managebs`,
    GET_BUSINESS: `${API_BASE_URL}/getbs`,
    GET_CURRENCY: `${API_BASE_URL}/getcurrency`,
    DELETE_SLA: `${API_BASE_URL}/deletesla`,
    GET_ALL_REGIONS: `${API_BASE_URL}/getAllRegion`,
    DELETE_REGION: `${API_BASE_URL}/regiondestroy`,
    MANAGE_REGION: `${API_BASE_URL}/manageregion`,
    GET_ALL_ZONE: `${API_BASE_URL}/getAllZone`,
    MANAGE_ZONE: `${API_BASE_URL}/managezone`,
    DELETE_ZONE: `${API_BASE_URL}/zonedestroy`,
    GET_NOTIFICATION: `${API_BASE_URL}/getns`,
    MANAGE_NOTIFICATION: `${API_BASE_URL}/managens`,
    GET_ALL_TAX: `${API_BASE_URL}/getAlltax`,
    MANAGE_TAX: `${API_BASE_URL}/managetax`,
    DELETE_TAX: `${API_BASE_URL}/taxdestroy`,
    GET_ALL_PRODUCT_FAMILY: `${API_BASE_URL}/getAllproductfamily`,
    MANAGE_PRODUCT_FAMILY: `${API_BASE_URL}/manageproductfamily`,
    DELETE_PRODUCT_FAMILY: `${API_BASE_URL}/productfamilydestroy`,
    ADD_ZENDESK: `${API_BASE_URL}/addzendesk`,
    GET_ZENDESK: `${API_BASE_URL}/getzendesk`,
    IS_NEW_ACCOUNT: `${API_BASE_URL}/isnewAccount`,
    DONE_REGION_SETTING: `${API_BASE_URL}/doneregionsetting`,
    GET_ALL_DESIGNATION: `${API_BASE_URL}/getAlldesignation`,
    DELETE_DESIGNATION: `${API_BASE_URL}/designationdestroy`,
    MANAGE_DESIGNATION: `${API_BASE_URL}/managedesignation`,
    GET_ALL_PARTNER: `${API_BASE_URL}/getAllpartner`,
    MANAGE_PARTNER: `${API_BASE_URL}/managepartner`,
    DELETE_PARTNER: `${API_BASE_URL}/partnerdestroy`,
    GET_ALL_APPROVER: `${API_BASE_URL}/getAllapprover`,
    MANAGE_APPROVER: `${API_BASE_URL}/manageapprover`,
    DELETE_APPROVER: `${API_BASE_URL}/approverdestroy`,
    GET_ALL_APPROVER_RULE: `${API_BASE_URL}/getAllapproverrules`,
    MANAGE_APPROVER_RULE: `${API_BASE_URL}/manageapproverrule`,
    DELETE_APPROVER_RULE: `${API_BASE_URL}/approverruledestroy`,
    GET_ALL_APPROVER_RULE_CONDITION: `${API_BASE_URL}/getAllapproverruleconditions`,
    MANAGE_APPROVER_RULE_CONDITION: `${API_BASE_URL}/manageapproverrulecondition`,
    DELETE_APPROVER_RULE_CONDITION: `${API_BASE_URL}/approverruleconditiondestroy`,
    GET_ALL_APPROVAL: `${API_BASE_URL}/getAllapprovals`,
    MANAGE_APPROVAL: `${API_BASE_URL}/manageapproval`,
    DELETE_APPROVAL: `${API_BASE_URL}/approvaldestroy`,
    ADD_APPROVER: `${API_BASE_URL}/addapprover`,
  },
  USER: {
    LOGIN: `${API_BASE_URL}/login`,
    SIGNUP: `${API_BASE_URL}/register`,
    UPDATE_USER_PLATFORM: `${API_BASE_URL}/updateUserPlatform`,
    VERIFY_EMAIL: `${API_BASE_URL}/verify/email`,
    UPDATE_ARR_USER_PASSWORD: `${API_BASE_URL}/updatearruserpassword`,
    GET_LOGIN_USER: `${API_BASE_URL}/getloginuser`,
    FORGOT_PASSWORD: `${API_BASE_URL}/forgot/password`,
    RESET_PASSWORD: `${API_BASE_URL}/set/password`,
  },
  SALESFORCE: {
    GET_PLATFORM_OBJECT: `${API_BASE_URL}/getPlatformObject`,
    GET_PLATFORM_OBJECT_FIELD: `${API_BASE_URL}/getPlatformObjectField`,
    SAVE_PLATFORM_OBJECT_FIELD: `${API_BASE_URL}/savePlatformObjectField`,
    GET_ALL_ACCOUNT: `${API_BASE_URL}/getAllAccount`,
    GET_ALL_PRODUCT: `${API_BASE_URL}/getAllProduct`,
    GET_ALL_CONTACT: `${API_BASE_URL}/getAllContact`,
    GET_ALL_OPPORTUNITY: `${API_BASE_URL}/getAllOpportunity`,
    GET_ALL_CONTRACT: `${API_BASE_URL}/getAllContract`,
    GET_ALL_PRICEBOOK: `${API_BASE_URL}/getAllPriceBook`,
    GET_ALL_QUOTE: `${API_BASE_URL}/getAllQuote`,
    GET_QOUTE_PDF: `${API_BASE_URL}/getquotepdf`,
    MANAGE_ACCOUNT: `${API_BASE_URL}/manageaccount`,
    MANAGE_PRODUCT: `${API_BASE_URL}/manageproduct`,
    MANAGE_OPPORTUNITY: `${API_BASE_URL}/manageopportunity`,
    MANAGE_QUOTE: `${API_BASE_URL}/managequote`,
    MANAGE_PRICEBOOK: `${API_BASE_URL}/manapricebook`,
    GET_ALL_PRICEBOOK_ENTITIES: `${API_BASE_URL}/getAllPriceBookEntity`,
    MANAGE_PRICEBOOK_ENTITIES: `${API_BASE_URL}/managepricebookentity`,
    GET_ALL_QUOTE_LINEITEMS: `${API_BASE_URL}/getAllQuoteLineItem`,
    MANAGE_QUOTE_LINEITEMS: `${API_BASE_URL}/managequotelineitem`,
    MANAGE_SINGLE_QUOTE_LINEITEMS: `${API_BASE_URL}/managequotelineitem`,
    GET_ALL_ACCOUNT_COMMENT: `${API_BASE_URL}/getAllAccountComment`,
    MANAGE_ACCOUNT_COMMENT: `${API_BASE_URL}/manageaccountcomment`,
    GET_ALL_ARR_USER: `${API_BASE_URL}/getAllarrusers`,
    MANAGE_ARR_USER: `${API_BASE_URL}/managearruser`,
    GET_ALL_ACCNT_SA_COMMENT: `${API_BASE_URL}/getAllAccountsaComment`,
    MANAGE_ACCNT_SA_COMMENT: `${API_BASE_URL}/manageaccountsacomment`,
    GET_RENEWAL_PROCESS: `${API_BASE_URL}/getRenewalProcess`,
    MANAGE_RENEWAL_PROCESS: `${API_BASE_URL}/manageRenewalProcess`,
    MANAGE_CONTACT: `${API_BASE_URL}/managecontact`,
    GET_ACCOUNT_TEAM: `${API_BASE_URL}/getAccoungTeam`,
    MANAGE_ACCOUNT_TEAM: `${API_BASE_URL}/manageAccoungTeam`,
    GET_CUSTOMER_TEAM: `${API_BASE_URL}/getCustomerTeam`,
    MANAGE_CUSTOMER_TEAM: `${API_BASE_URL}/manageCustomerTeam`,
    DELETE_ARR_USER: `${API_BASE_URL}/arruserdestroy`,
    DELETE_ACCOUNT: `${API_BASE_URL}/accountdestroy`,
    DELETE_ACCOUNT_COMMENT: `${API_BASE_URL}/accountcommentdestroy`,
    DELETE_ACCOUNT_SA_COMMENT: `${API_BASE_URL}/accountsacommentdestroy`,
    DELETE_ACCOUNT_TEAM: `${API_BASE_URL}/AccoungTeamdestroy`,
    DELETE_CUSTOMER_TEAM: `${API_BASE_URL}/CustomerTeamdestroy`,
    DELETE_PRODUCT: `${API_BASE_URL}/productdestroy`,
    DELETE_CONTACT: `${API_BASE_URL}/contactdestroy`,
    DELETE_OPPORTUNITY: `${API_BASE_URL}/opportunitydestroy`,
    DELETE_PRICEBOOK: `${API_BASE_URL}/pricebookdestroy`,
    DELETE_PRICEBOOK_ENTITY: `${API_BASE_URL}/pricebookentitydestroy`,
    DELETE_QUOTE: `${API_BASE_URL}/quotedestroy`,
    DELETE_QUOTE_LINEITEMS: `${API_BASE_URL}/quotelineitemdestroy`,
    CLONE_QUOTE: `${API_BASE_URL}/clonequote`,
    CREATE_QUOTE_FROM_CONTRACT: `${API_BASE_URL}/createquotefromcontract`,
    GET_REVENUE_DETAILS: `${API_BASE_URL}/getrevenuedetails`,
    MANAGE_CONTRACT: `${API_BASE_URL}/managecontract`,
    GET_LATE_RENEWALS: `${API_BASE_URL}/getlaterenewals`,
    GET_UPSELLS: `${API_BASE_URL}/getupsells`,
    GET_CONTRACT_PRODUCT: `${API_BASE_URL}/getcontractProducts`,
    GET_REVENUE_MAP_DETAILS: `${API_BASE_URL}/getrevenuemapdetails`,
    GET_CONTRACT_LEVEL_LIST: `${API_BASE_URL}/getContractRisklevelList`,
    GET_CONTRACT_TIME_LEVEL_LIST: `${API_BASE_URL}/getContractTimeRisklevelList`,
    MANAGE_CONTRACT_RENEWVAL_EVENT: `${API_BASE_URL}/manageContractRenewalEvent`,
    GET_CONTRACT_REASON_COC: `${API_BASE_URL}/getContractReasonCOC`,
    MANAGE_RENEWAL_NOTES: `${API_BASE_URL}/manageContractRenewalNote`,
    GET_RENEWAL_NOTES: `${API_BASE_URL}/getContractRenewalNote`,
    GET_CONTRACT_ACCOUNT_TEAM: `${API_BASE_URL}/getContractAccoungTeam`,
    DELETE_CONTRACT_ACCOUNT_TEAM: `${API_BASE_URL}/ContractAccoungTeamdestroy`,
    MANAGE_CONTRACT_ACCOUNT_TEAM: `${API_BASE_URL}/manageContractAccoungTeam`,
    GET_ALL_CUSTOMER_CONTACT: `${API_BASE_URL}/getContractCustomerTeam`,
    DELETE_CUSTOMER_CONTACT: `${API_BASE_URL}/ContractCustomerTeamdestroy`,
    MANAGE_CUSTOMER_CONTACT: `${API_BASE_URL}/manageContractCustomerTeam`,
    GET_QUOTES_SUMMERY: `${API_BASE_URL}/quotesummery`,
    MANAGE_CONTRACT_CUSTOMER_TEAM: `${API_BASE_URL}/manageContractCustomerTeam`,
    GET_CROSSSELLS: `${API_BASE_URL}/getcrosssells`,
    GET_CONTRACT_PDF: `${API_BASE_URL}/getcontractpdf`,
    GET_FORECAST_SUMMARY: `${API_BASE_URL}/getforcastsummery`,
    UPLOAD_CONTRACT_DOC: `${API_BASE_URL}/uploadcontractdoc`,
    MERGE_CONTRACT: `${API_BASE_URL}/mergecontract`,
    GET_ARR_PAGE_LIST: `${API_BASE_URL}/getArrpagelist`,
    MANAGE_ACCOUNT_PARTNER: `${API_BASE_URL}/manageAccountPartner`,
    GET_ACCOUNT_PARTNER: `${API_BASE_URL}/getAccountpartner`,
    DELETE_ACCOUNT_PARTNER: `${API_BASE_URL}/Accountpartnerdestroy`,
    FORCAST_BULK_UPDATE: `${API_BASE_URL}/forecastblukupdate`,
    GET_APPROVAL_RULES_FOR_QUOTE: `${API_BASE_URL}/getapprovalrulesforquote`,
    ADD_APPROVAL_FOR_QUOTE: `${API_BASE_URL}/addApproval`,
    ADD_QUOTE_SIGNATURE: `${API_BASE_URL}/addquotesignature`,
    UPDATE_CONTRACT_ENTITLEMENT: `${API_BASE_URL}/upodateContractEntitlement`,
  },
  ZENDESK: {
    GET_ZENDESK_OPEN_TICKET: `${API_BASE_URL}/getzendeskopenticket`,
    GET_ZENDESK_TICKET_BY_ACCOUNT: `${API_BASE_URL}/getzendeskticketsbyaccount`,
  }
}

export const ACTIONS = {
  LOADER: {
    SET_FP_STATUS: 'ACTIONS/LOADER/SET_FP_STATUS',
    SET_SIDEBAR: 'ACTIONS/LOADER/SET_SIDEBAR',
  },
  COMMON: {
    GET_JOB_TITLE: 'ACTIONS/COMMON/GET_JOB_TITLE',
    GET_PLATFORMS: 'ACTIONS/COMMON/GET_PLATFORMS',
    MANAGE_BUSINESS: 'ACTIONS/COMMON/MANAGE_BUSINESS',
    GET_BUSINESS: 'ACTIONS/COMMON/GET_BUSINESS',
    GET_CURRENCY: 'ACTIONS/COMMON/GET_CURRENCY',
    ADD_ZENDESK: 'ACTIONS/COMMON/ADD_ZENDESK',
    GET_ZENDESK: 'ACTIONS/COMMON/GET_ZENDESK',
  },
  USER: {
    LOGIN: 'ACTIONS/USER/LOGIN',
    LOGOUT: 'ACTIONS/USER/LOGOUT',
    SIGNUP: 'ACTIONS/USER/SIGNUP',
    UPDATE_USER_PLATFORM: 'ACTIONS/USER/UPDATE_USER_PLATFORM',
    VERIFY_EMAIL: 'ACTIONS/USER/VERIFY_EMAIL',
    UPDATE_ARR_USER_PASSWORD: 'ACTIONS/USER/UPDATE_ARR_USER_PASSWORD',
  },
  SALESFORCE: {
    SAVE_DATA: 'ACTIONS/SALESFORCE/SAVE_DATA',
    RESET_DATA: 'ACTIONS/SALESFORCE/RESET_DATA',
    GET_PLATFORM_OBJECT: 'ACTIONS/SALESFORCE/GET_PLATFORM_OBJECT',
    GET_PLATFORM_OBJECT_FIELD: 'ACTIONS/SALESFORCE/GET_PLATFORM_OBJECT_FIELD',
    SAVE_PLATFORM_OBJECT_FIELD: 'ACTIONS/SALESFORCE/SAVE_PLATFORM_OBJECT_FIELD',
    GET_ALL_ACCOUNT: 'ACTIONS/SALESFORCE/GET_ALL_ACCOUNT',
    GET_ALL_PRODUCT: 'ACTIONS/SALESFORCE/GET_ALL_PRODUCT',
    GET_ALL_CONTACT: 'ACTIONS/SALESFORCE/GET_ALL_CONTACT',
    GET_ALL_OPPORTUNITY: 'ACTIONS/SALESFORCE/GET_ALL_OPPORTUNITY',
    GET_ALL_CONTRACT: 'ACTIONS/SALESFORCE/GET_ALL_CONTRACT',
    GET_ALL_PRICEBOOK: 'ACTIONS/SALESFORCE/GET_ALL_PRICEBOOK',
    GET_ALL_QUOTE: 'ACTIONS/SALESFORCE/GET_ALL_QUOTE',
    MANAGE_ACCOUNT: 'ACTIONS/SALESFORCE/MANAGE_ACCOUNT',
    MANAGE_PRODUCT: 'ACTIONS/SALESFORCE/MANAGE_PRODUCT',
    MANAGE_OPPORTUNITY: 'ACTIONS/SALESFORCE/MANAGE_OPPORTUNITY',
    MANAGE_QUOTE: 'ACTIONS/SALESFORCE/MANAGE_QUOTE',
    MANAGE_PRICEBOOK: 'ACTIONS/SALESFORCE/MANAGE_PRICEBOOK',
    GET_ALL_PRICEBOOK_ENTITIES: 'ACTIONS/SALESFORCE/GET_ALL_PRICEBOOK_ENTITIES',
    MANAGE_PRICEBOOK_ENTITIES: 'ACTIONS/SALESFORCE/MANAGE_PRICEBOOK_ENTITIES',
    GET_ALL_QUOTE_LINEITEMS: 'ACTIONS/SALESFORCE/GET_ALL_QUOTE_LINEITEMS',
    MANAGE_QUOTE_LINEITEMS: 'ACTIONS/SALESFORCE/MANAGE_QUOTE_LINEITEMS',
    GET_ALL_ACCOUNT_COMMENT: 'ACTIONS/SALESFORCE/GET_ALL_ACCOUNT_COMMENT',
    MANAGE_ACCOUNT_COMMENT: 'ACTIONS/SALESFORCE/MANAGE_ACCOUNT_COMMENT',
    GET_ALL_ARR_USER: 'ACTIONS/SALESFORCE/GET_ALL_ARR_USER',
    MANAGE_ARR_USER: 'ACTIONS/SALESFORCE/MANAGE_ARR_USER',
    GET_ALL_ACCNT_SA_COMMENT: 'ACTIONS/SALESFORCE/GET_ALL_ACCNT_SA_COMMENT',
    MANAGE_ACCNT_SA_COMMENT: 'ACTIONS/SALESFORCE/MANAGE_ACCNT_SA_COMMENT',
    GET_RENEWAL_PROCESS: 'ACTIONS/SALESFORCE/GET_RENEWAL_PROCESS',
    MANAGE_RENEWAL_PROCESS: 'ACTIONS/SALESFORCE/MANAGE_RENEWAL_PROCESS',
    MANAGE_CONTACT: 'ACTIONS/SALESFORCE/MANAGE_CONTACT',
    GET_ACCOUNT_TEAM: 'ACTIONS/SALESFORCE/GET_ACCOUNT_TEAM',
    MANAGE_ACCOUNT_TEAM: 'ACTIONS/SALESFORCE/MANAGE_ACCOUNT_TEAM',
    GET_CUSTOMER_TEAM: 'ACTIONS/SALESFORCE/GET_CUSTOMER_TEAM',
    MANAGE_CUSTOMER_TEAM: 'ACTIONS/SALESFORCE/MANAGE_CUSTOMER_TEAM',
    DELETE_ARR_USER: 'ACTIONS/SALESFORCE/DELETE_ARR_USER',
    DELETE_ACCOUNT: 'ACTIONS/SALESFORCE/DELETE_ACCOUNT',
    DELETE_ACCOUNT_COMMENT: 'ACTIONS/SALESFORCE/DELETE_ACCOUNT_COMMENT',
    DELETE_ACCOUNT_SA_COMMENT: 'ACTIONS/SALESFORCE/DELETE_ACCOUNT_SA_COMMENT',
    DELETE_ACCOUNT_TEAM: 'ACTIONS/SALESFORCE/DELETE_ACCOUNT_TEAM',
    DELETE_CUSTOMER_TEAM: 'ACTIONS/SALESFORCE/DELETE_CUSTOMER_TEAM',
    DELETE_PRODUCT: 'ACTIONS/SALESFORCE/DELETE_PRODUCT',
    DELETE_CONTACT: 'ACTIONS/SALESFORCE/DELETE_CONTACT',
    DELETE_OPPORTUNITY: 'ACTIONS/SALESFORCE/DELETE_OPPORTUNITY',
    DELETE_PRICEBOOK: 'ACTIONS/SALESFORCE/DELETE_PRICEBOOK',
    DELETE_PRICEBOOK_ENTITY: 'ACTIONS/SALESFORCE/DELETE_PRICEBOOK_ENTITY',
    DELETE_QUOTE: 'ACTIONS/SALESFORCE/DELETE_QUOTE',
    DELETE_QUOTE_LINEITEMS: 'ACTIONS/SALESFORCE/DELETE_QUOTE_LINEITEMS',
    CLONE_QUOTE: 'ACTIONS/SALESFORCE/CLONE_QUOTE',
    CREATE_QUOTE_FROM_CONTRACT: 'ACTIONS/SALESFORCE/CREATE_QUOTE_FROM_CONTRACT',
    GET_REVENUE_DETAILS: 'ACTIONS/SALESFORCE/GET_REVENUE_DETAILS',
    MANAGE_CONTRACT: 'ACTIONS/SALESFORCE/MANAGE_CONTRACT',
    GET_LATE_RENEWALS: 'ACTIONS/SALESFORCE/GET_LATE_RENEWALS',
    GET_UPSELLS: 'ACTIONS/SALESFORCE/GET_UPSELLS',
    GET_CONTRACT_PRODUCT: 'ACTIONS/SALESFORCE/GET_CONTRACT_PRODUCT',
    GET_REVENUE_MAP_DETAILS: 'ACTIONS/SALESFORCE/GET_REVENUE_MAP_DETAILS',
    DELETE_CUSTOMER_CONTACT: 'ACTIONS/SALESFORCE/DELETE_CUSTOMER_CONTACT',
    MANAGE_CONTRACT_CUSTOMER_TEAM: 'ACTIONS/SALESFORCE/MANAGE_CONTRACT_CUSTOMER_TEAM',
    GET_CROSSSELLS: 'ACTIONS/SALESFORCE/GET_CROSSSELLS',
    GET_CONTRACT_PDF: 'ACTIONS/SALESFORCE/GET_CONTRACT_PDF',
    GET_FORECAST_SUMMARY: 'ACTIONS/SALESFORCE/GET_FORECAST_SUMMARY',
  }
}

export const SAGA_ACTIONS = {
  COMMON: {
    GET_JOB_TITLE: 'SAGA_ACTIONS/COMMON/GET_JOB_TITLE',
    GET_PLATFORMS: 'SAGA_ACTIONS/COMMON/GET_PLATFORMS',
    MANAGE_BUSINESS: 'ACTIONS/COMMON/MANAGE_BUSINESS',
    GET_BUSINESS: 'ACTIONS/COMMON/GET_BUSINESS',
    GET_CURRENCY: 'SAGA_ACTIONS/COMMON/GET_CURRENCY',
    DELETE_SLA: 'SAGA_ACTIONS/COMMON/DELETE_SLA',
    GET_ALL_REGIONS: 'SAGA_ACTIONS/COMMON/GET_ALL_REGIONS',
    DELETE_REGION: 'SAGA_ACTIONS/COMMON/DELETE_REGION',
    MANAGE_REGION: 'SAGA_ACTIONS/COMMON/MANAGE_REGION',
    GET_ALL_ZONE: 'SAGA_ACTIONS/COMMON/GET_ALL_ZONE',
    MANAGE_ZONE: 'SAGA_ACTIONS/COMMON/MANAGE_ZONE',
    DELETE_ZONE: 'SAGA_ACTIONS/COMMON/DELETE_ZONE',
    GET_NOTIFICATION: 'SAGA_ACTIONS/COMMON/GET_NOTIFICATION',
    MANAGE_NOTIFICATION: 'SAGA_ACTIONS/COMMON/MANAGE_NOTIFICATION',
    GET_ALL_TAX: 'SAGA_ACTIONS/COMMON/GET_ALL_TAX',
    MANAGE_TAX: 'SAGA_ACTIONS/COMMON/MANAGE_TAX',
    DELETE_TAX: 'SAGA_ACTIONS/COMMON/DELETE_TAX',
    GET_ALL_PRODUCT_FAMILY: 'SAGA_ACTIONS/COMMON/GET_ALL_PRODUCT_FAMILY',
    MANAGE_PRODUCT_FAMILY: 'SAGA_ACTIONS/COMMON/MANAGE_PRODUCT_FAMILY',
    DELETE_PRODUCT_FAMILY: 'SAGA_ACTIONS/COMMON/DELETE_PRODUCT_FAMILY',
    ADD_ZENDESK: 'SAGA_ACTIONS/COMMON/ADD_ZENDESK',
    GET_ZENDESK: 'SAGA_ACTIONS/COMMON/GET_ZENDESK',
    IS_NEW_ACCOUNT: 'SAGA_ACTIONS/COMMON/IS_NEW_ACCOUNT',
    DONE_REGION_SETTING: 'SAGA_ACTIONS/COMMON/DONE_REGION_SETTING',
    GET_ALL_DESIGNATION: 'SAGA_ACTIONS/COMMON/GET_ALL_DESIGNATION',
    DELETE_DESIGNATION: 'SAGA_ACTIONS/COMMON/DELETE_DESIGNATION',
    MANAGE_DESIGNATION: 'SAGA_ACTIONS/COMMON/MANAGE_DESIGNATION',
    GET_ALL_PARTNER: 'SAGA_ACTIONS/COMMON/GET_ALL_PARTNER',
    MANAGE_PARTNER: 'SAGA_ACTIONS/COMMON/MANAGE_PARTNER',
    DELETE_PARTNER: 'SAGA_ACTIONS/COMMON/DELETE_PARTNER',
    GET_ALL_APPROVER: 'SAGA_ACTIONS/COMMON/GET_ALL_APPROVER',
    MANAGE_APPROVER: 'SAGA_ACTIONS/COMMON/MANAGE_APPROVER',
    DELETE_APPROVER: 'SAGA_ACTIONS/COMMON/DELETE_APPROVER',
    GET_ALL_APPROVER_RULE: 'SAGA_ACTIONS/COMMON/GET_ALL_APPROVER_RULE',
    MANAGE_APPROVER_RULE: 'SAGA_ACTIONS/COMMON/MANAGE_APPROVER_RULE',
    DELETE_APPROVER_RULE: 'SAGA_ACTIONS/COMMON/DELETE_APPROVER_RULE',
    GET_ALL_APPROVER_RULE_CONDITION: 'SAGA_ACTIONS/COMMON/GET_ALL_APPROVER_RULE_CONDITION',
    MANAGE_APPROVER_RULE_CONDITION: 'SAGA_ACTIONS/COMMON/MANAGE_APPROVER_RULE_CONDITION',
    DELETE_APPROVER_RULE_CONDITION: 'SAGA_ACTIONS/COMMON/DELETE_APPROVER_RULE_CONDITION',
    GET_ALL_APPROVAL: 'SAGA_ACTIONS/COMMON/GET_ALL_APPROVAL',
    MANAGE_APPROVAL: 'SAGA_ACTIONS/COMMON/MANAGE_APPROVAL',
    DELETE_APPROVAL: 'SAGA_ACTIONS/COMMON/DELETE_APPROVAL',
    ADD_APPROVER: 'SAGA_ACTIONS/COMMON/ADD_APPROVER',
  },
  USER: {
    LOGIN: 'SAGA_ACTIONS/USER/LOGIN',
    LOGOUT: 'SAGA_ACTIONS/USER/LOGOUT',
    SIGNUP: 'SAGA_ACTIONS/USER/SIGNUP',
    UPDATE_USER_PLATFORM: 'SAGA_ACTIONS/USER/UPDATE_USER_PLATFORM',
    VERIFY_EMAIL: 'SAGA_ACTIONS/USER/VERIFY_EMAIL',
    UPDATE_ARR_USER_PASSWORD: 'SAGA_ACTIONS/USER/UPDATE_ARR_USER_PASSWORD',
    GET_LOGIN_USER: 'SAGA_ACTIONS/USER/GET_LOGIN_USER',
    FORGOT_PASSWORD: 'SAGA_ACTIONS/USER/FORGOT_PASSWORD',
    RESET_PASSWORD: 'SAGA_ACTIONS/USER/RESET_PASSWORD',
  },
  SALESFORCE: {
    SAVE_DATA: 'SAGA_ACTIONS/SALESFORCE/SAVE_DATA',
    RESET_DATA: 'SAGA_ACTIONS/SALESFORCE/RESET_DATA',
    GET_PLATFORM_OBJECT: 'SAGA_ACTIONS/SALESFORCE/GET_PLATFORM_OBJECT',
    GET_PLATFORM_OBJECT_FIELD: 'SAGA_ACTIONS/SALESFORCE/GET_PLATFORM_OBJECT_FIELD',
    SAVE_PLATFORM_OBJECT_FIELD: 'SAGA_ACTIONS/SALESFORCE/SAVE_PLATFORM_OBJECT_FIELD',
    GET_ALL_ACCOUNT: 'SAGA_ACTIONS/SALESFORCE/GET_ALL_ACCOUNT',
    GET_ALL_PRODUCT: 'SAGA_ACTIONS/SALESFORCE/GET_ALL_PRODUCT',
    GET_ALL_CONTACT: 'SAGA_ACTIONS/SALESFORCE/GET_ALL_CONTACT',
    GET_ALL_OPPORTUNITY: 'SAGA_ACTIONS/SALESFORCE/GET_ALL_OPPORTUNITY',
    GET_ALL_CONTRACT: 'SAGA_ACTIONS/SALESFORCE/GET_ALL_CONTRACT',
    GET_ALL_PRICEBOOK: 'SAGA_ACTIONS/SALESFORCE/GET_ALL_PRICEBOOK',
    GET_ALL_QUOTE: 'SAGA_ACTIONS/SALESFORCE/GET_ALL_QUOTE',
    GET_QUOTE_PDF: 'SAGA_ACTIONS/SALESFORCE/GET_QUOTE_PDF',
    MANAGE_ACCOUNT: 'SAGA_ACTIONS/SALESFORCE/MANAGE_ACCOUNT',
    MANAGE_PRODUCT: 'SAGA_ACTIONS/SALESFORCE/MANAGE_PRODUCT',
    MANAGE_OPPORTUNITY: 'SAGA_ACTIONS/SALESFORCE/MANAGE_OPPORTUNITY',
    MANAGE_QUOTE: 'SAGA_ACTIONS/SALESFORCE/MANAGE_QUOTE',
    MANAGE_PRICEBOOK: 'SAGA_ACTIONS/SALESFORCE/MANAGE_PRICEBOOK',
    GET_ALL_PRICEBOOK_ENTITIES: 'SAGA_ACTIONS/SALESFORCE/GET_ALL_PRICEBOOK_ENTITIES',
    MANAGE_PRICEBOOK_ENTITIES: 'SAGA_ACTIONS/SALESFORCE/MANAGE_PRICEBOOK_ENTITIES',
    GET_ALL_QUOTE_LINEITEMS: 'SAGA_ACTIONS/SALESFORCE/GET_ALL_QUOTE_LINEITEMS',
    MANAGE_QUOTE_LINEITEMS: 'SAGA_ACTIONS/SALESFORCE/MANAGE_QUOTE_LINEITEMS',
    MANAGE_SINGLE_QUOTE_LINEITEMS: 'SAGA_ACTIONS/SALESFORCE/MANAGE_SINGLE_QUOTE_LINEITEMS',
    GET_ALL_ACCOUNT_COMMENT: 'SAGA_ACTIONS/SALESFORCE/GET_ALL_ACCOUNT_COMMENT',
    MANAGE_ACCOUNT_COMMENT: 'SAGA_ACTIONS/SALESFORCE/MANAGE_ACCOUNT_COMMENT',
    GET_ALL_ARR_USER: 'SAGA_ACTIONS/SALESFORCE/GET_ALL_ARR_USER',
    MANAGE_ARR_USER: 'SAGA_ACTIONS/SALESFORCE/MANAGE_ARR_USER',
    GET_ALL_ACCNT_SA_COMMENT: 'SAGA_ACTIONS/SALESFORCE/GET_ALL_ACCNT_SA_COMMENT',
    MANAGE_ACCNT_SA_COMMENT: 'SAGA_ACTIONS/SALESFORCE/MANAGE_ACCNT_SA_COMMENT',
    GET_RENEWAL_PROCESS: 'SAGA_ACTIONS/SALESFORCE/GET_RENEWAL_PROCESS',
    MANAGE_RENEWAL_PROCESS: 'SAGA_ACTIONS/SALESFORCE/MANAGE_RENEWAL_PROCESS',
    MANAGE_CONTACT: 'SAGA_ACTIONS/SALESFORCE/MANAGE_CONTACT',
    GET_ACCOUNT_TEAM: 'SAGA_ACTIONS/SALESFORCE/GET_ACCOUNT_TEAM',
    MANAGE_ACCOUNT_TEAM: 'SAGA_ACTIONS/SALESFORCE/MANAGE_ACCOUNT_TEAM',
    GET_CUSTOMER_TEAM: 'SAGA_ACTIONS/SALESFORCE/GET_CUSTOMER_TEAM',
    MANAGE_CUSTOMER_TEAM: 'SAGA_ACTIONS/SALESFORCE/MANAGE_CUSTOMER_TEAM',
    DELETE_ARR_USER: 'SAGA_ACTIONS/SALESFORCE/DELETE_ARR_USER',
    DELETE_ACCOUNT: 'SAGA_ACTIONS/SALESFORCE/DELETE_ACCOUNT',
    DELETE_ACCOUNT_COMMENT: 'SAGA_ACTIONS/SALESFORCE/DELETE_ACCOUNT_COMMENT',
    DELETE_ACCOUNT_SA_COMMENT: 'SAGA_ACTIONS/SALESFORCE/DELETE_ACCOUNT_SA_COMMENT',
    DELETE_ACCOUNT_TEAM: 'SAGA_ACTIONS/SALESFORCE/DELETE_ACCOUNT_TEAM',
    DELETE_CUSTOMER_TEAM: 'SAGA_ACTIONS/SALESFORCE/DELETE_CUSTOMER_TEAM',
    DELETE_PRODUCT: 'SAGA_ACTIONS/SALESFORCE/DELETE_PRODUCT',
    DELETE_CONTACT: 'SAGA_ACTIONS/SALESFORCE/DELETE_CONTACT',
    DELETE_OPPORTUNITY: 'SAGA_ACTIONS/SALESFORCE/DELETE_OPPORTUNITY',
    DELETE_PRICEBOOK: 'SAGA_ACTIONS/SALESFORCE/DELETE_PRICEBOOK',
    DELETE_PRICEBOOK_ENTITY: 'SAGA_ACTIONS/SALESFORCE/DELETE_PRICEBOOK_ENTITY',
    DELETE_QUOTE: 'SAGA_ACTIONS/SALESFORCE/DELETE_QUOTE',
    DELETE_QUOTE_LINEITEMS: 'SAGA_ACTIONS/SALESFORCE/DELETE_QUOTE_LINEITEMS',
    CLONE_QUOTE: 'SAGA_ACTIONS/SALESFORCE/CLONE_QUOTE',
    CREATE_QUOTE_FROM_CONTRACT: 'SAGA_ACTIONS/SALESFORCE/CREATE_QUOTE_FROM_CONTRACT',
    GET_REVENUE_DETAILS: 'SAGA_ACTIONS/SALESFORCE/GET_REVENUE_DETAILS',
    MANAGE_CONTRACT: 'SAGA_ACTIONS/SALESFORCE/MANAGE_CONTRACT',
    GET_LATE_RENEWALS: 'SAGA_ACTIONS/SALESFORCE/GET_LATE_RENEWALS',
    GET_UPSELLS: 'SAGA_ACTIONS/SALESFORCE/GET_UPSELLS',
    GET_CONTRACT_PRODUCT: 'SAGA_ACTIONS/SALESFORCE/GET_CONTRACT_PRODUCT',
    GET_REVENUE_MAP_DETAILS: 'SAGA_ACTIONS/SALESFORCE/GET_REVENUE_MAP_DETAILS',
    GET_CONTRACT_LEVEL_LIST: 'SAGA_ACTIONS/SALESFORCE/GET_CONTRACT_LEVEL_LIST',
    GET_CONTRACT_TIME_LEVEL_LIST: 'SAGA_ACTIONS/SALESFORCE/GET_CONTRACT_TIME_LEVEL_LIST',
    MANAGE_CONTRACT_RENEWVAL_EVENT: 'SAGA_ACTIONS/SALESFORCE/MANAGE_CONTRACT_RENEWVAL_EVENT',
    GET_CONTRACT_REASON_COC: 'SAGA_ACTIONS/SALESFORCE/GET_CONTRACT_REASON_COC',
    MANAGE_RENEWAL_NOTES: 'SAGA_ACTIONS/SALESFORCE/MANAGE_RENEWAL_NOTES',
    GET_RENEWAL_NOTES: 'SAGA_ACTIONS/SALESFORCE/GET_RENEWAL_NOTES',
    GET_CONTRACT_ACCOUNT_TEAM: 'SAGA_ACTIONS/SALESFORCE/GET_CONTRACT_ACCOUNT_TEAM',
    DELETE_CONTRACT_ACCOUNT_TEAM: 'SAGA_ACTIONS/SALESFORCE/DELETE_CONTRACT_ACCOUNT_TEAM',
    MANAGE_CONTRACT_ACCOUNT_TEAM: 'SAGA_ACTIONS/SALESFORCE/MANAGE_CONTRACT_ACCOUNT_TEAM',
    GET_ALL_CUSTOMER_CONTACT: 'SAGA_ACTIONS/SALESFORCE/GET_ALL_CUSTOMER_CONTACT',
    DELETE_CUSTOMER_CONTACT: 'SAGA_ACTIONS/SALESFORCE/DELETE_CUSTOMER_CONTACT',
    MANAGE_CUSTOMER_CONTACT: 'SAGA_ACTIONS/SALESFORCE/MANAGE_CUSTOMER_CONTACT',
    GET_QUOTES_SUMMERY: 'SAGA_ACTIONS/SALESFORCE/GET_QUOTES_SUMMERY',
    MANAGE_CONTRACT_CUSTOMER_TEAM: 'SAGA_ACTIONS/SALESFORCE/MANAGE_CONTRACT_CUSTOMER_TEAM',
    GET_CROSSSELLS: 'SAGA_ACTIONS/SALESFORCE/GET_CROSSSELLS',
    GET_CONTRACT_PDF: 'SAGA_ACTIONS/SALESFORCE/GET_CONTRACT_PDF',
    GET_FORECAST_SUMMARY: 'SAGA_ACTIONS/SALESFORCE/GET_FORECAST_SUMMARY',
    UPLOAD_CONTRACT_DOC: 'SAGA_ACTIONS/SALESFORCE/UPLOAD_CONTRACT_DOC',
    MERGE_CONTRACT: 'SAGA_ACTIONS/SALESFORCE/MERGE_CONTRACT',
    GET_ARR_PAGE_LIST: 'SAGA_ACTIONS/SALESFORCE/GET_ARR_PAGE_LIST',
    MANAGE_ACCOUNT_PARTNER: 'SAGA_ACTIONS/COMMON/MANAGE_ACCOUNT_PARTNER',
    GET_ACCOUNT_PARTNER: 'SAGA_ACTIONS/COMMON/GET_ACCOUNT_PARTNER',
    DELETE_ACCOUNT_PARTNER: 'SAGA_ACTIONS/COMMON/DELETE_ACCOUNT_PARTNER',
    FORCAST_BULK_UPDATE: 'SAGA_ACTIONS/COMMON/FORCAST_BULK_UPDATE',
    GET_APPROVAL_RULES_FOR_QUOTE: 'SAGA_ACTIONS/COMMON/GET_APPROVAL_RULES_FOR_QUOTE',
    ADD_APPROVAL_FOR_QUOTE: 'SAGA_ACTIONS/COMMON/ADD_APPROVAL_FOR_QUOTE',
    ADD_QUOTE_SIGNATURE: 'SAGA_ACTIONS/COMMON/ADD_QUOTE_SIGNATURE',
    UPDATE_CONTRACT_ENTITLEMENT: 'SAGA_ACTIONS/COMMON/UPDATE_CONTRACT_ENTITLEMENT',
  },
  ZENDESK: {
    GET_ZENDESK_OPEN_TICKET: 'SAGA_ACTIONS/ZENDESK/GET_ZENDESK_OPEN_TICKET',
    GET_ZENDESK_TICKET_BY_ACCOUNT: 'SAGA_ACTIONS/ZENDESK/GET_ZENDESK_TICKET_BY_ACCOUNT',
  }
}