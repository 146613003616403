import { SAGA_ACTIONS } from '../../../_config';
import { useApiCall } from '../common/appApiCallHook';
import {
  BlankReq,
  LoginReq,
  SignUpReq,
  UpdateUserPlatformReq,
} from '../../interfaces/ApiReqRes'


export function useUserApi() {

  const callApi = useApiCall()

  const login = (data: LoginReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.LOGIN, data, onSuccess, onError);
  }

  const logout = (data: BlankReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.LOGOUT, data, onSuccess, onError);
  }

  const signup = (data: SignUpReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.SIGNUP, data, onSuccess, onError);
  }

  const updateUserPlatform = (data: UpdateUserPlatformReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.UPDATE_USER_PLATFORM, data, onSuccess, onError);
  }

  const verifyEmail = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.VERIFY_EMAIL, data, onSuccess, onError);
  }

  const updateArrUserPassword = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.UPDATE_ARR_USER_PASSWORD, data, onSuccess, onError);
  }

  const getLoginUser = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.GET_LOGIN_USER, data, onSuccess, onError);
  }

  const forgotPassword = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.FORGOT_PASSWORD, data, onSuccess, onError);
  }

  const resetPassword = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.RESET_PASSWORD, data, onSuccess, onError);
  }

  return {
    login: login,
    signup: signup,
    logout: logout,
    updateUserPlatform: updateUserPlatform,
    verifyEmail: verifyEmail,
    updateArrUserPassword: updateArrUserPassword,
    getLoginUser: getLoginUser,
    forgotPassword: forgotPassword,
    resetPassword: resetPassword,
  }
}