import React, { useEffect, useMemo, useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import EditButtonCell from '../components/actionsBtns/EditButtonCell'
import ViewButtonCell from '../components/actionsBtns/ViewButtonCell'
import Footer from '../components/commonComponents/Footer'
import Header from '../components/commonComponents/Header'
import LeftPanel from '../components/commonComponents/LeftPanel'
import TableContainer from '../components/commonComponents/TableContainer'
import { usePlatformApi } from '../_common/hooks/api/PlatformApiHook'
import { useAppLoader } from '../_common/hooks/common/appLoaderHook'
import { URLS } from '../_config'
import DeleteButtonCell from '../components/actionsBtns/DeleteButtonCell'
import { formatNumber } from '../_common/functions'
import moment from 'moment'
import FilterRow from '../components/commonComponents/FilterRow'

const AccountList = () => {

    const platformApi = usePlatformApi();
    const { showLoader, hideLoader } = useAppLoader();

    const [accountData, setAccountData] = useState<any>(null)
    const [data, setData] = useState<any>([])
    const [additionalData, setAdditionalData] = useState<any>(null)
    const [loading, setLoading] = useState<boolean>(false)
    const [filterDetails, setFilterDetails] = useState<any>({})

    const [searchParams, setSearchParams] = useSearchParams();
    const quarterValue = searchParams.get("quarter")
    const typeOfContract = searchParams.get("type")
    const yearValue = searchParams.get("year")
    const regionValue = searchParams.get("region")
    const repValue = searchParams.get("rep")

    const getAccountData = (page = 1) => {
        showLoader();
        setLoading(true);
        let params: any = quarterValue && typeOfContract ?
            { page: page, quarter: quarterValue, typeOfContract: typeOfContract }
            :
            { page: page };
        if (repValue && repValue != 'undefined') { params['rep'] = repValue };
        if (regionValue && regionValue != 'undefined') { params['region'] = regionValue };
        if (yearValue && yearValue != 'undefined') { params['year'] = yearValue };
        if (filterDetails && Object.keys(filterDetails).length > 0) {
            Object.assign(params, filterDetails)
        }
        platformApi.getAllAccount(params,
            (message: string, resp: any) => {
                // console.log(resp);
                setLoading(false);
                setAccountData(resp.data.list);
                setData(resp.data.list.data);
                setAdditionalData(resp.data.addldata);
                hideLoader();
            }, (message: string) => {
                setLoading(false);
                hideLoader();
            })
    }

    const deleteAccount = (id: any) => {
        return new Promise((resolve: any, reject: any) => {
            platformApi.accountDestroy({
                id
            }, (message: string, resp: any) => {
                resolve(message)
            }, (message: string) => {
                reject(message);
            });
        })
    }

    const columns = useMemo(
        () => [
            {
                Header: 'Actions',
                accessor: "AccountId",
                Cell: ({ column, value, row }: any) =>
                    <>
                        <DeleteButtonCell
                            column={column}
                            value={row.original.id}
                            deleteFunc={deleteAccount}
                            callbackFunc={[getAccountData]}
                            type={"Account"}
                        />
                        <ViewButtonCell column={column} value={value} link={'account-view'} />
                        <EditButtonCell column={column} value={value} link={'add-account'} />
                    </>,
            },
            {
                Header: "ID",
                accessor: "id",
            },
            {
                Header: "Account Name",
                accessor: "Name",
            },
            {
                Header: "Industry",
                accessor: "Industry",
            },
            {
                Header: "Address",
                accessor: "BillingStreet",
            },
            {
                Header: "Business Channel",
                accessor: "BusinessChannel",
            },
            {
                Header: "Account Manager",
                accessor: "account_managerobj.name",
            },
            {
                Header: "Region",
                accessor: "region.region_name",
            },
            {
                Header: "Contract End Date",
                accessor: "ContractEndDate",
                Cell: ({ column, value, row }: any) =>
                    <>
                        {additionalData && Object.entries(additionalData).map(([key, value]: any) =>
                            key == row?.original?.AccountId ?
                                value.lastrenewdate && value.lastrenewdate != "" ?
                                    moment(value?.lastrenewdate).format("MM/DD/YY") : "" : ""
                        )}
                    </>
            },
            {
                Header: "Lifetime Value ($)",
                accessor: "AnnualRevenue",
                Cell: ({ column, value, row }: any) =>
                    <>
                        {additionalData && Object.entries(additionalData).map(([key, value]: any) =>
                            key == row?.original?.AccountId ? formatNumber(value?.annualrevenue) : ""
                        )}
                    </>
            },
            {
                Header: "ARR ($)",
                accessor: "ARR",
                Cell: ({ column, value, row }: any) =>
                    <>
                        {additionalData && Object.entries(additionalData).map(([key, value]: any) =>
                            key == row?.original?.AccountId ? formatNumber(value?.arr) : ""
                        )}
                    </>
            },
            {
                Header: "MRR ($)",
                accessor: "MRR",
                Cell: ({ column, value, row }: any) =>
                    <>
                        {additionalData && Object.entries(additionalData).map(([key, value]: any) =>
                            key == row?.original?.AccountId ? formatNumber(value?.mrr != "" ? +value.mrr : 0) : ""
                        )}
                    </>
            }
        ],
        [additionalData]
    )

    useEffect(() => {
        getAccountData();
    }, [])

    return (
        <React.Fragment>
            <div id="layout-wrapper">
                <Header />
                <LeftPanel />
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            {/* start page title */}
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-flex align-items-center justify-content-between">
                                        <h4 className="mb-0 font-size-18">Account List</h4>
                                        <div className="page-title-right">
                                            <ol className="breadcrumb m-0">
                                                <li className="breadcrumb-item">
                                                    <Link to={URLS.REVENUE}>Revenue</Link>
                                                </li>
                                                <li className="breadcrumb-item active">Account List</li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* end page title */}

                            <div className="card">
                                <div className="card-body">
                                    <FilterRow
                                        type="account-list"
                                        filterDetails={filterDetails}
                                        setFilterDetails={setFilterDetails}
                                        callbackFunctions={[
                                            getAccountData
                                        ]}
                                    />
                                </div>
                            </div>


                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="d-flex flex-wrap justify-content-between">
                                                <h5 className="card-title mr-2">Accounts</h5>
                                                <Link to={URLS.ADD_ACCOUNT} className="btn btn-success">Add Account</Link>
                                            </div>
                                            <div className="table-responsive">
                                                <TableContainer
                                                    columns={columns}
                                                    data={data}
                                                    fetchData={getAccountData}
                                                    loading={loading}
                                                    rawData={accountData}
                                                    clickFunc={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </React.Fragment>
    )
}

export default AccountList