import React, { useEffect, useMemo, useState } from 'react';
import { usePlatformApi } from '../../_common/hooks/api/PlatformApiHook';
import { useAppLoader } from '../../_common/hooks/common/appLoaderHook';
import TableContainer from '../commonComponents/TableContainer';
import { formatNumber } from '../../_common/functions';
import moment from 'moment';

interface IncomingProps {
    filterDetails: any;
    doFilter: boolean;
    setDoFilter: any;
}

const LateRenewalsList = ({ filterDetails, doFilter, setDoFilter }: IncomingProps) => {

    const platformApi = usePlatformApi();
    const { showLoader, hideLoader } = useAppLoader();

    const [lateRenewalsData, setLateRenewalData] = useState<any>([])
    const [rawdata, setRawData] = useState<any>(null)
    const [loading, setLoading] = useState<boolean>(false)

    const getLetRenewals = (page = 1) => {
        showLoader();
        setLoading(true);
        let params: any = { page: page }
        if (filterDetails && Object.keys(filterDetails).length > 0) {
            Object.assign(params, filterDetails)
        }
        platformApi.getLateRenewals(params,
            (message: string, resp: any) => {
                // console.log(resp);
                setLoading(false);
                setLateRenewalData(resp.data.list.data);
                setRawData(resp.data.list);
                hideLoader();
                setDoFilter(false)
            }, (message: string) => {
                setLoading(false);
                hideLoader();
                setDoFilter(false)
            })
    };

    const columns = useMemo(
        () => [
            {
                Header: "Account",
                accessor: "account.Name",
            },
            {
                Header: "Contract",
                accessor: "ContractNumber",
            },
            {
                Header: "Due Date",
                accessor: "EndDate",
                className: "nowrap",
                Cell: ({ column, value, row }: any) => (
                    <>
                        {value ? moment(value).format("MM/DD/YY") : ""}
                    </>
                )
            },
            {
                Header: "Renew Date",
                accessor: "RenewDate",
                className: "nowrap",
                Cell: ({ column, value, row }: any) => (
                    <>
                        {value ? moment(value).format("MM/DD/YY") : ""}
                    </>
                )
            },
            {
                Header: "Amount ($)",
                accessor: "ContractValue",
                Cell: ({ column, value, row }: any) => (
                    <div>
                        {value ? formatNumber(value) : '0.00'}
                    </div>
                )
            },
        ],
        []
    )

    useEffect(() => {
        getLetRenewals();
    }, [])

    useEffect(() => {
        if (doFilter) {
            getLetRenewals();
        }
    }, [doFilter])

    return (
        <React.Fragment>
            <div className="table-responsive">
                <TableContainer
                    columns={columns}
                    data={lateRenewalsData}
                    fetchData={getLetRenewals}
                    loading={loading}
                    rawData={rawdata}
                    small={true}
                    cstmClassName={"table-scroll-overflow"}
                    clickFunc={true}
                />
            </div>
        </React.Fragment>
    )
}

export default LateRenewalsList