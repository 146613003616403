import React from 'react'
import { Link } from 'react-router-dom'
import CommingSoon from '../components/commonComponents/CommingSoon'
import Footer from '../components/commonComponents/Footer'
import Header from '../components/commonComponents/Header'
import LeftPanel from '../components/commonComponents/LeftPanel'
import { URLS } from '../_config'

const AtRisk = () => {
    return (
        <React.Fragment>
            <div id="layout-wrapper">
                <Header />
                <LeftPanel />
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            {/* start page title */}
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-flex align-items-center justify-content-between">
                                        <h4 className="mb-0 font-size-18">At Risk</h4>
                                        <div className="page-title-right">
                                            <ol className="breadcrumb m-0">
                                                <li className="breadcrumb-item">
                                                    <Link to={URLS.REVENUE}>Revenue</Link>
                                                </li>
                                                <li className="breadcrumb-item active">At Risk</li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* end page title */}
                            <CommingSoon />
                            {/* <div className="row">
                                <div className="col-xl-12">
                                    <div className="row dashboard-top-panel">
                                        <div className="col-lg-3 col-md-6 col-12">
                                            <div className="card mini-stats-wid">
                                                <div className="card-body">
                                                    <div className="d-flex flex-wrap">
                                                        <div className="mr-3">
                                                            <p className="text-muted mb-2">ARR / Forecasted</p>
                                                            <h5 className="mb-2">305</h5>
                                                        </div>
                                                        <div className="avatar-sm ml-auto">
                                                            <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                                                                <i className="mdi mdi-file-document-outline" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <ul className="dash-para-fix">
                                                        <li className="mb-1">
                                                            NRR: <strong>105</strong> ORR: <strong>92</strong>
                                                        </li>
                                                        <li>
                                                            Best: <strong>209</strong> Most Likely: <strong>275</strong>
                                                            <br />
                                                            Worst: <strong>220</strong>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6 col-12">
                                            <div className="card blog-stats-wid">
                                                <div className="card-body">
                                                    <div className="d-flex flex-wrap">
                                                        <div className="mr-3">
                                                            <p className="text-muted mb-2">Expansion / Forecast</p>
                                                            <h5 className="mb-0">60/75</h5>
                                                        </div>
                                                        <div className="avatar-sm ml-auto">
                                                            <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                                                                <i className="mdi mdi-chart-bell-curve-cumulative" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6 col-12">
                                            <div className="card blog-stats-wid">
                                                <div className="card-body">
                                                    <div className="d-flex flex-wrap">
                                                        <div className="mr-3">
                                                            <p className="text-muted mb-2">Renewed</p>
                                                            <h5 className="mb-0">200</h5>
                                                        </div>
                                                        <div className="avatar-sm ml-auto">
                                                            <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                                                                <i className="mdi mdi-autorenew" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6 col-12">
                                            <div className="card blog-stats-wid">
                                                <div className="card-body">
                                                    <div className="d-flex flex-wrap">
                                                        <div className="mr-3">
                                                            <p className="text-muted mb-2">Churned / Forecasted</p>
                                                            <h5 className="mb-0">25</h5>
                                                        </div>
                                                        <div className="avatar-sm ml-auto">
                                                            <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                                                                <i className="mdi mdi-file-multiple-outline" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="d-flex flex-wrap">
                                                <h5 className="card-title mr-2">Month by Month</h5>
                                            </div>
                                            <div id="mixed_chart" className="apex-charts" dir="ltr" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="d-flex flex-wrap mb-4">
                                                <h4 className="card-title">Expansion &amp; Cross Sell Accounts</h4>
                                                <div className="ml-auto d-flex">
                                                    <button className="btn btn-primary mr-2 w-md">Add Account</button>
                                                    <div className="dropdown mb-0 ml-1">
                                                        <a
                                                            className="btn btn-light pt-1 pb-1 text-muted dropdown-toggle d-flex align-items-center"
                                                            role="button"
                                                            data-toggle="dropdown"
                                                            aria-haspopup="true"
                                                        >
                                                            <i className="mdi mdi-filter-menu-outline font-size-20 mr-2" />
                                                            Filter
                                                        </a>
                                                        <div className="dropdown-menu dropdown-menu-right">
                                                            <a className="dropdown-item" href="#">
                                                                Share Files
                                                            </a>
                                                            <a className="dropdown-item" href="#">
                                                                Share with me
                                                            </a>
                                                            <a className="dropdown-item" href="#">
                                                                Other Actions
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="risk-table-nav">
                                                <ul className="nav nav-tabs nav-tabs-custom" role="tablist">
                                                    <li className="nav-item">
                                                        <a
                                                            className="nav-link active"
                                                            data-toggle="tab"
                                                            href="#buy"
                                                            role="tab"
                                                        >
                                                            Upsell
                                                        </a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a
                                                            className="nav-link"
                                                            data-toggle="tab"
                                                            href="#sell"
                                                            role="tab"
                                                        >
                                                            Output
                                                        </a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a
                                                            className="nav-link"
                                                            data-toggle="tab"
                                                            href="#sell1"
                                                            role="tab"
                                                        >
                                                            Cross Sell
                                                        </a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a
                                                            className="nav-link"
                                                            data-toggle="tab"
                                                            href="#sell2"
                                                            role="tab"
                                                        >
                                                            Stage
                                                        </a>
                                                    </li>
                                                </ul>
                                                <div className="tab-content pt-4 pb-4">
                                                    <div className="tab-pane active" id="buy" role="tabpanel">
                                                        <div className="table-responsive">
                                                            <table
                                                                id="datatable"
                                                                className="table table-bordered dt-responsive nowrap"
                                                                style={{
                                                                    borderCollapse: "collapse",
                                                                    borderSpacing: 0,
                                                                    width: "100%"
                                                                }}
                                                            >
                                                                <thead>
                                                                    <tr>
                                                                        <th>Account</th>
                                                                        <th>Contract</th>
                                                                        <th>ARR</th>
                                                                        <th>Rep/Manager</th>
                                                                        <th>Region</th>
                                                                        <th>Due/Expected Date</th>
                                                                        <th>Risk Level</th>
                                                                        <th>Region</th>
                                                                        <th>Notes</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <div className="tab-pane" id="sell" role="tabpanel">
                                                        <h4>Comming Soon</h4>
                                                    </div>
                                                    <div className="tab-pane" id="sell1" role="tabpanel">
                                                        <h4>Comming Soon</h4>
                                                    </div>
                                                    <div className="tab-pane" id="sell2" role="tabpanel">
                                                        <h4>Comming Soon</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="d-flex flex-wrap mb-4">
                                                <h4 className="card-title">Entitlements &amp; Usage</h4>
                                                <div className="ml-auto d-flex">
                                                    <button className="btn btn-primary mr-2 w-md">Add Account</button>
                                                    <div className="dropdown mb-0 ml-1">
                                                        <a
                                                            className="btn btn-light pt-1 pb-1 text-muted dropdown-toggle d-flex align-items-center"
                                                            role="button"
                                                            data-toggle="dropdown"
                                                            aria-haspopup="true"
                                                        >
                                                            <i className="mdi mdi-filter-menu-outline font-size-20 mr-2" />
                                                            Filter
                                                        </a>
                                                        <div className="dropdown-menu dropdown-menu-right">
                                                            <a className="dropdown-item" href="#">
                                                                0
                                                            </a>
                                                            <a className="dropdown-item" href="#">
                                                                20%
                                                            </a>
                                                            <a className="dropdown-item" href="#">
                                                                30%
                                                            </a>
                                                            <a className="dropdown-item" href="#">
                                                                40%
                                                            </a>
                                                            <a className="dropdown-item" href="#">
                                                                50%
                                                            </a>
                                                            <a className="dropdown-item" href="#">
                                                                60%
                                                            </a>
                                                            <a className="dropdown-item" href="#">
                                                                70%
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="risk-table-nav">
                                                <ul className="nav nav-tabs nav-tabs-custom" role="tablist">
                                                    <li className="nav-item">
                                                        <a
                                                            className="nav-link active"
                                                            data-toggle="tab"
                                                            href="#buy"
                                                            role="tab"
                                                        >
                                                            Upsell
                                                        </a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a
                                                            className="nav-link"
                                                            data-toggle="tab"
                                                            href="#sell"
                                                            role="tab"
                                                        >
                                                            Output
                                                        </a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a
                                                            className="nav-link"
                                                            data-toggle="tab"
                                                            href="#sell"
                                                            role="tab"
                                                        >
                                                            Cross Sell
                                                        </a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a
                                                            className="nav-link"
                                                            data-toggle="tab"
                                                            href="#sell"
                                                            role="tab"
                                                        >
                                                            Stage
                                                        </a>
                                                    </li>
                                                </ul>
                                                <div className="tab-content pt-4 pb-4">
                                                    <div className="tab-pane active" id="buy" role="tabpanel">
                                                        <div className="table-responsive">
                                                            <table
                                                                id="datatable2"
                                                                className="table table-bordered dt-responsive nowrap"
                                                                style={{
                                                                    borderCollapse: "collapse",
                                                                    borderSpacing: 0,
                                                                    width: "100%"
                                                                }}
                                                            >
                                                                <thead>
                                                                    <tr>
                                                                        <th>Account</th>
                                                                        <th>Contract</th>
                                                                        <th>ARR</th>
                                                                        <th>Rep/Manager</th>
                                                                        <th>Region</th>
                                                                        <th>Due Date</th>
                                                                        <th>Risk Level</th>
                                                                        <th>Region/Notes</th>
                                                                        <th>Something</th>
                                                                        <th>Usage</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                        <td>Example</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <div className="tab-pane" id="sell" role="tabpanel"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>

                    </div>
                    <Footer />
                </div>
            </div>
        </React.Fragment>
    )
}

export default AtRisk