import React, { useEffect, useRef, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { useAppLoader } from '../../_common/hooks/common/appLoaderHook';
import { toast } from 'react-hot-toast';
import SignatureCanvas from 'react-signature-canvas';
import { usePlatformApi } from '../../_common/hooks/api/PlatformApiHook';
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Controller, useForm } from 'react-hook-form';

interface FormValues {
	signature_fullname: string;
}

const validationSchema = Yup.object().shape({
	signature_fullname: Yup.string().required("Full name is required"),
});

interface IncomingProps {
	show: boolean;
	close: () => void;
	setSignatureUrl: Function;
	setSignatureFullname: Function;
}

const QuoteSignatureModal = ({ show, close, setSignatureUrl, setSignatureFullname }: IncomingProps) => {

	const platformApi = usePlatformApi();
	const { showLoader, hideLoader } = useAppLoader();

	const signatureCanvasRef = useRef<any>();
	const signatureImgRef = useRef<any>();

	const [imageURL, setImageURL] = useState<string>('');
	const [canvasURL, setCanvasURL] = useState<string>('');
	const [errorMessage, setErrorMessage] = useState<string>('');
	const [fileName, setfileName] = useState<string>('');
	const [showSignatureTab, setShowSignatureTab] = useState<boolean>(true);


	const { control, formState: { errors }, handleSubmit, reset } = useForm<FormValues>({
		resolver: yupResolver(validationSchema),
		defaultValues: {
			signature_fullname: "",
		},
	});

	const handleClear = (type: string) => {
		if (type === 'image') {
			if (signatureImgRef.current) {
				signatureImgRef.current.value = null;
			}
			setImageURL('')
			setfileName('')
		} else {
			signatureCanvasRef.current?.clear();
			if (signatureCanvasRef.current) {
				signatureCanvasRef.current.value = null;
			}
			setCanvasURL('')
		}
	};

	const handleSave = (type: string) => {
		let signatureImage: any = null;
		if (type === 'image') {
			signatureImage = signatureImgRef.current.files[0];
			if (signatureImgRef.current && signatureImgRef.current.files.length > 0) {
				setImageURL(URL.createObjectURL(signatureImage))
				setErrorMessage('')
				handleClear('canvas')
			}
		} else {
			if (!signatureCanvasRef.current.isEmpty()) {
				signatureImage = signatureCanvasRef.current.toDataURL();
				setCanvasURL(signatureImage);
				setErrorMessage('')
				handleClear('image')
			}
		}

	};

	const onSubmit = (data: any) => {
		// let fd = new FormData();
		// fd.append('id', quoteid);
		// fd.append('signature_fullname', data.signature_fullname);
		setSignatureFullname(data.signature_fullname)
		if ((!canvasURL || canvasURL == '') && (!imageURL || imageURL == '')) {
			setErrorMessage('Please draw a signature or upload a signature')
			return
		}
		if (canvasURL && canvasURL != '') {
			// fd.append('signature', canvasURL)
			setSignatureUrl(canvasURL)
		} else if (imageURL && imageURL != '') {
			// fd.append('signature', signatureImgRef.current.files[0])
			setSignatureUrl(signatureImgRef.current.files[0])
		}
		handleClose()
		// showLoader()
		// platformApi.addQuoteSignature(fd, (message: string, resp: any) => {
		// 	toast.success(message)
		// 	close()
		// 	hideLoader()
		// }, (message: string) => {
		// 	toast.error(message)
		// 	hideLoader()
		// })
	}

	const handleClose = () => {
		setErrorMessage('');
		setImageURL('')
		setCanvasURL('')
		setfileName('')
		reset();
		if (signatureImgRef && signatureImgRef.current) {
			signatureImgRef.current.value = null;
		}
		if (signatureCanvasRef && signatureCanvasRef.current) {
			signatureCanvasRef.current.clear();
			signatureCanvasRef.current.value = null;
		}
		close();
	}

	// const changeTab = (isChange: boolean) => {
	// 	if (!isChange) {
	// 		if (!signatureCanvasRef.current.isEmpty()) {
	// 			let signatureImage = signatureCanvasRef.current.toDataURL();
	// 			setCanvasURL(signatureImage);
	// 			// setErrorMessage('')
	// 			// handleClear('image')
	// 		}
	// 	}
	// 	setShowSignatureTab(isChange)
	// }

	useEffect(() => {
		if (signatureCanvasRef && signatureCanvasRef.current && showSignatureTab) {
			// console.log("rest canvas image")
			signatureCanvasRef.current.fromDataURL(canvasURL)
		}
	}, [showSignatureTab])

	return (
		<React.Fragment>
			<Modal
				className='salesforce-modal'
				show={show}
			// onHide={handleClose}
			>
				<div className="modal-header custom-modal-header">
					<button
						type="button"
						className="close"
						data-dismiss="modal"
						aria-label="Close"
						onClick={handleClose}
					>
						<span aria-hidden="true">×</span>
					</button>
				</div>
				<Modal.Body>
					<div className="row">
						<form onSubmit={handleSubmit(onSubmit)} className="w-100" >
							<div className="col-12">
								<div className="card">
									<div className="card-body">
										<div className="row">
											<div className="form-group col-12">
												<label htmlFor="signature_fullname">Full name
													<span className='text-danger'>*</span>
												</label>
												<Controller
													name={"signature_fullname"}
													control={control}
													render={({ field: { value, onChange, onBlur, name, ref } }) => (
														<>
															<input
																type="text"
																className={"form-control " + (errors?.signature_fullname ? ' border-danger' : '')}
																id="signature_fullname"
																name={name}
																onChange={onChange}
																value={value}
																onBlur={onBlur}
																ref={ref}
																autoComplete='off'
																placeholder="Enter full name"
															/>
														</>
													)}
												/>
												{errors && errors.signature_fullname &&
													<span className='text-danger'>{errors?.signature_fullname?.message}</span>
												}
											</div>
											<ul className="nav nav-tabs signadd_tab">
												<li className="nav-item cursor-pointer" onClick={() => setShowSignatureTab(true)}>
													<span className={"nav-link" + (showSignatureTab ? ' active' : '')} aria-current="page">Draw Signature</span>
												</li>
												<li className="nav-item cursor-pointer" onClick={() => setShowSignatureTab(false)}>
													<span className={"nav-link" + (!showSignatureTab ? ' active' : '')}>Upload Signature</span>
												</li>
											</ul>
											<div className="form-group col-12">
												{showSignatureTab ?
													<div className='signtab_content'>
														<SignatureCanvas
															ref={signatureCanvasRef}
															canvasProps={{ height: 200, className: 'signature-canvas' }}
															onEnd={() => handleSave('canvas')}
														/>
														<div>
															<button
																type='button'
																onClick={() => handleClear('canvas')}
																className='btn btn-outline-danger'
															>
																Clear
															</button>
															{/* <button
																type='button'
																onClick={() => handleSave('canvas')}
																className='btn btn-success ml-3'
															>
																Save
															</button> */}
														</div>
													</div>
													:
													<div className='signtab_content'>
														<div className="input_filerctrl">
															<input
																type="file"
																accept='image/*'
																id="zonename"
																ref={signatureImgRef}
																onChange={(e: any) => { setfileName(e?.target?.files[0]?.name); handleSave('image') }}
															/>
															<div className="custom_fileselect"><i className="bx bx-upload"></i> Upload Signature</div>
															{<span className='ml-3'>{fileName}</span>}
														</div>
														<div className='mt-2'>
															<button
																type='button'
																onClick={() => handleClear('image')}
																className='btn btn-outline-danger'
															>
																Clear
															</button>
															{/* <button
																type='button'
																onClick={() => handleSave('image')}
																className='btn btn-success ml-3'
															>
																Save
															</button> */}
														</div>
														{imageURL && imageURL != '' ?
															<div className='signature-image'>
																<img
																	src={imageURL}
																	alt=""
																/>
															</div>
															:
															// canvasURL && canvasURL != '' ?
															// 	<div className='signature-image'>
															// 		<img
															// 			src={canvasURL}
															// 			alt=""
															// 		/>
															// 	</div>
															// 	:
															null
														}
													</div>
												}
												{errorMessage && errorMessage != '' ?
													<div className='text-danger'>{errorMessage}</div> : null}
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="col-12">
								<div className='align-items-center d-flex gap-10 justify-content-center'>
									<button type='submit' className='btn btn-primary'>Submit</button>
									<button
										type='button'
										className='btn btn btn-outline-danger'
										onClick={handleClose}
									>
										Cancel
									</button>
								</div>
							</div>
						</form>
					</div>
				</Modal.Body>
			</Modal>
		</React.Fragment>
	)
}

export default QuoteSignatureModal