import React from 'react'
import { AiOutlineEdit } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom'

interface IncomingProps {
  column: any;
  value: any;
  link: any;
  type?: string;
}


const EditButtonCell = ({ column, value, link, type }: IncomingProps) => {

  const navigate = useNavigate();
  return (
    <AiOutlineEdit
      onClick={() => navigate(type == 'quote' ? `/${link}&id=${value}` : `/${link}?id=${value}`)}
      className="cursor-pointer mr-2"
      data-toggle="tooltip" data-placement="top" title="Edit"
    />
  )
  // }
}

export default EditButtonCell