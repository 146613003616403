import React, { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast';
import { Link, useNavigate } from 'react-router-dom'
import SalesforceConnectModal from '../../components/modals/salesforceConnect.modal';
import { store } from '../../store';
import { useCommonApi } from '../../_common/hooks/api/common/commonApiHook';
import { useUserApi } from '../../_common/hooks/api/UserApiHook';
import { useAppLoader } from '../../_common/hooks/common/appLoaderHook';
import { useSalesforceDataSelector } from '../../_common/hooks/selectors/platformSelector';
import { useUserSelector } from '../../_common/hooks/selectors/userSelector';
import { ACTIONS, STORAGE, URLS } from "../../_config";

const FinalExternalApplication = () => {

    const userApi = useUserApi();
    const commonApi = useCommonApi();
    const user: any = useUserSelector();
    const salesforceData: any = useSalesforceDataSelector();
    const navigate = useNavigate()

    const checkConnected = localStorage.getItem(STORAGE);

    const { showLoader, hideLoader } = useAppLoader();
    const [showSalesforceModal, setShowSalesforceModal] = useState<boolean>(false);
    const [platforms, setPlatforms] = useState<any>([]);

    const closeSalesforceModal = () => {
        setShowSalesforceModal(false)
    }

    const logout = (e: any) => {
        //	console.log("logout")
        e.preventDefault();
        e.stopPropagation();
        userApi.logout({}, (message: string, resp: any) => {
        }, (message: string) => {
        })
    }

    const getPlatforms = () => {
        showLoader()
        commonApi.getPlatforms({},
            (message: string, resp: any) => {
                // console.log({ resp })
                setPlatforms(resp?.data)
                hideLoader()
            }, (message: string) => {
                hideLoader()
            })
    }

    const updateUserPlatform = (data: any) => {
        showLoader()
        userApi.updateUserPlatform(data,
            (message: string, resp: any) => {
                // console.log({ resp })
                store.dispatch({
                    type: ACTIONS.SALESFORCE.RESET_DATA,
                });
                toast.success(message)
                navigate(URLS.REVENUE)
                hideLoader()
            }, (message: string) => {
                toast.error(message)
                hideLoader()
            })
    }

    const showExternalApplication = (applicationName: string) => {
        const data = platforms.find((item: any) => item.name === applicationName);
        if (data && data.status === '1') {
            return true;
        }
        return false;
    }

    const getLoginUserDetails = () => {
        showLoader()
        userApi.getLoginUser({},
            (message: string, resp: any) => {
                if (resp.data.userType == 'primary' && resp.data.user.doneregionsetting == '0') {
                    navigate(URLS.REGION_SETTINGS)
                }
                else if (resp.data.userType == 'primary' && resp.data.user.donebasicsetting == '0') {
                    navigate(URLS.BUSINESS_DETAILS)
                }
                hideLoader()
            }, (message: string) => {
                hideLoader()
            })
    }

    useEffect(() => {
        // console.log(checkConnected)
        if (checkConnected) {
            const parsedValue: any = JSON.parse(checkConnected)
            if (parsedValue.connectedPlatform.length > 0) {
                navigate(URLS.REVENUE)
            }
        }
    }, [checkConnected])

    useEffect(() => {
        getPlatforms()
        getLoginUserDetails()
    }, [])

    useEffect(() => {
        if (salesforceData) {
            let data: any = {}
            if (salesforceData.client_id) {
                data["client_id"] = salesforceData.client_id
            }
            if (salesforceData.client_secret) {
                data["client_secret"] = salesforceData.client_secret
            }
            if (salesforceData.client_code) {
                data["client_code"] = salesforceData.client_code
            }
            data["platform_id"] = 1
            // console.log("salesdata===>", data)
            updateUserPlatform(data)
        }
    }, [salesforceData])

    return (
        <React.Fragment>
            <SalesforceConnectModal
                show={showSalesforceModal}
                close={closeSalesforceModal}
            />
            <div id="layout-wrapper">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="d-flex flex-wrap mb-3 justify-content-between">
                                            <h5 className="card-title mr-2">Connects External Application</h5>
                                            <div className="d-flex align-items-center">
                                                <h5 className="w-100 mb-0" style={{ whiteSpace: "nowrap" }}>{user?.full_name}</h5>
                                                <button className="dropdown-item text-danger" type='button' onClick={logout}>
                                                    <i className="bx bx-power-off font-size-16 align-middle mr-1 text-danger" />
                                                    <span key="t-logout">Logout</span>
                                                </button>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div
                                                className={"col-lg-4 col-md-6 col-12 " + (showExternalApplication("Salesforce") ? "d-block" : "d-none")}
                                            >
                                                <div className="application-box">
                                                    <div className="application-box-img">
                                                        <img src="assets/images/sales-force.png" alt="#" />
                                                    </div>
                                                    <div className="application-box-btn">
                                                        <button
                                                            type="button"
                                                            className="btn btn-primary"
                                                            // data-toggle="modal"
                                                            // data-target="#demo-1"
                                                            onClick={() => setShowSalesforceModal(true)}
                                                        >
                                                            Manage Connections
                                                        </button>
                                                        <button type="button" className="btn btn-secondary" disabled>
                                                            Manage Mapping
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className={"col-lg-4 col-md-6 col-12 " + (showExternalApplication("Zendesk") ? "d-block" : "d-none")}
                                            >
                                                <div className="application-box">
                                                    <div className="application-box-img">
                                                        <img src="assets/images/zendesk.png" alt="#" />
                                                    </div>
                                                    <div className="application-box-btn">
                                                        <button type="button" className="btn btn-primary">
                                                            Manage Connections
                                                        </button>
                                                        <button type="button" className="btn btn-primary">
                                                            Manage Mapping
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className={"col-lg-4 col-md-6 col-12 " + (showExternalApplication("ZohoCRM") ? "d-block" : "d-none")}
                                            >
                                                <div className="application-box">
                                                    <div className="application-box-img">
                                                        <img src="assets/images/zoho.png" alt="#" />
                                                    </div>
                                                    <div className="application-box-btn">
                                                        <button type="button" className="btn btn-primary">
                                                            Manage Connections
                                                        </button>
                                                        <button type="button" className="btn btn-primary">
                                                            Manage Mapping
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className={"col-lg-4 col-md-6 col-12 " + (showExternalApplication("Hobspot") ? "d-block" : "d-none")}
                                            >
                                                <div className="application-box">
                                                    <div className="application-box-img">
                                                        <img src="assets/images/hubspot.png" alt="#" />
                                                    </div>
                                                    <div className="application-box-btn">
                                                        <button type="button" className="btn btn-primary">
                                                            Manage Connections
                                                        </button>
                                                        <button type="button" className="btn btn-primary">
                                                            Manage Mapping
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className={"col-lg-4 col-md-6 col-12 " + (showExternalApplication("Microsoft Dynamics") ? "d-block" : "d-none")}
                                            >
                                                <div className="application-box">
                                                    <div className="application-box-img">
                                                        <img src="assets/images/microsoft.png" alt="#" />
                                                    </div>
                                                    <div className="application-box-btn">
                                                        <button type="button" className="btn btn-primary">
                                                            Manage Connections
                                                        </button>
                                                        <button type="button" className="btn btn-primary">
                                                            Manage Mapping
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default FinalExternalApplication