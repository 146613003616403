import React, { useEffect, useState } from 'react'
import { useCommonApi } from '../../_common/hooks/api/common/commonApiHook';
import { useUserApi } from '../../_common/hooks/api/UserApiHook';
import { useAppLoader } from '../../_common/hooks/common/appLoaderHook';
import { useUserSelector } from '../../_common/hooks/selectors/userSelector';
import { toast } from 'react-hot-toast';
import { URLS } from '../../_config';
import { useNavigate } from 'react-router-dom';
import * as Yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { AiOutlineDelete } from 'react-icons/ai';
import { deleteNotification } from '../../_common/functions';
import { useCommonFunction } from '../../_common/hooks/common/appCommonFunctionHook';
import Select from 'react-select';
import { monthsOptions } from '../../_common/staticData';

interface AddProductFormValues {
    businessname: string;
    fy_start_month: any;
    zone: any;
    basecurrency: any;
    logo: any;
    state: string;
}

const validationSchema = Yup.object().shape({
    businessname: Yup.string().required("Business name is required"),
    state: Yup.string().required("State is required"),
    fy_start_month: Yup.object().required("Financial start month is required"),
    // zone: Yup.object().required("Zone is required"),
    basecurrency: Yup.object().required("Base currency is required"),
    // logo: Yup.string().required("Logo is required")
});

const BusinessDetails = () => {

    const commonApi = useCommonApi();
    const userApi = useUserApi();
    const commonFunctions = useCommonFunction();
    const user: any = useUserSelector();
    const navigate = useNavigate();
    const { showLoader, hideLoader } = useAppLoader();


    const [businessSettingsData, setBusinessSettingData] = useState<any>(null)
    const [fileValue, setFileValue] = useState<any>(null)
    const [isFilePresent, setIsFilePresent] = useState<boolean>(false)
    const [currencyOptions, setCurrencyOptions] = useState<any>([])

    const { control, formState: { errors }, handleSubmit, setValue, reset } = useForm<AddProductFormValues>({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            businessname: "",
            fy_start_month: "",
            basecurrency: "",
            state: "",
            logo: null,
            // zone: "",
        },
    });

    const logout = (e: any) => {
        //	console.log("logout")
        e.preventDefault();
        e.stopPropagation();
        userApi.logout({}, (message: string, resp: any) => {
        }, (message: string) => {
        })
    }

    const getCurrency = () => {
        showLoader();
        commonApi.getCurrency({},
            (message: string, resp: any) => {
                // console.log(resp);
                const tempList: any = []
                if (resp.data.length > 0) {
                    resp.data.forEach((item: any) => {
                        let tempObj: any = {};
                        tempObj["label"] = item
                        tempObj["value"] = item
                        tempList.push(tempObj);
                    })
                    setCurrencyOptions(tempList);
                }
                hideLoader();
            }, (message: string) => {
                hideLoader();
            })
    }

    const getBusinessSettingsData = () => {
        showLoader();
        commonApi.getBusinesss({},
            (message: string, resp: any) => {
                // console.log(resp);
                setBusinessSettingData(resp.data.list);
                hideLoader();
            }, (message: string) => {
                hideLoader();
            })
    }

    const fileOnChange = (data: any) => {
        if (data?.target?.files) {
            setFileValue(data.target.files[0])
        }
    }

    const onSubmit = (data: any) => {
        let fd = new FormData();
        if (data?.businessname) {
            fd.append("businessname", data?.businessname)
        }
        if (data?.fy_start_month) {
            fd.append("fy_start_month", data?.fy_start_month?.value)
        }
        if (data?.state) {
            fd.append("state", data?.state)
        }
        if (data?.basecurrency && data?.basecurrency?.value) {
            fd.append("basecurrency", data?.basecurrency?.value)
        }
        if (businessSettingsData && businessSettingsData?.id) {
            fd.append("id", businessSettingsData?.id)
        }
        if (fileValue) {
            fd.append("logo", fileValue)
        } else if (!isFilePresent) {
            return toast.error("Please upload logo")
        }
        showLoader()
        commonApi.manageBusiness(fd, (message: string, resp: any) => {
            // console.log(message, resp)
            toast.success(message)
            reset()
            navigate(URLS.HOME)
            // getBusinessSettingsData()
            hideLoader()
        }, (message: string) => {
            // console.log(message)
            toast.error(message)
            hideLoader()
        })
    }

    useEffect(() => {
        getBusinessSettingsData()
        getCurrency()
        // getZone()
    }, [])


    useEffect(() => {
        if (businessSettingsData) {
            setValue('businessname', businessSettingsData?.businessname)
            setValue('state', businessSettingsData?.state)
            setValue('fy_start_month', monthsOptions?.find((item: any) => item.value == businessSettingsData?.fy_start_month) || '')
            setValue('basecurrency', currencyOptions?.find((item: any) => item.value === businessSettingsData?.basecurrency) || '')
            setIsFilePresent(businessSettingsData?.bslogo ? true : false)
        }
    }, [businessSettingsData, currencyOptions]);

    return (
        <React.Fragment>
            <div id="layout-wrapper">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="d-flex flex-wrap mb-3 justify-content-between">
                                            <h5 className="card-title mr-2">Business Settings</h5>
                                            <div className="d-flex align-items-center">
                                                <h5 className="w-100 mb-0" style={{ whiteSpace: "nowrap" }}>{user?.full_name}</h5>
                                                <button className="dropdown-item text-danger" type='button' onClick={logout}>
                                                    <i className="bx bx-power-off font-size-16 align-middle mr-1 text-danger" />
                                                    <span key="t-logout">Logout</span>
                                                </button>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="card-body">
                                                <div className="d-flex flex-wrap justify-content-between">
                                                    <h5 className="card-title mr-2">Business Details</h5>
                                                </div>
                                                <div className="row">
                                                    <form onSubmit={handleSubmit(onSubmit)}>
                                                        <div className="col-12">
                                                            <div className="card">
                                                                <div className="card-body">
                                                                    <div className="row">
                                                                        <div className="form-group col-6">
                                                                            <label htmlFor="businessname">Business name <span className='text-danger'>*</span></label>
                                                                            <Controller
                                                                                name={"businessname"}
                                                                                control={control}
                                                                                render={({ field: { value, onChange, onBlur, name, ref } }) => (
                                                                                    <>
                                                                                        <input
                                                                                            type="text"
                                                                                            className={"form-control " + (errors?.businessname ? ' border-danger' : '')}
                                                                                            id="businessname"
                                                                                            name={name}
                                                                                            onChange={onChange}
                                                                                            value={value}
                                                                                            onBlur={onBlur}
                                                                                            ref={ref}
                                                                                            placeholder="Enter business name"
                                                                                        />
                                                                                    </>
                                                                                )}
                                                                            />
                                                                            {errors && errors.businessname &&
                                                                                <span className='text-danger'>{errors?.businessname?.message}</span>
                                                                            }
                                                                        </div>
                                                                        <div className="form-group col-6">
                                                                            <label htmlFor="fy_start_month">Financial year start month <span className='text-danger'>*</span></label>
                                                                            <Controller
                                                                                name={"fy_start_month"}
                                                                                control={control}
                                                                                render={({ field: { value, onChange, onBlur, name, ref } }) => (
                                                                                    <>
                                                                                        <Select
                                                                                            className={"select-box " + (errors?.fy_start_month ? ' border-danger' : '')}
                                                                                            id="fy_start_month"
                                                                                            options={monthsOptions ? monthsOptions : []}
                                                                                            placeholder={"Select FY start month"}
                                                                                            onChange={onChange}
                                                                                            value={value}
                                                                                            defaultValue={value}
                                                                                            ref={ref}
                                                                                        />
                                                                                    </>
                                                                                )}
                                                                            />
                                                                            {errors && errors.fy_start_month &&
                                                                                <span className='text-danger'>Financial start month is required</span>
                                                                            }
                                                                        </div>
                                                                        <div className="form-group col-6">
                                                                            <label htmlFor="state">State <span className='text-danger'>*</span></label>
                                                                            <Controller
                                                                                name={"state"}
                                                                                control={control}
                                                                                render={({ field: { value, onChange, onBlur, name, ref } }) => (
                                                                                    <>
                                                                                        <input
                                                                                            type="text"
                                                                                            className={"form-control " + (errors?.state ? ' border-danger' : '')}
                                                                                            id="state"
                                                                                            name={name}
                                                                                            onChange={onChange}
                                                                                            value={value}
                                                                                            onBlur={onBlur}
                                                                                            ref={ref}
                                                                                            placeholder="Enter business name"
                                                                                        />
                                                                                    </>
                                                                                )}
                                                                            />
                                                                            {errors && errors.state &&
                                                                                <span className='text-danger'>{errors?.state?.message}</span>
                                                                            }
                                                                        </div>
                                                                        <div className="form-group col-6">
                                                                            <label htmlFor="basecurrency">Base currency <span className='text-danger'>*</span></label>
                                                                            <Controller
                                                                                name={"basecurrency"}
                                                                                control={control}
                                                                                render={({ field: { value, onChange, onBlur, name, ref } }) => (
                                                                                    <>
                                                                                        <Select
                                                                                            className={"select-box " + (errors?.basecurrency ? ' border-danger' : '')}
                                                                                            id="basecurrency"
                                                                                            options={currencyOptions ? currencyOptions : []}
                                                                                            placeholder={"Select base currency"}
                                                                                            onChange={onChange}
                                                                                            value={value}
                                                                                            defaultValue={value}
                                                                                            ref={ref}
                                                                                        />
                                                                                    </>
                                                                                )}
                                                                            />
                                                                            {errors && errors.basecurrency &&
                                                                                <span className='text-danger'>Base currency is required</span>
                                                                            }
                                                                        </div>
                                                                        <div className="align-items-center col-6 d-flex form-group gap-10">
                                                                            <label htmlFor="logo" className='mb-0'>Logo <span className='text-danger'>*</span></label>
                                                                            {
                                                                                businessSettingsData?.bslogo ?
                                                                                    <div className="form-group">
                                                                                        <img src={businessSettingsData?.logo?.thumb} alt='' />
                                                                                        <AiOutlineDelete
                                                                                            color='red'
                                                                                            size={"18px"}
                                                                                            className='cursor-pointer'
                                                                                            style={{ paddingLeft: "5px" }}
                                                                                            onClick={() =>
                                                                                                deleteNotification(businessSettingsData?.logo?.id, "document", commonFunctions.deleteFile, getBusinessSettingsData)
                                                                                            }
                                                                                        />
                                                                                    </div>
                                                                                    :
                                                                                    <>
                                                                                        <Controller
                                                                                            name={"logo"}
                                                                                            control={control}
                                                                                            render={({ field: { value, onChange, onBlur, name, ref } }) => (
                                                                                                <>
                                                                                                    <div className="custom-file mb-3">
                                                                                                        <input
                                                                                                            type="file"
                                                                                                            accept='image/*'
                                                                                                            className={"custom-file-input" + (errors?.logo ? ' border-danger' : '')}
                                                                                                            id="customFile"
                                                                                                            name={name}
                                                                                                            onChange={(e: any) => { onChange(e); fileOnChange(e) }}
                                                                                                            value={value}
                                                                                                            onBlur={onBlur}
                                                                                                            ref={ref}
                                                                                                            placeholder="Enter logo"
                                                                                                        />
                                                                                                        <label className="custom-file-label" htmlFor="customFile">{fileValue && fileValue != undefined ? fileValue?.name : "Choose file"}</label>
                                                                                                    </div>
                                                                                                </>
                                                                                            )}
                                                                                        />
                                                                                    </>
                                                                            }
                                                                            {errors && errors.logo &&
                                                                                <span className='text-danger'>Logo is required</span>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-12">
                                                            <div className="card">
                                                                <div className="card-body">
                                                                    <div className='align-items-center d-flex gap-10 justify-content-center'>
                                                                        <button type='submit' className='btn btn-primary'>Save</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default BusinessDetails