import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Controller, useForm } from 'react-hook-form';
import { useAppLoader } from '../../_common/hooks/common/appLoaderHook';
import { toast } from 'react-hot-toast';
import { useCommonApi } from '../../_common/hooks/api/common/commonApiHook';
import { handleBlur, handleFocus, handleKeyPressDecimalNumber, handleKeyUpDown, handleWheel } from '../../_common/functions';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';

interface FormValues {
	rulename: string;
	targetobject: string | object | {};
	step: string;
	status: boolean;
	approver: string | object | {};
}

const validationSchema = Yup.object().shape({
	rulename: Yup.string().required("Rule name is required"),
	targetobject: Yup.object().required("Target objet is required"),
	approver: Yup.object().required("Approver is required"),
	step: Yup.string().required("Step is required"),
});

interface IncomingProps {
	show: boolean;
	close: () => void;
	id?: any
	isEdit: boolean;
}

const AddApproverRuleModal = ({ show, close, id, isEdit = false }: IncomingProps) => {

	const commonApi = useCommonApi();
	const navigate = useNavigate();
	const { showLoader, hideLoader } = useAppLoader();

	const [approverRuleData, setApproverRuleData] = useState<any>(null)
	const [loading, setLoading] = useState<boolean>(false)
	const [approverOptions, setApproverOptions] = useState<any | []>([])
	const [targetOptions, setTargetOptions] = useState<any | []>([{ label: 'Quote', value: 'Quote' }])

	const { control, formState: { errors }, handleSubmit, setValue, reset } = useForm<FormValues>({
		resolver: yupResolver(validationSchema),
		defaultValues: {
			rulename: "",
			targetobject: "",
			step: "1",
			status: true,
			approver: ""
		},
	});

	const getApproverRuleData = () => {
		showLoader();
		setLoading(true)
		commonApi.getAllApproverRule({ id: id },
			(message: string, resp: any) => {
				// console.log(resp);
				setApproverRuleData(resp.data.list);
				setLoading(false)
				hideLoader();
			}, (message: string) => {
				hideLoader();
				setLoading(false)
			})
	}

	const getApproverList = () => {
		showLoader();
		commonApi.getAllApprover({},
			(message: string, resp: any) => {
				// console.log(resp);
				let tempArr = resp.data.list.data
				if (tempArr && tempArr.length > 0) {
					setApproverOptions(tempArr.map((item: any) => {
						item.label = item.approvername;
						item.value = item.id;
						return item
					}));
				}
				hideLoader();
			}, (message: string) => {
				hideLoader();
			})
	}

	const onSubmit = (data: any) => {
		// console.log({ data })
		let params: any = {
			rulename: data?.rulename,
			step: data?.step,
			targetobject: data?.targetobject?.value,
			approver: data?.approver?.value,
			status: data?.status === true ? "1" : "0"
		}
		if (isEdit) {
			if (approverRuleData && approverRuleData.id) {
				params["id"] = approverRuleData.id
			}
		}
		// console.log(params)
		showLoader()
		commonApi.manageApproverRule(params, (message: string, resp: any) => {
			// console.log(message, resp)
			toast.success(message)
			reset()
			close()
			hideLoader()
			navigate(`/approver-rule-details/${resp?.data?.details?.id}`)
		}, (message: string) => {
			// console.log(message)
			toast.error(message)
			hideLoader()
		})
	}

	const handleClose = () => {
		close();
		reset();
		setApproverRuleData(null);
	}

	useEffect(() => {
		if (id) {
			getApproverRuleData()
		}
	}, [id])

	useEffect(() => {
		getApproverList()
	}, [])

	useEffect(() => {
		// console.log(approverRuleData)
		if (approverRuleData) {
			setValue('rulename', approverRuleData?.rulename)
			setValue('step', approverRuleData?.step)
			setValue('targetobject', targetOptions?.find((item: any) => item.value == approverRuleData?.targetobject))
			setValue('approver', approverOptions?.find((item: any) => item.value == approverRuleData?.approver))
			setValue('status', approverRuleData?.status && approverRuleData?.status == "1" ? true : false)
		}
	}, [approverRuleData, approverOptions, targetOptions])

	useEffect(() => {
		setValue('targetobject', targetOptions[0])
	}, [targetOptions])

	return (
		<React.Fragment>
			<Modal
				className='salesforce-modal modal-lndscp'
				show={show}
			// onHide={handleClose}
			>
				<div className="modal-header custom-modal-header">
					<button
						type="button"
						className="close"
						data-dismiss="modal"
						aria-label="Close"
						onClick={handleClose}
					>
						<span aria-hidden="true">×</span>
					</button>
				</div>
				<Modal.Body>
					{loading ?
						<div>
							Loading......
						</div>
						:
						<div className="row">
							<form onSubmit={handleSubmit(onSubmit)} className="w-100" >
								<div className="col-12">
									<div className="card">
										<div className="card-body">
											<div className="d-flex flex-wrap">
												<h5 className="card-title mr-2">Approver Rule Information</h5>
											</div>
											<div className="row">
												<div className="form-group col-6">
													<label htmlFor="rulename">Name <span className='text-danger'>*</span></label>
													<Controller
														name={"rulename"}
														control={control}
														render={({ field: { value, onChange, onBlur, name, ref } }) => (
															<>
																<input
																	type="text"
																	className={"form-control " + (errors?.rulename ? ' border-danger' : '')}
																	id="rulename"
																	name={name}
																	onChange={onChange}
																	value={value}
																	onBlur={onBlur}
																	ref={ref}
																	placeholder="Enter rule name"
																/>
															</>
														)}
													/>
													{errors && errors.rulename &&
														<span className='text-danger'>{errors?.rulename?.message}</span>
													}
												</div>
												<div className="form-group col-6">
													<label htmlFor="targetobject">Target Field <span className='text-danger'>*</span></label>
													<Controller
														name={"targetobject"}
														control={control}
														render={({ field: { value, onChange, onBlur, name, ref } }) => (
															<>
																<Select
																	className={"select-box" + (errors?.targetobject ? ' border-danger' : '')}
																	id="targetobject"
																	options={targetOptions ? targetOptions : []}
																	placeholder={"select target"}
																	onChange={onChange}
																	value={value}
																	defaultValue={value}
																	isDisabled={true}
																/>
															</>
														)}
													/>
													{errors && errors.targetobject &&
														<span className='text-danger'>Target is required</span>
													}
												</div>
												<div className="form-group col-6">
													<label htmlFor="step">Step <span className='text-danger'>*</span></label>
													<Controller
														name={"step"}
														control={control}
														render={({ field: { value, onChange, onBlur, name, ref } }) => (
															<>
																<input
																	type="number"
																	className={"form-control " + (errors?.step ? ' border-danger' : '')}
																	id="step"
																	name={name}
																	value={value}
																	onChange={onChange}
																	onBlur={(e) => { onBlur(); handleBlur(e) }}
																	onFocus={(e) => handleFocus(e)}
																	onKeyDown={(event: any) => { handleKeyPressDecimalNumber(event); handleKeyUpDown(event) }}
																	onWheel={handleWheel}
																	step={"1"}
																	min={0}
																	ref={ref}
																	placeholder="Enter step"
																	disabled
																/>
															</>
														)}
													/>
													{errors && errors.step ?
														<span className='text-danger'>{errors?.step?.message}</span>
														:
														null
													}
												</div>
												<div className="form-group col-6">
													<label htmlFor="approver">Approver <span className='text-danger'>*</span></label>
													<Controller
														name={"approver"}
														control={control}
														render={({ field: { value, onChange, onBlur, name, ref } }) => (
															<>
																<Select
																	className={"select-box" + (errors?.approver ? ' border-danger' : '')}
																	id="approver"
																	options={approverOptions ? approverOptions : []}
																	placeholder={"select approver"}
																	onChange={onChange}
																	value={value}
																	defaultValue={value}
																/>
															</>
														)}
													/>
													{errors && errors.approver &&
														<span className='text-danger'>Approver is required</span>
													}
												</div>
												<div className="form-group col-6">
													<div>
														<label htmlFor="status">Status <span className='text-danger'>*</span></label>
													</div>
													<Controller
														name={"status"}
														control={control}
														render={({ field: { value, onChange, onBlur, name, ref } }) => (
															<div className='cstm-switch'>
																<input
																	type="checkbox"
																	id={"new_account"}
																	className={"form-control"}
																	name={name}
																	onChange={onChange}
																	value={value === true ? "1" : "0"}
																	onBlur={onBlur}
																	defaultChecked={approverRuleData?.status === "1"}
																	checked={value === true}
																/>
																<label htmlFor="new_account" data-on-label="on" data-off-label="off"></label>
															</div>
														)}
													/>
													{errors && errors.status &&
														<span className='text-danger'>{errors?.status?.message}</span>
													}
												</div>
											</div>
										</div>
									</div>
								</div>

								<div className="col-12">
									<div className='align-items-center d-flex gap-10 justify-content-center'>
										<button type='submit' className='btn btn-primary'>Save</button>
										<button
											type='button'
											className='btn btn-outline-danger'
											onClick={handleClose}
										>
											Cancel
										</button>
									</div>
								</div>
							</form>
						</div>
					}
				</Modal.Body>
			</Modal>
		</React.Fragment>
	)
}

export default AddApproverRuleModal