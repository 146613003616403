import { takeLatest, all, takeEvery } from 'redux-saga/effects'; // fork, take,  takeEvery, takeLeading
import { SAGA_ACTIONS } from '../../_config';

import * as commonSaga from './commonSaga';
import * as userSaga from './userSaga';
import * as platformSaga from './platformSaga';
import * as zendeskSaga from './zendeskSaga';


export function* sagas() {
  yield all([
    takeEvery(SAGA_ACTIONS.COMMON.GET_JOB_TITLE, commonSaga.getJobTitle),
    takeEvery(SAGA_ACTIONS.COMMON.GET_PLATFORMS, commonSaga.getPlatforms),
    takeEvery(SAGA_ACTIONS.COMMON.GET_BUSINESS, commonSaga.getBusiness),
    takeLatest(SAGA_ACTIONS.COMMON.MANAGE_BUSINESS, commonSaga.manageBusiness),
    takeEvery(SAGA_ACTIONS.COMMON.GET_CURRENCY, commonSaga.getCurrency),
    takeLatest(SAGA_ACTIONS.COMMON.DELETE_SLA, commonSaga.deleteSla),
    takeEvery(SAGA_ACTIONS.COMMON.GET_ALL_REGIONS, commonSaga.getAllRegions),
    takeLatest(SAGA_ACTIONS.COMMON.DELETE_REGION, commonSaga.regionDestroy),
    takeLatest(SAGA_ACTIONS.COMMON.MANAGE_REGION, commonSaga.manageRegion),
    takeEvery(SAGA_ACTIONS.COMMON.GET_ALL_ZONE, commonSaga.getAllZone),
    takeLatest(SAGA_ACTIONS.COMMON.DELETE_ZONE, commonSaga.deleteZone),
    takeLatest(SAGA_ACTIONS.COMMON.MANAGE_ZONE, commonSaga.manageZone),
    takeEvery(SAGA_ACTIONS.COMMON.GET_NOTIFICATION, commonSaga.getNotification),
    takeLatest(SAGA_ACTIONS.COMMON.MANAGE_NOTIFICATION, commonSaga.manageNotification),
    takeEvery(SAGA_ACTIONS.COMMON.GET_ALL_TAX, commonSaga.getAllTax),
    takeLatest(SAGA_ACTIONS.COMMON.MANAGE_TAX, commonSaga.manageTax),
    takeLatest(SAGA_ACTIONS.COMMON.DELETE_TAX, commonSaga.deleteTax),
    takeEvery(SAGA_ACTIONS.COMMON.GET_ALL_PRODUCT_FAMILY, commonSaga.getAllProductFamily),
    takeLatest(SAGA_ACTIONS.COMMON.MANAGE_PRODUCT_FAMILY, commonSaga.manageProductFamily),
    takeLatest(SAGA_ACTIONS.COMMON.DELETE_PRODUCT_FAMILY, commonSaga.deleteProductFamily),
    takeLatest(SAGA_ACTIONS.COMMON.GET_ZENDESK, commonSaga.getZendesk),
    takeLatest(SAGA_ACTIONS.COMMON.ADD_ZENDESK, commonSaga.addZendesk),
    takeLatest(SAGA_ACTIONS.COMMON.IS_NEW_ACCOUNT, commonSaga.isNewAccount),
    takeEvery(SAGA_ACTIONS.COMMON.DONE_REGION_SETTING, commonSaga.doneRegionSetting),
    takeEvery(SAGA_ACTIONS.COMMON.GET_ALL_DESIGNATION, commonSaga.getAllDesignation),
    takeEvery(SAGA_ACTIONS.COMMON.MANAGE_DESIGNATION, commonSaga.manageDesignation),
    takeEvery(SAGA_ACTIONS.COMMON.DELETE_DESIGNATION, commonSaga.deleteDesignation),
    takeEvery(SAGA_ACTIONS.COMMON.GET_ALL_PARTNER, commonSaga.getAllPartner),
    takeEvery(SAGA_ACTIONS.COMMON.MANAGE_PARTNER, commonSaga.managePartner),
    takeEvery(SAGA_ACTIONS.COMMON.DELETE_PARTNER, commonSaga.deletePartner),
    takeEvery(SAGA_ACTIONS.COMMON.GET_ALL_APPROVER, commonSaga.getAllApprover),
    takeEvery(SAGA_ACTIONS.COMMON.MANAGE_APPROVER, commonSaga.manageApprover),
    takeEvery(SAGA_ACTIONS.COMMON.DELETE_APPROVER, commonSaga.deleteApprover),
    takeEvery(SAGA_ACTIONS.COMMON.GET_ALL_APPROVER_RULE, commonSaga.getAllApproverRule),
    takeEvery(SAGA_ACTIONS.COMMON.MANAGE_APPROVER_RULE, commonSaga.manageApproverRule),
    takeEvery(SAGA_ACTIONS.COMMON.DELETE_APPROVER_RULE, commonSaga.deleteApproverRule),
    takeEvery(SAGA_ACTIONS.COMMON.GET_ALL_APPROVER_RULE_CONDITION, commonSaga.getAllApproverRuleCondition),
    takeEvery(SAGA_ACTIONS.COMMON.MANAGE_APPROVER_RULE_CONDITION, commonSaga.manageApproverRuleCondition),
    takeEvery(SAGA_ACTIONS.COMMON.DELETE_APPROVER_RULE_CONDITION, commonSaga.deleteApproverRuleCondition),
    takeEvery(SAGA_ACTIONS.COMMON.GET_ALL_APPROVAL, commonSaga.getAllApproval),
    takeEvery(SAGA_ACTIONS.COMMON.MANAGE_APPROVAL, commonSaga.manageApproval),
    takeEvery(SAGA_ACTIONS.COMMON.DELETE_APPROVAL, commonSaga.deleteApproval),
    takeEvery(SAGA_ACTIONS.COMMON.ADD_APPROVER, commonSaga.addApprover),

    takeLatest(SAGA_ACTIONS.USER.LOGIN, userSaga.login),
    takeLatest(SAGA_ACTIONS.USER.LOGOUT, userSaga.logout),
    takeLatest(SAGA_ACTIONS.USER.SIGNUP, userSaga.signup),
    takeLatest(SAGA_ACTIONS.USER.UPDATE_USER_PLATFORM, userSaga.updateUserPlatform),
    takeLatest(SAGA_ACTIONS.USER.VERIFY_EMAIL, userSaga.verifyEmail),
    takeLatest(SAGA_ACTIONS.USER.UPDATE_ARR_USER_PASSWORD, userSaga.updateArrUserPassword),
    takeLatest(SAGA_ACTIONS.USER.GET_LOGIN_USER, userSaga.getloginuser),
    takeLatest(SAGA_ACTIONS.USER.FORGOT_PASSWORD, userSaga.forgotPassword),
    takeLatest(SAGA_ACTIONS.USER.RESET_PASSWORD, userSaga.resetPassword),

    takeEvery(SAGA_ACTIONS.SALESFORCE.GET_PLATFORM_OBJECT, platformSaga.getPlatformObject),
    takeEvery(SAGA_ACTIONS.SALESFORCE.GET_PLATFORM_OBJECT_FIELD, platformSaga.getPlatformObjectField),
    takeLatest(SAGA_ACTIONS.SALESFORCE.SAVE_PLATFORM_OBJECT_FIELD, platformSaga.savePlatformObjectField),
    takeEvery(SAGA_ACTIONS.SALESFORCE.GET_ALL_ACCOUNT, platformSaga.getAllAccount),
    takeEvery(SAGA_ACTIONS.SALESFORCE.GET_ALL_PRODUCT, platformSaga.getAllProduct),
    takeEvery(SAGA_ACTIONS.SALESFORCE.GET_ALL_CONTACT, platformSaga.getAllContact),
    takeEvery(SAGA_ACTIONS.SALESFORCE.GET_ALL_OPPORTUNITY, platformSaga.getAllOpportunity),
    takeEvery(SAGA_ACTIONS.SALESFORCE.GET_ALL_CONTRACT, platformSaga.getAllContract),
    takeEvery(SAGA_ACTIONS.SALESFORCE.GET_ALL_PRICEBOOK, platformSaga.getAllPriceBook),
    takeEvery(SAGA_ACTIONS.SALESFORCE.GET_ALL_QUOTE, platformSaga.getAllQuote),
    takeEvery(SAGA_ACTIONS.SALESFORCE.GET_QUOTE_PDF, platformSaga.getQuotePdf),
    takeLatest(SAGA_ACTIONS.SALESFORCE.MANAGE_ACCOUNT, platformSaga.manageAccount),
    takeLatest(SAGA_ACTIONS.SALESFORCE.MANAGE_PRODUCT, platformSaga.manageProduct),
    takeLatest(SAGA_ACTIONS.SALESFORCE.MANAGE_OPPORTUNITY, platformSaga.manageOpportunity),
    takeLatest(SAGA_ACTIONS.SALESFORCE.MANAGE_QUOTE, platformSaga.manageQuote),
    takeLatest(SAGA_ACTIONS.SALESFORCE.MANAGE_PRICEBOOK, platformSaga.managePriceBook),
    takeEvery(SAGA_ACTIONS.SALESFORCE.GET_ALL_PRICEBOOK_ENTITIES, platformSaga.getAllPriceBookEntities),
    takeLatest(SAGA_ACTIONS.SALESFORCE.MANAGE_PRICEBOOK_ENTITIES, platformSaga.managePriceBookEntities),
    takeEvery(SAGA_ACTIONS.SALESFORCE.GET_ALL_QUOTE_LINEITEMS, platformSaga.getAllQuoteLineItem),
    takeEvery(SAGA_ACTIONS.SALESFORCE.GET_ALL_ACCOUNT_COMMENT, platformSaga.getAllAccountComment),
    takeLatest(SAGA_ACTIONS.SALESFORCE.MANAGE_ACCOUNT_COMMENT, platformSaga.manageAccountComment),
    takeLatest(SAGA_ACTIONS.SALESFORCE.MANAGE_QUOTE_LINEITEMS, platformSaga.manageQuoteLineItem),
    takeLatest(SAGA_ACTIONS.SALESFORCE.MANAGE_SINGLE_QUOTE_LINEITEMS, platformSaga.manageSingleQuoteLineItem),
    takeEvery(SAGA_ACTIONS.SALESFORCE.GET_ALL_ARR_USER, platformSaga.getAllArrUsers),
    takeLatest(SAGA_ACTIONS.SALESFORCE.MANAGE_ARR_USER, platformSaga.manageArrUser),
    takeEvery(SAGA_ACTIONS.SALESFORCE.GET_ALL_ACCNT_SA_COMMENT, platformSaga.getAllAccountSaComment),
    takeLatest(SAGA_ACTIONS.SALESFORCE.MANAGE_ACCNT_SA_COMMENT, platformSaga.manageAccountSaComment),
    takeEvery(SAGA_ACTIONS.SALESFORCE.GET_RENEWAL_PROCESS, platformSaga.getRenewalProcess),
    takeLatest(SAGA_ACTIONS.SALESFORCE.MANAGE_RENEWAL_PROCESS, platformSaga.manageRenewalProcess),
    takeLatest(SAGA_ACTIONS.SALESFORCE.MANAGE_CONTACT, platformSaga.manageContact),
    takeEvery(SAGA_ACTIONS.SALESFORCE.GET_ACCOUNT_TEAM, platformSaga.getAccountTeam),
    takeLatest(SAGA_ACTIONS.SALESFORCE.MANAGE_ACCOUNT_TEAM, platformSaga.manageAccountTeam),
    takeEvery(SAGA_ACTIONS.SALESFORCE.GET_CUSTOMER_TEAM, platformSaga.getCustomerTeam),
    takeLatest(SAGA_ACTIONS.SALESFORCE.MANAGE_CUSTOMER_TEAM, platformSaga.manageCustomerTeam),
    takeLatest(SAGA_ACTIONS.SALESFORCE.DELETE_ARR_USER, platformSaga.arrUserDestroy),
    takeLatest(SAGA_ACTIONS.SALESFORCE.DELETE_ACCOUNT, platformSaga.accountDestroy),
    takeLatest(SAGA_ACTIONS.SALESFORCE.DELETE_ACCOUNT_COMMENT, platformSaga.accountCommentDestroy),
    takeLatest(SAGA_ACTIONS.SALESFORCE.DELETE_ACCOUNT_SA_COMMENT, platformSaga.accountSaCommentDestroy),
    takeLatest(SAGA_ACTIONS.SALESFORCE.DELETE_ACCOUNT_TEAM, platformSaga.accoungTeamDestroy),
    takeLatest(SAGA_ACTIONS.SALESFORCE.DELETE_CUSTOMER_TEAM, platformSaga.customerTeamDestroy),
    takeLatest(SAGA_ACTIONS.SALESFORCE.DELETE_PRODUCT, platformSaga.productDestroy),
    takeLatest(SAGA_ACTIONS.SALESFORCE.DELETE_CONTACT, platformSaga.contactDestroy),
    takeLatest(SAGA_ACTIONS.SALESFORCE.DELETE_OPPORTUNITY, platformSaga.opportunityDestroy),
    takeLatest(SAGA_ACTIONS.SALESFORCE.DELETE_PRICEBOOK, platformSaga.pricebookDestroy),
    takeLatest(SAGA_ACTIONS.SALESFORCE.DELETE_PRICEBOOK_ENTITY, platformSaga.pricebookEntityDestroy),
    takeLatest(SAGA_ACTIONS.SALESFORCE.DELETE_QUOTE, platformSaga.quoteDestroy),
    takeLatest(SAGA_ACTIONS.SALESFORCE.DELETE_QUOTE_LINEITEMS, platformSaga.quoteLineitemDestroy),
    takeLatest(SAGA_ACTIONS.SALESFORCE.CLONE_QUOTE, platformSaga.cloneQuote),
    takeLatest(SAGA_ACTIONS.SALESFORCE.CREATE_QUOTE_FROM_CONTRACT, platformSaga.createQuoteFromContract),
    takeEvery(SAGA_ACTIONS.SALESFORCE.GET_REVENUE_DETAILS, platformSaga.getRevenueDetails),
    takeLatest(SAGA_ACTIONS.SALESFORCE.MANAGE_CONTRACT, platformSaga.manageContract),
    takeEvery(SAGA_ACTIONS.SALESFORCE.GET_LATE_RENEWALS, platformSaga.getLateRenewals),
    takeEvery(SAGA_ACTIONS.SALESFORCE.GET_UPSELLS, platformSaga.getUpsells),
    takeEvery(SAGA_ACTIONS.SALESFORCE.GET_CONTRACT_PRODUCT, platformSaga.getContractProduct),
    takeEvery(SAGA_ACTIONS.SALESFORCE.GET_REVENUE_MAP_DETAILS, platformSaga.getRevenueMapDetails),
    takeEvery(SAGA_ACTIONS.SALESFORCE.GET_CONTRACT_LEVEL_LIST, platformSaga.getContractLevelList),
    takeEvery(SAGA_ACTIONS.SALESFORCE.GET_CONTRACT_TIME_LEVEL_LIST, platformSaga.getContractTimeLevelList),
    takeLatest(SAGA_ACTIONS.SALESFORCE.MANAGE_CONTRACT_RENEWVAL_EVENT, platformSaga.manageContractReNewvalEvent),
    takeEvery(SAGA_ACTIONS.SALESFORCE.GET_CONTRACT_REASON_COC, platformSaga.getContractReasonCOC),
    takeLatest(SAGA_ACTIONS.SALESFORCE.MANAGE_RENEWAL_NOTES, platformSaga.manageRenewalNotes),
    takeEvery(SAGA_ACTIONS.SALESFORCE.GET_RENEWAL_NOTES, platformSaga.getRenewalNotes),
    takeEvery(SAGA_ACTIONS.SALESFORCE.GET_CONTRACT_ACCOUNT_TEAM, platformSaga.getContractAccountTeam),
    takeLatest(SAGA_ACTIONS.SALESFORCE.DELETE_CONTRACT_ACCOUNT_TEAM, platformSaga.accoungContractTeamDestroy),
    takeLatest(SAGA_ACTIONS.SALESFORCE.MANAGE_CONTRACT_ACCOUNT_TEAM, platformSaga.manageContractAccountTeam),
    takeEvery(SAGA_ACTIONS.SALESFORCE.GET_ALL_CUSTOMER_CONTACT, platformSaga.getAllCustomerContact),
    takeLatest(SAGA_ACTIONS.SALESFORCE.DELETE_CUSTOMER_CONTACT, platformSaga.contactCustomerDestroy),
    takeLatest(SAGA_ACTIONS.SALESFORCE.MANAGE_CUSTOMER_CONTACT, platformSaga.manageCustomerContact),
    takeEvery(SAGA_ACTIONS.SALESFORCE.GET_QUOTES_SUMMERY, platformSaga.getQuotesSummery),
    takeLatest(SAGA_ACTIONS.SALESFORCE.MANAGE_CONTRACT_CUSTOMER_TEAM, platformSaga.manageContractCustomerTeam),
    takeEvery(SAGA_ACTIONS.SALESFORCE.GET_CROSSSELLS, platformSaga.getCrossSells),
    takeEvery(SAGA_ACTIONS.SALESFORCE.GET_CONTRACT_PDF, platformSaga.getContractPdf),
    takeEvery(SAGA_ACTIONS.SALESFORCE.GET_FORECAST_SUMMARY, platformSaga.getForeCastSummary),
    takeEvery(SAGA_ACTIONS.SALESFORCE.UPLOAD_CONTRACT_DOC, platformSaga.uploadContractDoc),
    takeEvery(SAGA_ACTIONS.SALESFORCE.MERGE_CONTRACT, platformSaga.mergeContract),
    takeEvery(SAGA_ACTIONS.SALESFORCE.GET_ARR_PAGE_LIST, platformSaga.getArrPageList),
    takeEvery(SAGA_ACTIONS.SALESFORCE.GET_ACCOUNT_PARTNER, platformSaga.getAccountPartner),
    takeEvery(SAGA_ACTIONS.SALESFORCE.MANAGE_ACCOUNT_PARTNER, platformSaga.manageAccountPartner),
    takeEvery(SAGA_ACTIONS.SALESFORCE.DELETE_ACCOUNT_PARTNER, platformSaga.deleteAccountPartner),
    takeLatest(SAGA_ACTIONS.SALESFORCE.FORCAST_BULK_UPDATE, platformSaga.forecastBlukUpdate),
    takeLatest(SAGA_ACTIONS.SALESFORCE.GET_APPROVAL_RULES_FOR_QUOTE, platformSaga.getAllApprovalRulesForQuote),
    takeLatest(SAGA_ACTIONS.SALESFORCE.ADD_APPROVAL_FOR_QUOTE, platformSaga.addApprovalForQuote),
    takeLatest(SAGA_ACTIONS.SALESFORCE.ADD_QUOTE_SIGNATURE, platformSaga.addQuoteSignature),
    takeLatest(SAGA_ACTIONS.SALESFORCE.UPDATE_CONTRACT_ENTITLEMENT, platformSaga.updateContractEntitlement),

    takeEvery(SAGA_ACTIONS.ZENDESK.GET_ZENDESK_OPEN_TICKET, zendeskSaga.getZendeskOpenTicket),
    takeEvery(SAGA_ACTIONS.ZENDESK.GET_ZENDESK_TICKET_BY_ACCOUNT, zendeskSaga.getZendeskTicketByAccount),
  ]);
}