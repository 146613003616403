import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import Footer from '../components/commonComponents/Footer'
import Header from '../components/commonComponents/Header'
import LeftPanel from '../components/commonComponents/LeftPanel'
import { URLS } from '../_config'
import * as Yup from "yup";
import { useForm, Controller } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import Select from 'react-select'
import { usePlatformApi } from '../_common/hooks/api/PlatformApiHook'
import { useAppLoader } from '../_common/hooks/common/appLoaderHook'
import { handleKeyPressNumber } from '../_common/functions'
import { toast } from 'react-hot-toast'
import InputMask from 'react-input-mask'
import { useCommonApi } from '../_common/hooks/api/common/commonApiHook'
import CreatableSelect from 'react-select/creatable'
import AddRegionModal from '../components/modals/addRegion.modal'
import { AiFillPlusSquare, AiOutlinePlusSquare } from 'react-icons/ai'
import { countriesData, employeesData, industriesData, typesData } from '../_common/staticData'
import AddUserModal from '../components/modals/addUser.modal'


interface AddAccountFormValues {
    accntName: string;
    type: any;
    website: string;
    description: string;
    email: string;
    employees: any;
    phone: string;
    industry: any;
    billingStreet: string;
    billingCity: string;
    billingState: string;
    billingZip: string;
    billingCountry: any;
    shippingStreet: string;
    shippingCity: string;
    shippingState: string;
    shippingZip: string;
    shippingCountry: any;
    businessChannel: string;
    accountManager: any;
    region: string;
}

const validationSchema = Yup.object().shape({
    accntName: Yup.string().required("Account Name is required"),
    email: Yup.string().nullable().email('Email is not valid').required("Email is required"),
});


const AddAccount = () => {

    const platformApi = usePlatformApi()
    const commonApi = useCommonApi()
    const navigate = useNavigate()
    const [searchParams] = useSearchParams();
    const id: any = searchParams.get("id")

    const { showLoader, hideLoader } = useAppLoader()
    const [employeesOptions, setEmployeesOptions] = useState<any>(employeesData)
    const [industriesOptions, setIndustriesOptions] = useState<any>(industriesData)
    const [countriesOptions, setCountriesOptions] = useState<any>(countriesData)
    const [typeOptions, setTypeOptions] = useState<any>(typesData)
    const [accountData, setAccountData] = useState<any>(null)
    const [sameAsBilling, setSameAsBilling] = useState<boolean>(false)
    const [accountManagerOptions, setAccountManagerOption] = useState<any>(null)
    const [regionOptions, setRegionOption] = useState<any>(null)
    const [isCreateLoading, setIsCreateLoading] = useState<boolean>(false)
    const [showAddRegionModal, setShowAddRegionModal] = useState<boolean>(false)
    const [showAddUserModal, setShowAddUserModal] = useState<boolean>(false)
    const [arrPageData, setArrPageData] = useState<any>(null)

    const { control, formState: { errors }, handleSubmit, setValue, watch, reset } = useForm<AddAccountFormValues>({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            accntName: "",
            type: "",
            website: undefined,
            description: "",
            email: "",
            employees: "",
            phone: undefined,
            industry: "",
            billingStreet: "",
            billingCity: "",
            billingState: "",
            billingZip: "",
            billingCountry: "",
            shippingStreet: "",
            shippingCity: "",
            shippingState: "",
            shippingZip: "",
            shippingCountry: "",
            businessChannel: "",
            region: "",
        },
    });

    const bStreet = watch('billingStreet')
    const bCity = watch('billingCity')
    const bState = watch('billingState')
    const bZip = watch('billingZip')
    const bCountry = watch('billingCountry')

    const getAccountData = () => {
        showLoader();
        platformApi.getAllAccount({ AccountId: id },
            (message: string, resp: any) => {
                console.log(resp);
                setAccountData(resp.data.list.data[0]);
                hideLoader();
            }, (message: string) => {
                hideLoader();
            })
    }

    const getAccountManagerList = () => {
        showLoader();
        platformApi.getAllArrUsers({},
            (message: string, resp: any) => {
                // console.log(resp);
                let tempArr = resp.data.list.data
                if (tempArr && tempArr.length > 0) {
                    setAccountManagerOption(tempArr.map((item: any) => {
                        item.label = item.name;
                        item.value = item.id;
                        return item
                    }));
                }
                hideLoader();
            }, (message: string) => {
                hideLoader();
            })
    }

    const getRegionList = () => {
        showLoader();
        commonApi.getAllRegions({},
            (message: string, resp: any) => {
                // console.log(resp);
                let tempArr = resp.data.list.data
                if (tempArr && tempArr.length > 0) {
                    setRegionOption(tempArr.map((item: any) => {
                        item.label = item.region_name;
                        item.value = item.Region;
                        return item
                    }));
                }
                hideLoader();
            }, (message: string) => {
                hideLoader();
            })
    }

    const customOptionUI = (props: any) => {
        const { innerProps, innerRef, data } = props;
        return (
            <div ref={innerRef} {...innerProps} className="cstm-option m-2 cursor-pointer">
                {data?.name}
                {/* <span className='ml-2'>({data?.licencetype})</span> */}
            </div>
        );
    }

    const onSubmit = (data: any) => {
        console.log(data);
        let params: any = {
            "BillingCity": data?.billingCity,
            "BillingCountry": data?.billingCountry?.value,
            "BillingPostalCode": data?.billingZip,
            "BillingState": data?.billingState,
            "BillingStreet": data?.billingStreet,
            "Description": data?.description,
            "Industry": data?.industry?.value,
            "Name": data?.accntName,
            "NumberOfEmployees": data?.employees?.value,
            "Phone": data?.phone,
            "ShippingCity": data?.shippingCity,
            "ShippingCountry": data?.shippingCountry?.value,
            "ShippingPostalCode": data?.shippingZip,
            "ShippingState": data?.shippingState,
            "ShippingStreet": data?.shippingStreet,
            "Type": data?.type.value,
            "Website": data?.website,
            "Email": data?.email,
            "AccountManager": data?.accountManager?.value,
            "BusinessChannel": data?.businessChannel,
            "Region": data?.region?.value,
        }
        if (accountData && accountData.id) {
            params["id"] = accountData.id;
        }
        // console.log(params)
        showLoader()
        platformApi.manageAccount(params, (message: string, resp: any) => {
            // console.log(message, resp)
            toast.success(message)
            navigate(`/account-view/${resp?.data?.details?.AccountId}`)
            reset()
            hideLoader()
        }, (message: string) => {
            // console.log(message)
            toast.error(message)
            hideLoader()
        })
    }

    const closeAddRegionModal = () => {
        setShowAddRegionModal(false)
    }

    const getArrPageList = () => {
        platformApi.getArrPageList({},
            (message: string, resp: any) => {
                // console.log(resp);
                setArrPageData(resp.data);
            }, (message: string) => {
            })
    }

    const closeAddUserModal = () => {
        setShowAddUserModal(false)
        getRegionList()
    }

    useEffect(() => {
        if (id) {
            getAccountData()
        }
    }, [id])

    useEffect(() => {
        getAccountManagerList()
        getRegionList()
        getArrPageList()
    }, [])

    useEffect(() => {
        if (accountData) {
            setValue('accntName', accountData?.Name)
            setValue('email', accountData?.Email)
            setValue('type', typesData?.find((item: any) => item.value === accountData?.Type) || '')
            setValue('phone', accountData?.Phone)
            setValue('website', accountData?.Website)
            setValue('industry', industriesData?.find((item: any) => item.value === accountData?.Industry) || '')
            setValue('employees', employeesData?.find((item: any) => item.value === accountData?.NumberOfEmployees) || '')
            setValue('description', accountData?.Description)
            setValue('billingStreet', accountData?.BillingStreet)
            setValue('billingCity', accountData?.BillingCity)
            setValue('billingState', accountData?.BillingState)
            setValue('billingZip', accountData?.BillingPostalCode)
            setValue('billingCountry', countriesData?.find((item: any) => item.value === accountData?.BillingCountry) || '')
            // setValue('billingCountry', accountData?.BillingCountry)
            setValue('shippingStreet', accountData?.ShippingStreet)
            setValue('shippingCity', accountData?.ShippingCity)
            setValue('shippingState', accountData?.ShippingState)
            setValue('shippingZip', accountData?.ShippingPostalCode)
            setValue('shippingCountry', countriesData?.find((item: any) => item.value === accountData?.ShippingCountry) || '')
            // setValue('shippingCountry', accountData?.ShippingCountry)
            setValue('businessChannel', accountData?.BusinessChannel)
            setValue('accountManager', accountManagerOptions?.find((item: any) => item.value == accountData?.AccountManager) || '')
            setValue('region', regionOptions?.find((item: any) => item.value === accountData?.Region) || '')
        }
    }, [accountData, accountManagerOptions, regionOptions])

    useEffect(() => {
        if (sameAsBilling) {
            if (bStreet) { setValue('shippingStreet', bStreet) }
            if (bCity) { setValue('shippingCity', bCity) }
            if (bState) { setValue('shippingState', bState) }
            if (bZip) { setValue('shippingZip', bZip) }
            if (bCountry) { setValue('shippingCountry', bCountry) }
        } else {
            setValue('shippingStreet', "")
            setValue('shippingCity', "")
            setValue('shippingState', "")
            setValue('shippingZip', "")
            setValue('shippingCountry', "")
        }
    }, [sameAsBilling])

    return (
        <React.Fragment>
            <AddRegionModal
                show={showAddRegionModal}
                close={closeAddRegionModal}
                isEdit={false}
                regionListCallbackFunc={getRegionList}
            />
            <AddUserModal
                show={showAddUserModal}
                arrPageData={arrPageData}
                close={closeAddUserModal}
                isEdit={false}
                callbackFuncs={[getAccountManagerList]}
            />
            <div id="layout-wrapper">
                <Header />
                <LeftPanel />
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            {/* start page title */}
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-flex align-items-center justify-content-between">
                                        <h4 className="mb-0 font-size-18">New Account</h4>
                                        <div className="page-title-right">
                                            <ol className="breadcrumb m-0">
                                                <li className="breadcrumb-item">
                                                    {accountData && accountData.AccountId ?
                                                        <Link to={`/account-view/${accountData.AccountId}`}>Account View</Link>
                                                        :
                                                        <Link to={URLS.ACCOUNT_LIST}>Account List</Link>
                                                    }
                                                </li>
                                                <li className="breadcrumb-item active">Add Account</li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* end page title */}
                            <div className="row">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="d-flex flex-wrap">
                                                    <h5 className="card-title mr-2">Account Information</h5>
                                                </div>
                                                <div className="row">
                                                    <div className="form-group col-6">
                                                        <label htmlFor="accntName">Account name <span className='text-danger'>*</span></label>
                                                        <Controller
                                                            name={"accntName"}
                                                            control={control}
                                                            render={({ field: { value, onChange, onBlur, name, ref } }) => (
                                                                <>
                                                                    <input
                                                                        type="text"
                                                                        className={"form-control " + (errors?.accntName ? ' border-danger' : '')}
                                                                        id="accntName"
                                                                        name={name}
                                                                        onChange={onChange}
                                                                        value={value}
                                                                        onBlur={onBlur}
                                                                        ref={ref}
                                                                        placeholder="Enter account name"
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                        {errors && errors.accntName &&
                                                            <span className='text-danger'>{errors?.accntName?.message}</span>
                                                        }
                                                    </div>
                                                    <div className="form-group col-6">
                                                        <label htmlFor="email">Email <span className='text-danger'>*</span></label>
                                                        <Controller
                                                            name={"email"}
                                                            control={control}
                                                            render={({ field: { value, onChange, onBlur, name, ref } }) => (
                                                                <>
                                                                    <input
                                                                        type="text"
                                                                        className={"form-control " + (errors?.email ? ' border-danger' : '')}
                                                                        id="email"
                                                                        name={name}
                                                                        onChange={onChange}
                                                                        value={value}
                                                                        onBlur={onBlur}
                                                                        ref={ref}
                                                                        placeholder="Enter email"
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                        {errors && errors.email &&
                                                            <span className='text-danger'>{errors?.email?.message}</span>
                                                        }
                                                    </div>
                                                    <div className="form-group col-6">
                                                        <label htmlFor="type">Type</label>
                                                        <Controller
                                                            name={"type"}
                                                            control={control}
                                                            render={({ field: { value, onChange, onBlur, name } }) => (
                                                                <>
                                                                    <Select
                                                                        className={"select-box " + (errors?.type ? ' border-danger' : '')}
                                                                        options={typeOptions ? typeOptions : []}
                                                                        placeholder={"Select type"}
                                                                        onChange={onChange}
                                                                        value={value}
                                                                        defaultValue={value}
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                        {errors && errors.type &&
                                                            <span className='text-danger'>Type is required</span>
                                                        }
                                                    </div>
                                                    <div className="form-group col-6">
                                                        <label htmlFor="phone">Phone</label>
                                                        <Controller
                                                            name={"phone"}
                                                            control={control}
                                                            render={({ field: { value, onChange, onBlur, name } }) => (
                                                                <>
                                                                    <InputMask
                                                                        mask="(999) 999-9999"
                                                                        maskChar="_"
                                                                        className={"form-control " + (errors?.phone ? ' border-danger' : '')}
                                                                        id="phone"
                                                                        name={name}
                                                                        onChange={onChange}
                                                                        value={value}
                                                                        onBlur={onBlur}
                                                                        placeholder="Enter phone"
                                                                        onKeyPress={(event: any) => handleKeyPressNumber(event)}
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                        {errors && errors.phone &&
                                                            <span className='text-danger'>{errors?.phone?.message}</span>
                                                        }
                                                    </div>
                                                    <div className="form-group col-6">
                                                        <label htmlFor="website">Website</label>
                                                        <Controller
                                                            name={"website"}
                                                            control={control}
                                                            render={({ field: { value, onChange, onBlur, name } }) => (
                                                                <>
                                                                    <input
                                                                        type="text"
                                                                        className={"form-control " + (errors?.website ? ' border-danger' : '')}
                                                                        id="website"
                                                                        name={name}
                                                                        onChange={onChange}
                                                                        value={value}
                                                                        onBlur={onBlur}
                                                                        placeholder="Enter website"
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                        {errors && errors.website &&
                                                            <span className='text-danger'>{errors?.website?.message}</span>
                                                        }
                                                    </div>
                                                    <div className="form-group col-6">
                                                        <label htmlFor="industry">Industry</label>
                                                        <Controller
                                                            name={"industry"}
                                                            control={control}
                                                            render={({ field: { value, onChange, onBlur, name } }) => (
                                                                <>
                                                                    <Select
                                                                        className={"select-box " + (errors?.industry ? ' border-danger' : '')}
                                                                        options={industriesOptions ? industriesOptions : []}
                                                                        placeholder={"Select industry"}
                                                                        onChange={onChange}
                                                                        value={value}
                                                                        defaultValue={value}
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                        {errors && errors.industry &&
                                                            <span className='text-danger'>please select on industry</span>
                                                        }
                                                    </div>
                                                    <div className="form-group col-6">
                                                        <label htmlFor="employees">Employees</label>
                                                        <Controller
                                                            name="employees"
                                                            control={control}
                                                            render={({ field: { value, onChange, onBlur, name } }) => (
                                                                <>
                                                                    <Select
                                                                        className={"select-box " + (errors?.employees ? ' border-danger' : '')}
                                                                        options={employeesOptions ? employeesOptions : []}
                                                                        placeholder={"Select employees"}
                                                                        onChange={onChange}
                                                                        value={value}
                                                                        defaultValue={value}
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                        {errors && errors.employees &&
                                                            <span className='text-danger'>Employees is required</span>
                                                        }
                                                    </div>
                                                    <div className="form-group col-6">
                                                        <label htmlFor="description">Description</label>
                                                        <Controller
                                                            name={"description"}
                                                            control={control}
                                                            render={({ field: { value, onChange, onBlur, name } }) => (
                                                                <>
                                                                    <textarea
                                                                        // type="text"
                                                                        className={"form-control " + (errors?.description ? ' border-danger' : '')}
                                                                        id="description"
                                                                        name={name}
                                                                        onChange={onChange}
                                                                        value={value}
                                                                        onBlur={onBlur}
                                                                        placeholder="Enter descriptiom"
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                        {errors && errors.description &&
                                                            <span className='text-danger'>{errors?.description?.message}</span>
                                                        }
                                                    </div>
                                                    <div className="form-group col-6">
                                                        <label htmlFor="businessChannel">Business channel
                                                            {/* <span className='text-danger'>*</span> */}
                                                        </label>
                                                        <Controller
                                                            name={"businessChannel"}
                                                            control={control}
                                                            render={({ field: { value, onChange, onBlur, name, ref } }) => (
                                                                <>
                                                                    <input
                                                                        type="text"
                                                                        className={"form-control " + (errors?.businessChannel ? ' border-danger' : '')}
                                                                        id="businessChannel"
                                                                        name={name}
                                                                        onChange={onChange}
                                                                        value={value}
                                                                        onBlur={onBlur}
                                                                        ref={ref}
                                                                        placeholder="Enter business channel"
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                        {/* {errors && errors.businessChannel &&
                                                            <span className='text-danger'>{errors?.businessChannel?.message}</span>
                                                        } */}
                                                    </div>
                                                    <div className="form-group col-6">
                                                        <label htmlFor="accountManager">Account manager
                                                            {/* <span className='text-danger'>*</span> */}
                                                        </label>
                                                        <div className='d-flex align-items-center gap-10 add-new-fld-row'>
                                                            <Controller
                                                                name={"accountManager"}
                                                                control={control}
                                                                render={({ field: { value, onChange, onBlur, name, ref } }) => (
                                                                    <>
                                                                        <Select
                                                                            className={"select-box" + (errors?.accountManager ? ' border-danger' : '')}
                                                                            id="accountManager"
                                                                            components={{ Option: customOptionUI }}
                                                                            options={accountManagerOptions ? accountManagerOptions : []}
                                                                            placeholder={"Select account manager"}
                                                                            onChange={onChange}
                                                                            value={value}
                                                                            defaultValue={value}
                                                                        />
                                                                    </>
                                                                )}
                                                            />
                                                            <span
                                                                data-toggle="tooltip"
                                                                data-placement="top"
                                                                title={"Add Account Manager"}
                                                                onClick={() => setShowAddUserModal(true)}
                                                            >
                                                                <AiOutlinePlusSquare className='cursor-pointer' />
                                                            </span>
                                                        </div>
                                                        {/* {errors && errors.accountManager &&
                                                            <span className='text-danger'>{errors?.accountManager?.message}</span>
                                                        } */}
                                                    </div>
                                                    <div className="form-group col-6">
                                                        <div className='d-flex justify-content-between'>
                                                            <label htmlFor="region">Region
                                                                {/* <span className='text-danger'>*</span> */}
                                                            </label>
                                                        </div>
                                                        <div className='d-flex align-items-center gap-10 add-new-fld-row'>
                                                            <Controller
                                                                name={"region"}
                                                                control={control}
                                                                render={({ field: { value, onChange, onBlur, name, ref } }) => (
                                                                    <>
                                                                        <Select
                                                                            className={"select-box" + (errors?.region ? ' border-danger' : '')}
                                                                            id="region"
                                                                            options={regionOptions ? regionOptions : []}
                                                                            placeholder={"Select region"}
                                                                            onChange={onChange}
                                                                            value={value}
                                                                            defaultValue={value}
                                                                            isClearable={true}
                                                                        // isDisabled={isCreateLoading}
                                                                        // isLoading={isCreateLoading}
                                                                        // onCreateOption={handleCreate}
                                                                        />
                                                                    </>
                                                                )}
                                                            />
                                                            <span
                                                                data-toggle="tooltip"
                                                                data-placement="top"
                                                                title={"Add Region"}
                                                                onClick={() => setShowAddRegionModal(true)}
                                                            >
                                                                <AiOutlinePlusSquare className='cursor-pointer' />
                                                            </span>
                                                        </div>
                                                        {/* {errors && errors.region &&
                                                            <span className='text-danger'>{errors?.region?.message}</span>
                                                        } */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="d-flex flex-wrap justify-content-between">
                                                    <h5 className="card-title mr-2">Address Information</h5>
                                                    <div className="align-items-center col-6 d-flex form-group gap-10">
                                                        <input
                                                            type="checkbox"
                                                            id="sameBilling"
                                                            defaultChecked={sameAsBilling}
                                                            onClick={(e: any) => setSameAsBilling(e.target.checked)}
                                                        />
                                                        <label htmlFor="sameBilling" className='mb-0'>same as billing</label>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="form-group col-6">
                                                        <label htmlFor="billingStreet">Billing Street</label>
                                                        <Controller
                                                            name={"billingStreet"}
                                                            control={control}
                                                            render={({ field: { value, onChange, onBlur, name } }) => (
                                                                <>
                                                                    <input
                                                                        type="text"
                                                                        className={"form-control " + (errors?.billingStreet ? ' border-danger' : '')}
                                                                        id="billingStreet"
                                                                        name={name}
                                                                        onChange={onChange}
                                                                        value={value}
                                                                        onBlur={onBlur}
                                                                        placeholder="Enter billing street"
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                        {errors && errors.billingStreet &&
                                                            <span className='text-danger'>{errors?.billingStreet?.message}</span>
                                                        }
                                                    </div>
                                                    <div className="form-group col-6">
                                                        <label htmlFor="shippingStreet">Shiping Street</label>
                                                        <Controller
                                                            name={"shippingStreet"}
                                                            control={control}
                                                            render={({ field: { value, onChange, onBlur, name } }) => (
                                                                <>
                                                                    <input
                                                                        type="text"
                                                                        className={"form-control " + (errors?.shippingStreet ? ' border-danger' : '')}
                                                                        id="shippingStreet"
                                                                        name={name}
                                                                        onChange={onChange}
                                                                        value={value}
                                                                        onBlur={onBlur}
                                                                        disabled={sameAsBilling}
                                                                        placeholder="Enter shipping street"
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                        {errors && errors.shippingStreet &&
                                                            <span className='text-danger'>{errors?.shippingStreet?.message}</span>
                                                        }
                                                    </div>
                                                    <div className="form-group col-6">
                                                        <label htmlFor="billingCity">Billing City</label>
                                                        <Controller
                                                            name={"billingCity"}
                                                            control={control}
                                                            render={({ field: { value, onChange, onBlur, name } }) => (
                                                                <>
                                                                    <input
                                                                        type="text"
                                                                        className={"form-control " + (errors?.billingCity ? ' border-danger' : '')}
                                                                        id="billingCity"
                                                                        name={name}
                                                                        onChange={onChange}
                                                                        value={value}
                                                                        onBlur={onBlur}
                                                                        placeholder="Enter billing city"
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                        {errors && errors.billingCity &&
                                                            <span className='text-danger'>{errors?.billingCity?.message}</span>
                                                        }
                                                    </div>
                                                    <div className="form-group col-6">
                                                        <label htmlFor="shippingCity">Shipping City</label>
                                                        <Controller
                                                            name={"shippingCity"}
                                                            control={control}
                                                            render={({ field: { value, onChange, onBlur, name } }) => (
                                                                <>
                                                                    <input
                                                                        type="text"
                                                                        className={"form-control " + (errors?.shippingCity ? ' border-danger' : '')}
                                                                        id="shippingCity"
                                                                        name={name}
                                                                        onChange={onChange}
                                                                        value={value}
                                                                        onBlur={onBlur}
                                                                        disabled={sameAsBilling}
                                                                        placeholder="Enter shipping city"
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                        {errors && errors.shippingCity &&
                                                            <span className='text-danger'>{errors?.shippingCity?.message}</span>
                                                        }
                                                    </div>
                                                    <div className="form-group col-6">
                                                        <label htmlFor="billingState">Billing State/Province</label>
                                                        <Controller
                                                            name={"billingState"}
                                                            control={control}
                                                            render={({ field: { value, onChange, onBlur, name } }) => (
                                                                <>
                                                                    <input
                                                                        type="text"
                                                                        className={"form-control " + (errors?.billingState ? ' border-danger' : '')}
                                                                        id="billingState"
                                                                        name={name}
                                                                        onChange={onChange}
                                                                        value={value}
                                                                        onBlur={onBlur}
                                                                        placeholder="Enter billing state"
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                        {errors && errors.billingState &&
                                                            <span className='text-danger'>{errors?.billingState?.message}</span>
                                                        }
                                                    </div>
                                                    <div className="form-group col-6">
                                                        <label htmlFor="shippingState">Shipping State/Province</label>
                                                        <Controller
                                                            name={"shippingState"}
                                                            control={control}
                                                            render={({ field: { value, onChange, onBlur, name } }) => (
                                                                <>
                                                                    <input
                                                                        type="text"
                                                                        className={"form-control " + (errors?.shippingState ? ' border-danger' : '')}
                                                                        id="shippingState"
                                                                        name={name}
                                                                        onChange={onChange}
                                                                        value={value}
                                                                        onBlur={onBlur}
                                                                        disabled={sameAsBilling}
                                                                        placeholder="Enter shipping state"
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                        {errors && errors.shippingState &&
                                                            <span className='text-danger'>{errors?.shippingState?.message}</span>
                                                        }
                                                    </div>
                                                    <div className="form-group col-6">
                                                        <label htmlFor="billingZip">Billing Zip/Postal Code</label>
                                                        <Controller
                                                            name={"billingZip"}
                                                            control={control}
                                                            render={({ field: { value, onChange, onBlur, name } }) => (
                                                                <>
                                                                    <input
                                                                        type="text"
                                                                        className={"form-control " + (errors?.billingZip ? ' border-danger' : '')}
                                                                        id="billingZip"
                                                                        name={name}
                                                                        onChange={onChange}
                                                                        value={value}
                                                                        onBlur={onBlur}
                                                                        placeholder="Enter billing zip"
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                        {errors && errors.billingZip &&
                                                            <span className='text-danger'>{errors?.billingZip?.message}</span>
                                                        }
                                                    </div>
                                                    <div className="form-group col-6">
                                                        <label htmlFor="shippingZip">Shipping Zip/Postal Code</label>
                                                        <Controller
                                                            name={"shippingZip"}
                                                            control={control}
                                                            render={({ field: { value, onChange, onBlur, name } }) => (
                                                                <>
                                                                    <input
                                                                        type="text"
                                                                        className={"form-control " + (errors?.shippingZip ? ' border-danger' : '')}
                                                                        id="shippingZip"
                                                                        name={name}
                                                                        onChange={onChange}
                                                                        value={value}
                                                                        onBlur={onBlur}
                                                                        disabled={sameAsBilling}
                                                                        placeholder="Enter shipping zip"
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                        {errors && errors.shippingZip &&
                                                            <span className='text-danger'>{errors?.shippingZip?.message}</span>
                                                        }
                                                    </div>
                                                    <div className="form-group col-6">
                                                        <label htmlFor="billingCountry">Billing Country</label>
                                                        <Controller
                                                            name={"billingCountry"}
                                                            control={control}
                                                            render={({ field: { value, onChange, onBlur, name } }) => (
                                                                <>
                                                                    {/* <input
                                                                        type="text"
                                                                        className={"form-control " + (errors?.billingCountry ? ' border-danger' : '')}
                                                                        id="billingCountry"
                                                                        name={name}
                                                                        onChange={onChange}
                                                                        value={value}
                                                                        onBlur={onBlur}
                                                                        placeholder="Enter billing country"
                                                                    /> */}
                                                                    <Select
                                                                        className={"select-box " + (errors?.billingCountry ? ' border-danger' : '')}
                                                                        id="billingCountry"
                                                                        options={countriesOptions ? countriesOptions : []}
                                                                        placeholder={"Select country"}
                                                                        onChange={onChange}
                                                                        value={value}
                                                                        defaultValue={value}
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                        {/* {errors && errors.billingCountry &&
                                                            <span className='text-danger'>{errors?.billingCountry?.message}</span>
                                                        } */}
                                                    </div>
                                                    <div className="form-group col-6">
                                                        <label htmlFor="shippingCountry">Shipping Country</label>
                                                        <Controller
                                                            name={"shippingCountry"}
                                                            control={control}
                                                            render={({ field: { value, onChange, onBlur, name } }) => (
                                                                <>
                                                                    {/* <input
                                                                        type="text"
                                                                        className={"form-control " + (errors?.shippingCountry ? ' border-danger' : '')}
                                                                        id="shippingCountry"
                                                                        name={name}
                                                                        onChange={onChange}
                                                                        value={value}
                                                                        onBlur={onBlur}
                                                                        disabled={sameAsBilling}
                                                                        placeholder="Enter shipping country"
                                                                    /> */}
                                                                    <Select
                                                                        className={"select-box " + (errors?.shippingCountry ? ' border-danger' : '')}
                                                                        id="shippingCountry"
                                                                        options={countriesOptions ? countriesOptions : []}
                                                                        placeholder={"Select country"}
                                                                        onChange={onChange}
                                                                        value={value}
                                                                        defaultValue={value}
                                                                        isDisabled={sameAsBilling}
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                        {/* {errors && errors.shippingCountry &&
                                                            <span className='text-danger'>{errors?.shippingCountry?.message}</span>
                                                        } */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className='align-items-center d-flex gap-10 justify-content-center'>
                                                    <button type='submit' className='btn btn-primary'>Save</button>
                                                    <button
                                                        type='button'
                                                        className='btn btn-outline-danger'
                                                        onClick={() => navigate(URLS.ACCOUNT_LIST)}
                                                    >
                                                        Cancel
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </React.Fragment>
    )
}

export default AddAccount