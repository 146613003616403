import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Controller, useForm } from 'react-hook-form';
import { useAppLoader } from '../../_common/hooks/common/appLoaderHook';
import { toast } from 'react-hot-toast';
import { useCommonApi } from '../../_common/hooks/api/common/commonApiHook';
import { handleBlur, handleFocus, handleKeyPressDecimalNumber, handleKeyUpDown, handleWheel } from '../../_common/functions';
import Select from 'react-select';

interface FormValues {
	name: string,
	discount: number,
	tax: any,
	status: boolean
}

const validationSchema = Yup.object().shape({
	name: Yup.string().required("Product family name is required"),
	discount: Yup.number().max(100, 'Discount can not be grater than 100').required("Discount is required"),
	// .test('discount', 'Percentage can not be grater than 100', (value: any) => {
	// 	return +value > 100;
	// }),
	tax: Yup.object().required("Tax for is required"),
});

interface IncomingProps {
	show: boolean;
	close: () => void;
	id?: any
	isEdit: boolean;
	callbackFunc: any;
}

const AddProductFamilyModal = ({ show, close, id, isEdit = false, callbackFunc }: IncomingProps) => {

	const commonApi = useCommonApi();
	const { showLoader, hideLoader } = useAppLoader();

	const [productFamilyData, setProductFamilyData] = useState<any>(null)
	const [taxOptions, setTaxOptions] = useState<any>(null)

	const { control, formState: { errors }, handleSubmit, setValue, reset } = useForm<FormValues>({
		resolver: yupResolver(validationSchema),
		defaultValues: {
			name: "",
			discount: 0.00,
			tax: "",
			status: false
		},
	});

	const getTaxData = () => {
		showLoader();
		commonApi.getAllTax({
			tax: 'Product',
			status: 1
		},
			(message: string, resp: any) => {
				// console.log(resp);
				let tempArr = resp.data.list.data
				if (tempArr && tempArr.length > 0) {
					setTaxOptions(tempArr.map((item: any) => {
						item.label = item.taxname + ` (${item.taxpercent}%)`;
						item.value = item.id;
						return item
					}));
				}
				hideLoader();
			}, (message: string) => {
				hideLoader();
			})
	}

	const getProductFamilyData = () => {
		showLoader();
		commonApi.getAllProductFamily({ id: id },
			(message: string, resp: any) => {
				setProductFamilyData(resp.data.list);
				hideLoader();
			}, (message: string) => {
				hideLoader();
			})
	}

	const onSubmit = (data: any) => {
		// console.log({ data })
		let params: any = {
			name: data?.name,
			discount: data?.discount,
			tax: data?.tax?.value,
			status: data?.status && data?.status === true ? "1" : "0"
		}
		if (isEdit) {
			if (productFamilyData && productFamilyData.id) {
				params["id"] = productFamilyData.id
			}
		}
		// console.log(params)
		showLoader()
		commonApi.manageProductFamily(params, (message: string, resp: any) => {
			// console.log(message, resp)
			toast.success(message)
			reset()
			close()
			hideLoader()
			callbackFunc()
		}, (message: string) => {
			// console.log(message)
			toast.error(message)
			hideLoader()
		})
	}

	const handleClose = () => {
		setProductFamilyData(null)
		close()
		reset()
	}

	useEffect(() => {
		if (show) {
			getTaxData()
		}
	}, [show])

	useEffect(() => {
		if (id) {
			getProductFamilyData()
		}
	}, [id])

	useEffect(() => {
		// console.log(productFamilyData)
		if (productFamilyData) {
			setValue('name', productFamilyData?.name)
			setValue('discount', productFamilyData?.discount ? productFamilyData?.discount : 0.00)
			setValue('tax', taxOptions?.find((item: any) => item.value == productFamilyData?.tax))
			setValue('status', productFamilyData?.status && productFamilyData?.status == "1" ? true : false)
		}
	}, [productFamilyData, taxOptions])

	return (
		<React.Fragment>
			<Modal
				className='salesforce-modal modal-lndscp'
				show={show}
			// onHide={handleClose}
			>
				<div className="modal-header custom-modal-header">
					<button
						type="button"
						className="close"
						data-dismiss="modal"
						aria-label="Close"
						onClick={handleClose}
					>
						<span aria-hidden="true">×</span>
					</button>
				</div>
				<Modal.Body>
					<div className="row">
						<form onSubmit={handleSubmit(onSubmit)} className="w-100" >
							<div className="col-12">
								<div className="card">
									<div className="card-body">
										<div className="d-flex flex-wrap">
											<h5 className="card-title mr-2">Product Family Information</h5>
										</div>
										<div className="row">
											<div className="form-group col-6">
												<label htmlFor="name">Product Family name
													<span className='text-danger'>*</span>
												</label>
												<Controller
													name={"name"}
													control={control}
													render={({ field: { value, onChange, onBlur, name, ref } }) => (
														<>
															<input
																type="text"
																className={"form-control " + (errors?.name ? ' border-danger' : '')}
																id="name"
																name={name}
																onChange={onChange}
																value={value}
																onBlur={onBlur}
																ref={ref}
																placeholder="Enter tax name"
															/>
														</>
													)}
												/>
												{errors && errors.name &&
													<span className='text-danger'>{errors?.name?.message}</span>
												}
											</div>
											<div className="form-group col-6">
												<label htmlFor="discount">Discount (%) <span className='text-danger'>*</span></label>
												<Controller
													name={"discount"}
													control={control}
													render={({ field: { value, onChange, onBlur, name, ref } }) => (
														<>
															<input
																type="number"
																className={"form-control " + (errors?.discount ? ' border-danger' : '')}
																id="discount"
																name={name}
																value={value}
																onChange={onChange}
																onBlur={(e) => { onBlur(); handleBlur(e) }}
																onFocus={(e) => handleFocus(e)}
																onKeyDown={(event: any) => { handleKeyPressDecimalNumber(event); handleKeyUpDown(event) }}
																onWheel={handleWheel}
																step={"0.01"}
																min={0}
																ref={ref}
																placeholder="Enter discount"
															/>
														</>
													)}
												/>
												{errors && errors.discount && ["max", "required"].includes(errors.discount.type) ?
													<span className='text-danger'>{errors?.discount?.message}</span>
													:
													null
												}
												{errors && errors.discount && !["max", "required"].includes(errors.discount.type) &&
													<span className='text-danger'>Discount percent is required</span>
												}
											</div>
											<div className="form-group col-6">
												<label htmlFor="tax">Tax <span className='text-danger'>*</span></label>
												<Controller
													name={"tax"}
													control={control}
													render={({ field: { value, onChange, onBlur, name, ref } }) => (
														<>
															<Select
																className={"select-box" + (errors?.tax ? ' border-danger' : '')}
																id="tax"
																options={taxOptions ? taxOptions : []}
																placeholder={"Select tax"}
																onChange={onChange}
																value={value}
																defaultValue={value}
															/>
														</>
													)}
												/>
												{errors && errors.tax &&
													<span className='text-danger'>Tax is required</span>
												}
											</div>
											<div className="form-group col-6 d-flex flex-column">
												<label htmlFor="status">Status <span className='text-danger'>*</span></label>
												<Controller
													name={"status"}
													control={control}
													render={({ field: { value, onChange, onBlur, name, ref } }) => (
														<div className='cstm-switch'>
															<input
																type="checkbox"
																id={"new_account"}
																className={"form-control"}
																name={name}
																onChange={onChange}
																value={value === true ? "1" : "0"}
																onBlur={onBlur}
																defaultChecked={productFamilyData?.status === "1"}
																checked={value === true}
															/>
															<label htmlFor="new_account" data-on-label="on" data-off-label="off"></label>
														</div>
													)}
												/>
												{errors && errors.status &&
													<span className='text-danger'>{errors?.status?.message}</span>
												}
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="col-12">
								<div className='align-items-center d-flex gap-10 justify-content-center'>
									<button type='submit' className='btn btn-primary'>Save</button>
									<button
										type='button'
										className='btn btn-outline-danger'
										onClick={handleClose}
									>
										Cancel
									</button>
								</div>
							</div>
						</form>
					</div>
				</Modal.Body>
			</Modal>
		</React.Fragment>
	)
}

export default AddProductFamilyModal