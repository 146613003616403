import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import Footer from '../components/commonComponents/Footer'
import Header from '../components/commonComponents/Header'
import LeftPanel from '../components/commonComponents/LeftPanel'
import { URLS } from '../_config'
import * as Yup from "yup";
import { useForm, Controller } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import Select from 'react-select'
import { usePlatformApi } from '../_common/hooks/api/PlatformApiHook'
import { useAppLoader } from '../_common/hooks/common/appLoaderHook'
import { handleKeyPressNumber } from '../_common/functions'
import { toast } from 'react-hot-toast'
import { useUserSelector } from '../_common/hooks/selectors/userSelector'
import InputMask from 'react-input-mask';
import { countriesData, salutationData } from '../_common/staticData'

interface FormValues {
    salutation: any;
    firstName: string;
    middleName: string;
    lastName: string;
    suffix: string;
    accntName: any;
    title: string;
    email: string;
    phone: string;
    mobile: string;
    contactOwner: string;
    reportsTo: string;
    department: string;
    fax: string;
    mailingStreet: string;
    mailingCity: string;
    mailingState: string;
    mailingZip: string;
    mailingCountry: any;
}

const validationSchema = Yup.object().shape({
    accntName: Yup.object().required("Account Name is required"),
    lastName: Yup.string().required("Last Name is required"),
});


const AddContact = () => {

    const platformApi = usePlatformApi()
    const navigate = useNavigate()
    const urlParams = useParams()
    const user: any = useUserSelector()
    const [searchParams] = useSearchParams();
    const id: any = searchParams.get("id")
    console.log('urlParamsurlParams', searchParams.get("type"))
    const getType: any = searchParams ? searchParams.get("type") : null
    const getContractId: any = searchParams ? searchParams.get("ContactId") : null

    const { showLoader, hideLoader } = useAppLoader()
    const [salutationsOptions, setSalutaionOptions] = useState<any>(salutationData)
    const [countriesOptions, setCountriesOptions] = useState<any>(countriesData)
    const [contactData, setContactData] = useState<any>(null)
    const [accountOptions, setAccountOptions] = useState<any>(null)

    const { control, formState: { errors }, handleSubmit, setValue, watch, reset } = useForm<FormValues>({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            salutation: "",
            firstName: "",
            middleName: "",
            lastName: "",
            suffix: "",
            accntName: "",
            title: "",
            email: undefined,
            phone: undefined,
            mobile: undefined,
            contactOwner: "",
            reportsTo: "",
            department: "",
            fax: "",
            mailingStreet: "",
            mailingCity: "",
            mailingState: "",
            mailingZip: "",
            mailingCountry: "",
        },
    });

    const getAccountList = () => {
        showLoader();
        let params: any = {};
        platformApi.getAllAccount(params,
            (message: string, resp: any) => {
                // console.log(resp);
                const tempData = resp.data.list.data
                // const tempData = resp.data.list
                if (tempData && tempData.length > 0) {
                    setAccountOptions(tempData.map((item: any) => {
                        item.value = item.AccountId
                        item.label = item.Name
                        return item
                    }))
                }
                hideLoader();
            }, (message: string) => {
                hideLoader();
            })
    }

    const getContactData = () => {
        platformApi.getAllContact({
            ContactId: id
        },
            (message: string, resp: any) => {
                // console.log(resp);
                setContactData(resp.data.list.data[0]);
                hideLoader();
            }, (message: string) => {
                hideLoader();
            })
    }

    const onSubmit = (data: any) => {
        // console.log(data);
        // console.log('getType', getType)
        if (getType && getType == '"contract"') {
            let params: any = {
                "ContractId": getContractId,
                "Department": data?.department,
                "Email": data?.email,
                "Fax": data?.fax,
                "FirstName": data?.firstName,
                "LastName": data?.lastName,
                "MailingCity": data?.mailingCity,
                "MailingCountry": data?.mailingCountry?.value,
                "MailingPostalCode": data?.mailingZip,
                "MailingState": data?.mailingState,
                "MailingStreet": data?.mailingStreet,
                "MobilePhone": data?.mobile,
                "Phone": data?.phone,
                "ReportsToId": data?.reportsTo,
                "Salutation": data?.salutation?.value,
                "Title": data?.title
            }
            // if (contactData && contactData.id) {
            // params["id"] = getContractId;
            params["ContactId"] = ""
            // }
            // console.log(params)
            showLoader()
            platformApi.manageCustomerContact(params, (message: string, resp: any) => {
                // console.log(message, resp)
                toast.success(message)
                navigate(`/contract-details/${getContractId}`)
                reset()
                hideLoader()
            }, (message: string) => {
                // console.log(message)
                toast.error(message)
                hideLoader()
            })
        }
        else {
            let params: any = {
                "AccountId": data?.accntName?.value,
                "Department": data?.department,
                "Email": data?.email,
                "Fax": data?.fax,
                "FirstName": data?.firstName,
                "LastName": data?.lastName,
                "MailingCity": data?.mailingCity,
                "MailingCountry": data?.mailingCountry?.value,
                "MailingPostalCode": data?.mailingZip,
                "MailingState": data?.mailingState,
                "MailingStreet": data?.mailingStreet,
                "MobilePhone": data?.mobile,
                "Phone": data?.phone,
                "ReportsToId": data?.reportsTo,
                "Salutation": data?.salutation?.value,
                "Title": data?.title
                // "id": data?.as,
                // "Name": data?.as,
                // "OwnerId": data?.as,
                // "AssistantName": data?.as,
                // "AssistantPhone": data?.as,
                // "Birthdate": data?.as,
                // "Description": data?.as,
                // "HomePhone": data?.as,
                // "LeadSource": data?.as,
                // "ContactLevel": data?.as,
                // "OtherCity": data?.as,
                // "OtherCountry": data?.as,
                // "OtherPhone": data?.as,
                // "OtherPostalCode": data?.as,
                // "OtherState": data?.as,
                // "OtherStreet": data?.as,
                // "PhotoUrl": data?.as,
            }
            if (contactData && contactData.id) {
                params["id"] = contactData.id;
            }
            // console.log(params)
            showLoader()
            platformApi.manageContact(params, (message: string, resp: any) => {
                // console.log(message, resp)
                toast.success(message)
                navigate(`/account-view/${urlParams?.id}`)
                reset()
                hideLoader()
            }, (message: string) => {
                // console.log(message)
                toast.error(message)
                hideLoader()
            })
        }
    }

    useEffect(() => {
        if (id) {
            getContactData()
        }
    }, [id])

    useEffect(() => {
        getAccountList()
    }, [])

    useEffect(() => {
        if (user) {
            setValue("contactOwner", user?.first_name + " " + user?.last_name || " ")
        }
    }, [user])

    useEffect(() => {
        console.log('urlParams', urlParams)
        if (urlParams && urlParams.id && accountOptions && accountOptions.length > 0) {
            setValue('accntName', accountOptions?.find((item: any) => item.value === urlParams.id) || '')
        }
    }, [urlParams, accountOptions])

    useEffect(() => {
        // console.log('contactData', contactData)
        if (contactData) {
            setValue('accntName', accountOptions?.find((item: any) => item.value === contactData.AccountId) || '')
            setValue('department', contactData?.Department)
            setValue('phone', contactData?.Phone)
            setValue('mobile', contactData?.MobilePhone)
            setValue('email', contactData?.Email)
            setValue('fax', contactData?.Fax)
            setValue('firstName', contactData?.FirstName)
            setValue('lastName', contactData?.LastName)
            setValue('reportsTo', contactData?.ReportsToId)
            setValue('title', contactData?.Title)
            setValue('salutation', salutationData?.find((item: any) => item.value === contactData?.Salutation) || '')
            setValue('mailingCity', contactData?.MailingCity)
            setValue('mailingCountry', contactData?.MailingCountry)
            setValue('mailingState', contactData?.MailingState)
            setValue('mailingZip', contactData?.MailingPostalCode)
            setValue('mailingCountry', countriesData?.find((item: any) => item.value === contactData?.MailingCountry) || '')
        }
    }, [contactData])

    return (
        <React.Fragment>
            <div id="layout-wrapper">
                <Header />
                <LeftPanel />
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            {/* start page title */}
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-flex align-items-center justify-content-between">
                                        <h4 className="mb-0 font-size-18">New Contact</h4>
                                        <div className="page-title-right">
                                            <ol className="breadcrumb m-0">
                                                <li className="breadcrumb-item">
                                                    <Link to={`/account-view/${urlParams?.id}`}>Account Details</Link>
                                                </li>
                                                <li className="breadcrumb-item active">Add Contact</li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* end page title */}
                            <div className="row">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="d-flex flex-wrap">
                                                    <h5 className="card-title mr-2">Contact Information</h5>
                                                </div>
                                                <div className="row">
                                                    <div className="form-group col-6">
                                                        <label htmlFor="accntName">Salutation</label>
                                                        <Controller
                                                            name={"salutation"}
                                                            control={control}
                                                            render={({ field: { value, onChange, onBlur, name } }) => (
                                                                <>
                                                                    <Select
                                                                        className={"select-box " + (errors?.salutation ? ' border-danger' : '')}
                                                                        id="salutation"
                                                                        options={salutationsOptions ? salutationsOptions : []}
                                                                        placeholder={"Select salutation"}
                                                                        onChange={onChange}
                                                                        value={value}
                                                                        defaultValue={value}
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                        {/* {errors && errors.salutation &&
                                                            <span className='text-danger'>Salutation is required</span>
                                                        } */}
                                                    </div>
                                                    <div className="form-group col-6">
                                                        <label htmlFor="contactOwner">Contact Owner</label>
                                                        <Controller
                                                            name={"contactOwner"}
                                                            control={control}
                                                            render={({ field: { value, onChange, onBlur, name } }) => (
                                                                <>
                                                                    <input
                                                                        type="text"
                                                                        className={"form-control " + (errors?.contactOwner ? ' border-danger' : '')}
                                                                        id="contactOwner"
                                                                        name={name}
                                                                        onChange={onChange}
                                                                        value={value}
                                                                        onBlur={onBlur}
                                                                        placeholder="Enter contact owner name"
                                                                        readOnly
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                        {errors && errors.contactOwner &&
                                                            <span className='text-danger'>{errors?.contactOwner?.message}</span>
                                                        }
                                                    </div>
                                                    <div className="form-group col-6">
                                                        <label htmlFor="firstName">First Name</label>
                                                        <Controller
                                                            name={"firstName"}
                                                            control={control}
                                                            render={({ field: { value, onChange, onBlur, name } }) => (
                                                                <>
                                                                    <input
                                                                        type="text"
                                                                        className={"form-control " + (errors?.firstName ? ' border-danger' : '')}
                                                                        id="firstName"
                                                                        name={name}
                                                                        onChange={onChange}
                                                                        value={value}
                                                                        onBlur={onBlur}
                                                                        placeholder="Enter contact owner name"
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                        {errors && errors.firstName &&
                                                            <span className='text-danger'>{errors?.firstName?.message}</span>
                                                        }
                                                    </div>
                                                    <div className="form-group col-6">
                                                        <label htmlFor="reportsTo">Reports To</label>
                                                        <Controller
                                                            name="reportsTo"
                                                            control={control}
                                                            render={({ field: { value, onChange, onBlur, name } }) => (
                                                                <>
                                                                    <input
                                                                        type="text"
                                                                        className={"form-control " + (errors?.reportsTo ? ' border-danger' : '')}
                                                                        id="reportsTo"
                                                                        name={name}
                                                                        onChange={onChange}
                                                                        value={value}
                                                                        onBlur={onBlur}
                                                                        placeholder="Enter reportsTo"
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                        {errors && errors.reportsTo &&
                                                            <span className='text-danger'>{errors?.reportsTo?.message}</span>
                                                        }
                                                    </div>
                                                    <div className="form-group col-6">
                                                        <label htmlFor="middleName">Middle Name</label>
                                                        <Controller
                                                            name={"middleName"}
                                                            control={control}
                                                            render={({ field: { value, onChange, onBlur, name } }) => (
                                                                <>
                                                                    <input
                                                                        type="text"
                                                                        className={"form-control " + (errors?.middleName ? ' border-danger' : '')}
                                                                        id="middleName"
                                                                        name={name}
                                                                        onChange={onChange}
                                                                        value={value}
                                                                        onBlur={onBlur}
                                                                        placeholder="Enter middle name"
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                        {errors && errors.middleName &&
                                                            <span className='text-danger'>{errors?.middleName?.message}</span>
                                                        }
                                                    </div>
                                                    <div className="form-group col-6">
                                                        <label htmlFor="department">Department</label>
                                                        <Controller
                                                            name={"department"}
                                                            control={control}
                                                            render={({ field: { value, onChange, onBlur, name } }) => (
                                                                <>
                                                                    <input
                                                                        type="text"
                                                                        className={"form-control " + (errors?.department ? ' border-danger' : '')}
                                                                        id="department"
                                                                        name={name}
                                                                        onChange={onChange}
                                                                        value={value}
                                                                        onBlur={onBlur}
                                                                        placeholder="Enter descriptiom"
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                        {errors && errors.department &&
                                                            <span className='text-danger'>{errors?.department?.message}</span>
                                                        }
                                                    </div>
                                                    <div className="form-group col-6">
                                                        <label htmlFor="lastName">Last Name <span className='text-danger'>*</span></label>
                                                        <Controller
                                                            name={"lastName"}
                                                            control={control}
                                                            render={({ field: { value, onChange, onBlur, name, ref } }) => (
                                                                <>
                                                                    <input
                                                                        type="text"
                                                                        className={"form-control " + (errors?.lastName ? ' border-danger' : '')}
                                                                        id="lastName"
                                                                        name={name}
                                                                        onChange={onChange}
                                                                        value={value}
                                                                        onBlur={onBlur}
                                                                        ref={ref}
                                                                        placeholder="Enter last name"
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                        {errors && errors.lastName &&
                                                            <span className='text-danger'>{errors?.lastName?.message}</span>
                                                        }
                                                    </div>
                                                    <div className="form-group col-6">
                                                        <label htmlFor="accntName">Account Name <span className='text-danger'>*</span></label>
                                                        <Controller
                                                            name={"accntName"}
                                                            control={control}
                                                            render={({ field: { value, onChange, onBlur, name, ref } }) => (
                                                                <>
                                                                    <Select
                                                                        className={"select-box " + (errors?.accntName ? ' border-danger' : '')}
                                                                        id="accntName"
                                                                        options={accountOptions ? accountOptions : []}
                                                                        placeholder={"Select account"}
                                                                        onChange={onChange}
                                                                        value={value}
                                                                        defaultValue={value}
                                                                        isDisabled
                                                                        ref={ref}
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                        {errors && errors.accntName &&
                                                            <span className='text-danger'>Account is required</span>
                                                        }
                                                    </div>
                                                    <div className="form-group col-6">
                                                        <label htmlFor="suffix">Suffix</label>
                                                        <Controller
                                                            name={"suffix"}
                                                            control={control}
                                                            render={({ field: { value, onChange, onBlur, name } }) => (
                                                                <>
                                                                    <input
                                                                        type="text"
                                                                        className={"form-control " + (errors?.suffix ? ' border-danger' : '')}
                                                                        id="suffix"
                                                                        name={name}
                                                                        onChange={onChange}
                                                                        value={value}
                                                                        onBlur={onBlur}
                                                                        placeholder="Enter suffix"
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                        {errors && errors.suffix &&
                                                            <span className='text-danger'>{errors?.suffix?.message}</span>
                                                        }
                                                    </div>
                                                    <div className="form-group col-6">
                                                        <label htmlFor="title">Title</label>
                                                        <Controller
                                                            name={"title"}
                                                            control={control}
                                                            render={({ field: { value, onChange, onBlur, name } }) => (
                                                                <>
                                                                    <input
                                                                        type="text"
                                                                        className={"form-control " + (errors?.title ? ' border-danger' : '')}
                                                                        id="title"
                                                                        name={name}
                                                                        onChange={onChange}
                                                                        value={value}
                                                                        onBlur={onBlur}
                                                                        placeholder="Enter title"
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                        {errors && errors.title &&
                                                            <span className='text-danger'>{errors?.title?.message}</span>
                                                        }
                                                    </div>
                                                    <div className="form-group col-6">
                                                        <label htmlFor="phone">Phone</label>
                                                        <Controller
                                                            name={"phone"}
                                                            control={control}
                                                            render={({ field: { value, onChange, onBlur, name } }) => (
                                                                <>
                                                                    {/* <input
                                                                        type="text"
                                                                        className={"form-control " + (errors?.phone ? ' border-danger' : '')}
                                                                        id="phone"
                                                                        name={name}
                                                                        onChange={onChange}
                                                                        value={value}
                                                                        onBlur={onBlur}
                                                                        placeholder="Enter phone"
                                                                        onKeyPress={(event: any) => handleKeyPressNumber(event)}
                                                                    /> */}
                                                                    <InputMask
                                                                        mask="(999) 999-9999"
                                                                        maskChar="_"
                                                                        name={name}
                                                                        value={value}
                                                                        onBlur={onBlur}
                                                                        onChange={onChange}
                                                                        className={
                                                                            "form-control " + (errors?.phone ? "border-danger" : "")
                                                                        }
                                                                        id="phone"
                                                                        placeholder="Enter phone"
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                        {errors && errors.phone &&
                                                            <span className='text-danger'>{errors?.phone?.message}</span>
                                                        }
                                                    </div>
                                                    <div className="form-group col-6">
                                                        <label htmlFor="email">Email</label>
                                                        <Controller
                                                            name={"email"}
                                                            control={control}
                                                            render={({ field: { value, onChange, onBlur, name } }) => (
                                                                <>
                                                                    <input
                                                                        type="email"
                                                                        className={"form-control " + (errors?.email ? ' border-danger' : '')}
                                                                        id="email"
                                                                        name={name}
                                                                        onChange={onChange}
                                                                        value={value}
                                                                        onBlur={onBlur}
                                                                        placeholder="Enter email"
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                        {errors && errors.email &&
                                                            <span className='text-danger'>{errors?.email?.message}</span>
                                                        }
                                                    </div>
                                                    <div className="form-group col-6">
                                                        <label htmlFor="mobile">Mobile</label>
                                                        <Controller
                                                            name={"mobile"}
                                                            control={control}
                                                            render={({ field: { value, onChange, onBlur, name } }) => (
                                                                <>
                                                                    <InputMask
                                                                        mask="(999) 999-9999"
                                                                        maskChar="_"
                                                                        className={"form-control " + (errors?.mobile ? ' border-danger' : '')}
                                                                        id="mobile"
                                                                        name={name}
                                                                        onChange={onChange}
                                                                        value={value}
                                                                        onBlur={onBlur}
                                                                        placeholder="Enter mobile"
                                                                        onKeyPress={(event: any) => handleKeyPressNumber(event)}
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                        {errors && errors.mobile &&
                                                            <span className='text-danger'>{errors?.mobile?.message}</span>
                                                        }
                                                    </div>
                                                    <div className="form-group col-6">
                                                        <label htmlFor="fax">Fax</label>
                                                        <Controller
                                                            name={"fax"}
                                                            control={control}
                                                            render={({ field: { value, onChange, onBlur, name } }) => (
                                                                <>
                                                                    <input
                                                                        type="text"
                                                                        className={"form-control " + (errors?.fax ? ' border-danger' : '')}
                                                                        id="fax"
                                                                        name={name}
                                                                        onChange={onChange}
                                                                        value={value}
                                                                        onBlur={onBlur}
                                                                        placeholder="Enter fax"
                                                                        onKeyPress={(event: any) => handleKeyPressNumber(event)}
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                        {errors && errors.fax &&
                                                            <span className='text-danger'>{errors?.fax?.message}</span>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="d-flex flex-wrap justify-content-between">
                                                    <h5 className="card-title mr-2">Address Information</h5>
                                                </div>
                                                <div className="row">
                                                    <div className="form-group col-6">
                                                        <label htmlFor="mailingStreet">Mailling Street</label>
                                                        <Controller
                                                            name={"mailingStreet"}
                                                            control={control}
                                                            render={({ field: { value, onChange, onBlur, name } }) => (
                                                                <>
                                                                    <input
                                                                        type="text"
                                                                        className={"form-control " + (errors?.mailingStreet ? ' border-danger' : '')}
                                                                        id="mailingStreet"
                                                                        name={name}
                                                                        onChange={onChange}
                                                                        value={value}
                                                                        onBlur={onBlur}
                                                                        placeholder="Enter mailling street"
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                        {errors && errors.mailingStreet &&
                                                            <span className='text-danger'>{errors?.mailingStreet?.message}</span>
                                                        }
                                                    </div>
                                                    <div className="form-group col-6">
                                                        <label htmlFor="mailingCity">Mailling City</label>
                                                        <Controller
                                                            name={"mailingCity"}
                                                            control={control}
                                                            render={({ field: { value, onChange, onBlur, name } }) => (
                                                                <>
                                                                    <input
                                                                        type="text"
                                                                        className={"form-control " + (errors?.mailingCity ? ' border-danger' : '')}
                                                                        id="mailingCity"
                                                                        name={name}
                                                                        onChange={onChange}
                                                                        value={value}
                                                                        onBlur={onBlur}
                                                                        placeholder="Enter billing city"
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                        {errors && errors.mailingCity &&
                                                            <span className='text-danger'>{errors?.mailingCity?.message}</span>
                                                        }
                                                    </div>
                                                    <div className="form-group col-6">
                                                        <label htmlFor="mailingState">Mailling State/Province</label>
                                                        <Controller
                                                            name={"mailingState"}
                                                            control={control}
                                                            render={({ field: { value, onChange, onBlur, name } }) => (
                                                                <>
                                                                    <input
                                                                        type="text"
                                                                        className={"form-control " + (errors?.mailingState ? ' border-danger' : '')}
                                                                        id="mailingState"
                                                                        name={name}
                                                                        onChange={onChange}
                                                                        value={value}
                                                                        onBlur={onBlur}
                                                                        placeholder="Enter billing state"
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                        {errors && errors.mailingState &&
                                                            <span className='text-danger'>{errors?.mailingState?.message}</span>
                                                        }
                                                    </div>
                                                    <div className="form-group col-6">
                                                        <label htmlFor="mailingZip">Mailling Zip/Postal Code</label>
                                                        <Controller
                                                            name={"mailingZip"}
                                                            control={control}
                                                            render={({ field: { value, onChange, onBlur, name } }) => (
                                                                <>
                                                                    <input
                                                                        type="text"
                                                                        className={"form-control " + (errors?.mailingZip ? ' border-danger' : '')}
                                                                        id="mailingZip"
                                                                        name={name}
                                                                        onChange={onChange}
                                                                        value={value}
                                                                        onBlur={onBlur}
                                                                        placeholder="Enter billing zip"
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                        {errors && errors.mailingZip &&
                                                            <span className='text-danger'>{errors?.mailingZip?.message}</span>
                                                        }
                                                    </div>
                                                    <div className="form-group col-6">
                                                        <label htmlFor="mailingCountry">Mailling Country</label>
                                                        <Controller
                                                            name={"mailingCountry"}
                                                            control={control}
                                                            render={({ field: { value, onChange, onBlur, name } }) => (
                                                                <>
                                                                    <Select
                                                                        className={"select-box " + (errors?.mailingCountry ? ' border-danger' : '')}
                                                                        id="mailingCountry"
                                                                        options={countriesOptions ? countriesOptions : []}
                                                                        placeholder={"Select country"}
                                                                        onChange={onChange}
                                                                        value={value}
                                                                        defaultValue={value}
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                        {/* {errors && errors.mailingCountry &&
                                                            <span className='text-danger'>{errors?.mailingCountry?.message}</span>
                                                        } */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className='align-items-center d-flex gap-10 justify-content-center'>
                                                    <button type='submit' className='btn btn-primary'>Save</button>
                                                    <button
                                                        type='button'
                                                        className='btn btn-outline-danger'
                                                        onClick={() => navigate(`/account-view/${urlParams?.id}`)}
                                                    >
                                                        Cancel
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </React.Fragment>
    )
}

export default AddContact