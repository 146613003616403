import React, { useEffect, useMemo, useState } from 'react';
import { usePlatformApi } from '../../_common/hooks/api/PlatformApiHook';
import { useAppLoader } from '../../_common/hooks/common/appLoaderHook';
import TableContainer from '../commonComponents/TableContainer';
import moment from 'moment';
import { formatNumber } from '../../_common/functions';

interface IncomingProps {
    quarterValue: any;
    typeOfContract: any;
    filterDetails: any;
    doFilter: boolean;
    setDoFilter: any;
}

const CrossSellsList = ({ quarterValue, typeOfContract, filterDetails, doFilter, setDoFilter }: IncomingProps) => {

    const platformApi = usePlatformApi();
    const { showLoader, hideLoader } = useAppLoader();

    const [crossSells, setCrossSellsData] = useState<any>(null)
    const [data, setData] = useState<any>([])
    const [loading, setLoading] = useState<boolean>(false)

    const getCrossSellsData = (page = 1) => {
        showLoader();
        setLoading(true);
        let params: any = quarterValue && typeOfContract ?
            { page: page, quarter: quarterValue, typeOfContract: typeOfContract }
            :
            { page: page };
        if (filterDetails && Object.keys(filterDetails).length > 0) {
            Object.assign(params, filterDetails)
        }
        platformApi.getCrossSells(params,
            (message: string, resp: any) => {
                // console.log(resp);
                setLoading(false);
                setCrossSellsData(resp.data.list);
                setData(resp.data.list.data);
                hideLoader();
                setDoFilter(false)
            }, (message: string) => {
                setLoading(false);
                hideLoader();
                setDoFilter(false)
            })
    }

    const columns = useMemo(
        () => [
            {
                Header: "Account Cross Sells",
                accessor: "AccountId",
            },
            {
                Header: "Contract Number",
                accessor: "ContractNumber",
            },
            {
                Header: "Arr ($)",
                accessor: "arr",
                Cell: ({ column, value, row }: any) =>
                    <>
                        {value ? formatNumber(value) : "0.00"}
                    </>,
            },
            {
                Header: "Contract Term (months)",
                accessor: "ContractTerm",
            },
            {
                Header: "Cross Sell ($)",
                accessor: "Crosssellval",
                Cell: ({ column, value, row }: any) =>
                    <>
                        {value ? formatNumber(value) : "0.00"}
                    </>,
            },
            {
                Header: "Due Date",
                accessor: "EndDate",
                Cell: ({ column, value, row }: any) => (
                    <>
                        {value ? moment(value).format("MM/DD/YY") : ""}
                    </>
                )
            },
            {
                Header: "Status",
                accessor: "newstatus",
            },
        ],
        []
    )

    useEffect(() => {
        getCrossSellsData();
    }, [])

    useEffect(() => {
        if (doFilter) {
            getCrossSellsData();
        }
    }, [doFilter])

    return (
        <React.Fragment>
            <div className="table-responsive">
                <TableContainer
                    columns={columns}
                    data={data}
                    fetchData={getCrossSellsData}
                    loading={loading}
                    rawData={crossSells}
                    clickFunc={true}
                />
            </div>
        </React.Fragment>
    )
}

export default CrossSellsList