import React from 'react'
import { Link } from 'react-router-dom'
import CommingSoon from '../components/commonComponents/CommingSoon'
import Footer from '../components/commonComponents/Footer'
import Header from '../components/commonComponents/Header'
import LeftPanel from '../components/commonComponents/LeftPanel'
import { URLS } from '../_config'

const AdvanceApprovals = () => {
    return (
        <React.Fragment>
            <div id="layout-wrapper">
                <Header />
                <LeftPanel />
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            {/* start page title */}
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-flex align-items-center justify-content-between title-mob-fix">
                                        <h4 className="mb-0 font-size-18">Advanced Approvals</h4>
                                        <div className="page-title-right">
                                            <ol className="breadcrumb m-0">
                                                <li className="breadcrumb-item">
                                                    <Link to={URLS.REVENUE}>Revenue</Link>
                                                </li>
                                                <li className="breadcrumb-item active">Advanced Approvals</li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* end page title */}
                            <CommingSoon />
                            {/* <div className="row">
                                <div className="col-xl-12">
                                    <div className="row dashboard-top-panel">
                                        <div className="col-lg-3 col-md-6 col-12">
                                            <div className="card mini-stats-wid">
                                                <div className="card-body">
                                                    <div className="d-flex flex-wrap">
                                                        <div className="mr-3">
                                                            <p className="text-muted mb-2">ARR / Forecasted</p>
                                                            <h5 className="mb-2">305</h5>
                                                        </div>
                                                        <div className="avatar-sm ml-auto">
                                                            <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                                                                <i className="bx bxs-book-bookmark" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <ul className="dash-para-fix">
                                                        <li className="mb-1">
                                                            NRR: <strong>105</strong> ORR: <strong>92</strong>
                                                        </li>
                                                        <li>
                                                            Best: <strong>209</strong> Most Likely: <strong>275</strong>
                                                            <br />
                                                            Worst: <strong>220</strong>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6 col-12">
                                            <div className="card blog-stats-wid">
                                                <div className="card-body">
                                                    <div className="d-flex flex-wrap">
                                                        <div className="mr-3">
                                                            <p className="text-muted mb-2">Expansion / Forecast</p>
                                                            <h5 className="mb-0">60/75</h5>
                                                        </div>
                                                        <div className="avatar-sm ml-auto">
                                                            <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                                                                <i className="bx bxs-note" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6 col-12">
                                            <div className="card blog-stats-wid">
                                                <div className="card-body">
                                                    <div className="d-flex flex-wrap">
                                                        <div className="mr-3">
                                                            <p className="text-muted mb-2">Renewed</p>
                                                            <h5 className="mb-0">200</h5>
                                                        </div>
                                                        <div className="avatar-sm ml-auto">
                                                            <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                                                                <i className="bx bxs-message-square-dots" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6 col-12">
                                            <div className="card blog-stats-wid">
                                                <div className="card-body">
                                                    <div className="d-flex flex-wrap">
                                                        <div className="mr-3">
                                                            <p className="text-muted mb-2">Churned / Forecasted</p>
                                                            <h5 className="mb-0">25</h5>
                                                        </div>
                                                        <div className="avatar-sm ml-auto">
                                                            <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                                                                <i className="bx bxs-message-square-dots" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="table-responsive">
                                                <table
                                                    id="datatable"
                                                    className="table table-bordered dt-responsive nowrap"
                                                    style={{
                                                        borderCollapse: "collapse",
                                                        borderSpacing: 0,
                                                        width: "100%"
                                                    }}
                                                >
                                                    <thead>
                                                        <tr>
                                                            <th>
                                                                <div className="d-inline-flex justify-content-between align-items-center">
                                                                    Approval Reason{" "}
                                                                    <a href="#" className="text-white font-size-22">
                                                                        <i className="bx bx-plus ml-5" />
                                                                    </a>
                                                                </div>
                                                            </th>
                                                            <th>Aprrovals Needed </th>
                                                            <th>Approval 1</th>
                                                            <th>Approval 2</th>
                                                            <th>Approval 3</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                no churn or contraction and flat renewal with uplift
                                                            </td>
                                                            <td>
                                                                <select id="formrow-inputRegion" className="form-control">
                                                                    <option>Auto approved</option>
                                                                    <option>Needs approvals</option>
                                                                    <option>May need approvals </option>
                                                                </select>
                                                            </td>
                                                            <td />
                                                            <td />
                                                            <td />
                                                        </tr>
                                                        <tr>
                                                            <td>Reduction in licenses or Discount in ARR </td>
                                                            <td>
                                                                <select id="formrow-inputRegion" className="form-control">
                                                                    <option>Auto approved</option>
                                                                    <option>Needs approvals</option>
                                                                    <option>May need approvals </option>
                                                                </select>
                                                            </td>
                                                            <td>@Mitchell Rueckert </td>
                                                            <td>@Joe Show </td>
                                                            <td>@Adam Albright</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Change in T&amp;C's</td>
                                                            <td>
                                                                <select id="formrow-inputRegion" className="form-control">
                                                                    <option>Auto approved</option>
                                                                    <option>Needs approvals</option>
                                                                    <option>May need approvals </option>
                                                                </select>
                                                            </td>
                                                            <td>@Mitchell Rueckert </td>
                                                            <td>@Joe Show </td>
                                                            <td>@Adam Albright</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Reduction in Term length </td>
                                                            <td>
                                                                <select id="formrow-inputRegion" className="form-control">
                                                                    <option>Auto approved</option>
                                                                    <option>Needs approvals</option>
                                                                    <option>May need approvals </option>
                                                                </select>
                                                            </td>
                                                            <td>@manager1</td>
                                                            <td>@Joe Show </td>
                                                            <td />
                                                        </tr>
                                                        <tr>
                                                            <td>Change in Partner margin </td>
                                                            <td>
                                                                <select id="formrow-inputRegion" className="form-control">
                                                                    <option>Auto approved</option>
                                                                    <option>Needs approvals</option>
                                                                    <option>May need approvals </option>
                                                                </select>
                                                            </td>
                                                            <td>@partnerteam2</td>
                                                            <td>@Joe Show </td>
                                                            <td />
                                                        </tr>
                                                        <tr>
                                                            <td>Cross-sell on quote </td>
                                                            <td>
                                                                <select id="formrow-inputRegion" className="form-control">
                                                                    <option>Needs approvals</option>
                                                                    <option>Auto approved</option>
                                                                    <option>May need approvals </option>
                                                                </select>
                                                            </td>
                                                            <td>@Mitchell Rueckert </td>
                                                            <td>@salesmanager1</td>
                                                            <td>@Adam Albright</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Premium support on quote</td>
                                                            <td>
                                                                <select id="formrow-inputRegion" className="form-control">
                                                                    <option>May need approvals </option>
                                                                    <option>Auto approved</option>
                                                                    <option>Needs approvals</option>
                                                                </select>
                                                            </td>
                                                            <td>@Premiumsupportteam </td>
                                                            <td>@Joe Show </td>
                                                            <td />
                                                        </tr>
                                                        <tr>
                                                            <td>Additional Pages on contract </td>
                                                            <td>
                                                                <select id="formrow-inputRegion" className="form-control">
                                                                    <option>Needs approvals</option>
                                                                    <option>Auto approved</option>
                                                                    <option>May need approvals </option>
                                                                </select>
                                                            </td>
                                                            <td>@Mitchell Rueckert </td>
                                                            <td>@legalteam3</td>
                                                            <td />
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </React.Fragment>
    )
}

export default AdvanceApprovals