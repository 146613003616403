import { SAGA_ACTIONS } from '../../../../_config';
import { useApiCall } from '../../common/appApiCallHook';
import {
    BlankReq,
} from '../../../interfaces/ApiReqRes'


export function useCommonApi() {

    const callApi = useApiCall();

    const getJobTitle = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.COMMON.GET_JOB_TITLE, data, onSuccess, onError);
    }

    const getPlatforms = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.COMMON.GET_PLATFORMS, data, onSuccess, onError);
    }

    const getBusinesss = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.COMMON.GET_BUSINESS, data, onSuccess, onError);
    }

    const manageBusiness = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.COMMON.MANAGE_BUSINESS, data, onSuccess, onError);
    }

    const getCurrency = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.COMMON.GET_CURRENCY, data, onSuccess, onError);
    }

    const deleteSla = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.COMMON.DELETE_SLA, data, onSuccess, onError);
    }

    const deleteRegion = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.COMMON.DELETE_REGION, data, onSuccess, onError);
    }

    const getAllRegions = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.COMMON.GET_ALL_REGIONS, data, onSuccess, onError);
    }

    const manageRegion = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.COMMON.MANAGE_REGION, data, onSuccess, onError);
    }

    const deleteZone = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.COMMON.DELETE_ZONE, data, onSuccess, onError);
    }

    const getAllZone = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.COMMON.GET_ALL_ZONE, data, onSuccess, onError);
    }

    const manageZone = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.COMMON.MANAGE_ZONE, data, onSuccess, onError);
    }

    const getNotification = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.COMMON.GET_NOTIFICATION, data, onSuccess, onError);
    }

    const manageNotification = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.COMMON.MANAGE_NOTIFICATION, data, onSuccess, onError);
    }

    const deleteTax = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.COMMON.DELETE_TAX, data, onSuccess, onError);
    }

    const getAllTax = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.COMMON.GET_ALL_TAX, data, onSuccess, onError);
    }

    const manageTax = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.COMMON.MANAGE_TAX, data, onSuccess, onError);
    }

    const deleteProductFamily = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.COMMON.DELETE_PRODUCT_FAMILY, data, onSuccess, onError);
    }

    const getAllProductFamily = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.COMMON.GET_ALL_PRODUCT_FAMILY, data, onSuccess, onError);
    }

    const manageProductFamily = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.COMMON.MANAGE_PRODUCT_FAMILY, data, onSuccess, onError);
    }

    const getZendesk = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.COMMON.GET_ZENDESK, data, onSuccess, onError);
    }

    const addZendesk = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.COMMON.ADD_ZENDESK, data, onSuccess, onError);
    }

    const isNewAccount = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.COMMON.IS_NEW_ACCOUNT, data, onSuccess, onError);
    }

    const doneRegionSetting = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.COMMON.DONE_REGION_SETTING, data, onSuccess, onError);
    }

    const getAllDesignation = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.COMMON.GET_ALL_DESIGNATION, data, onSuccess, onError);
    }

    const deleteDesignation = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.COMMON.DELETE_DESIGNATION, data, onSuccess, onError);
    }

    const manageDesignation = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.COMMON.MANAGE_DESIGNATION, data, onSuccess, onError);
    }

    const getAllPartner = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.COMMON.GET_ALL_PARTNER, data, onSuccess, onError);
    }

    const managePartner = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.COMMON.MANAGE_PARTNER, data, onSuccess, onError);
    }

    const deletePartner = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.COMMON.DELETE_PARTNER, data, onSuccess, onError);
    }

    const getAllApprover = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.COMMON.GET_ALL_APPROVER, data, onSuccess, onError);
    }

    const manageApprover = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.COMMON.MANAGE_APPROVER, data, onSuccess, onError);
    }

    const deleteApprover = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.COMMON.DELETE_APPROVER, data, onSuccess, onError);
    }

    const getAllApproverRule = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.COMMON.GET_ALL_APPROVER_RULE, data, onSuccess, onError);
    }

    const manageApproverRule = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.COMMON.MANAGE_APPROVER_RULE, data, onSuccess, onError);
    }

    const deleteApproverRule = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.COMMON.DELETE_APPROVER_RULE, data, onSuccess, onError);
    }

    const getAllApproverRuleCondition = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.COMMON.GET_ALL_APPROVER_RULE_CONDITION, data, onSuccess, onError);
    }

    const manageApproverRuleCondition = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.COMMON.MANAGE_APPROVER_RULE_CONDITION, data, onSuccess, onError);
    }

    const deleteApproverRuleCondition = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.COMMON.DELETE_APPROVER_RULE_CONDITION, data, onSuccess, onError);
    }

    const getAllApproval = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.COMMON.GET_ALL_APPROVAL, data, onSuccess, onError);
    }

    const manageApproval = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.COMMON.MANAGE_APPROVAL, data, onSuccess, onError);
    }

    const deleteApproval = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.COMMON.DELETE_APPROVAL, data, onSuccess, onError);
    }

    const addApprover = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.COMMON.ADD_APPROVER, data, onSuccess, onError);
    }

    return {
        getJobTitle: getJobTitle,
        getPlatforms: getPlatforms,
        getBusinesss: getBusinesss,
        manageBusiness: manageBusiness,
        getCurrency: getCurrency,
        deleteSla: deleteSla,
        getAllRegions: getAllRegions,
        deleteRegion: deleteRegion,
        manageRegion: manageRegion,
        getAllZone: getAllZone,
        deleteZone: deleteZone,
        manageZone: manageZone,
        getNotification: getNotification,
        manageNotification: manageNotification,
        deleteTax: deleteTax,
        getAllTax: getAllTax,
        manageTax: manageTax,
        deleteProductFamily: deleteProductFamily,
        getAllProductFamily: getAllProductFamily,
        manageProductFamily: manageProductFamily,
        addZendesk: addZendesk,
        getZendesk: getZendesk,
        isNewAccount: isNewAccount,
        doneRegionSetting: doneRegionSetting,
        getAllDesignation: getAllDesignation,
        deleteDesignation: deleteDesignation,
        manageDesignation: manageDesignation,
        getAllPartner: getAllPartner,
        managePartner: managePartner,
        deletePartner: deletePartner,
        getAllApprover: getAllApprover,
        manageApprover: manageApprover,
        deleteApprover: deleteApprover,
        getAllApproverRule: getAllApproverRule,
        manageApproverRule: manageApproverRule,
        deleteApproverRule: deleteApproverRule,
        getAllApproverRuleCondition: getAllApproverRuleCondition,
        manageApproverRuleCondition: manageApproverRuleCondition,
        deleteApproverRuleCondition: deleteApproverRuleCondition,
        getAllApproval: getAllApproval,
        manageApproval: manageApproval,
        deleteApproval: deleteApproval,
        addApprover: addApprover,
    }
}