import { useSelector } from 'react-redux'
import { User } from '../../interfaces/models/user'
import { StateExtended } from '../../interfaces/StateExtended'


export function useSalesforceDataSelector() {
    const data = useSelector((state: StateExtended) => state.platform.salesforceData)
    return data
}

export function useConnentedPlatformsSelector() {
    const connectedPlatforms = useSelector((state: StateExtended) => state.user.connectedPlatform)
    return connectedPlatforms
}