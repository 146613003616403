import { useSelector } from 'react-redux'
import { StateExtended } from '../../interfaces/StateExtended'

export function useAppLoaderSelector() {
    const show = useSelector((state: StateExtended) => state.loader.fpLoaderShown)
    return show
}

export function useSidebarSelector() {

    const show = useSelector((state: StateExtended) => {
        //  console.log("check load"+state.loader.sidebar);
        return state.loader.sidebar
    })
    return show
}

