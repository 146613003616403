// css ----------
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './App.scss';
import 'react-datepicker/dist/react-datepicker.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { URLS } from './_config';
import PrivateRoutes from './_config/privateRoutes';
import OpenRoutes from './_config/openRoutes';
import Login from './pages/Login';
import Revenue from "./pages/Revenue";
import Upside from "./pages/Upside";
import AtRisk from "./pages/AtRisk";
import ContractPage from "./pages/ContractPage";
import ChurnForcast from "./pages/ChurnForcast";
import OtherReports from "./pages/OtherReports";
import ExternalApplication from "./pages/settings/ExternalApplication";
import ManageUsers from "./pages/ManageUsers";
import Permission from "./pages/Permission";
import PriceBook from "./pages/PriceBook";
import AdvanceApprovals from "./pages/AdvanceApprovals";
import QuotingManagement from "./pages/QuotingManagement";
import Signup from "./pages/Signup";
import FinalExternalApplication from "./pages/newUserPages/FinalExternalApplication";
import AccountView from "./pages/AccountView";
import CallbackPage from "./pages/CallbackPage";
import SalesforceMaping from "./pages/SalesforceMaping";
import Dashboard from "./pages/Dashboard";
import AccountList from "./pages/AccountList";
import ProductList from "./pages/ProductList";
import AddAccount from "./pages/AddAccount";
import AddProduct from "./pages/AddProduct";
import AddPriceBook from "./pages/AddPriceBook";
import AddOpportunity from "./pages/AddOpportunity";
import AddQuote from "./pages/AddQuote";
import OpportunityDetails from "./pages/OpportunityDetails";
import QuoteDetails from "./pages/QuoteDetails";
import PriceBookDetails from "./pages/PriceBookDetails";
import AddPriceBookEntities from "./pages/AddPriceBookEntities";
import AddLineItems from "./pages/AddLineItems";
import ContractDetails from "./pages/ContractDetails";
import AddContact from "./pages/AddContact";
import ScrollToTop from "./_config/ScrollToTop";
import Authtentication from "./pages/Authtentication";
import Forecast from "./pages/Forecast";
import ManageRegions from "./pages/settings/ManageRegions";
import BusinessSettings from "./pages/settings/BusinessSettings";
import ManageZone from "./pages/settings/ManageZone";
import ManageTax from "./pages/settings/ManageTax";
import ManageProductFamily from "./pages/settings/ManageProductFamily";
import RegionSettings from "./pages/newUserPages/RegionSettings";
import PartnerSettings from "./pages/settings/PartnerSettings";
import DesignationSettings from "./pages/settings/DesignationSettings";
import NotificationSettings from "./pages/settings/NotificationSettings";
import ManageApprover from "./pages/approverSettings/ManageApprover";
import ManageApproverRule from "./pages/approverSettings/ManageApproverRule";
import ApprovalRulesDetails from "./pages/approverSettings/ApprovalRulesDetails";
import ManageApprovals from "./pages/ManageApprovals";
import ApprovalDetails from "./pages/ApprovalDetails";
import BusinessDetails from "./pages/newUserPages/BusinessDetails";
import Conditions from "./pages/approverSettings/Conditions";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";


function App() {
	return (
		<Router>
			<ScrollToTop />
			<Routes>
				<Route element={<OpenRoutes />} >
					<Route path={URLS.LOGIN} element={<Login />} />
					<Route path={URLS.SIGNUP} element={<Signup />} />
					{/* <Route path={URLS.DASHBOARD} element={<Dashboard />} /> */}
					<Route path={URLS.AUTHENTICATION} element={<Authtentication />} />
					<Route path={URLS.FORGOT_PASSWORD} element={<ForgotPassword />} />
					<Route path={URLS.RESET_PASSWORD} element={<ResetPassword />} />
				</Route>

				<Route element={<PrivateRoutes />} >
					<Route path={URLS.HOME} element={<FinalExternalApplication />} />
					<Route path={URLS.REVENUE} element={<Revenue />} />
					<Route path={URLS.UPSIDE} element={<Upside />} />
					<Route path={URLS.ATRISK} element={<AtRisk />} />
					<Route path={URLS.CHURN_FORCAST} element={<ChurnForcast />} />
					<Route path={URLS.FORECAST} element={<Forecast />} />
					<Route path={URLS.CONTRACT_PAGE} element={<ContractPage />} />
					<Route path={URLS.ACCOUNT_LIST} element={<AccountList />} />
					<Route path={URLS.OTHER_REPORTS} element={<OtherReports />} />
					<Route path={URLS.CONNECTS_EXTERNAL_APPLICATION} element={<ExternalApplication />} />
					<Route path={URLS.MANAGE_USERS} element={<ManageUsers />} />
					<Route path={URLS.PERMISSION} element={<Permission />} />
					<Route path={URLS.PRICE_BOOK} element={<PriceBook />} />
					<Route path={URLS.ADVANCE_APPROVALS} element={<AdvanceApprovals />} />
					<Route path={URLS.QUOTING_MANAGEMENT} element={<QuotingManagement />} />
					<Route path={URLS.ACCOUNT_VIEW} element={<AccountView />} />
					<Route path={URLS.SF_CALLBACK} element={<CallbackPage />} />
					<Route path={URLS.SF_CONNECTION} element={<SalesforceMaping />} />
					<Route path={URLS.DASHBOARD} element={<Dashboard />} />
					<Route path={URLS.PRODUCT_LIST} element={<ProductList />} />
					<Route path={URLS.ADD_ACCOUNT} element={<AddAccount />} />
					<Route path={URLS.ADD_PRODUCT} element={<AddProduct />} />
					<Route path={URLS.ADD_PRICEBOOK} element={<AddPriceBook />} />
					{/* <Route path={URLS.ADD_OPPORTUNITY} element={<AddOpportunity />} /> */}
					<Route path={URLS.ADD_QUOTE} element={<AddQuote />} />
					{/* <Route path={URLS.OPPORTUNITY_DETAILS} element={<OpportunityDetails />} /> */}
					<Route path={URLS.QUOTE_DETAILS} element={<QuoteDetails />} />
					<Route path={URLS.PRICEBOOK_DETAILS} element={<PriceBookDetails />} />
					<Route path={URLS.ADD_PRICEBOOK_ENTITIES} element={<AddPriceBookEntities />} />
					{/* <Route path={URLS.ADD_LINE_ITEMS} element={<AddLineItems />} /> */}
					<Route path={URLS.CONTRACT_DETAILS} element={<ContractDetails />} />
					<Route path={URLS.ADD_CONTACT} element={<AddContact />} />
					<Route path={URLS.MANAGE_REGIONS} element={<ManageRegions />} />
					<Route path={URLS.BUSINESS_SETTINGS} element={<BusinessSettings />} />
					<Route path={URLS.NOTIFICATION_SETTINGS} element={<NotificationSettings />} />
					<Route path={URLS.MANAGE_ZONE} element={<ManageZone />} />
					<Route path={URLS.MANAGE_TAX} element={<ManageTax />} />
					<Route path={URLS.MANAGE_PRODUCT_FAMILY} element={<ManageProductFamily />} />
					<Route path={URLS.REGION_SETTINGS} element={<RegionSettings />} />
					<Route path={URLS.PARTNER_SETTINGS} element={<PartnerSettings />} />
					<Route path={URLS.DESIGNATION_SETTINGS} element={<DesignationSettings />} />
					<Route path={URLS.MANAGE_APPROVER} element={<ManageApprover />} />
					<Route path={URLS.MANAGE_APPROVER_RULE} element={<ManageApproverRule />} />
					<Route path={URLS.APPROVER_RULE_DETAILS} element={<ApprovalRulesDetails />} />
					<Route path={URLS.MANAGE_APPROVALS} element={<ManageApprovals />} />
					<Route path={URLS.APPROVAL_DETAILS} element={<ApprovalDetails />} />
					<Route path={URLS.BUSINESS_DETAILS} element={<BusinessDetails />} />
					<Route path={URLS.CONDITION_LIST} element={<Conditions />} />
				</Route>
			</Routes>
		</Router>
	);
}

export default App;