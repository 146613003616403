import React, { useEffect, useMemo, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Footer from '../components/commonComponents/Footer'
import Header from '../components/commonComponents/Header'
import LeftPanel from '../components/commonComponents/LeftPanel'
import { useAppLoader } from '../_common/hooks/common/appLoaderHook'
import Modal from 'react-bootstrap/Modal'
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Controller, useForm } from 'react-hook-form';
import { useCommonApi } from '../_common/hooks/api/common/commonApiHook'
import { URLS } from '../_config'
import { toast } from 'react-hot-toast'
import { usePlatformApi } from '../_common/hooks/api/PlatformApiHook'
import moment from 'moment'
import { changeString, formatNumber } from '../_common/functions'
import TableContainer from '../components/commonComponents/TableContainer'

interface FormValues {
    comment: string;
}

const validationSchema = Yup.object().shape({
    comment: Yup.string().required("Comment is required"),
});

const ApprovalDetails = () => {

    const urlParams: any = useParams()
    const commonApi = useCommonApi()
    const platformApi = usePlatformApi()
    const navigate = useNavigate()

    const { showLoader, hideLoader } = useAppLoader()

    const [data, setData] = useState<any>(null)
    const [quoteData, setQuoteData] = useState<any>(null)
    const [showModal, setShowModal] = useState<boolean>(false)
    const [statusType, setStatusType] = useState<string>('')
    const [lineItemData, setLineItemData] = useState<any>([])
    const [rawData, setRawData] = useState<any>(null)
    const [loading, setLoading] = useState<boolean>(false)


    const { control, formState: { errors }, handleSubmit, setValue, reset } = useForm<FormValues>({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            comment: "",
        },
    });

    const getApprovalDetails = () => {
        showLoader()
        commonApi.getAllApproval({
            id: urlParams?.id
        }, (message: string, resp: any) => {
            // console.log(message, resp)
            setData(resp.data.list)
            hideLoader()
        }, (message: string) => {
            // console.log(message)
            hideLoader()
        })
    }

    const getQuoteDetails = () => {
        showLoader()
        platformApi.getAllQuote({
            QuoteId: data?.quoteobject?.QuoteId
        }, (message: string, resp: any) => {
            // console.log(message, resp)
            setQuoteData(resp.data.list.data[0])
            hideLoader()
        }, (message: string) => {
            // console.log(message)
            hideLoader()
        })
    }

    const getAllQuoteLineItem = (page = 1) => {
        showLoader();
        setLoading(true);
        platformApi.getAllQuoteLineItem({
            page: page,
            QuoteId: data?.quoteobject?.QuoteId
        },
            (message: string, resp: any) => {
                // console.log(resp);
                setLoading(false);
                setRawData(resp.data.list);
                setLineItemData(resp.data.list.data);
                hideLoader();
            }, (message: string) => {
                setLoading(false);
                hideLoader();
            })
    }

    const onSubmit = (data: any) => {
        let params: any = {
            "id": urlParams?.id,
            "comment": data?.comment,
            "status": statusType
        }
        showLoader()
        commonApi.manageApproval(params, (message: string, resp: any) => {
            toast.success(message)
            reset()
            close()
            hideLoader()
            getApprovalDetails()
        }, (message: string) => {
            toast.error(message)
            hideLoader()
        })
    }

    const columns = useMemo(
        () => [
            {
                Header: "Product Name",
                accessor: "product.Name",
            },
            {
                Header: "List Price ($)",
                accessor: "ListPrice",
                Cell: ({ column, value, row }: any) => (
                    <div>
                        {value ? formatNumber(value) : '0.00'}
                    </div>
                )
            },
            {
                Header: "Quantity",
                accessor: "Quantity",
            },
            {
                Header: "Sales Price ($)",
                accessor: "UnitPrice",
                Cell: ({ column, value, row }: any) => (
                    <div>
                        {value ? formatNumber(value) : '0.00'}
                    </div>
                )
            },
            {
                Header: "Discount (%)",
                accessor: "Discount",
                Cell: ({ column, value, row }: any) => (
                    <div>
                        {value ? formatNumber(value) : '0.00'}
                    </div>
                )
            },
            {
                Header: "Tax (%)",
                accessor: "taxper",
                Cell: ({ column, value, row }: any) => (
                    <div>
                        {value ? formatNumber(value) : '0.00'}
                    </div>
                )
            },
            {
                Header: "Total ($)",
                accessor: "TotalPrice",
                Cell: ({ column, value, row }: any) => (
                    <div>
                        {value ? formatNumber(value) : '0.00'}
                    </div>
                )
            },
        ],
        []
    )

    const handleRouting = (id: string, type: string) => {
        if (type === 'quote') {
            navigate(`/quote-details/${id}`)
        } else if (type === 'rules') {
            navigate(`/approver-rule-details/${id}`)
        }
    }

    const close = () => {
        setShowModal(false)
        setStatusType('')
    }

    useEffect(() => {
        if (urlParams && urlParams.id) {
            getApprovalDetails()
        }
    }, [urlParams])

    useEffect(() => {
        if (data) {
            getQuoteDetails()
            getAllQuoteLineItem()
        }
    }, [data])

    return (
        <React.Fragment>
            <div id="layout-wrapper">
                <Header />
                <LeftPanel />
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            {/* start page title */}
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-flex align-items-center justify-content-between">
                                        <div>
                                            <h4 className="mb-0 font-size-18">Approval details</h4>
                                        </div>
                                        <div className="page-title-right">
                                            <ol className="breadcrumb m-0">
                                                <li className="breadcrumb-item">
                                                    <Link to={URLS.MANAGE_APPROVALS}>Approvals</Link>
                                                </li>
                                                <li className="breadcrumb-item active">Approval details</li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* end page title */}
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="d-flex flex-wrap">
                                                <h5 className="card-title mr-2">Approval Information</h5>
                                            </div>
                                            <div className="row">
                                                <div className="col-6">
                                                    <label className='mr-2' htmlFor="quoteName">Approval :</label>
                                                    <strong>{data?.code ? data?.code : ""}</strong>
                                                </div>
                                                <div className="col-6">
                                                    <label className='mr-2' htmlFor="expDate">Approval Step :</label>
                                                    <strong>{data?.approval_step ? data?.approval_step : ""}</strong>
                                                </div>
                                                <div className="col-6">
                                                    <label className='mr-2' htmlFor="accntName">Record Field :</label>
                                                    <strong>{data?.record_field ? data?.record_field : ""}</strong>
                                                </div>
                                                <div className="col-6">
                                                    <label className='mr-2' htmlFor="ContractName">Approver :</label>
                                                    <strong>{data?.approverobject ? data?.approverobject?.approvername : ""}</strong>
                                                </div>
                                                <div className="col-6">
                                                    <label className='mr-2' htmlFor="ContractName">Assign to :</label>
                                                    <strong>{data?.user ? data?.user?.name : ""}</strong>
                                                </div>
                                                <div className="col-6">
                                                    <label className='mr-2' htmlFor="description">Quote :</label>
                                                    <strong
                                                        onClick={() => handleRouting(data?.quoteobject?.QuoteId, "quote")}
                                                        className='cursor-pointer'
                                                    >
                                                        {data?.quoteobject ? data?.quoteobject?.Name : ""}
                                                    </strong>
                                                </div>
                                                <div className="col-6">
                                                    <label className='mr-2' htmlFor="status">Rule :</label>
                                                    <strong
                                                        onClick={() => handleRouting(data?.rules, "rules")}
                                                        className='cursor-pointer'
                                                    >
                                                        {data?.ruleobject ? data?.ruleobject?.rulename : ""}
                                                    </strong>
                                                </div>
                                                <div className="col-6">
                                                    <label className='mr-2' htmlFor="status">Status :</label>
                                                    <strong>{data?.status ? data?.status : ""}</strong>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="col-12">
                                                <label className='mr-2' htmlFor="comment">Comment :</label>
                                                <div
                                                    style={{
                                                        maxHeight: " 80px",
                                                        overflowY: "auto"
                                                    }}
                                                >
                                                    <strong>{data?.comment ? data?.comment : ""}</strong>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}

                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="d-flex flex-wrap">
                                                <h5 className="card-title mr-2">Quote Information</h5>
                                            </div>
                                            <div className="row">
                                                <div className="col-6">
                                                    <label className='mr-2' htmlFor="quoteName">Quote name :</label>
                                                    <strong>{quoteData?.Name ? quoteData?.Name : ""}</strong>
                                                </div>
                                                <div className="col-6">
                                                    <label className='mr-2' htmlFor="expDate">Expiration Date :</label>
                                                    <strong>{quoteData?.ExpirationDate ? moment(quoteData?.ExpirationDate).format("MM/DD/YY") : ""}</strong>
                                                </div>
                                                <div className="col-6">
                                                    <label className='mr-2' htmlFor="accntName">Account Name :</label>
                                                    <strong>{quoteData?.account ? quoteData?.account?.Name : ""}</strong>
                                                </div>
                                                <div className="col-6">
                                                    <label className='mr-2' htmlFor="ContractName">Contract Number :</label>
                                                    <strong>{quoteData?.contract ? quoteData?.contract?.ContractNumber : ""}</strong>
                                                </div>
                                                <div className="col-6">
                                                    <label className='mr-2' htmlFor="ContractName">Contract Start Date :</label>
                                                    <strong>{quoteData?.contract && quoteData?.contract?.StartDate ? moment(quoteData?.contract?.StartDate).format('MM/DD/YY') : ""}</strong>
                                                </div>
                                                <div className="col-6">
                                                    <label className='mr-2' htmlFor="ContractName">Contract End Date :</label>
                                                    <strong>{quoteData?.contract && quoteData?.contract?.EndDate ? moment(quoteData?.contract?.EndDate).format('MM/DD/YY') : ""}</strong>
                                                </div>
                                                <div className="col-6">
                                                    <label className='mr-2' htmlFor="ContractName">Partner Name :</label>
                                                    <strong>{quoteData?.partner ? quoteData?.partner?.name : ""}</strong>
                                                </div>
                                                <div className="col-6">
                                                    <label className='mr-2' htmlFor="status">Status :</label>
                                                    <strong>{quoteData?.Status ? quoteData?.Status : ""}</strong>
                                                </div>
                                                {/* <div className="col-6">
                                                    <label className='mr-2' htmlFor="status">Approval Status :</label>
                                                    <strong>{quoteData?.approval ? quoteData?.approval[0]?.status : ""}</strong>
                                                </div>
                                                <div className="col-6">
                                                    <label className='mr-2' htmlFor="description">Approval Date :</label>
                                                    <strong>{quoteData?.approval && quoteData.approval.length > 0 ? moment(quoteData?.approval[0]?.updated_at).format('MM/DD/YY') : ""}</strong>
                                                </div>
                                                <div className="col-6">
                                                    <label className='mr-2' htmlFor="description">Approver Name :</label>
                                                    <strong>{quoteData?.approval && quoteData.approval.length > 0 && quoteData.approval[0]?.approverobject ? quoteData?.approval[0]?.approverobject?.approvername : ""}</strong>
                                                </div> */}
                                                <div className="col-6">
                                                    <label className='mr-2' htmlFor="description">Description :</label>
                                                    <strong>{quoteData?.Description ? quoteData?.Description : ""}</strong>
                                                </div>
                                                <div className="col-6">
                                                    <label className='mr-2' htmlFor="description">Pdf :</label>
                                                    <a
                                                        href={quoteData?.sla?.basepath}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        {quoteData?.sladoc?.file_name_original}
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {quoteData && quoteData.approval && quoteData.approval.length > 0 ?
                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="d-flex flex-wrap">
                                                    <h5 className="card-title mr-2">Approver List</h5>
                                                </div>
                                                <div className="row">
                                                    <div className="col-3">
                                                        <label className='mr-2' htmlFor="subTotal">Approver Name </label>
                                                    </div>
                                                    <div className="col-3">
                                                        <label className='mr-2' htmlFor="subTotal">Status </label>
                                                    </div>
                                                    <div className="col-3">
                                                        <label className='mr-2' htmlFor="subTotal">Comment </label>
                                                    </div>
                                                    <div className="col-3">
                                                        <label className='mr-2' htmlFor="subTotal">Updated At </label>
                                                    </div>
                                                </div>
                                                {quoteData && quoteData.approval && quoteData.approval.length > 0 ?
                                                    quoteData.approval?.map((item: any) =>
                                                        <div className="row mt-2">
                                                            <div className="col-3">
                                                                <div className='mr-2'>
                                                                    <strong>
                                                                        {item.user ? item.user?.full_name : ""}
                                                                    </strong>
                                                                </div>
                                                            </div>
                                                            <div className="col-3">
                                                                <div className='mr-2'>
                                                                    <strong>
                                                                        {item.status ? item?.status : ""}
                                                                    </strong>
                                                                </div>
                                                            </div>
                                                            <div className="col-3">
                                                                <div className='mr-2'>
                                                                    <strong
                                                                        data-toggle="tooltip"
                                                                        data-placement="top"
                                                                        title={item?.comment}
                                                                    >
                                                                        {item.comment ? changeString(item?.comment, 25) + " ..." : ""}
                                                                    </strong>
                                                                </div>
                                                            </div>
                                                            <div className="col-3">
                                                                <div className='mr-2'>
                                                                    <strong>
                                                                        {item.updated_at ? moment(item.updated_at).format('MM/DD/YY') : ""}
                                                                    </strong>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                    :
                                                    null}
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    null
                                }

                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="d-flex flex-wrap">
                                                <h5 className="card-title mr-2">Totals</h5>
                                            </div>
                                            <div className="row">
                                                <div className="col-6">
                                                    <label className='mr-2' htmlFor="subTotal">Sub Total ($) :</label>
                                                    <strong>{quoteData?.Subtotal ? formatNumber(quoteData?.Subtotal) : ""}</strong>
                                                </div>
                                                <div className="col-6">
                                                    <label className='mr-2' htmlFor="tax">Tax (%) :</label>
                                                    <strong>{quoteData?.Tax ? quoteData?.Tax : ""}</strong>
                                                </div>
                                                <div className="col-6">
                                                    <label className='mr-2' htmlFor="discount">Discount (%) :</label>
                                                    <strong>{quoteData?.Discount ? quoteData?.Discount : ""}</strong>
                                                </div>
                                                <div className="col-6">
                                                    <label className='mr-2' htmlFor="shipping">Shipping and Handling ($) :</label>
                                                    <strong>{quoteData?.ShippingCharge ? formatNumber(quoteData?.ShippingCharge) : ""}</strong>
                                                </div>
                                                <div className="col-6">
                                                    <label className='mr-2' htmlFor="totalPrice">Total Price ($) :</label>
                                                    <strong>{quoteData?.TotalPrice ? formatNumber(quoteData?.TotalPrice) : ""}</strong>
                                                </div>
                                                <div className="col-6">
                                                    <label className='mr-2' htmlFor="grandTotal">Grand Total ($) :</label>
                                                    <strong>{quoteData?.GrandTotal ? formatNumber(quoteData?.GrandTotal) : ""}</strong>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="d-flex flex-wrap">
                                                <h5 className="card-title mr-2">Prepared For</h5>
                                            </div>
                                            <div className="row">
                                                <div className="col-6">
                                                    <label className='mr-2' htmlFor="contactName">Contact Name :</label>
                                                    <strong>{quoteData?.contact ? quoteData?.contact?.FirstName + " " + quoteData?.contact?.LastName : ""}</strong>
                                                </div>
                                                <div className="col-6">
                                                    <label className='mr-2' htmlFor="phone">Phone :</label>
                                                    <strong>{quoteData?.Phone ? quoteData?.Phone : ""}</strong>
                                                </div>
                                                <div className="col-6">
                                                    <label className='mr-2' htmlFor="email">Email :</label>
                                                    <strong>{quoteData?.Email ? quoteData?.Email : ""}</strong>
                                                </div>
                                                <div className="col-6">
                                                    <label className='mr-2' htmlFor="fax">Fax :</label>
                                                    <strong>{quoteData?.Fax ? quoteData?.Fax : ""}</strong>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="d-flex flex-wrap">
                                                <h5 className="card-title mr-2">Address Information</h5>
                                            </div>
                                            <div className="row">
                                                <div className="col-6">
                                                    <label className='mr-2' htmlFor="billingName">Bill To Name :</label>
                                                    <strong>{quoteData?.BillingName ? quoteData?.BillingName : ""}</strong>
                                                </div>
                                                <div className="col-6">
                                                    <label className='mr-2' htmlFor="shippingName">Ship To Name :</label>
                                                    <strong>{quoteData?.ShippingName ? quoteData?.ShippingName : ""}</strong>
                                                </div>
                                                <div className="col-6">
                                                    <label className='mr-2' htmlFor="billingStreet">Bill To Street :</label>
                                                    <strong>{quoteData?.BillingStreet ? quoteData?.BillingStreet : ""}</strong>
                                                </div>
                                                <div className="col-6">
                                                    <label className='mr-2' htmlFor="shippingStreet">Ship To Street :</label>
                                                    <strong>{quoteData?.ShippingStreet ? quoteData?.ShippingStreet : ""}</strong>
                                                </div>
                                                <div className="col-6">
                                                    <label className='mr-2' htmlFor="billingCity">Bill To City :</label>
                                                    <strong>{quoteData?.BillingCity ? quoteData?.BillingCity : ""}</strong>
                                                </div>
                                                <div className="col-6">
                                                    <label className='mr-2' htmlFor="shippingCity">Ship To City :</label>
                                                    <strong>{quoteData?.ShippingCity ? quoteData?.ShippingCity : ""}</strong>
                                                </div>
                                                <div className="col-6">
                                                    <label className='mr-2' htmlFor="billingState">Bill To State/Province :</label>
                                                    <strong>{quoteData?.BillingState ? quoteData?.BillingState : ""}</strong>
                                                </div>
                                                <div className="col-6">
                                                    <label className='mr-2' htmlFor="shippingState">Ship To State/Province :</label>
                                                    <strong>{quoteData?.ShippingState ? quoteData?.ShippingState : ""}</strong>
                                                </div>
                                                <div className="col-6">
                                                    <label className='mr-2' htmlFor="billingZip">Bil To Zip/Postal Code :</label>
                                                    <strong>{quoteData?.BillingPostalCode ? quoteData?.BillingPostalCode : ""}</strong>
                                                </div>
                                                <div className="col-6">
                                                    <label className='mr-2' htmlFor="shippingZip">Ship To Zip/Postal Code :</label>
                                                    <strong>{quoteData?.ShippingPostalCode ? quoteData?.ShippingPostalCode : ""}</strong>
                                                </div>
                                                <div className="col-6">
                                                    <label className='mr-2' htmlFor="billingCountry">Bill To Country :</label>
                                                    <strong>{quoteData?.BillingCountry ? quoteData?.BillingCountry : ""}</strong>
                                                </div>
                                                <div className="col-6">
                                                    <label className='mr-2' htmlFor="shippingCountry">Ship To Country :</label>
                                                    <strong>{quoteData?.ShippingCountry ? quoteData?.ShippingCountry : ""}</strong>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="d-flex flex-wrap justify-content-between">
                                                <h5 className="card-title mr-2">Line Items</h5>
                                            </div>
                                            <div className="table-responsive">
                                                <TableContainer
                                                    columns={columns}
                                                    data={lineItemData}
                                                    fetchData={getAllQuoteLineItem}
                                                    loading={loading}
                                                    rawData={rawData}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {data?.tandc ?
                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <label className='mr-2' htmlFor="shippingCountry">Terms & Conditions:</label>
                                                <div
                                                    style={{
                                                        maxHeight: '150px',
                                                        overflowY: 'auto'
                                                    }}
                                                >
                                                    <strong>{data?.tandc ? data?.tandc : ""}</strong>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    : null
                                }

                                {data?.signature && data?.signature?.basepath != '' && data.signatureimg ?
                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="d-flex flex-wrap justify-content-around">
                                                    <h5 className="mb-auto mt-auto">Authorized Signature</h5>

                                                    <div>
                                                        {data?.signature && data?.signature?.basepath != '' && data.signatureimg ?
                                                            <div>
                                                                <img
                                                                    src={data?.signature?.basepath}
                                                                    alt=""
                                                                    className='bg-soft-secondary'
                                                                    style={{ maxHeight: '80px' }}
                                                                />
                                                                <div className='text-center mt-2'>({data?.signature_fullname})</div>
                                                            </div>
                                                            : null
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    : null
                                }

                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className='align-items-center d-flex gap-10 justify-content-center'>
                                                <button
                                                    type='button'
                                                    className="btn btn-success"
                                                    disabled={data?.status && data.status == 'Requested' ? false : true}
                                                    onClick={() => { setShowModal(true); setStatusType('Approved') }}
                                                >
                                                    Approve
                                                </button>
                                                <button
                                                    type='button'
                                                    className="btn btn-danger"
                                                    disabled={data?.status && data.status == 'Requested' ? false : true}
                                                    onClick={() => { setShowModal(true); setStatusType('Rejected') }}
                                                >
                                                    Reject
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
            <Modal
                className='salesforce-modal modal-lndscp'
                show={showModal}
                onHide={() => { close(); reset() }}
            >
                <Modal.Body>
                    <div className="row">
                        <form onSubmit={handleSubmit(onSubmit)} className="w-100" >
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="form-group col-12">
                                                <label htmlFor="comment">Comment <span className='text-danger'>*</span></label>
                                                <Controller
                                                    name={"comment"}
                                                    control={control}
                                                    render={({ field: { value, onChange, onBlur, name, ref } }) => (
                                                        <>
                                                            <textarea
                                                                className={"form-control " + (errors?.comment ? ' border-danger' : '')}
                                                                id="comment"
                                                                name={name}
                                                                onChange={onChange}
                                                                value={value}
                                                                onBlur={onBlur}
                                                                ref={ref}
                                                                placeholder="Enter comment"
                                                            />
                                                        </>
                                                    )}
                                                />
                                                {errors && errors.comment &&
                                                    <span className='text-danger'>{errors?.comment?.message}</span>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className='align-items-center d-flex gap-10 justify-content-center'>
                                            <button type='submit' className='btn btn-primary'>Save</button>
                                            <button
                                                type='button'
                                                className='btn btn-outline-danger'
                                                onClick={() => { close(); reset() }}
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    )
}

export default ApprovalDetails