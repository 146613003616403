import { type } from 'os';
import { ActionExtended } from '../../../_common/interfaces/ActionExtended';
import { User } from '../../../_common/interfaces/models/user';
import { ACTIONS } from '../../../_config'

export interface UserReducer {
  user: User | null;
  token: string | null;
  isExpired: any;
  isAuthenticated: boolean;
  connectedPlatform: any;
  site: any;
  userType: string | null;
  arruser: any;
  leftPannelMenu: any;
}

const initialState: UserReducer = {
  user: null,
  isExpired: false,
  token: null,
  isAuthenticated: false,
  connectedPlatform: null,
  site: null,
  userType: null,
  arruser: null,
  leftPannelMenu: null,
};

const userReducer = (state = initialState, action: ActionExtended) => {
  switch (action.type) {
    case ACTIONS.USER.LOGIN:
      return {
        ...state,
        user: action.payload.user,
        token: action.payload.token || localStorage.getItem('eh-ui-auth-key'),
        isAuthenticated: true,
        connectedPlatform: action.payload.connectedPlatform,
        site: action.payload.site,
        userType: action.payload.userType,
        arruser: action.payload.arruser,
        leftPannelMenu: action.payload.leftPannelMenu,
      };
    case ACTIONS.USER.LOGOUT:
      return {
        ...state,
        user: null,
        token: null,
        isAuthenticated: false,
        connectedPlatform: null,
        site: null,
        userType: null,
        arruser: null,
        leftPannelMenu: null,
      };
    default:
      return state;
  }
};

export default userReducer;
