import React, { useEffect, useRef } from 'react'
import Modal from 'react-bootstrap/Modal'
import { useAppLoader } from '../../_common/hooks/common/appLoaderHook';
import { usePlatformApi } from '../../_common/hooks/api/PlatformApiHook';
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Controller, useForm } from 'react-hook-form';
import DatePicker from 'react-datepicker'
import { getNextDate, handleKeyPressNumber } from '../../_common/functions';
import moment from 'moment';

interface FormValues {
	contractstartdate: any;
	contractterm: string | number;
}

const validationSchema = Yup.object().shape({
	contractterm: Yup.string().required("Term is required"),
});

interface IncomingProps {
	show: boolean;
	close: () => void;
	quoteData: any;
	confirmQuoteStatusEvent: any;
}

const QuoteStatusEditModal = ({ show, close, quoteData, confirmQuoteStatusEvent }: IncomingProps) => {

	const platformApi = usePlatformApi();
	const { showLoader, hideLoader } = useAppLoader();
	const datePickerRef = useRef<any>();


	const { control, formState: { errors }, handleSubmit, setValue, reset } = useForm<FormValues>({
		resolver: yupResolver(validationSchema),
		defaultValues: {
			contractstartdate: "",
			contractterm: "12"
		},
	});


	const onSubmit = (data: any) => {
		console.log(data)
		let params: any = { id: quoteData?.id, Status: "Accepted", contractterm: data?.contractterm };
		if (data.contractstartdate && data?.contractstartdate !== "") {
			params["contractstartdate"] = moment(data.contractstartdate).format("MM/DD/YY")
		}
		confirmQuoteStatusEvent("Accepted", params)
		handleClose()
	}

	const handleClose = () => {
		reset();
		close();
	}

	useEffect(() => {
		if (show && quoteData && quoteData?.contract) {
			if (quoteData?.contract?.EndDate) {
				let nextDate = getNextDate(quoteData?.contract?.EndDate)
				setValue("contractstartdate", nextDate)
			}
		}
	}, [quoteData, show])

	return (
		<React.Fragment>
			<Modal
				className='salesforce-modal'
				show={show}
			>
				<div className="modal-header custom-modal-header">
					<button
						type="button"
						className="close"
						data-dismiss="modal"
						aria-label="Close"
						onClick={handleClose}
					>
						<span aria-hidden="true">×</span>
					</button>
				</div>
				<Modal.Body>
					<div className="row">
						<form onSubmit={handleSubmit(onSubmit)} className="w-100" >
							<div className="col-12">
								<div className="card">
									<div className="card-body">
										<div className="row">
											<div className="form-group col-12">
												<div className="row">
													<div className="form-group col-6">
														<label htmlFor="contractstartdate">Contract Start Date</label><br></br>
														<Controller
															name={"contractstartdate"}
															control={control}
															render={({ field: { value, onChange, onBlur, name, ref } }) => (
																<>
																	<DatePicker
																		selected={value ? new Date(value) : null}
																		onChange={onChange}
																		dateFormat="MM/dd/yy"
																		className={"form-control " + (errors?.contractstartdate ? ' border-danger' : '')}
																		id="contractstartdate"
																		name={name}
																		onBlur={onBlur}
																		ref={ref}
																		value={value}
																		placeholderText="Enter contract start date"
																	/>
																</>
															)}
														/>
														{errors && errors.contractstartdate &&
															<span className='text-danger'>Date is required</span>
														}
													</div>
													<div className="form-group col-6">
														<label htmlFor="contractterm">Contract Term</label>
														<Controller
															name={"contractterm"}
															control={control}
															render={({ field: { value, onChange, onBlur, name, ref } }) => (
																<>
																	<input
																		type="text"
																		className={"form-control " + (errors?.contractterm ? ' border-danger' : '')}
																		id="contractterm"
																		name={name}
																		onChange={onChange}
																		value={value}
																		onBlur={onBlur}
																		ref={ref}
																		placeholder="Enter contract term"
																		onKeyDown={handleKeyPressNumber}
																	/>
																</>
															)}
														/>
														{errors && errors.contractterm &&
															<span className='text-danger'>{errors?.contractterm?.message}</span>
														}
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="col-12">
								<div className='align-items-center d-flex gap-10 justify-content-center'>
									<button type='submit' className='btn btn-primary'>Submit</button>
									<button
										type='button'
										className='btn btn btn-outline-danger'
										onClick={handleClose}
									>
										Cancel
									</button>
								</div>
							</div>
						</form>
					</div>
				</Modal.Body>
			</Modal>
		</React.Fragment>
	)
}

export default QuoteStatusEditModal