import React, { useEffect, useMemo, useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import Footer from '../components/commonComponents/Footer'
import Header from '../components/commonComponents/Header'
import LeftPanel from '../components/commonComponents/LeftPanel'
import TableContainer from '../components/commonComponents/TableContainer'
import { usePlatformApi } from '../_common/hooks/api/PlatformApiHook'
import { useAppLoader } from '../_common/hooks/common/appLoaderHook'
import { URLS } from '../_config'
import { toast } from 'react-hot-toast'
import { changeString, formatNumber } from '../_common/functions'
import moment from 'moment'
import ForecastEndDate from '../components/modals/forecastEndDate.modal'
import { AiOutlineEdit } from 'react-icons/ai'
import ForecastUpdateModal from '../components/modals/forecastUpdate.modal'
import FilterRow from '../components/commonComponents/FilterRow'
import ForecastBulkUpdateModal from '../components/modals/forecastBulkUpdate.modal'

const Forecast = () => {

    const platformApi = usePlatformApi();
    const { showLoader, hideLoader } = useAppLoader();

    const [contractData, setContractData] = useState<any>(null)
    const [data, setData] = useState<any>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [showModal, setShowModal] = useState<boolean>(false)
    const [showMassUpdateModal, setShowMassUpdateModal] = useState<boolean>(false)
    const [selectedContract, setSelectedContract] = useState<any>(null)
    const [forecastSummary, setForecastSummary] = useState<any>(null)
    const [filterDetails, setFilterDetails] = useState<any>({})
    const [showForecastModal, setShowForecastModal] = useState<boolean>(false)
    const [selectedContractId, setSelectedContractId] = useState<any>(null)
    const [selectedContractValue, setSelectedContractValue] = useState<any>(null)
    const [selectedContractColumn, setSelectedContractColumn] = useState<any>(null)
    const [selectedContractRow, setSelectedContractRow] = useState<any>([])
    const [selectedContractIds, setSelectedContractIds] = useState<any>([])

    const [searchParams, setSearchParams] = useSearchParams();
    const quarterValue = searchParams.get("quarter")
    const typeOfContract = searchParams.get("type")

    const handleRowClick = (rowId: number, value: string, check: boolean) => {
        if (check) {
            setSelectedContractRow((prevSelectedContractRows: any) => [...prevSelectedContractRows, rowId]);
            setSelectedContractIds((prevSelectedContractId: any) => [...prevSelectedContractId, value]);
        } else {
            setSelectedContractRow((prevSelectedContractRows: any[]) => prevSelectedContractRows.filter(id => id !== rowId));
            setSelectedContractIds((prevSelectedContractId: any[]) => prevSelectedContractId.filter(cntctId => cntctId !== value));
        }
    };

    const getContractData = (page = 1) => {
        showLoader();
        setLoading(true);
        let params: any = quarterValue && typeOfContract ? {
            page: page, for: 'forecast', year: new Date().getFullYear(), quarter: quarterValue, typeOfContract: typeOfContract
        } :
            { page: page, for: 'forecast', year: new Date().getFullYear() }
        if (filterDetails && Object.keys(filterDetails).length > 0) {
            Object.assign(params, filterDetails)
        }
        platformApi.getAllContract(params,
            (message: string, resp: any) => {
                // console.log(resp);
                setLoading(false);
                setContractData(resp.data.list);
                setData(resp.data.list.data);
                hideLoader();
            }, (message: string) => {
                setLoading(false);
                hideLoader();
            })
    }

    const getForeCastSummary = () => {
        let params: any = quarterValue && typeOfContract ? { quarter: quarterValue, typeOfContract: typeOfContract } : filterDetails;
        showLoader();
        platformApi.getForeCastSummary(params, (message: string, resp: any) => {
            setForecastSummary(resp?.data?.list)
            hideLoader();
        }, (message: string) => {
            hideLoader();
        })
    }

    const onToggleForecast = (data: any, toggle: boolean) => {
        // console.log(data.id);
        setShowModal(toggle);
        setSelectedContract(data);
        let params: any = {
            id: data.id,
            Forecasted: toggle
        };
        if (!toggle) {
            showLoader()
            platformApi.manageContract(params, (message: string, resp: any) => {
                // console.log(message, resp)
                toast.success(message)
                getContractData()
                hideLoader()
            }, (message: string) => {
                // console.log(message)
                toast.error(message)
                hideLoader()
            })
        }
    }

    const handleModalClose = () => {
        setShowModal(false);
        setSelectedContract(null)
    }

    const openForecastUpdateModalClose = (column: any, value: any, contractId: any) => {
        // console.log(column, value, contractId)
        setShowForecastModal(true);
        setSelectedContractId(contractId);
        setSelectedContractValue(value);
        setSelectedContractColumn(column);
    }

    const closeForecastUpdateModalClose = () => {
        setShowForecastModal(false);
        setSelectedContractId(null);
        setSelectedContractValue(null);
        setSelectedContractColumn(null);
    }

    const EditBtn = ({ column, value, id }: any) => {

        return (
            <span
                data-toggle="tooltip"
                data-placement="top"
                title="update/edit"
            >
                <AiOutlineEdit
                    onClick={() => openForecastUpdateModalClose(column, value, id)}
                    className='cursor-pointer ml-2'
                />
            </span >
        )
    }

    const columns = useMemo(
        () => [
            {
                Header: "Action",
                accessor: 'id',
                Cell: ({ column, value, row }: any) => (
                    <>
                        <input
                            className='mr-2 cursor-pointer'
                            type="checkbox"
                            checked={selectedContractRow.includes(row.id)}
                            onChange={(e) => { handleRowClick(row.id, value, e.target.checked) }}
                        />
                    </>
                )
            },
            {
                Header: "Account Name",
                accessor: "account.Name",
            },
            {
                Header: "Contract",
                accessor: "ContractNumber",
                className: "nowrap",
            },
            {
                Header: "Contract End Date",
                accessor: "EndDate",
                Cell: ({ column, value, row }: any) => (
                    <>
                        {value ? moment(value).format("MM/DD/YY") : ""}
                    </>
                )
            },
            // {
            //     Header: "Account Owner",
            //     accessor: "pocobj.name",
            // },
            {
                Header: "Region",
                accessor: "region.region_name",
            },
            {
                Header: "Progress/Stage",
                accessor: "RenewalEventPoint",
                Cell: ({ column, value, row }: any) => (
                    <div className="progress">
                        <div
                            className="progress-bar"
                            role="progressbar"
                            style={{ width: `${value}%` }}
                            aria-valuenow={25}
                            aria-valuemin={0}
                            aria-valuemax={100}
                            data-toggle="tooltip"
                            data-placement="top"
                            title={row.original?.RenewalEvent}
                        >
                        </div>
                        <div className="progressHide"></div>
                    </div>
                ),
            },
            {
                Header: "ARR ($)",
                accessor: "arr",
                Cell: ({ column, value, row }: any) => (
                    <div>
                        {value ? formatNumber(value) : '0.00'}
                    </div>
                )
            },
            {
                Header: "New ARR ($)",
                accessor: "newarr",
                Cell: ({ column, value, row }: any) =>
                    <>
                        {value ? formatNumber(value) : '0.00'}
                    </>,
            },
            {
                Header: "In Forecast",
                accessor: "Forecasted",
                Cell: ({ column, value, row }: any) => (
                    <div className='cstm-switch'>
                        <input
                            type="checkbox"
                            id={`switch${row.id}`}
                            defaultChecked={value === "1"}
                            onChange={(e: any) => { onToggleForecast(row.original, e.target.checked) }}
                            checked={value === "1"}
                        />
                        <label htmlFor={`switch${row.id}`} data-on-label="on" data-off-label="off"></label>
                    </div>
                )
            },
            {
                Header: "Most Likely Forecast  ($)",
                accessor: "mostlikely_forecast",
                Cell: ({ column, value, row }: any) => (
                    <div>
                        {value ? formatNumber(value) : '0.00'}
                        <EditBtn column={column} value={value} id={row.original.id} />
                    </div>
                )
            },
            {
                Header: "Best Case Forecast ($)",
                accessor: "bestcase",
                Cell: ({ column, value, row }: any) => (
                    <div>
                        {value ? formatNumber(value) : '0.00'}
                        <EditBtn column={column} value={value} id={row.original.id} />
                    </div>
                )
            },
            {
                Header: "Actual Renewed ($)",
                accessor: "renewAmt",
                Cell: ({ column, value, row }: any) =>
                    <>
                        {value ? formatNumber(value) : '0.00'}
                    </>,
            },
            {
                Header: "Actual Base Renewed ($)",
                accessor: "ContractValue",
                Cell: ({ column, value, row }: any) =>
                    <>
                        {value ? formatNumber(value) : '0.00'}
                    </>,
            },
            {
                Header: "Renewal Risk Level",
                accessor: "RenewalRiskLevel",
            },
            {
                Header: "Forecast Close Date",
                accessor: "forecast_closedate",
                Cell: ({ column, value, row }: any) => (
                    <>
                        {value ? moment(value).format("MM/DD/YY") : ""}
                        <EditBtn column={column} value={value} id={row.original.id} />
                    </>
                )
            },
            {
                Header: "Business Channel",
                accessor: "account.BusinessChannel",
            },
            {
                Header: "Next Steps",
                accessor: "nextsteps",
                Cell: ({ column, value, row }: any) => (
                    <div>
                        <span
                            data-toggle="tooltip"
                            data-placement="top"
                            title={value}

                        >
                            {value ? changeString(value, 16) + " ..." : ""}
                        </span>
                        <EditBtn column={column} value={value} id={row.original.id} />
                    </div>
                ),
            },
            {
                Header: "Renewal Notes",
                accessor: "RenewalNote",
                Cell: ({ column, value, row }: any) => (
                    <div>
                        <span
                            data-toggle="tooltip"
                            data-placement="top"
                            title={value}

                        >
                            {value ? changeString(value, 16) + " ..." : ""}
                        </span>
                        <EditBtn column={column} value={value} id={row.original.id} />
                    </div>
                ),
            },
            {
                Header: "Close Plans",
                accessor: "closeplan",
                Cell: ({ column, value, row }: any) => (
                    <div>
                        <span
                            data-toggle="tooltip"
                            data-placement="top"
                            title={value}

                        >
                            {value ? changeString(value, 16) + " ..." : ""}
                        </span>
                        <EditBtn column={column} value={value} id={row.original.id} />
                    </div>
                ),
            },
            {
                Header: "Sub motion",
                accessor: "submotion",
            },
        ],
        [contractData?.length, selectedContractRow]
    )

    const handleMassUpdate = () => {
        if (selectedContractRow && selectedContractRow.length == 0) {
            return toast.error('please select atleast one to proceed')
        }
        setShowMassUpdateModal(true)

    }

    const handleCloseMassUpdate = () => {
        setShowMassUpdateModal(false)
        setSelectedContractRow([])
        setSelectedContractIds([])
    }

    useEffect(() => {
        getContractData();
        getForeCastSummary();
    }, [!quarterValue])


    return (
        <React.Fragment>
            <ForecastEndDate
                show={showModal}
                close={handleModalClose}
                selectedContract={selectedContract}
                // getContractData={getContractData}
                callbackFunctions={[
                    getContractData,
                    getForeCastSummary
                ]}
            />
            <ForecastUpdateModal
                show={showForecastModal}
                close={closeForecastUpdateModalClose}
                contractId={selectedContractId}
                value={selectedContractValue}
                fieldDetail={selectedContractColumn}
                // getContractData={getContractData}
                callbackFunctions={[
                    getContractData,
                    getForeCastSummary
                ]}
            />
            <ForecastBulkUpdateModal
                show={showMassUpdateModal}
                close={handleCloseMassUpdate}
                selectedContractIds={selectedContractIds}
                // getContractData={getContractData}
                callbackFunctions={[
                    getContractData,
                    getForeCastSummary
                ]}
            />
            <div id="layout-wrapper">
                <Header />
                <LeftPanel />
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            {/* start page title */}
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-flex align-items-center justify-content-between">
                                        <h4 className="mb-0 font-size-18">Forecast</h4>
                                        <div className="page-title-right">
                                            <ol className="breadcrumb m-0">
                                                <li className="breadcrumb-item">
                                                    <Link to={URLS.REVENUE}>Revenue</Link>
                                                </li>
                                                <li className="breadcrumb-item active">Forecast</li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="row dashboard-top-panel">
                                        <div className="col-lg-3 col-md-6 col-12">
                                            <div className="card mini-stats-wid card-border-bottom">
                                                <div className="card-body"
                                                // onClick={() => gotToContractPage('FY', 'arr_forcasted')} style={{ cursor: "pointer" }}
                                                >
                                                    <div className="d-flex flex-column gap-10">
                                                        <div className="mr-3">
                                                            <span className="text mb-2">Customers: {" "}</span>
                                                            <strong>{forecastSummary?.forcasted?.customers}</strong>
                                                        </div>
                                                        <div className="mr-3">
                                                            <span className="text mb-2">ARR: {" "}</span>
                                                            <strong>${forecastSummary?.forcasted?.arr}</strong>
                                                        </div>
                                                        <div className="mr-3">
                                                            <span className="text mb-2">MRR: {" "}</span>
                                                            <strong>${forecastSummary?.forcasted?.mrr}</strong>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6 col-12">
                                            <div className="card blog-stats-wid card-border-bottom">
                                                <div className="card-body"
                                                // onClick={() => gotToContractPage('FY', 'expansion_forcasted')} style={{ cursor: "pointer" }}
                                                >
                                                    <div className="d-flex flex-column gap-10">
                                                        <div className="mr-3">
                                                            <span className="text mb-2">Most Likely Forecast: {" "}</span>
                                                            <strong>${forecastSummary?.forcasted?.mostlikely}</strong>
                                                        </div>
                                                        <div className="mr-3">
                                                            <span className="text mb-2">Best Case Forecast: {" "}</span>
                                                            <strong>${forecastSummary?.forcasted?.bestcase}</strong>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6 col-12">
                                            <div className="card blog-stats-wid card-border-bottom">
                                                <div className="card-body"
                                                // onClick={() => gotToContractPage('FY', 'renewed')} style={{ cursor: "pointer" }}
                                                >
                                                    <div className="d-flex flex-column gap-10">
                                                        <div className="mr-3">
                                                            <span className="text mb-2">Forecasted Net Revenue Retention Rate: {" "}</span>
                                                            <strong>{forecastSummary?.forcasted?.retaintionrate}%</strong>
                                                        </div>
                                                        <div className="mr-3">
                                                            <span className="text mb-2">NRR: {" "}</span>
                                                            <strong>{forecastSummary?.forcasted?.nrr}%</strong>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6 col-12">
                                            <div className="card blog-stats-wid card-border-bottom">
                                                <div className="card-body"
                                                // onClick={() => gotToContractPage('FY', 'churned_forecasted')} style={{ cursor: "pointer" }}
                                                >
                                                    <div className="d-flex flex-column gap-10">
                                                        <div className="mr-3">
                                                            <span className="text mb-2">New ARR Forecast: {" "}</span>
                                                            <strong>${forecastSummary?.forcasted?.newarr}</strong>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6 col-12">
                                            <div className="card blog-stats-wid card-border-bottom">
                                                <div className="card-body"
                                                // onClick={() => gotToContractPage('FY', 'churned_forecasted')} style={{ cursor: "pointer" }}
                                                >
                                                    <div className="d-flex flex-column gap-10">
                                                        <div className="mr-3">
                                                            <span className="text mb-2">AI Level Forecast: {" "}</span>
                                                            <strong>${forecastSummary?.forcasted?.alllevel}</strong>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6 col-12">
                                            <div className="card blog-stats-wid card-border-bottom">
                                                <div className="card-body"
                                                // onClick={() => gotToContractPage('FY', 'churned_forecasted')} style={{ cursor: "pointer" }}
                                                >
                                                    <div className="d-flex flex-column gap-10">
                                                        <div className="mr-3">
                                                            <span className="text mb-2">Base Renewal Closed: {" "}</span>
                                                            <strong>${forecastSummary?.forcasted?.bestrenewalclosed}</strong>
                                                        </div>
                                                        <div className="mr-3">
                                                            <span className="text mb-2">New ARR Closed: {" "}</span>
                                                            <strong>${forecastSummary?.forcasted?.newarrcosed}</strong>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6 col-12">
                                            <div className="card blog-stats-wid card-border-bottom">
                                                <div className="card-body"
                                                // onClick={() => gotToContractPage('FY', 'churned_forecasted')} style={{ cursor: "pointer" }}
                                                >
                                                    <div className="d-flex flex-column gap-10">
                                                        <div className="mr-3">
                                                            <span className="text mb-2">Forecasted Churn and Contraction: {" "}</span>
                                                            <strong>${forecastSummary?.forcasted?.churnandcontraction}</strong>
                                                        </div>
                                                        <div className="mr-3">
                                                            <span className="text mb-2">Customer Retention Rate: {" "}</span>
                                                            <strong>{forecastSummary?.forcasted?.customerretaintionrate}%</strong>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6 col-12">
                                            <div className="card blog-stats-wid card-border-bottom">
                                                <div className="card-body"
                                                // onClick={() => gotToContractPage('FY', 'churned_forecasted')} style={{ cursor: "pointer" }}
                                                >
                                                    <div className="d-flex flex-column gap-10">
                                                        <div className="mr-3">
                                                            <span className="text mb-2">Forecasted Gross Revenue Retention Rate: {" "}</span>
                                                            <strong>{forecastSummary?.forcasted?.grossrevenewretaintionrate}%</strong>
                                                        </div>
                                                        <div className="mr-3">
                                                            <span className="text mb-2">GRR: {" "}</span>
                                                            <strong>{forecastSummary?.forcasted?.grr}%</strong>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="card">
                                <div className="card-body">
                                    <FilterRow
                                        type="forecast"
                                        filterDetails={filterDetails}
                                        setFilterDetails={setFilterDetails}
                                        defaultYear={true}
                                        callbackFunctions={[
                                            getContractData,
                                            getForeCastSummary
                                        ]}
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="d-flex flex-wrap justify-content-between">
                                                <h5 className="card-title mr-2">Forecasts</h5>
                                                <button
                                                    className="btn btn-success"
                                                    onClick={handleMassUpdate}
                                                >
                                                    Update
                                                </button>
                                            </div>
                                            <div className="table-responsive">
                                                <TableContainer
                                                    columns={columns}
                                                    data={data}
                                                    fetchData={getContractData}
                                                    loading={loading}
                                                    rawData={contractData}
                                                    clickFunc={true}
                                                    extraLarge={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </React.Fragment>
    )
}

export default Forecast