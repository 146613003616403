import React, { useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import Footer from '../components/commonComponents/Footer'
import Header from '../components/commonComponents/Header'
import LeftPanel from '../components/commonComponents/LeftPanel'
import { URLS } from '../_config'
import UpsellsList from '../components/dataListing/UpsellsDataList'
import CrossSellsList from '../components/dataListing/CrossSellsDataList'
import CommonBoxDetails from '../components/commonComponents/CommonBoxDetails'
import FilterRow from '../components/commonComponents/FilterRow'

const Upside = () => {

    const [filterDetails, setFilterDetails] = useState<any>({})
    const [doFilter, setDoFilter] = useState<boolean>(false)

    const [searchParams, setSearchParams] = useSearchParams()
    const quarterValue = searchParams.get("quarter")
    const typeOfContract = searchParams.get("type")

    return (
        <React.Fragment>
            <div id="layout-wrapper">
                <Header />
                <LeftPanel />
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-flex align-items-center justify-content-between">
                                        <h4 className="mb-0 font-size-18">Upside</h4>
                                        <div className="page-title-right">
                                            <ol className="breadcrumb m-0">
                                                <li className="breadcrumb-item">
                                                    <Link to={URLS.REVENUE}>Revenue</Link>
                                                </li>
                                                <li className="breadcrumb-item active">Upside</li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-12">
                                    <CommonBoxDetails
                                        filterDetails={filterDetails}
                                        doFilter={doFilter}
                                        setDoFilter={setDoFilter}
                                    />
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-body">
                                    <FilterRow
                                        type="upside"
                                        filterDetails={filterDetails}
                                        setFilterDetails={setFilterDetails}
                                        setDoFilter={setDoFilter}
                                        defaultYear={true}
                                    />
                                </div>
                            </div>

                            {/* <div className="row">
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="d-flex flex-wrap">
                                                <h5 className="card-title mr-2">Month by Month</h5>
                                            </div>
                                            <div id="mixed_chart" className="apex-charts" dir="ltr" />
                                        </div>
                                    </div>
                                </div>
                            </div> */}

                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="d-flex flex-wrap mb-4">
                                                <h4 className="card-title">
                                                    Expansion &amp; Cross Sell Accounts
                                                </h4>
                                                <div className="ml-auto d-flex">
                                                </div>
                                            </div>
                                            <div className="risk-table-nav">
                                                <ul className="nav nav-tabs nav-tabs-custom" role="tablist">
                                                    <li className="nav-item">
                                                        <a
                                                            className="nav-link active"
                                                            data-toggle="tab"
                                                            href="#buy"
                                                            role="tab"
                                                        >
                                                            Upsell
                                                        </a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a
                                                            className="nav-link"
                                                            data-toggle="tab"
                                                            href="#sell"
                                                            role="tab"
                                                        >
                                                            Output
                                                        </a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a
                                                            className="nav-link"
                                                            data-toggle="tab"
                                                            href="#sell1"
                                                            role="tab"
                                                        >
                                                            Cross Sell
                                                        </a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a
                                                            className="nav-link"
                                                            data-toggle="tab"
                                                            href="#sell2"
                                                            role="tab"
                                                        >
                                                            Stage
                                                        </a>
                                                    </li>
                                                </ul>
                                                <div className="tab-content pt-4 pb-4">
                                                    <div className="tab-pane active" id="buy" role="tabpanel">
                                                        <UpsellsList
                                                            quarterValue={quarterValue}
                                                            typeOfContract={typeOfContract}
                                                            filterDetails={filterDetails}
                                                            doFilter={doFilter}
                                                            setDoFilter={setDoFilter}
                                                        />
                                                    </div>
                                                    <div className="tab-pane" id="sell" role="tabpanel">
                                                        <h4>Comming Soon</h4>
                                                    </div>
                                                    <div className="tab-pane" id="sell1" role="tabpanel">
                                                        <CrossSellsList
                                                            quarterValue={quarterValue}
                                                            typeOfContract={typeOfContract}
                                                            filterDetails={filterDetails}
                                                            doFilter={doFilter}
                                                            setDoFilter={setDoFilter}
                                                        />
                                                    </div>
                                                    <div className="tab-pane" id="sell2" role="tabpanel">
                                                        <h4>Comming Soon</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="d-flex flex-wrap mb-4">
                                                <h4 className="card-title">Entitlements &amp; Usage</h4>
                                            </div>
                                            <div className="risk-table-nav">
                                                <ul className="nav nav-tabs nav-tabs-custom" role="tablist">
                                                    <li className="nav-item">
                                                        <a
                                                            className="nav-link active"
                                                            data-toggle="tab"
                                                            href="#buy1"
                                                            role="tab"
                                                        >
                                                            Upsell
                                                        </a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a
                                                            className="nav-link"
                                                            data-toggle="tab"
                                                            href="#sell3"
                                                            role="tab"
                                                        >
                                                            Output
                                                        </a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a
                                                            className="nav-link"
                                                            data-toggle="tab"
                                                            href="#sell4"
                                                            role="tab"
                                                        >
                                                            Cross Sell
                                                        </a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a
                                                            className="nav-link"
                                                            data-toggle="tab"
                                                            href="#sell5"
                                                            role="tab"
                                                        >
                                                            Stage
                                                        </a>
                                                    </li>
                                                </ul>
                                                <div className="tab-content pt-4 pb-4">
                                                    <div className="tab-pane active" id="buy1" role="tabpanel">
                                                        <UpsellsList
                                                            quarterValue={quarterValue}
                                                            typeOfContract={typeOfContract}
                                                            filterDetails={filterDetails}
                                                            doFilter={doFilter}
                                                            setDoFilter={setDoFilter}
                                                        />
                                                    </div>
                                                    <div className="tab-pane" id="sell3" role="tabpanel">
                                                        <h4>Comming Soon</h4>
                                                    </div>
                                                    <div className="tab-pane" id="sell4" role="tabpanel">
                                                        <CrossSellsList
                                                            quarterValue={quarterValue}
                                                            typeOfContract={typeOfContract}
                                                            filterDetails={filterDetails}
                                                            doFilter={doFilter}
                                                            setDoFilter={setDoFilter}
                                                        />
                                                    </div>
                                                    <div className="tab-pane" id="sell5" role="tabpanel">
                                                        <h4>Comming Soon</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Footer />
                </div>
            </div>
        </React.Fragment>
    )
}

export default Upside