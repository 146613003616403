import React, { useEffect, useMemo, useState } from 'react'
import { usePlatformApi } from '../../_common/hooks/api/PlatformApiHook'
import { useAppLoader } from '../../_common/hooks/common/appLoaderHook';
import TableContainer from '../commonComponents/TableContainer'
import DeleteButtonCell from '../actionsBtns/DeleteButtonCell';

interface IncomingProps {
    AccountId: string;
    show: boolean;
    type?: any;
    showContractModal?: boolean;
}

const AccountTeamListing = ({ AccountId, show, type, showContractModal }: IncomingProps) => {

    const platformApi = usePlatformApi();
    const { showLoader, hideLoader } = useAppLoader();

    const [accountTeamData, setAccountTeamData] = useState<any>(null)
    const [data, setData] = useState<any>([])
    const [loading, setLoading] = useState<boolean>(false)

    const getAccountTeamData = (page = 1) => {
        showLoader();
        setLoading(true);
        if (type && type == 'contract') {
            platformApi.getContractAccountTeam({
                page: page,
                ContractId: AccountId
            },
                (message: string, resp: any) => {
                    // console.log(resp);
                    setLoading(false);
                    setAccountTeamData(resp.data.list);
                    setData(resp.data.list.data);
                    hideLoader();
                }, (message: string) => {
                    setLoading(false);
                    hideLoader();
                })
        }
        else {
            platformApi.getAccountTeam({
                page: page,
                AccountId: AccountId,
                limit: 4
            },
                (message: string, resp: any) => {
                    // console.log(resp);
                    setLoading(false);
                    setAccountTeamData(resp.data.list);
                    setData(resp.data.list.data);
                    hideLoader();
                }, (message: string) => {
                    setLoading(false);
                    hideLoader();
                })
        }
    }

    const deleteAccountTeam = (id: any) => {
        return new Promise((resolve: any, reject: any) => {
            if (type && type == 'contract') {
                platformApi.accoungContractTeamDestroy({
                    id
                }, (message: string, resp: any) => {
                    resolve(message)
                }, (message: string) => {
                    reject(message);
                });
            }
            else {
                platformApi.accoungTeamDestroy({
                    id
                }, (message: string, resp: any) => {
                    resolve(message)
                }, (message: string) => {
                    reject(message);
                });
            }
        })
    }

    const columns = useMemo(
        () => [
            {
                Header: 'Actions',
                accessor: "id",
                Cell: ({ column, value }: any) =>
                    <>
                        <DeleteButtonCell
                            column={column}
                            value={value}
                            deleteFunc={deleteAccountTeam}
                            callbackFunc={[getAccountTeamData]}
                            type={"Account Team"}
                        />
                    </>,
            },
            {
                Header: "Name",
                accessor: "name",
                className: "nowrap",
            },
            {
                Header: "Email",
                accessor: "email",
            },
            {
                Header: "Role",
                accessor: "role",
                className: "nowrap",
            },
        ],
        []
    )

    useEffect(() => {
        getAccountTeamData();
    }, [show])

    useEffect(() => {
        if (!showContractModal) {
            getAccountTeamData();
        }
    }, [showContractModal])


    return (
        <React.Fragment>
            <div className="table-responsive">
                <TableContainer
                    columns={columns}
                    data={data}
                    fetchData={getAccountTeamData}
                    loading={loading}
                    rawData={accountTeamData}
                    small={true}
                    cstmClassName={"table-scroll-overflow"}
                />
            </div>
        </React.Fragment>
    )
}

export default AccountTeamListing