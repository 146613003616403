import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Controller, useForm } from 'react-hook-form';
import { useAppLoader } from '../../_common/hooks/common/appLoaderHook';
import { toast } from 'react-hot-toast';
import { useCommonApi } from '../../_common/hooks/api/common/commonApiHook';
import { handleBlur, handleFocus, handleKeyPressDecimalNumber, handleKeyUpDown, handleWheel } from '../../_common/functions';
import Select from 'react-select';
import { taxForOption } from '../../_common/staticData';

interface FormValues {
	taxname: string,
	taxpercent: number,
	taxfor: any,
	status: boolean
}

const validationSchema = Yup.object().shape({
	taxname: Yup.string().required("Tax name is required"),
	taxpercent: Yup.number().max(100, 'Percentage can not be grater than 100').required("Tax percent is required"),
	// .test('taxpercent', 'Percentage can not be grater than 100', (value: any) => {
	// 	return +value > 100;
	// }),
	taxfor: Yup.object().required("Tax for is required"),
});

interface IncomingProps {
	show: boolean;
	close: () => void;
	id?: any
	isEdit: boolean;
	callbackFunc: any;
}

const AddTaxModal = ({ show, close, id, isEdit = false, callbackFunc }: IncomingProps) => {

	const commonApi = useCommonApi();
	const { showLoader, hideLoader } = useAppLoader();

	const [taxData, setTaxData] = useState<any>(null)
	const [loading, setLoading] = useState<boolean>(false)

	const { control, formState: { errors }, handleSubmit, setValue, reset } = useForm<FormValues>({
		resolver: yupResolver(validationSchema),
		defaultValues: {
			taxname: "",
			taxpercent: undefined,
			taxfor: "",
			status: false
		},
	});

	const getZoneData = () => {
		showLoader();
		setLoading(true)
		commonApi.getAllTax({ id: id },
			(message: string, resp: any) => {
				// console.log(resp);
				setTaxData(resp.data.list);
				setLoading(false)
				hideLoader();
			}, (message: string) => {
				hideLoader();
				setLoading(false)
			})
	}

	const onSubmit = (data: any) => {
		// console.log({ data })
		let params: any = {
			taxname: data?.taxname,
			taxpercent: data?.taxpercent,
			taxfor: data?.taxfor?.value,
			status: data?.status && data?.status === true ? "1" : "0"
		}
		if (isEdit) {
			if (taxData && taxData.id) {
				params["id"] = taxData.id
			}
		}
		// console.log(params)
		showLoader()
		commonApi.manageTax(params, (message: string, resp: any) => {
			// console.log(message, resp)
			toast.success(message)
			reset()
			close()
			hideLoader()
			callbackFunc()
		}, (message: string) => {
			// console.log(message)
			toast.error(message)
			hideLoader()
		})
	}

	useEffect(() => {
		if (id) {
			getZoneData()
		}
	}, [id])

	useEffect(() => {
		// console.log(taxData)
		if (taxData) {
			setValue('taxname', taxData?.taxname)
			setValue('taxpercent', taxData?.taxpercent)
			setValue('taxfor', taxForOption?.find((item: any) => item.value === taxData?.taxfor))
			setValue('status', taxData?.status && taxData?.status === "1" ? true : false)
		}
	}, [taxData, taxForOption])

	return (
		<React.Fragment>
			<Modal
				className='salesforce-modal modal-lndscp'
				show={show}
				onHide={() => { close(); reset() }}
			>
				<div className="modal-header custom-modal-header">
					<button
						type="button"
						className="close"
						data-dismiss="modal"
						aria-label="Close"
						onClick={() => { close(); reset() }}
					>
						<span aria-hidden="true">×</span>
					</button>
				</div>
				<Modal.Body>
					{loading ?
						<div>
							Loading......
						</div>
						:
						<div className="row">
							<form onSubmit={handleSubmit(onSubmit)} className="w-100" >
								<div className="col-12">
									<div className="card">
										<div className="card-body">
											<div className="d-flex flex-wrap">
												<h5 className="card-title mr-2">Tax Information</h5>
											</div>
											<div className="row">
												<div className="form-group col-6">
													<label htmlFor="taxname">Tax name <span className='text-danger'>*</span></label>
													<Controller
														name={"taxname"}
														control={control}
														render={({ field: { value, onChange, onBlur, name, ref } }) => (
															<>
																<input
																	type="text"
																	className={"form-control " + (errors?.taxname ? ' border-danger' : '')}
																	id="taxname"
																	name={name}
																	onChange={onChange}
																	value={value}
																	onBlur={onBlur}
																	ref={ref}
																	placeholder="Enter tax name"
																/>
															</>
														)}
													/>
													{errors && errors.taxname &&
														<span className='text-danger'>{errors?.taxname?.message}</span>
													}
												</div>
												<div className="form-group col-6">
													<label htmlFor="taxpercent">Tax (%) <span className='text-danger'>*</span></label>
													<Controller
														name={"taxpercent"}
														control={control}
														render={({ field: { value, onChange, onBlur, name, ref } }) => (
															<>
																<input
																	type="number"
																	className={"form-control " + (errors?.taxpercent ? ' border-danger' : '')}
																	id="taxpercent"
																	name={name}
																	value={value}
																	onChange={onChange}
																	onBlur={(e) => { onBlur(); handleBlur(e) }}
																	onFocus={(e) => handleFocus(e)}
																	onKeyDown={handleKeyUpDown}
																	onWheel={handleWheel}
																	step={"0.01"}
																	min={0}
																	ref={ref}
																	placeholder="Enter tax percentage"
																	onKeyPress={(event: any) => handleKeyPressDecimalNumber(event)}
																/>
															</>
														)}
													/>
													{errors && errors.taxpercent && ["max", "required"].includes(errors.taxpercent.type) ?
														<span className='text-danger'>{errors?.taxpercent?.message}</span>
														:
														null
													}
													{errors && errors.taxpercent && !["max", "required"].includes(errors.taxpercent.type) &&
														<span className='text-danger'>Tax percent is required</span>
													}
												</div>
												<div className="form-group col-6">
													<label htmlFor="taxfor">Tax For <span className='text-danger'>*</span></label>
													<Controller
														name={"taxfor"}
														control={control}
														render={({ field: { value, onChange, onBlur, name, ref } }) => (
															<>
																<Select
																	className={"select-box" + (errors?.taxfor ? ' border-danger' : '')}
																	id="taxfor"
																	options={taxForOption ? taxForOption : []}
																	placeholder={""}
																	onChange={onChange}
																	value={value}
																	defaultValue={value}
																/>
															</>
														)}
													/>
													{errors && errors.taxfor &&
														<span className='text-danger'>Tax for is required</span>
													}
												</div>
												<div className="form-group col-6">
													<div className='d-flex flex-column'>
														<label htmlFor="status">Status <span className='text-danger'>*</span></label>
														<Controller
															name={"status"}
															control={control}
															render={({ field: { value, onChange, onBlur, name, ref } }) => (
																<div className='cstm-switch'>
																	<input
																		type="checkbox"
																		id={"new_account"}
																		className={"form-control"}
																		name={name}
																		onChange={onChange}
																		value={value === true ? "1" : "0"}
																		onBlur={onBlur}
																		defaultChecked={taxData?.status === "1"}
																		checked={value === true}
																	/>
																	<label htmlFor="new_account" data-on-label="on" data-off-label="off"></label>
																</div>
															)}
														/>
													</div>
													{errors && errors.status &&
														<span className='text-danger'>{errors?.status?.message}</span>
													}
												</div>
											</div>
										</div>
									</div>
								</div>

								<div className="col-12">
									<div className='align-items-center d-flex gap-10 justify-content-center'>
										<button type='submit' className='btn btn-primary'>Save</button>
										<button
											type='button'
											className='btn btn-outline-danger'
											onClick={() => { close(); reset() }}
										>
											Cancel
										</button>
									</div>
								</div>
							</form>
						</div>
					}
				</Modal.Body>
			</Modal>
		</React.Fragment>
	)
}

export default AddTaxModal