import React, { useEffect, useMemo, useState } from 'react'
import { usePlatformApi } from '../../_common/hooks/api/PlatformApiHook'
import { useAppLoader } from '../../_common/hooks/common/appLoaderHook'
import EditButtonCell from '../actionsBtns/EditButtonCell'
import TableContainer from '../commonComponents/TableContainer'
import DeleteButtonCell from '../actionsBtns/DeleteButtonCell'

interface IncomingProps {
    AccountId?: any;
    type?: any;
}

const ContactList = ({ AccountId, type }: IncomingProps) => {

    const platformApi = usePlatformApi();
    const { showLoader, hideLoader } = useAppLoader();

    const [contactData, setContactData] = useState<any>(null)
    const [data, setData] = useState<any>([])
    const [loading, setLoading] = useState<boolean>(false)

    const getContactData = (page = 1) => {
        showLoader();
        setLoading(true);
        platformApi.getAllContact({
            page: page,
            AccountId: AccountId,
            limit: 4
        },
            (message: string, resp: any) => {
                // console.log(resp);
                setLoading(false);
                setContactData(resp.data.list);
                setData(resp.data.list.data);
                hideLoader();
            }, (message: string) => {
                setLoading(false);
                hideLoader();
            })
    }

    const deleteContact = (id: any) => {
        return new Promise((resolve: any, reject: any) => {
            platformApi.contactDestroy({
                id
            }, (message: string, resp: any) => {
                resolve(message)
            }, (message: string) => {
                reject(message);
            });
        })
    }

    const columns = useMemo(
        () => [{
            Header: 'Actions',
            accessor: "ContactId",
            Cell: ({ column, value, row }: any) =>
                <>
                    <DeleteButtonCell
                        column={column}
                        value={row.original.id}
                        deleteFunc={deleteContact}
                        callbackFunc={[getContactData]}
                        type={"Contact"}
                    />
                    <EditButtonCell column={column} value={value} link={`add-contact/${AccountId}`} />
                </>,
        },
        {
            Header: "Name",
            accessor: (row: { FirstName: any; LastName: any; name: any }) => {
                return { FirstName: row.FirstName, LastName: row.LastName };
            },
            Cell: ({ value }: any) => {
                return (
                    <div className='nowrap'>
                        <span>{value.FirstName}{" "}{value.LastName}</span>
                    </div>
                );
            },
        },
        {
            Header: "Department",
            accessor: "Department",
        },
        {
            Header: "Title",
            accessor: "Title",
        },
        {
            Header: "Phone",
            accessor: "Phone",
            className: "nowrap",
        },
        ],
        []
    )

    useEffect(() => {
        if (AccountId) {
            getContactData();
        }
    }, [AccountId])

    return (
        <React.Fragment>
            {/* <div className="card-body">
                <div className="d-flex flex-wrap">
                    <h5 className="card-title mr-2">Contact</h5>
                </div> */}
            <div className="table-responsive">
                <TableContainer
                    columns={columns}
                    data={data}
                    fetchData={getContactData}
                    loading={loading}
                    rawData={contactData}
                    cstmClassName={"table-scroll-overflow"}
                />
            </div>
            {/* </div> */}
        </React.Fragment>
    )
}

export default ContactList