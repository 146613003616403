import { combineReducers } from 'redux';

import loaderReducer from './common/loaderReducer'
import platformReducer from './platforms/platformReducer';
import userReducer from './user/userReducer'

const rootReducer = combineReducers({
  loader: loaderReducer,
  user: userReducer,
  platform: platformReducer,
})

export default rootReducer