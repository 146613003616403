import React, { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import Footer from '../../components/commonComponents/Footer'
import Header from '../../components/commonComponents/Header'
import LeftPanel from '../../components/commonComponents/LeftPanel'
import TableContainer from '../../components/commonComponents/TableContainer'
import { useAppLoader } from '../../_common/hooks/common/appLoaderHook'
import { URLS } from '../../_config'
import { AiOutlineEdit } from 'react-icons/ai'
import DeleteButtonCell from '../../components/actionsBtns/DeleteButtonCell'
import { useCommonApi } from '../../_common/hooks/api/common/commonApiHook'
import AddPartnerModal from '../../components/modals/addPartnerModal.modal'


const PartnerSettings = () => {
    const commonApi = useCommonApi();
    const { showLoader, hideLoader } = useAppLoader();

    const [partnerData, setPartnerData] = useState<any>(null)
    const [data, setData] = useState<any>([])
    const [showAddPartnerModal, setShowPartnerModal] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false)
    const [isEdit, setIsEdit] = useState<boolean>(false)
    const [partnerId, setPartnerId] = useState<any>(null)

    const getPartnerData = (page = 1) => {
        showLoader();
        setLoading(true);
        commonApi.getAllPartner({ page: page },
            (message: string, resp: any) => {
                // console.log(resp);
                setLoading(false);
                setPartnerData(resp.data.list);
                setData(resp.data.list.data);
                hideLoader();
            }, (message: string) => {
                setLoading(false);
                hideLoader();
            })
    }

    const closeAddPartnerModal = () => {
        setShowPartnerModal(false)
        setIsEdit(false)
        setPartnerId(null)
        // getPartnerData()
    }

    const EditBtn = ({ column, value }: any) => {
        const handleClick = () => {
            // console.log(value)
            setShowPartnerModal(true)
            setPartnerId(value)
            setIsEdit(true)
        }
        return (
            <AiOutlineEdit
                onClick={handleClick}
                className="cursor-pointer mr-2"
                data-toggle="tooltip" data-placement="top" title="Edit"
            />
        )
    }


    const deletePartner = (id: any) => {
        // console.log(id)
        return new Promise((resolve: any, reject: any) => {
            commonApi.deletePartner({
                id
            }, (message: string, resp: any) => {
                resolve(message)
            }, (message: string) => {
                reject(message);
            });
        })
    }

    const columns = useMemo(
        () => [
            {
                Header: 'Actions',
                accessor: "id",
                Cell: ({ column, value }: any) =>
                    <>
                        <DeleteButtonCell
                            column={column}
                            value={value}
                            deleteFunc={deletePartner}
                            callbackFunc={[getPartnerData]}
                            type={"Partner"}
                        />
                        <EditBtn column={column} value={value} />
                    </>,
            },
            {
                Header: "Name",
                accessor: "name",
            },
            {
                Header: "Contact Name",
                accessor: "contactname",
            },
            {
                Header: "Email",
                accessor: "email",
            },
            {
                Header: "Phone",
                accessor: "phone",
            },
            {
                Header: "Country",
                accessor: "country",
            },
        ],
        []
    )

    useEffect(() => {
        getPartnerData()
    }, [])

    return (
        <React.Fragment>
            <AddPartnerModal
                show={showAddPartnerModal}
                close={closeAddPartnerModal}
                id={partnerId}
                isEdit={isEdit}
                partnerListCallbackFunc={getPartnerData}
            />
            <div id="layout-wrapper">
                <Header />
                <LeftPanel />
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            {/* start page title */}
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-flex align-items-center justify-content-between">
                                        <h4 className="mb-0 font-size-18">Partner</h4>
                                        <div className="page-title-right">
                                            <ol className="breadcrumb m-0">
                                                <li className="breadcrumb-item">
                                                    <Link to={URLS.REVENUE}>Revenue</Link>
                                                </li>
                                                <li className="breadcrumb-item active">Partner</li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-body">
                                    <div className="d-flex flex-wrap justify-content-between">
                                        <h5 className="card-title mr-2">Partner</h5>
                                        <button
                                            className="btn btn-success"
                                            onClick={() => setShowPartnerModal(true)}
                                        >
                                            Add Partner
                                        </button>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="table-responsive manage-user-table">
                                                <TableContainer
                                                    columns={columns}
                                                    data={data}
                                                    fetchData={getPartnerData}
                                                    loading={loading}
                                                    rawData={partnerData}
                                                    clickFunc={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </React.Fragment >
    )
}

export default PartnerSettings