import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Footer from '../components/commonComponents/Footer'
import Header from '../components/commonComponents/Header'
import LeftPanel from '../components/commonComponents/LeftPanel'
import ContactList from '../components/dataListing/ContactList'
import ContractDataList from '../components/dataListing/ContractDataList'
import QuoteList from '../components/dataListing/QuoteList'
import { URLS } from '../_config'
import * as Yup from "yup"
import { useForm, Controller } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { usePlatformApi } from '../_common/hooks/api/PlatformApiHook'
import { useAppLoader } from '../_common/hooks/common/appLoaderHook'
import { toast } from 'react-hot-toast'
import { deleteNotification, extractContent, formatNumber } from '../_common/functions'
import moment from 'moment'
import { AiOutlineDelete } from 'react-icons/ai'
import AddAccountTeamModal from '../components/modals/addAccountTeam.modal'
import AccountTeamListing from '../components/dataListing/AccountTeamListing'
import AddContractModal from '../components/modals/addContract.modal'
import { useCommonApi } from '../_common/hooks/api/common/commonApiHook'
import MergeContractModal from '../components/modals/mergeContract.modal'
import { useCommonFunction } from '../_common/hooks/common/appCommonFunctionHook'
import { useZendeskApi } from '../_common/hooks/api/ZendeskApiHook'
import ViewZendeskTicketModal from '../components/modals/viewZendeskTkt.modal'
import AccountPartnerListing from '../components/dataListing/AccountPartnerListing'
import AddPartnerTeamModal from '../components/modals/addPartnerTeam.modal'

interface FormValues {
    commenttxt: string;
}
interface SaFormValues {
    sanotes: string;
}
interface renewalProcessFormValues {
    renewalProcess: string;
}

const validationSchema = Yup.object().shape({
    commenttxt: Yup.string().required('Comment is required')
})

const saNotesValidationSchema = Yup.object().shape({
    sanotes: Yup.string().required('SA Notes is required')
})

const renewalProcessValidationSchema = Yup.object().shape({
    renewalProcess: Yup.string().required('Renewal Process is required')
})

const AccountView = () => {
    const urlParams: any = useParams();
    const platformApi = usePlatformApi();
    const commonApi = useCommonApi();
    const zendeskApi = useZendeskApi();
    const commonFunctions = useCommonFunction();
    const navigate = useNavigate();

    const { showLoader, hideLoader } = useAppLoader()
    const [comments, setComments] = useState<any>(null)
    const [saComments, setSaComments] = useState<any>(null)
    const [renewals, setRenewals] = useState<any>(null)
    const [editRenewals, setEditRenewals] = useState<boolean>(false)
    const [accountData, setAccountData] = useState<any>(null)
    const [accountAdditionalData, setAccountAdditionalData] = useState<any>(null)
    const [showAccountModal, setShowAccountModal] = useState<boolean>(false)
    const [showPartnerModal, setShowPartnerModal] = useState<boolean>(false)
    const [showCustomerModal, setShowCustomerModal] = useState<boolean>(false)
    const [showContractModal, setShowContractModal] = useState<boolean>(false)
    const [showMergeContractModal, setShowMergeContractModal] = useState<boolean>(false)
    const [showZendeskViewModal, setShowZendeskViewModal] = useState<boolean>(false)
    const [contractIsEdit, setContractIsEdit] = useState<boolean>(false)
    const [contractId, setContractId] = useState<any>(null)
    const [selectedRow, setSelectedRow] = useState<number | null>(null)
    const [selectedQuoteId, setSelectedQuoteId] = useState<string>('')
    const [selectedContractRow, setSelectedContractRow] = useState<any | []>([])
    const [selectedContract, setSelectedContract] = useState<any | []>([])
    const [fileValue, setFileValue] = useState<any>(null)
    const [zendeskTkt, setZendeskTkt] = useState<any>(null)
    const [contractStatus, setContractStatus] = useState<string>('')

    const { control, formState: { errors }, handleSubmit, reset } = useForm<FormValues>({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            commenttxt: "",
        }
    })

    const { control: saControl,
        formState: { errors: saErrors },
        handleSubmit: saHandleSubmit,
        reset: saReset } = useForm<SaFormValues>({
            resolver: yupResolver(saNotesValidationSchema),
            defaultValues: {
                sanotes: "",
            }
        })

    const { control: renewalControl,
        formState: { errors: renewalErrors },
        handleSubmit: renewalHandleSubmit,
        reset: renewalReset,
        setValue: renewalSetValue } = useForm<renewalProcessFormValues>({
            resolver: yupResolver(renewalProcessValidationSchema),
            defaultValues: {
                renewalProcess: "",
            }
        })

    const getAccountData = () => {
        showLoader();
        platformApi.getAllAccount({ AccountId: urlParams?.id },
            (message: string, resp: any) => {
                // console.log(resp);
                setAccountData(resp.data.list.data[0]);
                setAccountAdditionalData(resp.data.addldata)
                // setAccountData(resp.data.list[0]);
                hideLoader();
            }, (message: string) => {
                hideLoader();
            })
    }

    const getAccountComments = () => {
        showLoader()
        platformApi.getAllAccountComment({
            AccountId: urlParams?.id
        }, (message: string, resp: any) => {
            // console.log(resp)
            setComments(resp.data.list.data)
            hideLoader()
        }, (message: string) => {
            // toast.error(message)
            hideLoader()
        })
    }

    const getSaAccountComments = () => {
        showLoader()
        platformApi.getAllAccountSaComment({
            AccountId: urlParams?.id
        }, (message: string, resp: any) => {
            // console.log(resp)
            setSaComments(resp.data.list.data)
            hideLoader()
        }, (message: string) => {
            // toast.error(message)
            hideLoader()
        })
    }

    const getRenewalProcess = () => {
        showLoader()
        platformApi.getRenewalProcess({
            AccountId: urlParams?.id
        }, (message: string, resp: any) => {
            // console.log(resp)
            setRenewals(resp.data.list.RenewalProcess)
            hideLoader()
        }, (message: string) => {
            // toast.error(message)
            hideLoader()
        })
    }

    const onSubmit = (data: any) => {
        // console.log(data)
        let params: any = {
            "id": "",
            "AccountId": urlParams?.id,
            "commenttxt": data?.commenttxt
        }
        showLoader()
        platformApi.manageAccountComment(params, (message: string, resp: any) => {
            // console.log(resp)
            toast.success(message)
            reset()
            hideLoader()
            getAccountComments()
        }, (message: string) => {
            toast.error(message)
            hideLoader()
        })
    }

    const saSubmit = (data: any) => {
        // console.log(data)
        let params: any = {
            "id": "",
            "AccountId": urlParams?.id,
            "commenttxt": data?.sanotes
        }
        showLoader()
        platformApi.manageAccountSaComment(params, (message: string, resp: any) => {
            // console.log(resp)
            toast.success(message)
            saReset()
            hideLoader()
            getSaAccountComments()
        }, (message: string) => {
            toast.error(message)
            hideLoader()
        })
    }

    const renewalSubmit = (data: any) => {
        // console.log(data)
        let params: any = {
            "AccountId": urlParams?.id,
            "RenewalProcess": data?.renewalProcess
        }
        showLoader()
        platformApi.manageRenewalProcess(params, (message: string, resp: any) => {
            // console.log(resp)
            toast.success(message)
            setEditRenewals(false)
            renewalReset()
            hideLoader()
            getRenewalProcess()
        }, (message: string) => {
            toast.error(message)
            hideLoader()
        })
    }

    const deleteAccountComment = (id: any) => {
        return new Promise((resolve: any, reject: any) => {
            platformApi.accountCommentDestroy({
                id
            }, (message: string, resp: any) => {
                resolve(message)
            }, (message: string) => {
                reject(message);
            });
        })
    }

    const deleteAccountSaComment = (id: any) => {
        return new Promise((resolve: any, reject: any) => {
            platformApi.accountSaCommentDestroy({
                id
            }, (message: string, resp: any) => {
                resolve(message)
            }, (message: string) => {
                reject(message);
            });
        })
    }

    const closeAccountModal = () => {
        setShowAccountModal(false)
    }

    const closePartnerModal = () => {
        setShowPartnerModal(false)
    }

    const closeCustomerModal = () => {
        setShowCustomerModal(false)
    }

    const closeZendeskViewModal = () => {
        setShowZendeskViewModal(false)
    }

    const closeContractModal = () => {
        setShowContractModal(false)
        setContractIsEdit(false)
    }

    const handleCloneQuote = () => {
        if (selectedQuoteId == '') return toast.error('select a quote to clone')
        // console.log("===>", selectedQuoteId)
        showLoader()
        platformApi.cloneQuote({
            id: selectedQuoteId
        }, (message: string, resp: any) => {
            // console.log(resp)
            toast.success(message)
            navigate(`/quote-details/${resp?.data?.details?.QuoteId}`)
            hideLoader()
        }, (message: string) => {
            toast.error(message)
            hideLoader()
        })
    }

    const handleCreateQuoteFromContract = () => {
        // console.log(selectedContract, selectedContractRow); return
        if (selectedContract && selectedContract.length > 1) {
            return toast.error('select only one contract to create quote')
        }
        else if (selectedContract && selectedContract.length === 0) {
            return toast.error('select a contract to create quote')
        }
        showLoader()
        platformApi.createQuoteFromContract({
            id: selectedContract[0]?.id
        }, (message: string, resp: any) => {
            // console.log(resp)
            toast.success(message)
            if (resp?.data?.details?.QuoteId && resp?.data?.details?.Quote != "") {
                navigate(`/quote-details/${resp?.data?.details?.QuoteId}`)
            } else {
                navigate(`/add-quote/${urlParams?.id}?type=AccountId&func=ADD&contractId=${selectedContract}`)
            }
            hideLoader()
        }, (message: string) => {
            toast.error(message)
            hideLoader()
        })
    }

    const handleMergeContract = () => {
        // console.log(selectedContract, selectedContractRow); return
        if (selectedContract && selectedContract.length !== 2) {
            return toast.error('select two contract to merge')
        }
        else if (selectedContract && selectedContract.length === 2 && (selectedContract[0]?.Status !== "Activated" || selectedContract[1]?.Status !== "Activated")) {
            return toast.error('only activated contract can be merged')
        }
        setShowMergeContractModal(true)
    }

    const closeMergeContractModal = () => {
        setShowMergeContractModal(false)
    }

    const fileOnChange = (data: any) => {
        if (data?.target?.files) {
            setFileValue(data.target.files[0])
        }
    }

    const saveFile = () => {
        let formData: any = new FormData()
        if (fileValue) {
            formData.append('id', accountData?.id)
            formData.append('Name', accountData?.Name)
            formData.append('Email', accountData?.Email)
            formData.append('sla', fileValue)
        } else {
            return toast.error('Please upload a pdf file')
        }

        showLoader()
        platformApi.manageAccount(formData, (message: string, resp: any) => {
            // console.log(message, resp)
            toast.success(message)
            // navigate(`/account-view/${resp?.data?.details?.AccountId}`)
            setAccountData(resp.data.details)
            // reset()
            hideLoader()
            setFileValue(null)
        }, (message: string) => {
            // console.log(message)
            toast.error(message)
            hideLoader()
        })
    }

    const getZendeskOpenTktCnt = () => {
        zendeskApi.getZendeskOpenTicket({
            id: accountData?.id
        },
            (message: string, resp: any) => {
                setZendeskTkt(resp.data)
            }, (message: string) => {
            })
    }

    useEffect(() => {
        if (urlParams && urlParams.id) {
            getAccountData()
            getAccountComments()
            getSaAccountComments()
            getRenewalProcess()
        }
    }, [urlParams])

    useEffect(() => {
        if (renewals) {
            renewalSetValue('renewalProcess', renewals)
        }
    }, [renewals])

    useEffect(() => {
        if (accountData) {
            getZendeskOpenTktCnt()
        }
    }, [accountData])

    return (
        <React.Fragment>
            <AddAccountTeamModal
                close={closeAccountModal}
                show={showAccountModal}
                AccountId={urlParams?.id}
            />
            <AddPartnerTeamModal
                close={closePartnerModal}
                show={showPartnerModal}
                AccountId={urlParams?.id}
            />
            {/* <AddCustomerTeamModal
                close={closeCustomerModal}
                show={showCustomerModal}
                AccountId={urlParams?.id}
            /> */}
            <AddContractModal
                close={closeContractModal}
                show={showContractModal}
                AccountId={urlParams?.id}
                isEdit={contractIsEdit}
                ContractId={contractId}
            />
            <MergeContractModal
                close={closeMergeContractModal}
                show={showMergeContractModal}
                selectedContracts={selectedContract}
            />
            <ViewZendeskTicketModal
                close={closeZendeskViewModal}
                show={showZendeskViewModal}
                accountId={accountData?.id}
            />
            <div id="layout-wrapper">
                <Header />
                <LeftPanel />
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-flex align-items-center justify-content-between">
                                        <h4 className="mb-0 font-size-18">Account View</h4>
                                        <div className="page-title-right">
                                            <ol className="breadcrumb m-0">
                                                <li className="breadcrumb-item">
                                                    <Link to={URLS.ACCOUNT_LIST}>Account List</Link>
                                                </li>
                                                <li className="breadcrumb-item active">Account View</li>
                                            </ol>
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-end mb-3'>
                                        <Link
                                            to={`/add-account?id=${urlParams?.id}`}
                                            className="btn btn-success">
                                            Edit Account
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="d-flex dashboard-top-panel overflow-auto mb-3 top-acc-view">
                                        <div className="col-lg-4">
                                            <div className="card mini-stats-wid card-border-bottom">
                                                <div className="card-body account-block-stats">
                                                    {/* <p className="mb-2">
                                                        <strong>ARR ($): </strong><span>{accountAdditionalData && accountData ? formatNumber(accountAdditionalData[accountData?.AccountId]?.arr) : ""}</span>
                                                    </p> */}
                                                    <p className="mb-2">
                                                        <strong>POC: </strong><span>{accountData?.account_managerobj ? accountData?.account_managerobj?.full_name : ""}</span>
                                                    </p>
                                                    <p className="mb-2">
                                                        <strong>Email: </strong><span>{accountData?.Email ? accountData?.Email : ""}</span>
                                                    </p>
                                                    <p className="mb-2">
                                                        <strong>Name: </strong><span>{accountData?.Name ? accountData?.Name : ""}</span>
                                                    </p>
                                                    <p className="mb-2">
                                                        <strong>Phone: </strong><span>{accountData?.Phone ? accountData?.Phone : ""}</span>
                                                    </p><br />
                                                    <p className="">
                                                        <strong>Upload{accountData?.sladoc ? "ed" : ""} PDF: </strong>
                                                    </p>
                                                    {
                                                        accountData?.sladoc ?
                                                            <div className="form-group">
                                                                <a
                                                                    href={accountData?.sla?.basepath}
                                                                    target="_blank"
                                                                    rel="noreferrer"
                                                                >
                                                                    {accountData?.sladoc?.file_name_original}
                                                                </a>
                                                                <AiOutlineDelete
                                                                    color='red'
                                                                    size={"18px"}
                                                                    className='cursor-pointer'
                                                                    style={{ paddingLeft: "5px" }}
                                                                    onClick={() =>
                                                                        deleteNotification(accountData?.sladoc?.id, "document", commonFunctions.deleteFile, getAccountData)
                                                                    }
                                                                />
                                                            </div>
                                                            :
                                                            <>
                                                                <div className="form-group mb-0 w-100">
                                                                    {/* <input type="file" accept=".pdf" onChange={fileOnChange} /> */}

                                                                    <div className='d-flex align-items-center gap-10'>
                                                                        <div className="custom-file">
                                                                            <input type="file" accept=".pdf" onChange={fileOnChange} />
                                                                            <label className="custom-file-label" htmlFor="customFile">{fileValue && fileValue != undefined ? fileValue?.name : "Choose file"}</label>
                                                                        </div>
                                                                        <button
                                                                            className='btn btn-primary'
                                                                            type='submit'
                                                                            onClick={saveFile}
                                                                        >
                                                                            Upload
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                {/* <div className='w-100'>
                                                                </div> */}
                                                            </>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-8 account-blocks-wrapper">
                                            <div className="row">
                                                <div className="col-lg-3">
                                                    <div className="card blog-stats-wid card-border-bottom">
                                                        <div className="card-body">
                                                            <div className="d-flex">
                                                                <p className="text-muted mb-2">Health Score</p>
                                                                <div className="avatar-sm ml-auto">
                                                                    <div className="avatar-title-sm rounded-circle text-primary font-size-20">
                                                                        <i className="mdi mdi-head-heart-outline" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* <div className="d-flex justify-content-center">
                                                        <div className="text-center" dir="ltr">
                                                            <input
                                                                className="knob"
                                                                data-width={150}
                                                                data-height={150}
                                                                data-linecap="round"
                                                                data-fgcolor="#f06292"
                                                                defaultValue={80}
                                                                data-skin="tron"
                                                                data-angleoffset={180}
                                                                data-readonly="true"
                                                                data-thickness=".1"
                                                            />
                                                        </div>
                                                    </div> */}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3">
                                                    <div className="card blog-stats-wid card-border-bottom">
                                                        <div className="card-body">
                                                            <div className="d-flex">
                                                                <div className="mr-3">
                                                                    <p className="text-muted mb-2">Customer Lifetime Value</p>
                                                                    <h5 className="mb-0">${accountAdditionalData && formatNumber(accountAdditionalData[urlParams?.id]?.totalrevenue)}</h5>
                                                                </div>
                                                                <div className="avatar-sm ml-auto">
                                                                    <div className="avatar-title-sm rounded-circle text-primary font-size-20">
                                                                        <i className="mdi mdi-account-arrow-left-outline" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3">
                                                    <div className="card blog-stats-wid card-border-bottom">
                                                        <div className="card-body">
                                                            <div className="d-flex">
                                                                <div className="mr-3">
                                                                    <p className="text-muted mb-2">ARR ($)</p>
                                                                    <h5 className="mb-0">{accountAdditionalData && formatNumber(accountAdditionalData[urlParams?.id]?.arr)}</h5>
                                                                </div>
                                                                <div className="avatar-sm ml-auto">
                                                                    <div className="avatar-title-sm rounded-circle text-primary font-size-20">
                                                                        <i className="mdi mdi-file-document-outline" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3">
                                                    <div className="card blog-stats-wid card-border-bottom">
                                                        <div className="card-body">
                                                            <div className="d-flex">
                                                                <div className="mr-3">
                                                                    <p className="text-muted mb-2">MRR ($)</p>
                                                                    <h5 className="mb-0">{accountAdditionalData && formatNumber(accountAdditionalData[urlParams?.id]?.mrr)}</h5>
                                                                </div>
                                                                <div className="avatar-sm ml-auto">
                                                                    <div className="avatar-title-sm rounded-circle text-primary font-size-20">
                                                                        <i className="mdi mdi-file-document-outline" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3">
                                                    <div className="card blog-stats-wid card-border-bottom">
                                                        <div className="card-body">
                                                            <div className="d-flex">
                                                                <div className="mr-3">
                                                                    <p className="text-muted mb-2">NPS Score</p>
                                                                    <h5 className="mb-0">0</h5>
                                                                </div>
                                                                <div className="avatar-sm ml-auto">
                                                                    <div className="avatar-title-sm rounded-circle text-primary font-size-20">
                                                                        <i className="mdi mdi-text-box-check-outline" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3">
                                                    <div className="card blog-stats-wid card-border-bottom">
                                                        <div className="card-body cursor-pointer"

                                                            onClick={() => setShowZendeskViewModal(true)}
                                                        >
                                                            <div className="d-flex">
                                                                <div className="mr-3">
                                                                    <p className="text-muted mb-2">Open &amp; Recent Tickets</p>
                                                                    <h5 className="mb-0">{zendeskTkt && zendeskTkt?.requested_tickets}</h5>
                                                                </div>
                                                                <div className="avatar-sm ml-auto">
                                                                    <div className="avatar-title-sm rounded-circle text-primary font-size-20">
                                                                        <i className="mdi mdi-ticket-confirmation-outline" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3">
                                                    <div className="card blog-stats-wid card-border-bottom">
                                                        <div className="card-body">
                                                            <div className="d-flex">
                                                                <div className="mr-3">
                                                                    <p className="text-muted mb-2">Days Early</p>
                                                                    <h5 className="mb-0">{accountAdditionalData && accountAdditionalData[urlParams?.id]?.earlyrenew}</h5>
                                                                </div>
                                                                <div className="avatar-sm ml-auto">
                                                                    <div className="avatar-title-sm rounded-circle text-primary font-size-20">
                                                                        <i className="mdi mdi-file-document-outline" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="row d-none">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="d-flex flex-wrap">
                                                <h5 className="card-title mr-2">
                                                    Usage Over Time Month by Month
                                                </h5>
                                            </div>
                                            <div>
                                                <div
                                                    id="line_chart_datalabel"
                                                    className="apex-charts"
                                                    dir="ltr"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <div className="card eql-hght-nts-crd">
                                                <div className="card-body">
                                                    <div className="d-flex flex-wrap">
                                                        <h5 className="card-title mr-2">Account Notes:</h5>
                                                    </div>
                                                    <div className='note-cnt-overflow'>
                                                        <ul className="ml-0 pl-3">
                                                            {comments && comments.length > 0 ?
                                                                comments.map((item: any) => (
                                                                    <React.Fragment key={Math.random()}>
                                                                        <li>
                                                                            {item?.commenttxt}
                                                                            <div className='d-flex gap-10 mb-2'>
                                                                                <span
                                                                                    style={{ fontSize: "12px" }}
                                                                                    className='text-black-50'>
                                                                                    {moment(item?.created_at).format("DD-MM-YYYY HH:mm A")}
                                                                                </span>
                                                                                {/* <AiOutlineDelete
                                                                                    color='red'
                                                                                    className='cursor-pointer'
                                                                                    onClick={() =>
                                                                                        deleteNotification(item.id, "Account Comment", deleteAccountComment, getAccountComments)
                                                                                    }
                                                                                /> */}
                                                                            </div>
                                                                        </li>
                                                                    </React.Fragment>
                                                                ))
                                                                :
                                                                null
                                                            }
                                                        </ul>
                                                    </div>
                                                    <form onSubmit={handleSubmit(onSubmit)}>
                                                        <div className="form-group">
                                                            <Controller
                                                                name={"commenttxt"}
                                                                control={control}
                                                                render={({ field: { value, onChange, onBlur, name } }) => (
                                                                    <>
                                                                        <input
                                                                            type="text"
                                                                            className={"form-control " + (errors?.commenttxt ? ' border-danger' : '')}
                                                                            id="commenttxt"
                                                                            name={name}
                                                                            onChange={onChange}
                                                                            value={value}
                                                                            onBlur={onBlur}
                                                                            placeholder="Comment ..."
                                                                        />
                                                                    </>
                                                                )}
                                                            />
                                                            {errors && errors.commenttxt &&
                                                                <span className='text-danger'>{errors?.commenttxt?.message}</span>
                                                            }
                                                        </div>
                                                        <div>
                                                            <button
                                                                className='btn btn-primary'
                                                                type='submit'
                                                            >
                                                                save
                                                            </button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="card eql-hght-nts-crd">
                                                <div className="card-body">
                                                    <div className="d-flex flex-wrap">
                                                        <h5 className="card-title mr-2">Renewal Process:</h5>
                                                    </div>
                                                    {!editRenewals && renewals ?
                                                        <>
                                                            <div className='note-cnt-overflow renewal-overflow'>
                                                                {/* {renewals && changeString(extractContent(renewals), 180)} */}
                                                                {renewals && extractContent(renewals)}
                                                            </div>
                                                            <button
                                                                className='btn btn-primary mt-2'
                                                                type='button'
                                                                onClick={() => setEditRenewals(true)}
                                                            >
                                                                edit
                                                            </button>
                                                        </>
                                                        :
                                                        <form onSubmit={renewalHandleSubmit(renewalSubmit)}>
                                                            <div className="form-group">
                                                                <Controller
                                                                    name={"renewalProcess"}
                                                                    control={renewalControl}
                                                                    render={({ field: { value, onChange, onBlur, name } }) => (
                                                                        <>
                                                                            <textarea
                                                                                // type="text"
                                                                                className={"form-control " + (renewalErrors?.renewalProcess ? ' border-danger' : '')}
                                                                                id="renewalProcess"
                                                                                name={name}
                                                                                onChange={onChange}
                                                                                value={value}
                                                                                onBlur={onBlur}
                                                                                placeholder="Renewal Process ..."
                                                                            />
                                                                        </>
                                                                    )}
                                                                />
                                                                {renewalErrors && renewalErrors.renewalProcess &&
                                                                    <span className='text-danger'>{renewalErrors?.renewalProcess?.message}</span>
                                                                }
                                                            </div>
                                                            <div className='d-flex justify-content-center gap-10'>
                                                                <button
                                                                    className='btn btn-primary'
                                                                    type='submit'
                                                                >
                                                                    save
                                                                </button>
                                                                <button
                                                                    className='btn btn-outline-danger'
                                                                    type='button'
                                                                    onClick={() => setEditRenewals(false)}
                                                                >
                                                                    cancel
                                                                </button>
                                                            </div>
                                                        </form>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="card eql-hght-nts-crd">
                                                <div className="card-body">
                                                    <div className="d-flex flex-wrap">
                                                        <h5 className="card-title mr-2">SA Notes:</h5>
                                                    </div>
                                                    <div className='note-cnt-overflow'>
                                                        <ul className="ml-0 pl-3">
                                                            {saComments && saComments.length > 0 ?
                                                                saComments.map((item: any) => (
                                                                    item.commenttxt &&
                                                                    <li key={Math.random()}>
                                                                        {item?.commenttxt}
                                                                        <div className='d-flex gap-10 mb-2'>
                                                                            <span
                                                                                style={{ fontSize: "12px" }}
                                                                                className='text-black-50'>
                                                                                {moment(item?.created_at).format("DD-MM-YYYY HH:mm A")}
                                                                            </span>
                                                                            {/* <AiOutlineDelete
                                                                                color='red'
                                                                                className='cursor-pointer'
                                                                                onClick={() =>
                                                                                    deleteNotification(item.id, "Account SA Comment", deleteAccountSaComment, getSaAccountComments)
                                                                                }
                                                                            /> */}
                                                                        </div>
                                                                    </li>
                                                                ))
                                                                :
                                                                null
                                                            }
                                                        </ul>
                                                    </div>
                                                    <form onSubmit={saHandleSubmit(saSubmit)}>
                                                        <div className="form-group">
                                                            <Controller
                                                                name={"sanotes"}
                                                                control={saControl}
                                                                render={({ field: { value, onChange, onBlur, name } }) => (
                                                                    <>
                                                                        <input
                                                                            type="text"
                                                                            className={"form-control " + (saErrors?.sanotes ? ' border-danger' : '')}
                                                                            id="sanotes"
                                                                            name={name}
                                                                            onChange={onChange}
                                                                            value={value}
                                                                            onBlur={onBlur}
                                                                            placeholder="SA notes ..."
                                                                        />
                                                                    </>
                                                                )}
                                                            />
                                                            {saErrors && saErrors.sanotes &&
                                                                <span className='text-danger'>{saErrors?.sanotes?.message}</span>
                                                            }
                                                        </div>
                                                        <div>
                                                            <button
                                                                className='btn btn-primary'
                                                                type='submit'
                                                            >
                                                                save
                                                            </button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>{" "}
                                {/* end col */}
                            </div>
                            {/* end row */}
                            <div className="row">
                                <div className="col-12">
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <div className="card">
                                                <div className="card-body" style={{ minHeight: "488px" }}>
                                                    <div className="d-flex flex-wrap justify-content-between">
                                                        <h5 className="card-title mr-2">Account Team</h5>
                                                        <button
                                                            className="btn btn-success"
                                                            onClick={() => setShowAccountModal(true)}
                                                        >
                                                            Add Account Team
                                                        </button>
                                                    </div>
                                                    <AccountTeamListing AccountId={urlParams?.id} show={showAccountModal} showContractModal={showContractModal} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="card">
                                                <div className="card-body" style={{ minHeight: "488px" }}>
                                                    <div className="d-flex flex-wrap justify-content-between">
                                                        <h5 className="card-title mr-2">Customer Team</h5>
                                                        {/* <button
                                                            className="btn btn-success"
                                                            onClick={() => setShowCustomerModal(true)}
                                                        >
                                                            <AiOutlinePlus />
                                                        </button> */}
                                                        <Link
                                                            to={`/add-contact/${urlParams.id}`}
                                                            className="btn btn-success">
                                                            Add Customer Team
                                                            {/* <AiOutlinePlus /> */}
                                                        </Link>
                                                    </div>
                                                    <ContactList AccountId={urlParams?.id} />
                                                    {/* <CustomerTeamListing AccountId={urlParams?.id} show={showCustomerModal} /> */}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="card">
                                                <div className="card-body" style={{ minHeight: "488px" }}>
                                                    <div className="d-flex flex-wrap justify-content-between">
                                                        <h5 className="card-title mr-2">Partner Team</h5>
                                                        <button
                                                            className="btn btn-success"
                                                            onClick={() => setShowPartnerModal(true)}
                                                        >
                                                            Add Partner Team
                                                        </button>
                                                    </div>
                                                    <AccountPartnerListing AccountId={urlParams?.id} show={showPartnerModal} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="d-flex flex-wrap justify-content-between">
                                                <h5 className="card-title mr-2">Contracts</h5>
                                                <button
                                                    className="btn btn-success"
                                                    onClick={() => setShowContractModal(true)}
                                                >
                                                    Add Contract
                                                </button>
                                            </div>
                                            <ul className="nav nav-tabs nav-tabs-custom" role="tablist">
                                                <li className="nav-item">
                                                    <button
                                                        className="nav-link active"
                                                        data-toggle="tab"
                                                        onClick={() => setContractStatus('')}
                                                        role="tab"
                                                    >
                                                        All
                                                    </button>
                                                </li>
                                                <li className="nav-item">
                                                    <button
                                                        className="nav-link"
                                                        data-toggle="tab"
                                                        onClick={() => setContractStatus('Activated')}
                                                        role="tab"
                                                    >
                                                        Active
                                                    </button>
                                                </li>
                                                <li className="nav-item">
                                                    <button
                                                        className="nav-link"
                                                        data-toggle="tab"
                                                        onClick={() => setContractStatus('Expired')}
                                                        role="tab"
                                                    >
                                                        Expired
                                                    </button>
                                                </li>
                                            </ul>
                                            <ContractDataList
                                                AccountId={urlParams?.id}
                                                selectedContractRow={selectedContractRow}
                                                setSelectedContractRow={setSelectedContractRow}
                                                selectedContract={selectedContract}
                                                setSelectedContract={setSelectedContract}
                                                setShowContractModal={setShowContractModal}
                                                setContractIsEdit={setContractIsEdit}
                                                setContractId={setContractId}
                                                showContractModal={showContractModal}
                                                showMergeContractModal={showMergeContractModal}
                                                contractStatus={contractStatus}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row acc-btn">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <button
                                                        className="btn btn-lg btn-primary mr-2 w-100"
                                                        onClick={handleMergeContract}
                                                    >
                                                        MERGE CONTRACT
                                                    </button>
                                                </div>
                                                <div className="col-sm-6">
                                                    <button
                                                        className="btn btn-lg btn-primary mr-2 w-100"
                                                        onClick={handleCreateQuoteFromContract}
                                                    >
                                                        CREATE QUOTE
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>{" "}
                                {/* end col */}
                            </div>
                            {/* end row */}
                            {/* <div className="row d-none">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="d-flex flex-wrap justify-content-between">
                                                <h5 className="card-title mr-2">Opportunities</h5>
                                                <Link to={`/add-opportunity/${urlParams?.id}`} className="btn btn-success">Add Opportunity</Link>
                                            </div>
                                            <OpportunityList id={urlParams?.id} type="AccountId" />
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            {/* end row */}
                            <div className="row acc-btn d-none">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <button className="btn btn-lg btn-primary mr-2 w-100">
                                                        PROCESS QUOTE
                                                    </button>
                                                </div>
                                                <div className="col-sm-6">
                                                    <button className="btn btn-lg btn-primary mr-2 w-100">
                                                        CLONE QUOTE
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>{" "}
                                {/* end col */}
                            </div>
                            {/* end row */}
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="d-flex flex-wrap justify-content-between">
                                                <h5 className="card-title mr-2">Quotes</h5>
                                                <Link to={`/add-quote/${urlParams.id}?type=AccountId&func=ADD`} className="btn btn-success">Add Quote</Link>
                                            </div>
                                            <QuoteList
                                                id={urlParams?.id}
                                                type="AccountId"
                                                isEdit={true}
                                                selectedRow={selectedRow}
                                                setSelectedRow={setSelectedRow}
                                                selectedQuoteId={selectedQuoteId}
                                                setSelectedQuoteId={setSelectedQuoteId}
                                            />
                                        </div>
                                    </div>
                                </div>{" "}
                                {/* end col */}
                            </div>
                            {/* end row */}
                            <div className="row acc-btn d-none">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <button className="btn btn-lg btn-primary mr-2 w-100">
                                                        PROCESS QUOTE
                                                    </button>
                                                </div>
                                                <div className="col-sm-6">
                                                    <button className="btn btn-lg btn-primary mr-2 w-100">
                                                        CLONE QUOTE
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>{" "}
                                {/* end col */}
                            </div>
                            {/* end row */}
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <button
                                                        className="btn btn-lg btn-primary mr-2 w-100"
                                                        onClick={handleCloneQuote}
                                                    >
                                                        CLONE QUOTE
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </React.Fragment>
    )
}

export default AccountView