import React from 'react'
import Modal from 'react-bootstrap/Modal'
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Controller, useForm } from 'react-hook-form';
import { useAppLoader } from '../../_common/hooks/common/appLoaderHook';
import { toast } from 'react-hot-toast';
import { useUserApi } from '../../_common/hooks/api/UserApiHook';

interface FormValues {
	password: string;
}

const validationSchema = Yup.object().shape({
	password: Yup.string().required("Password is required"),
});

interface IncomingProps {
	show: boolean;
	close: () => void;
	id: string;
	userListCallbackFunc: any;
}

const EditArrUserPassword = ({ show, close, id, userListCallbackFunc }: IncomingProps) => {

	const userApi = useUserApi();
	const { showLoader, hideLoader } = useAppLoader();

	const { control, formState: { errors }, handleSubmit, reset } = useForm<FormValues>({
		resolver: yupResolver(validationSchema),
		defaultValues: {
			password: "",
		},
	});

	const onSubmit = (data: any) => {
		// console.log({ data })
		let params: any = {
			"id": id,
			"password": data?.password,
		}
		// console.log(params)
		showLoader()
		userApi.updateArrUserPassword(params, (message: string, resp: any) => {
			// console.log(message, resp)
			toast.success(message)
			reset()
			close()
			hideLoader()
			userListCallbackFunc()
		}, (message: string) => {
			// console.log(message)
			toast.error(message)
			hideLoader()
		})
	}

	return (
		<React.Fragment>
			<Modal
				className='salesforce-modal modal-lndscp'
				show={show}
			// onHide={() => { close(); reset() }}
			>
				<div className="modal-header custom-modal-header">
					<button
						type="button"
						className="close"
						data-dismiss="modal"
						aria-label="Close"
						onClick={() => { close(); reset() }}
					>
						<span aria-hidden="true">×</span>
					</button>
				</div>
				<Modal.Body>
					<div className="row">
						<form onSubmit={handleSubmit(onSubmit)} className="w-100" autoComplete='false'>
							<div className="col-12">
								<div className="card">
									<div className="card-body">
										<div className="row">
											<div className="form-group col-12">
												<label htmlFor="password">Password <span className='text-danger'>*</span></label>
												<Controller
													name={"password"}
													control={control}
													render={({ field: { value, onChange, onBlur, name, ref } }) => (
														<>
															<input
																type="text"
																className={"form-control " + (errors?.password ? ' border-danger' : '')}
																id="password"
																name={name}
																onChange={onChange}
																value={value}
																onBlur={onBlur}
																ref={ref}
																autoComplete='off'
																placeholder="Enter password"
															/>
														</>
													)}
												/>
												{errors && errors.password &&
													<span className='text-danger'>{errors?.password?.message}</span>
												}
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="col-12">
								<div className='align-items-center d-flex gap-10 justify-content-center'>
									<button type='submit' className='btn btn-primary'>Save</button>
									<button
										type='button'
										className='btn btn-outline-danger'
										onClick={() => { close(); reset() }}
									>
										Cancel
									</button>
								</div>
							</div>
						</form>
					</div>
				</Modal.Body>
			</Modal>
		</React.Fragment>
	)
}

export default EditArrUserPassword