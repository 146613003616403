import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import Footer from '../components/commonComponents/Footer'
import Header from '../components/commonComponents/Header'
import LeftPanel from '../components/commonComponents/LeftPanel'
import { URLS } from '../_config'
import * as Yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { usePlatformApi } from '../_common/hooks/api/PlatformApiHook'
import { useAppLoader } from '../_common/hooks/common/appLoaderHook'
import Select from 'react-select'
import { handleKeyPressNumber } from '../_common/functions'
import { toast } from 'react-hot-toast'

interface AddPriceBookFormValues {
    productName: string;
    listPrice: string;
    active: boolean;
}

const validationSchema = Yup.object().shape({
    productName: Yup.object().required("Product Name is required"),
    listPrice: Yup.string().required("List Price is required")
});


const AddPriceBookEntities = () => {

    const platformApi = usePlatformApi();
    const navigate = useNavigate();
    const urlParams: any = useParams();
    const [searchParams] = useSearchParams();
    const id: any = searchParams.get("id");

    const { showLoader, hideLoader } = useAppLoader();
    const [productOptions, setProductOptions] = useState<any>([])
    const [priceBookEntriesData, setPriceBookEntriesData] = useState<any>(null)
    const [loading, setLoading] = useState<boolean>(false)

    const { control, formState: { errors }, handleSubmit, setValue, reset } = useForm<AddPriceBookFormValues>({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            productName: "",
            listPrice: "",
            active: false
        },
    });

    const getProductData = () => {
        showLoader();
        platformApi.getAllProduct({},
            (message: string, resp: any) => {
                console.log(resp);
                const datas = resp?.data?.list?.data
                if (datas && datas.length > 0) {
                    setProductOptions(datas.map((item: any) => {
                        item.label = item.Name;
                        item.value = item.ProductId;
                        return item;
                    }))
                }
                hideLoader();
            }, (message: string) => {
                hideLoader();
            })
    }

    const getPriceBookEntries = () => {
        showLoader();
        setLoading(true)
        platformApi.getAllPriceBookEntities({
            PricebookEntryId: id
        },
            (message: string, resp: any) => {
                // console.log(resp);
                setPriceBookEntriesData(resp.data.list.data[0]);
                setLoading(false)
                hideLoader();
            }, (message: string) => {
                setLoading(false)
                hideLoader();
            })
    }

    const onSubmit = (data: any) => {
        // console.log(data);
        let params: any = {
            "IsActive": data?.active,
            "Pricebook2Id": urlParams?.id,
            "Product2Id": data?.productName?.value,
            "UnitPrice": data?.listPrice,
        }
        if (priceBookEntriesData && priceBookEntriesData?.id) {
            params["id"] = priceBookEntriesData?.id
        }
        // console.log(params)
        showLoader()
        platformApi.managePriceBookEntities(params, (message: string, resp: any) => {
            // console.log(message, resp)
            toast.success(message)
            reset()
            navigate(`/pricebook-details/${urlParams?.id}`)
            hideLoader()
        }, (message: string) => {
            // console.log(message)
            toast.error(message)
            hideLoader()
        })
    }

    useEffect(() => {
        getProductData()
    }, [])

    useEffect(() => {
        if (id) {
            getPriceBookEntries()
        }
    }, [id])

    useEffect(() => {
        if (priceBookEntriesData) {
            setValue('productName', productOptions?.find((item: any) => item.value == priceBookEntriesData?.Product2Id))
            setValue('listPrice', priceBookEntriesData?.UnitPrice)
            setValue('active', priceBookEntriesData?.IsActive == '1' ? true : false)
        }
    }, [priceBookEntriesData])

    return (
        <React.Fragment>
            <div id="layout-wrapper">
                <Header />
                <LeftPanel />
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            {/* start page title */}
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-flex align-items-center justify-content-between">
                                        <h4 className="mb-0 font-size-18">New Pricebook Entries</h4>
                                        <div className="page-title-right">
                                            <ol className="breadcrumb m-0">
                                                <li className="breadcrumb-item">
                                                    <Link to={`/pricebook-details/${urlParams?.id}`}>Price Book Details</Link>
                                                </li>
                                                <li className="breadcrumb-item active">Add Pricebook Entries</li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* end page title */}
                            {loading ?
                                <div>
                                    Loading......
                                </div>
                                :
                                <div className="row">
                                    <form onSubmit={handleSubmit(onSubmit)} className="w-100" >
                                        <div className="col-12">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="d-flex flex-wrap">
                                                        <h5 className="card-title mr-2">Price Book Information</h5>
                                                    </div>
                                                    <div className="row">
                                                        <div className="form-group col-6">
                                                            <label htmlFor="productName">Product name <span className='text-danger'>*</span></label>
                                                            <Controller
                                                                name={"productName"}
                                                                control={control}
                                                                render={({ field: { value, onChange, onBlur, name } }) => (
                                                                    <>
                                                                        <Select
                                                                            className={"select-box " + (errors?.productName ? ' border-danger' : '')}
                                                                            options={productOptions ? productOptions : []}
                                                                            placeholder={"Select product"}
                                                                            onChange={onChange}
                                                                            value={value}
                                                                            defaultValue={value}
                                                                        />
                                                                    </>
                                                                )}
                                                            />
                                                            {errors && errors.productName &&
                                                                <span className='text-danger'>Product Name is required</span>
                                                            }
                                                        </div>
                                                        <div className="form-group col-6">
                                                            <label htmlFor="listPrice">List Price <span className='text-danger'>*</span></label>
                                                            <Controller
                                                                name={"listPrice"}
                                                                control={control}
                                                                render={({ field: { value, onChange, onBlur, name } }) => (
                                                                    <>
                                                                        <input
                                                                            type="text"
                                                                            className={"form-control " + (errors?.listPrice ? ' border-danger' : '')}
                                                                            id="listPrice"
                                                                            name={name}
                                                                            onChange={onChange}
                                                                            value={value}
                                                                            onBlur={onBlur}
                                                                            placeholder="Enter list price"
                                                                            onKeyPress={(event: any) => handleKeyPressNumber(event)}
                                                                        />
                                                                    </>
                                                                )}
                                                            />
                                                            {errors && errors.listPrice &&
                                                                <span className='text-danger'>{errors?.listPrice?.message}</span>
                                                            }
                                                        </div>
                                                        <div className="align-items-center col-6 d-flex form-group gap-10">
                                                            <label htmlFor="active" className='mb-0'>Active</label>
                                                            <Controller
                                                                name={"active"}
                                                                control={control}
                                                                render={({ field: { value, onChange, onBlur, name } }) => (
                                                                    <>
                                                                        <input
                                                                            type="checkbox"
                                                                            className={(errors?.active ? ' border-danger' : '')}
                                                                            id="active"
                                                                            name={name}
                                                                            onChange={onChange}
                                                                            // value={value}
                                                                            onBlur={onBlur}
                                                                            defaultChecked={priceBookEntriesData?.IsActive == '1' ? true : false}
                                                                            placeholder="Enter active"
                                                                        />
                                                                    </>
                                                                )}
                                                            />
                                                            {errors && errors.active &&
                                                                <span className='text-danger'>{errors?.active?.message}</span>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-12">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className='align-items-center d-flex gap-10 justify-content-center'>
                                                        <button type='submit' className='btn btn-primary'>Save</button>
                                                        <button
                                                            type='button'
                                                            className='btn btn-outline-danger'
                                                            onClick={() => navigate(`/pricebook-details/${urlParams?.id}`)}
                                                        >
                                                            Cancel
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            }
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </React.Fragment>
    )
}

export default AddPriceBookEntities