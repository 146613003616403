import React, { useEffect, useMemo, useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import Footer from '../components/commonComponents/Footer'
import Header from '../components/commonComponents/Header'
import LeftPanel from '../components/commonComponents/LeftPanel'
import TableContainer from '../components/commonComponents/TableContainer'
import { usePlatformApi } from '../_common/hooks/api/PlatformApiHook'
import { useAppLoader } from '../_common/hooks/common/appLoaderHook'
import { URLS } from '../_config'
import { formatNumber } from '../_common/functions'
import ChartContainer from '../components/commonComponents/ChartContainer'
import moment from 'moment'
import CommonBoxDetails from '../components/commonComponents/CommonBoxDetails'
import FilterRow from '../components/commonComponents/FilterRow'

const ContractPage = () => {

    const platformApi = usePlatformApi();
    const { showLoader, hideLoader } = useAppLoader();

    const [contractData, setContractData] = useState<any>(null)
    const [data, setData] = useState<any>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [revenueDetails, setRevenueDetails] = useState<any>(null)
    const [filterDetails, setFilterDetails] = useState<any>({})
    const [doFilter, setDoFilter] = useState<boolean>(false)

    const [searchParams, setSearchParams] = useSearchParams();
    const quarterValue = searchParams.get("quarter")
    const typeOfContract = searchParams.get("type")
    const yearValue = searchParams.get("year")
    const regionValue = searchParams.get("region")
    const repValue = searchParams.get("rep")
    const statusValue = searchParams.get("Status")

    const getContractData = (page = 1) => {
        showLoader();
        setLoading(true);
        let params: any = quarterValue && typeOfContract ?
            { page: page, quarter: quarterValue, typeOfContract: typeOfContract }
            :
            { page: page }
        if (repValue && repValue != 'undefined') { params['rep'] = repValue };
        if (regionValue && regionValue != 'undefined') { params['region'] = regionValue };
        if (yearValue && yearValue != 'undefined') { params['year'] = yearValue };
        if (statusValue && statusValue != 'undefined') { params['Status'] = statusValue };
        if (filterDetails && Object.keys(filterDetails).length > 0) {
            Object.assign(params, filterDetails)
        }
        platformApi.getAllContract(params,
            (message: string, resp: any) => {
                // console.log(resp);
                setLoading(false);
                setContractData(resp.data.list);
                setData(resp.data.list.data);
                hideLoader();
            }, (message: string) => {
                setLoading(false);
                hideLoader();
            })
    }

    const getRevenueDetails = () => {
        showLoader();
        platformApi.getRevenueDetails(filterDetails, (message: string, resp: any) => {
            // console.log(resp)
            setRevenueDetails(resp?.data?.list)
            hideLoader();
        }, (message: string) => {
            hideLoader();
        })
    }

    const columns = useMemo(
        () => [
            {
                Header: "Account",
                accessor: "account.Name",
            },
            {
                Header: "Contract Number",
                accessor: "ContractNumber",
                className: "nowrap",
            },

            // {
            //     Header: "Most Likely amount",
            //     accessor: "mostlikely",
            //     className: "nowrap",
            // },
            // {
            //     Header: "New ARR",
            //     accessor: "renewQuoteGen",
            //     className: "nowrap",
            // },
            // {
            //     Header: "Quotation name",
            //     accessor: "quote?.Name",
            //     className: "nowrap",
            // },

            {
                Header: "Contract Term (months)",
                accessor: "ContractTerm",
            },
            {
                Header: "Start Date",
                accessor: "StartDate",
                Cell: ({ column, value, row }: any) => (
                    <>
                        {value ? moment(value).format("MM/DD/YY") : ""}
                    </>
                )
            },
            {
                Header: "End Date",
                accessor: "EndDate",
                Cell: ({ column, value, row }: any) => (
                    <>
                        {value ? moment(value).format("MM/DD/YY") : ""}
                    </>
                )
            },
            {
                Header: "Contract Amount ($)",
                accessor: "ContractValue",
                Cell: ({ column, value, row }: any) =>
                    <>
                        {value ? formatNumber(value) : "0.00"}
                    </>,
            },
            {
                Header: "ARR ($)",
                accessor: "arr",
                className: "nowrap",
                Cell: ({ column, value, row }: any) =>
                    <>
                        {value ? formatNumber(value) : "0.00"}
                    </>,
            },
            {
                Header: "MRR ($)",
                accessor: "mrr",
                className: "nowrap",
                Cell: ({ column, value, row }: any) =>
                    <>
                        {value ? formatNumber(value) : "0.00"}
                    </>,
            },
            {
                Header: "Renewal Amount ($)",
                accessor: "renewAmt",
                Cell: ({ column, value, row }: any) =>
                    <>
                        {value ? formatNumber(value) : "0.00"}
                    </>,
            },
            {
                Header: "Uplift ($)",
                accessor: "Upliftval",
                Cell: ({ column, value, row }: any) =>
                    <>
                        {row.original.isRenewed == '1' && value ? formatNumber(value) : "0.00"}
                    </>
            },
            {
                Header: "Cross Sell ($)",
                accessor: "Crosssellval",
                Cell: ({ column, value, row }: any) =>
                    <>
                        {row.original.isRenewed == '1' && value ? formatNumber(value) : "0.00"}
                    </>,
            },
            {
                Header: "UpSell ($)",
                accessor: "Upsellval",
                Cell: ({ column, value, row }: any) =>
                    <>
                        {row.original.isRenewed == '1' && value ? formatNumber(value) : "0.00"}
                    </>,
            },
            {
                Header: "Contraction ($)",
                accessor: "Contractionval",
                Cell: ({ column, value, row }: any) =>
                    <>
                        {row.original.isRenewed == '1' && value ? formatNumber(value) : "0.00"}
                    </>,
            },
            // {
            //     Header: "Renewal Type",
            //     accessor: "autorenewal",
            //     Cell: ({ column, value, row }: any) =>
            //         <>
            //             {value && value == 1 ? "Auto" : ""}
            //         </>,
            // },
            {
                Header: "Status",
                accessor: "newstatus",
            },
            {
                Header: "Sub motion",
                accessor: "submotion",
            },
        ],
        []
    )

    useEffect(() => {
        getContractData();
        getRevenueDetails();
    }, [!quarterValue])

    return (
        <React.Fragment>
            <div id="layout-wrapper">
                <Header />
                <LeftPanel />
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            {/* start page title */}
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-flex align-items-center justify-content-between">
                                        <h4 className="mb-0 font-size-18">Contract Page</h4>
                                        <div className="page-title-right">
                                            <ol className="breadcrumb m-0">
                                                <li className="breadcrumb-item">
                                                    <Link to={URLS.REVENUE}>Revenue</Link>
                                                </li>
                                                <li className="breadcrumb-item active">Contract Page</li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-12">
                                    <CommonBoxDetails
                                        filterDetails={filterDetails}
                                        doFilter={doFilter}
                                        setDoFilter={setDoFilter}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <FilterRow
                                                type="contract-page"
                                                filterDetails={filterDetails}
                                                setFilterDetails={setFilterDetails}
                                                setDoFilter={setDoFilter}
                                                defaultYear={true}
                                                callbackFunctions={[
                                                    getContractData,
                                                    getRevenueDetails
                                                ]}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-12">

                                    <div className="row">
                                        <div className="col-lg-12 col-12">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="table-responsive">
                                                        <table
                                                            id="datatable"
                                                            className="table table-striped dt-responsive nowrap"
                                                            style={{
                                                                borderCollapse: "collapse",
                                                                borderSpacing: 0,
                                                                width: "100%"
                                                            }}
                                                        >
                                                            <thead>
                                                                <tr>
                                                                    <th>Quarter of availble to renew</th>
                                                                    <th>Availble to renew ($)</th>
                                                                    <th>Open availble to renew ($)</th>
                                                                    <th>Forecast Renewal Rate (%)</th>
                                                                    <th>Actual C/C ($)</th>
                                                                    <th>Actual Base Renewed ($)</th>
                                                                    <th>Net Retention Rate (%)</th>
                                                                    <th>Actual Renew ($)</th>
                                                                    <th>Gross Renewal Rate (%)</th>
                                                                    <th>Renewal Forecast ($)</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td>FY</td>
                                                                    <td>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.fullyear?.availabletorenew)}</td>
                                                                    <td>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.fullyear?.openavailabletorenew)}</td>
                                                                    <td>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.fullyear?.forcastedrenewalrate)}</td>
                                                                    <td>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.fullyear?.actualcc)}</td>
                                                                    <td>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.fullyear?.actualbaserenewed)}</td>
                                                                    <td>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.fullyear?.netrelationrate)}</td>
                                                                    <td>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.fullyear?.actualrenew)}</td>
                                                                    <td>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.fullyear?.grossrenewalrate)}</td>
                                                                    <td>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.fullyear?.renewalforcast)}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>{revenueDetails?.currentfy} Q1</td>
                                                                    <td>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.first?.availabletorenew)}</td>
                                                                    <td>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.first?.openavailabletorenew)}</td>
                                                                    <td>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.first?.forcastedrenewalrate)}</td>
                                                                    <td>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.first?.actualcc)}</td>
                                                                    <td>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.first?.actualbaserenewed)}</td>
                                                                    <td>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.first?.netrelationrate)}</td>
                                                                    <td>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.first?.actualrenew)}</td>
                                                                    <td>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.first?.grossrenewalrate)}</td>
                                                                    <td>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.first?.renewalforcast)}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>{revenueDetails?.currentfy} Q2</td>
                                                                    <td>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.second?.availabletorenew)}</td>
                                                                    <td>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.second?.openavailabletorenew)}</td>
                                                                    <td>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.second?.forcastedrenewalrate)}</td>
                                                                    <td>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.second?.actualcc)}</td>
                                                                    <td>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.second?.actualbaserenewed)}</td>
                                                                    <td>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.second?.netrelationrate)}</td>
                                                                    <td>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.second?.actualrenew)}</td>
                                                                    <td>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.second?.grossrenewalrate)}</td>
                                                                    <td>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.second?.renewalforcast)}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>{revenueDetails?.currentfy} Q3</td>
                                                                    <td>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.third?.availabletorenew)}</td>
                                                                    <td>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.third?.openavailabletorenew)}</td>
                                                                    <td>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.third?.forcastedrenewalrate)}</td>
                                                                    <td>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.third?.actualcc)}</td>
                                                                    <td>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.third?.actualbaserenewed)}</td>
                                                                    <td>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.third?.netrelationrate)}</td>
                                                                    <td>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.third?.actualrenew)}</td>
                                                                    <td>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.third?.grossrenewalrate)}</td>
                                                                    <td>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.third?.renewalforcast)}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>{revenueDetails?.currentfy} Q4</td>
                                                                    <td>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.forth?.availabletorenew)}</td>
                                                                    <td>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.forth?.openavailabletorenew)}</td>
                                                                    <td>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.forth?.forcastedrenewalrate)}</td>
                                                                    <td>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.forth?.actualcc)}</td>
                                                                    <td>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.forth?.actualbaserenewed)}</td>
                                                                    <td>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.forth?.netrelationrate)}</td>
                                                                    <td>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.forth?.actualrenew)}</td>
                                                                    <td>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.forth?.grossrenewalrate)}</td>
                                                                    <td>{formatNumber(revenueDetails?.currentfydata?.quarterdata?.forth?.renewalforcast)}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-12">
                                            <div className="card">
                                                <div className="card-body">
                                                    <ChartContainer
                                                        filterDetails={filterDetails}
                                                        doFilter={doFilter}
                                                        setDoFilter={setDoFilter}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="d-flex flex-wrap justify-content-between">
                                                <h5 className="card-title mr-2">Contracts</h5>
                                            </div>
                                            <div className="table-responsive">
                                                <TableContainer
                                                    columns={columns}
                                                    data={data}
                                                    fetchData={getContractData}
                                                    loading={loading}
                                                    rawData={contractData}
                                                    clickFunc={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </React.Fragment>
    )
}

export default ContractPage