import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useUserApi } from "../../_common/hooks/api/UserApiHook";
import { useUserSelector } from "../../_common/hooks/selectors/userSelector";
import { ACTIONS, URLS } from "../../_config";
import { store } from "../../store";
import { useSidebarSelector } from "../../_common/hooks/selectors/loaderSelector";

export default function Header() {
	const userApi = useUserApi();
	const user: any = useUserSelector()
	const sidebar: boolean = useSidebarSelector();

	const logout = (e: any) => {
		//	console.log("logout")
		e.preventDefault();
		e.stopPropagation();
		userApi.logout({}, (message: string, resp: any) => {
		}, (message: string) => {
		})
	}

	const togglesidebar = () => {
		store.dispatch({
			type: ACTIONS.LOADER.SET_SIDEBAR,
			payload: {
				sidebar: !sidebar
			}
		});
	}

	// useEffect(() => {
	// 	togglesidebar()
	// }, []);

	useEffect(() => {
		// console.log({sidebar})
		if (sidebar === false) {
			document.body.classList.add("sidebar-enable", "vertical-collpsed")
		}
		else {
			document.body.classList.remove("sidebar-enable", "vertical-collpsed")
		}
	}, [sidebar])

	return (
		<React.Fragment>
			<header id="page-topbar">
				<div className="navbar-header">
					<div className="d-flex">
						{/* LOGO */}
						<div className="navbar-brand-box">
							<Link to={URLS.REVENUE} className="logo logo-dark">
								<span className="logo-sm">
									<img src="assets/images/logo.svg" alt="" height={22} />
								</span>
								<span className="logo-lg">
									<img src="assets/images/logo-dark.png" alt="" height={17} />
								</span>
							</Link>
							<Link to={URLS.REVENUE} className="logo logo-light">
								<span className="logo-sm">
									<img src="assets/images/logo-light.png" alt="" height={22} />
								</span>
								<span className="logo-lg">
									<img src="assets/images/logo-light.png" alt="" height={19} />
								</span>
							</Link>
						</div>
						<button
							type="button"
							className="btn btn-sm px-3 font-size-16 header-item waves-effect"
							id="vertical-menu-btn"
							onClick={togglesidebar}
						>
							<i className="fa fa-fw fa-bars" />
						</button>
						{/* App Search*/}
						{/* <form className="app-search d-none d-lg-block">
							<div className="position-relative">
								<input
									type="text"
									className="form-control"
									placeholder="Search..."
								/>
								<span className="bx bx-search-alt" />
							</div>
						</form> */}
					</div>
					<div className="d-flex">
						{/* <div className="dropdown d-inline-block d-lg-none ml-2">
							<button
								type="button"
								className="btn header-item noti-icon waves-effect"
								id="page-header-search-dropdown"
								data-toggle="dropdown"
								aria-haspopup="true"
								aria-expanded="false"
							>
								<i className="mdi mdi-magnify" />
							</button>
							<div
								className="dropdown-menu dropdown-menu-lg dropdown-menu-right p-0"
								aria-labelledby="page-header-search-dropdown"
							>
								<form className="p-3">
									<div className="form-group m-0">
										<div className="input-group">
											<input
												type="text"
												className="form-control"
												placeholder="Search ..."
												aria-label="Recipient's username"
											/>
											<div className="input-group-append">
												<button className="btn btn-primary" type="submit">
													<i className="mdi mdi-magnify" />
												</button>
											</div>
										</div>
									</div>
								</form>
							</div>
						</div>
						<div className="dropdown d-inline-block">
							<button
								type="button"
								className="btn header-item noti-icon waves-effect"
								id="page-header-notifications-dropdown"
								data-toggle="dropdown"
								aria-haspopup="true"
								aria-expanded="false"
							>
								<i className="bx bx-bell bx-tada" />
								<span className="badge badge-danger badge-pill">3</span>
							</button>
							<div
								className="dropdown-menu dropdown-menu-lg dropdown-menu-right p-0"
								aria-labelledby="page-header-notifications-dropdown"
							>
								<div className="p-3">
									<div className="row align-items-center">
										<div className="col">
											<h6 className="m-0" key="t-notifications">
												{" "}
												Notifications{" "}
											</h6>
										</div>
										<div className="col-auto">
											<a href="#!" className="small" key="t-view-all">
												{" "}
												View All
											</a>
										</div>
									</div>
								</div>
								<div data-simplebar="" style={{ maxHeight: 230 }}>
									<a href="" className="text-reset notification-item">
										<div className="media">
											<div className="avatar-xs mr-3">
												<span className="avatar-title bg-primary rounded-circle font-size-16">
													<i className="bx bx-cart" />
												</span>
											</div>
											<div className="media-body">
												<h6 className="mt-0 mb-1" key="t-your-order">
													Your order is placed
												</h6>
												<div className="font-size-12 text-muted">
													<p className="mb-1" key="t-grammer">
														If several languages coalesce the grammar
													</p>
													<p className="mb-0">
														<i className="mdi mdi-clock-outline" />{" "}
														<span key="t-min-ago">3 min ago</span>
													</p>
												</div>
											</div>
										</div>
									</a>
									<a href="" className="text-reset notification-item">
										<div className="media">
											<img
												src="assets/images/users/avatar-3.jpg"
												className="mr-3 rounded-circle avatar-xs"
												alt="user-pic"
											/>
											<div className="media-body">
												<h6 className="mt-0 mb-1">James Lemire</h6>
												<div className="font-size-12 text-muted">
													<p className="mb-1" key="t-simplified">
														It will seem like simplified English.
													</p>
													<p className="mb-0">
														<i className="mdi mdi-clock-outline" />{" "}
														<span key="t-hours-ago">1 hours ago</span>
													</p>
												</div>
											</div>
										</div>
									</a>
									<a href="" className="text-reset notification-item">
										<div className="media">
											<div className="avatar-xs mr-3">
												<span className="avatar-title bg-success rounded-circle font-size-16">
													<i className="bx bx-badge-check" />
												</span>
											</div>
											<div className="media-body">
												<h6 className="mt-0 mb-1" key="t-shipped">
													Your item is shipped
												</h6>
												<div className="font-size-12 text-muted">
													<p className="mb-1" key="t-grammer">
														If several languages coalesce the grammar
													</p>
													<p className="mb-0">
														<i className="mdi mdi-clock-outline" />{" "}
														<span key="t-min-ago">3 min ago</span>
													</p>
												</div>
											</div>
										</div>
									</a>
									<a href="" className="text-reset notification-item">
										<div className="media">
											<img
												src="assets/images/users/avatar-4.jpg"
												className="mr-3 rounded-circle avatar-xs"
												alt="user-pic"
											/>
											<div className="media-body">
												<h6 className="mt-0 mb-1">Salena Layfield</h6>
												<div className="font-size-12 text-muted">
													<p className="mb-1" key="t-occidental">
														As a skeptical Cambridge friend of mine occidental.
													</p>
													<p className="mb-0">
														<i className="mdi mdi-clock-outline" />{" "}
														<span key="t-hours-ago">1 hours ago</span>
													</p>
												</div>
											</div>
										</div>
									</a>
								</div>
								<div className="p-2 border-top">
									<Link
										className="btn btn-sm btn-link font-size-14 btn-block text-center"
										to="#"
									>
										<i className="mdi mdi-arrow-right-circle mr-1" />{" "}
										<span key="t-view-more">View More..</span>
									</Link>
								</div>
							</div>
						</div> */}
						<div className="dropdown d-inline-block">
							<button
								type="button"
								className="btn header-item waves-effect"
								id="page-header-user-dropdown"
								data-toggle="dropdown"
								aria-haspopup="true"
								aria-expanded="false"
							>
								<img
									className="rounded-circle header-profile-user"
									src="assets/images/users/avatar-9.jpg"
									alt="Header Avatar"
								/>
								<span className="d-none d-xl-inline-block ml-1" key="t-henry">
									{user?.first_name + " " + user?.last_name}
								</span>
								<i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
							</button>
							<div className="dropdown-menu dropdown-menu-right">
								{/* item*/}
								{/* <a className="dropdown-item" href="#">
									<i className="bx bx-user font-size-16 align-middle mr-1" />
									<span key="t-profile">Profile</span>
								</a>
								<a className="dropdown-item" href="#">
									<i className="bx bx-wallet font-size-16 align-middle mr-1" />{" "}
									<span key="t-my-wallet">My Wallet</span>
								</a>
								<a className="dropdown-item d-block" href="#">
									<span className="badge badge-success float-right">11</span>
									<i className="bx bx-wrench font-size-16 align-middle mr-1" />{" "}
									<span key="t-settings">Settings</span>
								</a>
								<a className="dropdown-item" href="#">
									<i className="bx bx-lock-open font-size-16 align-middle mr-1" />{" "}
									<span key="t-lock-screen">Lock screen</span>
								</a> */}
								{/* <div className="dropdown-divider" /> */}
								<button className="dropdown-item text-danger" onClick={logout}>
									<i className="bx bx-power-off font-size-16 align-middle mr-1 text-danger" />{" "}
									<span key="t-logout">Logout</span>
								</button>
							</div>
						</div>
					</div>
				</div>
			</header>
		</React.Fragment>
	);
}
