import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Header from '../../components/commonComponents/Header';
import LeftPanel from '../../components/commonComponents/LeftPanel';
import { URLS } from '../../_config';
import Footer from '../../components/commonComponents/Footer';
import ConditionList from '../../components/dataListing/ConditionList';
import AddViewConditionModal from '../../components/modals/addViewCondition.modal';


const Conditions = () => {

    const [showConditionModal, setShowConditionModal] = useState<boolean>(false);
    const [callFunction, setCallFunction] = useState<boolean>(false);
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [ruleId, setRuleId] = useState<any>(null);

    const closeConditionModal = () => {
        setShowConditionModal(false)
        setRuleId(null)
        setIsEdit(false)
    }

    return (
        <React.Fragment>
            <AddViewConditionModal
                show={showConditionModal}
                close={closeConditionModal}
                ruleId={ruleId}
                isEdit={isEdit}
                setCallFunction={setCallFunction}
            />
            <div id="layout-wrapper">
                <Header />
                <LeftPanel />
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-flex align-items-center justify-content-between">
                                        <h4 className="mb-0 font-size-18">Conditions</h4>
                                        <div className="page-title-right">
                                            <ol className="breadcrumb m-0">
                                                <li className="breadcrumb-item">
                                                    <Link to={URLS.REVENUE}>Revenue</Link>
                                                </li>
                                                <li className="breadcrumb-item active">Conditions</li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="table-responsive manage-user-table">
                                                <ConditionList
                                                    showConditionModal={showConditionModal}
                                                    setShowConditionModal={setShowConditionModal}
                                                    setRuleId={setRuleId}
                                                    setIsEdit={setIsEdit}
                                                    isEdit={isEdit}
                                                    callFunction={callFunction}
                                                    setCallFunction={setCallFunction}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </React.Fragment >
    )
}

export default Conditions