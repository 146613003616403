import React, { useEffect, useMemo, useState } from 'react';
import { usePlatformApi } from '../../_common/hooks/api/PlatformApiHook';
import { useAppLoader } from '../../_common/hooks/common/appLoaderHook';
import EditButtonCell from '../actionsBtns/EditButtonCell';
import ViewButtonCell from '../actionsBtns/ViewButtonCell';
import TableContainer from '../commonComponents/TableContainer';
import DeleteButtonCell from '../actionsBtns/DeleteButtonCell';
import { formatNumber } from '../../_common/functions';
import QuotePreviewModal from '../modals/quotePreview.modal';
import { AiOutlineFileText, AiOutlineDownload } from 'react-icons/ai';

interface IncomingProps {
    id?: any;
    type?: string;
    isEdit?: boolean;
    selectedRow?: any;
    setSelectedRow?: any;
    selectedQuoteId?: string;
    setSelectedQuoteId?: any;
}


const QuoteList = ({ id, type, isEdit = false, selectedRow, setSelectedRow, selectedQuoteId, setSelectedQuoteId }: IncomingProps) => {

    const platformApi = usePlatformApi();
    const { showLoader, hideLoader } = useAppLoader();

    const [quoteData, setQuoteData] = useState<any>(null)
    const [data, setData] = useState<any>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [showQuotePreviewModal, setShowQuotePreviewModal] = useState<boolean>(false)

    const handleRowClick = (rowId: number, value: string, check: boolean) => {
        // console.log(rowId, value, check)
        setSelectedRow(rowId === selectedRow ? null : rowId);
        if (check) {
            setSelectedQuoteId(value);
        } else {
            setSelectedQuoteId('')
        }
    }

    const getQuotePdf = (id: any) => {
        showLoader();
        setLoading(true);
        platformApi.getQuotePdf({ id },
            (message: string, resp: any) => {
                // console.log(resp);
                window.open(resp.data.link, '_blank');
                setLoading(false);
                hideLoader();
            }, (message: string) => {
                setLoading(false);
                hideLoader();
            })
    }

    const getQuoteData = (page = 1) => {
        showLoader();
        setLoading(true);
        let params: any = { page: page }
        params[`${type}`] = id;
        platformApi.getAllQuote(params,
            (message: string, resp: any) => {
                // console.log(resp);
                setLoading(false);
                setQuoteData(resp.data.list);
                setData(resp.data.list.data);
                hideLoader();
            }, (message: string) => {
                setLoading(false);
                hideLoader();
            })
    }

    const deleteQuote = (id: any) => {
        return new Promise((resolve: any, reject: any) => {
            platformApi.quoteDestroy({
                id
            }, (message: string, resp: any) => {
                resolve(message)
            }, (message: string) => {
                reject(message);
            });
        })
    }

    const openQuotePreviewModal = () => {
        setShowQuotePreviewModal(true)
    }

    const closeQuotePreviewModal = () => {
        setShowQuotePreviewModal(false)
    }

    const columns = useMemo(
        () => [
            {
                Header: 'Actions',
                accessor: "QuoteId",
                Cell: ({ column, value, row }: any) =>
                    <>
                        {isEdit ?
                            <>
                                <DeleteButtonCell
                                    column={column}
                                    value={row.original.id}
                                    deleteFunc={deleteQuote}
                                    callbackFunc={[getQuoteData]}
                                    type={"Quote"}
                                />
                                <ViewButtonCell column={column} value={value} link={'quote-details'} />
                                <AiOutlineFileText
                                    className="cursor-pointer mr-2"
                                    onClick={() => { openQuotePreviewModal(); setSelectedQuoteId(row.original.QuoteId) }}
                                    title="Preview"
                                />
                                {type == 'AccountId' ?
                                    <EditButtonCell column={column} value={value} type={"quote"} link={`add-quote/${id}?type=AccountId`} />
                                    :
                                    <EditButtonCell column={column} value={value} link={`add-quote/${id}`} />
                                }
                                <AiOutlineDownload
                                    className="cursor-pointer mr-2"
                                    onClick={() => { getQuotePdf(row.original.id) }}
                                    title="Download"
                                />
                                <input
                                    className='mr-2'
                                    type="checkbox"
                                    id={`check${row.id}`}
                                    checked={selectedRow === row.id}
                                    onChange={(e: any) => { handleRowClick(row.id, row.original.id, e.target.checked) }}
                                />
                            </>
                            :
                            <ViewButtonCell column={column} value={value} link={'quote-details'} />
                        }
                    </>,
            },
            {
                Header: "Name",
                accessor: "Name",
            },
            {
                Header: "Status",
                accessor: "Status",
            },
            {
                Header: "Subtotal ($)",
                accessor: "Subtotal",
                Cell: ({ column, value, row }: any) => (
                    <div>
                        {value ? formatNumber(value) : '0.00'}
                    </div>
                )
            },
            {
                Header: "Discount ($)",
                accessor: "Discount",
                Cell: ({ column, value, row }: any) => (
                    <div>
                        {value ? formatNumber(+row?.original?.Subtotal * +value / 100) : '0.00'}
                    </div>
                )
            },
            {
                Header: "Tax ($)",
                accessor: "Tax",
                Cell: ({ column, value, row }: any) => (
                    <div>
                        {value ? formatNumber(+row?.original?.TotalPrice * +value / 100) : '0.00'}
                    </div>
                )
            },
            {
                Header: "Shipping Charge ($)",
                accessor: "ShippingCharge",
                Cell: ({ column, value, row }: any) => (
                    <div>
                        {value ? formatNumber(value) : '0.00'}
                    </div>
                )
            },
            {
                Header: "GrandTotal ($)",
                accessor: "GrandTotal",
                Cell: ({ column, value, row }: any) => (
                    <div>
                        {value ? formatNumber(value) : '0.00'}
                    </div>
                )
            },
        ],
        [selectedRow, isEdit]
    )

    useEffect(() => {
        if (id) {
            getQuoteData();
        }
    }, [id])

    return (
        <React.Fragment>
            <div className="table-responsive">
                <TableContainer
                    columns={columns}
                    data={data}
                    fetchData={getQuoteData}
                    loading={loading}
                    rawData={quoteData}
                    clickFunc={true}
                />
            </div>
            {showQuotePreviewModal && <QuotePreviewModal
                show={showQuotePreviewModal}
                close={closeQuotePreviewModal}
                quoteId={selectedQuoteId}
            />}
        </React.Fragment>
    )
}

export default QuoteList