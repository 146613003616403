import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { useAppLoader } from '../../_common/hooks/common/appLoaderHook';
import { toast } from 'react-hot-toast';
import { useZendeskApi } from '../../_common/hooks/api/ZendeskApiHook';

interface IncomingProps {
	show: boolean;
	close: () => void;
	accountId: any
}

const ViewZendeskTicketModal = ({ show = false, close, accountId }: IncomingProps) => {

	const zendeskApi = useZendeskApi();
	const { showLoader, hideLoader } = useAppLoader();

	const [ticketData, setTicketData] = useState<any>(null)

	const getZendeskTktList = () => {
		showLoader();
		zendeskApi.getZendeskTicketByAccount({ id: accountId },
			(message: string, resp: any) => {
				// console.log(resp);
				setTicketData(resp.data.rows);
				hideLoader();
			}, (message: string) => {
				hideLoader();
			})
	}

	useEffect(() => {
		if (accountId && show) {
			getZendeskTktList()
		}
	}, [accountId, show])

	return (
		<React.Fragment>
			<Modal
				className='salesforce-modal modal-lndscp'
				show={show}
				onHide={close}
			>
				<div className="modal-header custom-modal-header">
					<button
						type="button"
						className="close"
						data-dismiss="modal"
						aria-label="Close"
						onClick={close}
					>
						<span aria-hidden="true">×</span>
					</button>
				</div>
				<Modal.Body>
					<div className="row">
						<div className="col-12">
							<div className="card">
								<div className="card-body">
									{ticketData && ticketData.length > 0 ?
										ticketData.map((item: any, index: number) =>
											<div className="row mb-2" key={index}>
												<div className="col-12">
													<label htmlFor="subject" className='mr-2'>Subject :</label>
													{item?.ticket?.subject}
												</div>
												<div className="col-12">
													<label htmlFor="desc" className='mr-2'>Description :</label>
													{item?.ticket?.description}
												</div>
											</div>
										)
										:
										<h6>No details !</h6>
									}

								</div>
							</div>
						</div>
					</div>
				</Modal.Body>
			</Modal>
		</React.Fragment>
	)
}

export default ViewZendeskTicketModal