import React, { useEffect, useRef, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Controller, useForm } from 'react-hook-form';
import { usePlatformApi } from '../../_common/hooks/api/PlatformApiHook';
import { useAppLoader } from '../../_common/hooks/common/appLoaderHook';
import { toast } from 'react-hot-toast';
import moment from 'moment';
import DatePicker from 'react-datepicker';

interface FormValues {
	closeDate: string;
}

const validationSchema = Yup.object().shape({
	closeDate: Yup.date().required("Close date is required"),
});

interface IncomingProps {
	show: boolean;
	close: () => void;
	selectedContract: any;
	callbackFunctions: any;
}

const ForecastEndDate = ({ show, close, selectedContract, callbackFunctions }: IncomingProps) => {

	const platformApi = usePlatformApi();
	const { showLoader, hideLoader } = useAppLoader();

	const [loading, setLoading] = useState<boolean>(false)
	const datePickerRef = useRef<any>(null);

	const { control, formState: { errors }, handleSubmit, setValue, reset } = useForm<FormValues>({
		resolver: yupResolver(validationSchema),
		defaultValues: {
			closeDate: "",
		},
	});

	const onSubmit = (data: any) => {
		// console.log({ data })
		let params: any = {
			forecast_closedate: data?.closeDate ? moment(data.closeDate).format("MM/DD/YY") : "",
			id: selectedContract?.id,
			Forecasted: show
		}
		// console.log(params)
		showLoader()
		platformApi.manageContract(params, (message: string, resp: any) => {
			// console.log(message, resp)
			toast.success(message)
			reset()
			close()
			hideLoader()
			callbackFunctions?.forEach((func: any) => {
				func();
			});
		}, (message: string) => {
			// console.log(message)
			toast.error(message)
			hideLoader()
		})
	}

	useEffect(() => {
		setValue('closeDate', selectedContract?.forecast_closedate && new Date(selectedContract?.forecast_closedate))
	}, [selectedContract])

	useEffect(() => {
		if (errors?.closeDate) {
			datePickerRef.current.setOpen(true);
		}
	}, [errors])

	return (
		<React.Fragment>
			<Modal
				className='salesforce-modal modal-lndscp'
				show={show}
			// onHide={() => { close(); reset() }}
			>
				<div className="modal-header custom-modal-header">
					<button
						type="button"
						className="close"
						data-dismiss="modal"
						aria-label="Close"
						onClick={() => { close(); reset() }}
					>
						<span aria-hidden="true">×</span>
					</button>
				</div>
				<Modal.Body>
					{loading ?
						<div>
							Loading......
						</div>
						:
						<div className="row">
							<form onSubmit={handleSubmit(onSubmit)} className="w-100" >
								<div className="col-12">
									<div className="card">
										<div className="card-body">
											{/* <div className="d-flex flex-wrap">
													<h5 className="card-title mr-2">User Information</h5>
												</div> */}
											<div className="row">
												<div className="form-group col-12">
													<label htmlFor="closeDate">Forecast close date <span className='text-danger'>*</span></label>
													<Controller
														name={"closeDate"}
														control={control}
														render={({ field: { value, onChange, onBlur, name, ref } }) => (
															<>
																<DatePicker
																	selected={value ? new Date(value) : null}
																	onChange={onChange}
																	dateFormat="MM/dd/yy"
																	className={"form-control " + (errors?.closeDate ? ' border-danger' : '')}
																	id="closeDate"
																	name={name}
																	onBlur={onBlur}
																	ref={datePickerRef}
																	value={value}
																	placeholderText="Enter forecast close date"
																/>
															</>
														)}
													/>
													{errors && errors.closeDate &&
														<span className='text-danger'>Close date is required</span>
													}
												</div>
											</div>
										</div>
									</div>
								</div>

								<div className="col-12">
									<div className='align-items-center d-flex gap-10 justify-content-center'>
										<button type='submit' className='btn btn-primary'>Save</button>
										<button
											type='button'
											className='btn btn-outline-danger'
											onClick={() => { close(); reset() }}
										>
											Cancel
										</button>
									</div>
								</div>
							</form>
						</div>
					}
				</Modal.Body>
			</Modal>
		</React.Fragment>
	)
}

export default ForecastEndDate