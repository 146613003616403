import React, { useEffect, useRef, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Controller, useForm } from 'react-hook-form';
import { useAppLoader } from '../../_common/hooks/common/appLoaderHook';
import { toast } from 'react-hot-toast';
import { useCommonApi } from '../../_common/hooks/api/common/commonApiHook';
import { handleBlur, handleFocus, handleKeyPressDecimalNumber, handleKeyUpDown, handleWheel } from '../../_common/functions';
import Select from 'react-select';
import { operatorOptions, targetFieldOptions } from '../../_common/staticData';

interface FormValues {
	conditionname: string;
	conditionindex: string;
	fieldvalue: string;
	rules: any;
	targetfield: any;
	operator: any;
	fieldtype: any;
}

const validationSchema = Yup.object().shape({
	conditionname: Yup.string().required("Condition name is required"),
	operator: Yup.object().required("Operator is required"),
	targetfield: Yup.object().required("Target field is required"),
	fieldvalue: Yup.number().required("Field value is required").when('targetfield',
		{
			is: (item: any) => item && item.value != 'Amount',
			then: Yup.number().max(100, 'Percentage can not be grater than 100'),
			otherwise: Yup.number(),
		}),
	// conditionindex: Yup.string().required("Condition index is required"),
	// fieldtype: Yup.object().required("Field type is required"),
	// rules: Yup.object().required("Rules is required"),
});

interface IncomingProps {
	show: boolean;
	close: () => void;
	ruleId: any
	approverRuleId?: any;
	isEdit?: boolean;
	setCallFunction: any;
}

const AddViewConditionModal = ({ show, close, ruleId, isEdit, setCallFunction }: IncomingProps) => {

	const commonApi = useCommonApi();
	const { showLoader, hideLoader } = useAppLoader();

	const [conditionData, setConditionData] = useState<any>(null)
	const [loading, setLoading] = useState<boolean>(false)
	const [showAddForm, setShowAddForm] = useState<boolean>(false)
	const [approverRuleOptions, setApproverRuleOptions] = useState<any | []>([])
	const [fieldTypeOptions, setFieldTypeOptions] = useState<any | []>([{ label: "value", value: "value" }])
	const [conditionid, setConditionId] = useState<any>(null)

	const formScrollRef = useRef<any>(null);
	const tableScrollRef = useRef<any>(null);

	const { control, formState: { errors }, handleSubmit, setValue, reset, watch } = useForm<FormValues>({
		resolver: yupResolver(validationSchema),
		defaultValues: {
			conditionname: "",
			conditionindex: "1",
			fieldvalue: undefined,
			rules: "",
			targetfield: "",
			operator: "",
			fieldtype: fieldTypeOptions[0],
		},
	});

	const selectedTargetField = watch('targetfield');

	const getApproverRuleConditionData = () => {
		showLoader();
		setLoading(true)
		commonApi.getAllApproverRuleCondition({ rules: ruleId },
			(message: string, resp: any) => {
				// console.log(resp);
				setConditionData(resp.data.list.data[0]);
				setLoading(false)
				hideLoader();
			}, (message: string) => {
				hideLoader();
				setLoading(false)
			})
	}

	const getApproverRuleList = () => {
		showLoader();
		commonApi.getAllApproverRule({},
			(message: string, resp: any) => {
				// console.log(resp);
				let tempArr = resp.data.list.data
				if (tempArr && tempArr.length > 0) {
					setApproverRuleOptions(tempArr.map((item: any) => {
						item.label = item.rulename;
						item.value = item.id;
						return item
					}));
				}
				hideLoader();
			}, (message: string) => {
				hideLoader();
			})
	}

	const onSubmit = (data: any) => {
		// console.log({ data })
		let params: any = {
			conditionname: data?.conditionname,
			conditionindex: data?.conditionindex,
			fieldvalue: data?.fieldvalue,
			rules: ruleId ? ruleId : data?.rules?.value,
			targetfield: data?.targetfield?.value,
			operator: data?.operator?.value,
			fieldtype: data?.fieldtype?.value,
		}
		if (conditionData && conditionData.id) {
			params["id"] = conditionData.id
		}
		// console.log(params.id)
		showLoader()
		commonApi.manageApproverRuleCondition(params, (message: string, resp: any) => {
			// console.log(message, resp)
			toast.success(message)
			handleClose()
			hideLoader()
			setCallFunction(true)
		}, (message: string) => {
			// console.log(message)
			toast.error(message)
			hideLoader()
		})
	}

	const handleClose = (isModalClose: boolean = false) => {
		// setConditionData(null)
		reset()
		setShowAddForm(false)
		setConditionId(null)
		setConditionData(null)
		close()
		// if (isModalClose) {
		// }
	}

	// useEffect(() => {
	// 	if (showAddForm && formScrollRef && formScrollRef.current) {
	// 		formScrollRef.current.scrollIntoView({ behavior: 'smooth' });
	// 	} else if (!showAddForm && tableScrollRef && tableScrollRef.current) {
	// 		tableScrollRef.current.scrollIntoView({ behavior: 'smooth' });
	// 	}
	// }, [showAddForm])

	useEffect(() => {
		if (ruleId) {
			getApproverRuleConditionData()
		}
	}, [ruleId])

	useEffect(() => {
		if (show) {
			getApproverRuleList()
		}
	}, [show])

	useEffect(() => {
		// console.log(conditionData?.fieldvalue)
		if (conditionData) {
			setValue('conditionname', conditionData?.conditionname)
			setValue('conditionindex', conditionData?.conditionindex)
			setValue('fieldvalue', conditionData?.fieldvalue ? conditionData?.fieldvalue : '')
			setValue('rules', approverRuleOptions?.find((item: any) => item.value == conditionData?.rules))
			setValue('targetfield', targetFieldOptions?.find((item: any) => item.value == conditionData?.targetfield))
			setValue('operator', operatorOptions?.find((item: any) => item.value == conditionData?.operator))
			setValue('fieldtype', fieldTypeOptions?.find((item: any) => item.value == conditionData?.fieldtype))
		}
	}, [conditionData, approverRuleOptions, targetFieldOptions, fieldTypeOptions])

	// useEffect(() => {
	// 	setValue('rules', approverRuleOptions?.find((item: any) => item.value == approverRuleId))
	// }, [approverRuleOptions])

	return (
		<React.Fragment>
			<Modal
				className='salesforce-modal modal-lndscp'
				show={show}
			// onHide={() => handleClose(true)}
			>
				<div className="modal-header custom-modal-header">
					<button
						type="button"
						className="close"
						data-dismiss="modal"
						aria-label="Close"
						onClick={() => handleClose(true)}
					>
						<span aria-hidden="true">×</span>
					</button>
				</div>
				<Modal.Body>
					{loading ?
						<div>
							Loading......
						</div>
						:
						<div className="row">

							<div className="col-12">
								<div className="card">
									<div className="card-body" ref={tableScrollRef}>
										<div className="d-flex flex-wrap justify-content-between">
											<h5 className="card-title mr-2">Condition</h5>
											{/* <button
												onClick={() => setShowAddForm(true)}
												className="btn btn-success"
											>
												Add Condition
											</button> */}
										</div>

										{/* {showAddForm ? */}
										<form onSubmit={handleSubmit(onSubmit)} className="w-100 mt-2" >
											<div className="col-12">
												<div className="row">
													<div className="form-group col-3">
														<label htmlFor="rules">Approver Name <span className='text-danger'>*</span></label>
														<Controller
															name={"rules"}
															control={control}
															render={({ field: { value, onChange, onBlur, name, ref } }) => (
																<Select
																	className={"select-box" + (errors?.rules ? ' border-danger' : '')}
																	id="rules"
																	options={approverRuleOptions ? approverRuleOptions : []}
																	placeholder={"select approver"}
																	onChange={onChange}
																	value={value}
																	defaultValue={value}
																	isClearable={true}
																	isDisabled={isEdit}
																/>
															)}
														/>
														{errors && errors.rules &&
															<span className='text-danger'>Approver is required</span>
														}
													</div>
													<div className="form-group col-3">
														<label htmlFor="conditionname">Name <span className='text-danger'>*</span></label>
														<Controller
															name={"conditionname"}
															control={control}
															render={({ field: { value, onChange, onBlur, name, ref } }) => (
																<>
																	<input
																		type="text"
																		className={"form-control " + (errors?.conditionname ? ' border-danger' : '')}
																		id="conditionname"
																		name={name}
																		onChange={onChange}
																		value={value}
																		onBlur={onBlur}
																		ref={ref}
																		placeholder="Enter condition name"
																	/>
																</>
															)}
														/>
														{errors && errors.conditionname &&
															<span className='text-danger'>{errors?.conditionname?.message}</span>
														}
													</div>
													<div className="form-group col-3">
														<label htmlFor="operator">Operator <span className='text-danger'>*</span></label>
														<Controller
															name={"operator"}
															control={control}
															render={({ field: { value, onChange, onBlur, name, ref } }) => (
																<>
																	<Select
																		className={"select-box" + (errors?.operator ? ' border-danger' : '')}
																		id="operator"
																		options={operatorOptions ? operatorOptions : []}
																		placeholder={"slect operator"}
																		onChange={onChange}
																		value={value}
																		defaultValue={value}
																	/>
																</>
															)}
														/>
														{errors && errors.operator &&
															<span className='text-danger'>operator is required</span>
														}
													</div>
													<div className="form-group col-3">
														<label htmlFor="targetfield">Target Field <span className='text-danger'>*</span></label>
														<Controller
															name={"targetfield"}
															control={control}
															render={({ field: { value, onChange, onBlur, name, ref } }) => (
																<>
																	<Select
																		className={"select-box" + (errors?.targetfield ? ' border-danger' : '')}
																		id="targetfield"
																		options={targetFieldOptions ? targetFieldOptions : []}
																		placeholder={"slect target"}
																		onChange={onChange}
																		value={value}
																		defaultValue={value}
																	/>
																</>
															)}
														/>
														{errors && errors.targetfield &&
															<span className='text-danger'>target field is required</span>
														}
													</div>
													<div className="form-group col-3">
														<label htmlFor="fieldvalue">Field value {selectedTargetField && selectedTargetField.value != 'Amount' ? '(%)' : '($)'} <span className='text-danger'>*</span></label>
														<Controller
															name={"fieldvalue"}
															control={control}
															render={({ field: { value, onChange, onBlur, name, ref } }) => (
																<>
																	<input
																		type="number"
																		className={"form-control " + (errors?.fieldvalue ? ' border-danger' : '')}
																		id="fieldvalue"
																		name={name}
																		value={value}
																		onChange={onChange}
																		onBlur={(e) => { onBlur(); handleBlur(e) }}
																		onFocus={(e) => handleFocus(e)}
																		onKeyDown={(event: any) => {
																			handleKeyPressDecimalNumber(event);
																			handleKeyUpDown(event)
																		}}
																		onWheel={handleWheel}
																		step={"0.01"}
																		min={0}
																		ref={ref}
																		placeholder="Enter field value"
																	/>
																</>
															)}
														/>
														{errors && errors.fieldvalue && errors.fieldvalue.type == "typeError" ?
															<span className='text-danger'>Field value is required</span>
															:
															<span className='text-danger'>{errors?.fieldvalue?.message}</span>
														}
													</div>
												</div>
											</div>

											<div className="col-12" ref={formScrollRef}>
												<div className='align-items-center d-flex gap-10 justify-content-center'>
													<button type='submit' className='btn btn-primary'>Save</button>
													<button
														type='button'
														className='btn btn-outline-danger'
														onClick={() => handleClose(true)}
													>
														Cancel
													</button>
												</div>
											</div>
										</form>
										{/* : null
										} */}
										{/* <ConditionList
											callFunction={callFunction}
											setCallFunction={setCallFunction}
											ruleId={ruleId}
											setIsEdit={setIsEdit}
											setConditionId={setConditionId}
											setShowAddForm={setShowAddForm}
										/> */}
									</div>
								</div>
							</div>
						</div>
					}
				</Modal.Body>
			</Modal>
		</React.Fragment>
	)
}

export default AddViewConditionModal