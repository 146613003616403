import React, { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import EditButtonCell from '../components/actionsBtns/EditButtonCell'
import ViewButtonCell from '../components/actionsBtns/ViewButtonCell'
import Footer from '../components/commonComponents/Footer'
import Header from '../components/commonComponents/Header'
import LeftPanel from '../components/commonComponents/LeftPanel'
import TableContainer from '../components/commonComponents/TableContainer'
import { useAppLoader } from '../_common/hooks/common/appLoaderHook'
import { URLS } from '../_config'
import DeleteButtonCell from '../components/actionsBtns/DeleteButtonCell'
import { toast } from 'react-hot-toast'
import { useCommonApi } from '../_common/hooks/api/common/commonApiHook'

const ManageApprovals = () => {

    const commonApi = useCommonApi();
    const { showLoader, hideLoader } = useAppLoader();

    const [approvalsData, setApprovalsData] = useState<any>(null)
    const [data, setData] = useState<any>([])
    const [loading, setLoading] = useState<boolean>(false)

    const getApprovalsData = (page = 1) => {
        showLoader();
        setLoading(true);
        commonApi.getAllApproval({ page: page },
            (message: string, resp: any) => {
                // console.log(resp);
                setLoading(false);
                setApprovalsData(resp.data.list);
                setData(resp.data.list.data);
                hideLoader();
            }, (message: string) => {
                setLoading(false);
                hideLoader();
            })
    }

    const deleteApproval = (id: any) => {
        return new Promise((resolve: any, reject: any) => {
            commonApi.deleteApproval({
                id
            }, (message: string, resp: any) => {
                resolve(message)
            }, (message: string) => {
                reject(message);
            });
        })
    }

    const columns = useMemo(
        () => [
            {
                Header: 'Actions',
                accessor: "id",
                Cell: ({ column, value, row }: any) =>
                    <>
                        <DeleteButtonCell
                            column={column}
                            value={value}
                            deleteFunc={deleteApproval}
                            callbackFunc={[getApprovalsData]}
                            type={"Approvals"}
                        />
                        <ViewButtonCell column={column} value={value} link={'approval-details'}/>
                    </>,
            },
            {
                Header: "Quote Name",
                accessor: "quoteobject.Name",
            },
            {
                Header: "Account Name",
                accessor: "quoteobject.account.Name",
            },
            {
                Header: "Approver",
                accessor: "approverobject.approvername",
            },
            {
                Header: "Status",
                accessor: "status",
            },
        ],
        []
    )

    useEffect(() => {
        getApprovalsData();
    }, [])

    return (
        <React.Fragment>
            <div id="layout-wrapper">
                <Header />
                <LeftPanel />
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            {/* start page title */}
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-flex align-items-center justify-content-between">
                                        <h4 className="mb-0 font-size-18">Approvals</h4>
                                        <div className="page-title-right">
                                            <ol className="breadcrumb m-0">
                                                <li className="breadcrumb-item">
                                                    <Link to={URLS.REVENUE}>Revenue</Link>
                                                </li>
                                                <li className="breadcrumb-item active">Approvals</li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* end page title */}
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="d-flex flex-wrap justify-content-between">
                                                <h5 className="card-title mr-2">Approvals</h5>
                                            </div>
                                            <div className="table-responsive">
                                                <TableContainer
                                                    columns={columns}
                                                    data={data}
                                                    fetchData={getApprovalsData}
                                                    loading={loading}
                                                    rawData={approvalsData}
                                                    clickFunc={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </React.Fragment>
    )
}

export default ManageApprovals