import React from 'react'
import { AiOutlineEye } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom'

interface IncomingProps {
    column: any;
    value: any;
    link: any;
}

const ViewButtonCell = ({ column, value, link }: IncomingProps) => {
    const navigate = useNavigate();
    return (
        <AiOutlineEye
            className="cursor-pointer mr-2"
            onClick={() => navigate(`/${link}/${value}`)}
            data-toggle="tooltip" data-placement="top" title="View"
        />
    )
}

export default ViewButtonCell