import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import { usePlatformApi } from '../../_common/hooks/api/PlatformApiHook';
import { useAppLoader } from '../../_common/hooks/common/appLoaderHook';
import { toast } from 'react-hot-toast';
import { useCommonApi } from '../../_common/hooks/api/common/commonApiHook';
import { AiOutlinePlusSquare } from 'react-icons/ai';
import AddRegionModal from './addRegion.modal';
import AddDesignationModal from './addDesignation.modal';
import { licenceOptions } from '../../_common/staticData';

interface FormValues {
	name: string;
	username: string;
	password: string;
	// role: string;
	email: string;
	licencetype: any;
	region: any;
	timezone: string;
	designationid: any;
	per_homepage: boolean;
	per_revenue: boolean;
	per_upside: boolean;
	per_atrisk: boolean;
	per_cforcast: boolean;
	per_forcast: boolean;
	per_quoting: boolean;
	per_contracts: boolean;
	per_account: boolean;
	per_pricebook: boolean;
	per_product: boolean;
	per_adv: boolean;
	per_users: boolean;
	per_future: boolean;
	per_approvals: boolean;
	// canapprove: boolean;
	isEdit: boolean;
}

const validationSchema = Yup.object().shape({
	name: Yup.string().required("Name is required"),
	username: Yup.string().required("User Name is required"),
	password: Yup.string().when('isEdit', {
		is: false,
		then: Yup.string().required('Password is required'),
		otherwise: Yup.string().notRequired(),
	}),
	// password: Yup.string().required("Password is required"),
	// role: Yup.string().required("Role is required"),
	email: Yup.string().email('Invalid email').required("Email is required"),
	licencetype: Yup.object().required("Licence is required"),
	region: Yup.object().required("Region is required"),
	designationid: Yup.object().required("Designation is required"),
	timezone: Yup.string().required("Time Zone is required"),
});

interface IncomingProps {
	show: boolean;
	close: () => void;
	arrUserId?: any;
	isEdit: boolean;
	arrPageData: any;
	callbackFuncs: any;
}

const AddUserModal = ({ show, close, arrUserId, isEdit = false, arrPageData, callbackFuncs}: IncomingProps) => {

	const platformApi = usePlatformApi();
	const commonApi = useCommonApi();
	const { showLoader, hideLoader } = useAppLoader();

	const [arrUserData, setArrUserData] = useState<any>(null)
	const [loading, setLoading] = useState<boolean>(false)
	const [regionOptions, setRegionOption] = useState<any>(null)
	const [designationOptions, setDesignationOptions] = useState<any>(null)
	const [permissionList, setPermissionsList] = useState<any>(null)
	const [isCreateLoading, setIsCreateLoading] = useState<boolean>(false)
	const [showAddRegionModal, setShowAddRegionModal] = useState<boolean>(false)
	const [showAddDesignationModal, setShowDesignationModal] = useState<boolean>(false)

	const { control, formState: { errors }, handleSubmit, setValue, reset } = useForm<FormValues>({
		resolver: yupResolver(validationSchema),
		defaultValues: {
			name: "",
			username: "",
			password: "",
			// role: "",
			email: "",
			licencetype: "",
			region: "",
			timezone: "",
			designationid: "",
			per_homepage: false,
			per_revenue: true,
			per_upside: false,
			per_atrisk: false,
			per_cforcast: false,
			per_forcast: false,
			per_quoting: false,
			per_contracts: false,
			per_account: false,
			per_pricebook: false,
			per_product: false,
			per_adv: false,
			per_users: false,
			per_future: false,
			per_approvals: false,
			// canapprove: false,
		},
	});

	const getRegionList = () => {
		showLoader();
		commonApi.getAllRegions({},
			(message: string, resp: any) => {
				// console.log(resp);
				let tempArr = resp.data.list.data
				if (tempArr && tempArr.length > 0) {
					setRegionOption(tempArr.map((item: any) => {
						item.label = item.region_name;
						item.value = item.Region;
						return item
					}));
				}
				hideLoader();
			}, (message: string) => {
				hideLoader();
			})
	}

	const getArrUserData = () => {
		showLoader();
		setLoading(true)
		platformApi.getAllArrUsers({ id: arrUserId },
			(message: string, resp: any) => {
				// console.log(resp);
				setArrUserData(resp.data.list);
				setLoading(false)
				hideLoader();
			}, (message: string) => {
				hideLoader();
				setLoading(false)
			})
	}

	const getDesignationData = (page = 1) => {
		commonApi.getAllDesignation({ page: page },
			(message: string, resp: any) => {
				const tempList: any = resp.data.list.data;
				if (tempList && tempList.length > 0) {
					setDesignationOptions(tempList.map((item: any) => {
						item.label = item.name
						item.value = item.id
						return item
					}));
				}
			}, (message: string) => {
			})
	}

	const onSubmit = (data: any) => {
		// console.log({ data })
		let params: any = {
			// "user_id": "",
			// "platform_id": "",
			// "arr_user_id": "",
			// "role": data?.role,
			"username": data?.username,
			"email": data?.email,
			"name": data?.name,
			"password": data?.password,
			"licencetype": data?.licencetype?.value,
			"region": data?.region?.value,
			"timezone": data?.timezone,
			"status": data?.status,
			"designationid": data?.designationid?.value,
			"per_homepage": data?.per_homepage,
			"per_revenue": data?.per_revenue,
			"per_upside": data?.per_upside,
			"per_atrisk": data?.per_atrisk,
			"per_cforcast": data?.per_cforcast,
			"per_forcast": data?.per_forcast,
			"per_quoting": data?.per_quoting,
			"per_contracts": data?.per_contracts,
			"per_account": data?.per_account,
			"per_pricebook": data?.per_pricebook,
			"per_product": data?.per_product,
			"per_adv": data?.per_adv,
			"per_users": data?.per_users,
			"per_future": data?.per_future,
			"per_approvals": data?.per_approvals,
			// "canapprove": data?.canapprove
		}
		if (isEdit) {
			if (arrUserData && arrUserData.id) {
				params["id"] = arrUserData.id
			}
		}
		// console.log(params)
		showLoader()
		platformApi.manageArrUser(params, (message: string, resp: any) => {
			// console.log(message, resp)
			toast.success(message)
			reset()
			close()
			hideLoader()
			callbackFuncs?.forEach((func: any) => func())
		}, (message: string) => {
			// console.log(message)
			toast.error(message)
			hideLoader()
		})
	}

	const closeAddRegionModal = () => {
		setShowAddRegionModal(false)
	}

	const closeAddDesignationModal = () => {
		setShowDesignationModal(false)
	}

	useEffect(() => {
		if (arrUserId) {
			getArrUserData()
		}
	}, [arrUserId])

	useEffect(() => {
		getRegionList()
		getDesignationData()
	}, [])

	useEffect(() => {
		setValue('isEdit', isEdit)
	}, [isEdit])

	useEffect(() => {
		const tempArr = arrPageData && Object.keys(arrPageData).length > 0 && Object.keys(arrPageData)
			.filter(key => arrPageData[key].status === 1)
			.map((item: any, idx: number) => ({
				labelName: arrPageData[item].name,
				formName: arrPageData[item].key,
			}));
		setPermissionsList(tempArr)
	}, [arrPageData])

	useEffect(() => {
		// console.log(arrUserData)
		if (arrUserData) {
			setValue('name', arrUserData?.name)
			setValue('username', arrUserData?.username)
			setValue('password', arrUserData?.password)
			// setValue('role', arrUserData?.role)
			setValue('email', arrUserData?.email)
			setValue('licencetype', licenceOptions?.find((item: any) => item.value === arrUserData?.licencetype) || '')
			setValue('designationid', designationOptions?.find((item: any) => item.value == arrUserData?.designationid) || '')
			setValue('region', regionOptions?.find((item: any) => item.value === arrUserData?.region) || '')
			setValue('timezone', arrUserData?.timezone)
			setValue('per_homepage', arrUserData?.per_homepage == '1' ? true : false)
			setValue('per_revenue', arrUserData?.per_revenue == '1' ? true : false)
			setValue('per_upside', arrUserData?.per_upside == '1' ? true : false)
			setValue('per_atrisk', arrUserData?.per_atrisk == '1' ? true : false)
			setValue('per_cforcast', arrUserData?.per_cforcast == '1' ? true : false)
			setValue('per_forcast', arrUserData?.per_forcast == '1' ? true : false)
			setValue('per_quoting', arrUserData?.per_quoting == '1' ? true : false)
			setValue('per_contracts', arrUserData?.per_contracts == '1' ? true : false)
			setValue('per_account', arrUserData?.per_account == '1' ? true : false)
			setValue('per_pricebook', arrUserData?.per_pricebook == '1' ? true : false)
			setValue('per_product', arrUserData?.per_product == '1' ? true : false)
			setValue('per_adv', arrUserData?.per_adv == '1' ? true : false)
			setValue('per_users', arrUserData?.per_users == '1' ? true : false)
			setValue('per_future', arrUserData?.per_future == '1' ? true : false)
			setValue('per_approvals', arrUserData?.per_approvals == '1' ? true : false)
		}
	}, [arrUserData, regionOptions, licenceOptions, designationOptions])

	return (
		<React.Fragment>
			<AddRegionModal
				show={showAddRegionModal}
				close={closeAddRegionModal}
				isEdit={false}
				regionListCallbackFunc={getRegionList}
			/>
			<AddDesignationModal
				show={showAddDesignationModal}
				close={closeAddDesignationModal}
				isEdit={false}
				designationListCallbackFunc={getDesignationData}
			/>
			<Modal
				className={'salesforce-modal modal-lndscp ' + (showAddRegionModal || showAddDesignationModal ? 'opec-5' : '')}
				show={show}
			// onHide={() => { close(); reset() }}
			>
				<div className="modal-header custom-modal-header">
					<button
						type="button"
						className="close"
						data-dismiss="modal"
						aria-label="Close"
						onClick={() => { close(); reset() }}
					>
						<span aria-hidden="true">×</span>
					</button>
				</div>
				<Modal.Body>
					{loading ?
						<div>
							Loading......
						</div>
						:
						<div className="row">
							<form onSubmit={handleSubmit(onSubmit)} className="w-100" >
								<div className="col-12">
									<div className="card">
										<div className="card-body">
											<div className="d-flex flex-wrap">
												<h5 className="card-title mr-2">User Information</h5>
											</div>
											<div className="row">
												<div className="form-group col-6">
													<label htmlFor="name">Name <span className='text-danger'>*</span></label>
													<Controller
														name={"name"}
														control={control}
														render={({ field: { value, onChange, onBlur, name, ref } }) => (
															<>
																<input
																	type="text"
																	className={"form-control " + (errors?.name ? ' border-danger' : '')}
																	id="name"
																	name={name}
																	onChange={onChange}
																	value={value}
																	onBlur={onBlur}
																	ref={ref}
																	placeholder="Enter name"
																/>
															</>
														)}
													/>
													{errors && errors.name &&
														<span className='text-danger'>{errors?.name?.message}</span>
													}
												</div>
												{/* <div className="form-group col-6">
													<label htmlFor="role">Role <span className='text-danger'>*</span></label>
													<Controller
														name={"role"}
														control={control}
														render={({ field: { value, onChange, onBlur, name, ref } }) => (
															<>
																<input
																	type="text"
																	className={"form-control " + (errors?.role ? ' border-danger' : '')}
																	id="role"
																	name={name}
																	onChange={onChange}
																	value={value}
																	onBlur={onBlur}
																	ref={ref}
																	placeholder="Enter role"
																/>
															</>
														)}
													/>
													{errors && errors.role &&
														<span className='text-danger'>{errors?.role?.message}</span>
													}
												</div> */}
												<div className="form-group col-6">
													<label htmlFor="username">User Name <span className='text-danger'>*</span></label>
													<Controller
														name={"username"}
														control={control}
														render={({ field: { value, onChange, onBlur, name, ref } }) => (
															<>
																<input
																	type="text"
																	className={"form-control " + (errors?.username ? ' border-danger' : '')}
																	id="username"
																	name={name}
																	onChange={onChange}
																	value={value}
																	onBlur={onBlur}
																	ref={ref}
																	placeholder="Enter user name"
																	autoComplete='off'
																/>
															</>
														)}
													/>
													{errors && errors.username &&
														<span className='text-danger'>{errors?.username?.message}</span>
													}
												</div>
												<div className="form-group col-6">
													<label htmlFor="licencetype">Licence Type <span className='text-danger'>*</span></label>
													<Controller
														name={"licencetype"}
														control={control}
														render={({ field: { value, onChange, onBlur, name, ref } }) => (
															<>
																<Select
																	className={"select-box " + (errors?.licencetype ? ' border-danger' : '')}
																	options={licenceOptions ? licenceOptions : []}
																	placeholder={"Select licence"}
																	onChange={onChange}
																	value={value}
																	defaultValue={value}
																	ref={ref}
																/>
															</>
														)}
													/>
													{errors && errors.licencetype &&
														<span className='text-danger'>Licence is required</span>
													}
												</div>
												<div className="form-group col-6">
													<label htmlFor="email">Email <span className='text-danger'>*</span></label>
													<Controller
														name={"email"}
														control={control}
														render={({ field: { value, onChange, onBlur, name, ref } }) => (
															<>
																<input
																	type="email"
																	className={"form-control " + (errors?.email ? ' border-danger' : '')}
																	id="email"
																	name={name}
																	onChange={onChange}
																	value={value}
																	onBlur={onBlur}
																	ref={ref}
																	placeholder="Enter email"
																/>
															</>
														)}
													/>
													{errors && errors.email &&
														<span className='text-danger'>{errors?.email?.message}</span>
													}
												</div>
												{arrUserId == null ?
													<div className="form-group col-6">
														<label htmlFor="password">Password <span className='text-danger'>*</span></label>
														<Controller
															name={"password"}
															control={control}
															render={({ field: { value, onChange, onBlur, name, ref } }) => (
																<>
																	<input
																		type="password"
																		className={"form-control " + (errors?.password ? ' border-danger' : '')}
																		id="password"
																		name={name}
																		onChange={onChange}
																		value={value}
																		onBlur={onBlur}
																		ref={ref}
																		placeholder="Enter password"
																		autoComplete="new-password"
																	/>
																</>
															)}
														/>
														{errors && errors.password &&
															<span className='text-danger'>{errors?.password?.message}</span>
														}
													</div>
													: null
												}
												<div className="form-group col-6">
													<label htmlFor="timezone">Time Zone <span className='text-danger'>*</span></label>
													<Controller
														name={"timezone"}
														control={control}
														render={({ field: { value, onChange, onBlur, name, ref } }) => (
															<>
																<input
																	type="text"
																	className={"form-control " + (errors?.timezone ? ' border-danger' : '')}
																	id="timezone"
																	name={name}
																	onChange={onChange}
																	value={value}
																	onBlur={onBlur}
																	ref={ref}
																	placeholder="Enter timezone"
																/>
															</>
														)}
													/>
													{errors && errors.timezone &&
														<span className='text-danger'>{errors?.timezone?.message}</span>
													}
												</div>
												<div className="form-group col-6">
													<div className='d-flex justify-content-between'>
														<label htmlFor="region">Region <span className='text-danger'>*</span></label>
													</div>
													<div className='d-flex align-items-center gap-10 add-new-fld-row'>
														<Controller
															name={"region"}
															control={control}
															render={({ field: { value, onChange, onBlur, name, ref } }) => (
																<>
																	<Select
																		className={"select-box" + (errors?.region ? ' border-danger' : '')}
																		id="region"
																		options={regionOptions ? regionOptions : []}
																		placeholder={"Select region"}
																		onChange={onChange}
																		value={value}
																		defaultValue={value}
																		isClearable={true}
																	// isDisabled={isCreateLoading}
																	// isLoading={isCreateLoading}
																	// onCreateOption={handleCreate}
																	/>
																</>
															)}
														/>
														<span
															data-toggle="tooltip"
															data-placement="top"
															title={"Add Region"}
															onClick={() => setShowAddRegionModal(true)}
														>
															<AiOutlinePlusSquare className='cursor-pointer' />
														</span>
													</div>

													{errors && errors.region &&
														<span className='text-danger'>Region is required</span>
													}
												</div>
												<div className="form-group col-6">
													<div className='d-flex justify-content-between'>
														<label htmlFor="region">Designation <span className='text-danger'>*</span></label>
													</div>
													<div className='d-flex align-items-center gap-10 add-new-fld-row'>
														<Controller
															name={"designationid"}
															control={control}
															render={({ field: { value, onChange, onBlur, name, ref } }) => (
																<>
																	<Select
																		className={"select-box" + (errors?.designationid ? ' border-danger' : '')}
																		id="designationid"
																		options={designationOptions ? designationOptions : []}
																		placeholder={"Select designation"}
																		onChange={onChange}
																		value={value}
																		defaultValue={value}
																		isClearable={true}
																	// isDisabled={isCreateLoading}
																	// isLoading={isCreateLoading}
																	// onCreateOption={handleCreate}
																	/>
																</>
															)}
														/>
														<span
															data-toggle="tooltip"
															data-placement="top"
															title={"Add Designation"}
															onClick={() => setShowDesignationModal(true)}
														>
															<AiOutlinePlusSquare className='cursor-pointer' />
														</span>
													</div>

													{errors && errors.designationid &&
														<span className='text-danger'>Designation is required</span>
													}
												</div>
											</div>
										</div>
									</div>
								</div>

								<div className="col-12">
									<div className="card">
										<div className="card-body">
											<div className="d-flex flex-wrap">
												<h5 className="card-title mr-2">Permission</h5>
											</div>
											<div className="row">
												{/* <div className="align-items-center col-6 d-flex form-group gap-10">
													<label htmlFor='canapprove' className='mb-0' >Approver</label>
													<Controller
														name='canapprove'
														control={control}
														render={({ field: { value, onChange, onBlur, name } }) => (
															<>
																<input
																	type="checkbox"
																	// className={(errors?.[item.formName] ? ' border-danger' : '')}
																	id='canapprove'
																	name={name}
																	onChange={onChange}
																	// value={value}
																	onBlur={onBlur}
																	defaultChecked={isEdit ? arrUserData?.canapprove == '1' ? true : false : false}
																	placeholder="Enter per_revenue"
																/>
															</>
														)}
													/>
												</div> */}
												{permissionList && permissionList.length > 0 &&
													permissionList.map((item: any, idx: number) => (
														<div className="align-items-center col-6 d-flex form-group gap-10" key={Math.random()}>
															<Controller
																name={item.formName}
																control={control}
																render={({ field: { value, onChange, onBlur, name } }) => (
																	<>
																		<input
																			type="checkbox"
																			// className={(errors?.[item.formName] ? ' border-danger' : '')}
																			id={item.formName}
																			name={name}
																			onChange={onChange}
																			// value={value}
																			onBlur={onBlur}
																			disabled={item.formName == "per_revenue"}
																			defaultChecked={item.formName != "per_revenue" ? isEdit && arrUserData?.[item.formName] == '1' ? true : false : true}
																			placeholder="Enter per_revenue"
																		/>
																	</>
																)}
															/>
															<label htmlFor={item.formName} className='mb-0' >{item.labelName}</label>
														</div>
													))}
											</div>
										</div>
									</div>
								</div>

								<div className="col-12">
									<div className='align-items-center d-flex gap-10 justify-content-center'>
										<button type='submit' className='btn btn-primary'>Save</button>
										<button
											type='button'
											className='btn btn-outline-danger'
											onClick={() => { close(); reset() }}
										>
											Cancel
										</button>
									</div>
								</div>
							</form>
						</div>
					}
				</Modal.Body>
			</Modal>
		</React.Fragment>
	)
}

export default AddUserModal