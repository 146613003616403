import React, { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import Footer from '../../components/commonComponents/Footer'
import Header from '../../components/commonComponents/Header'
import LeftPanel from '../../components/commonComponents/LeftPanel'
import TableContainer from '../../components/commonComponents/TableContainer'
import { useAppLoader } from '../../_common/hooks/common/appLoaderHook'
import { URLS } from '../../_config'
import { AiOutlineEdit } from 'react-icons/ai'
import DeleteButtonCell from '../../components/actionsBtns/DeleteButtonCell'
import { useCommonApi } from '../../_common/hooks/api/common/commonApiHook'
import AddProductFamilyModal from '../../components/modals/addProductFamily.modal'


const ManageProductFamily = () => {
    const commonApi = useCommonApi();
    const { showLoader, hideLoader } = useAppLoader();

    const [productFamilyData, setProductFamilyData] = useState<any>(null)
    const [data, setData] = useState<any>([])
    const [showAddProductFamilyModal, setShowAddProductFamily] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false)
    const [isEdit, setIsEdit] = useState<boolean>(false)
    const [productFamilyId, setProductFamilyId] = useState<any>(null)

    const getProductFamilyData = (page = 1) => {
        showLoader();
        setLoading(true);
        commonApi.getAllProductFamily({ page: page },
            (message: string, resp: any) => {
                // console.log(resp);
                setLoading(false);
                setProductFamilyData(resp.data.list);
                setData(resp.data.list.data);
                hideLoader();
            }, (message: string) => {
                setLoading(false);
                hideLoader();
            })
    }

    const closeAddProductFamilyModal = () => {
        setShowAddProductFamily(false)
        setIsEdit(false)
        setProductFamilyId(null)
    }

    const EditBtn = ({ column, value }: any) => {
        const handleClick = () => {
            // console.log(value)
            setShowAddProductFamily(true)
            setProductFamilyId(value)
            setIsEdit(true)
        }
        return (
            <AiOutlineEdit
                onClick={handleClick}
                className="cursor-pointer mr-2"
                data-toggle="tooltip" data-placement="top" title="Edit"
            />
        )
    }


    const deleteProductFamily = (id: any) => {
        // console.log(id)
        return new Promise((resolve: any, reject: any) => {
            commonApi.deleteProductFamily({
                id
            }, (message: string, resp: any) => {
                resolve(message)
            }, (message: string) => {
                reject(message);
            });
        })
    }

    const columns = useMemo(
        () => [
            {
                Header: 'Actions',
                accessor: "id",
                Cell: ({ column, value }: any) =>
                    <>
                        <DeleteButtonCell
                            column={column}
                            value={value}
                            deleteFunc={deleteProductFamily}
                            callbackFunc={[getProductFamilyData]}
                            type={"Product Family"}
                        />
                        <EditBtn column={column} value={value} />
                    </>,
            },
            {
                Header: "Product Family Name",
                accessor: "name",
            },
            {
                Header: "Dicount (%)",
                accessor: "discount",
            },
            {
                Header: "Tax",
                accessor: "taxobj.taxname",
            },
            {
                Header: "Status",
                accessor: "status",
                Cell: ({ column, value, row }: any) => (
                    <div>
                        {value && value == '1' ? "Active" : 'Inactive'}
                    </div>
                )
            },
        ],
        []
    )

    useEffect(() => {
        getProductFamilyData()
    }, [])

    return (
        <React.Fragment>
            <AddProductFamilyModal
                show={showAddProductFamilyModal}
                close={closeAddProductFamilyModal}
                id={productFamilyId}
                isEdit={isEdit}
                callbackFunc={getProductFamilyData}
            />
            <div id="layout-wrapper">
                <Header />
                <LeftPanel />
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            {/* start page title */}
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-flex align-items-center justify-content-between">
                                        <h4 className="mb-0 font-size-18">Product Family</h4>
                                        <div className="page-title-right">
                                            <ol className="breadcrumb m-0">
                                                <li className="breadcrumb-item">
                                                    <Link to={URLS.REVENUE}>Revenue</Link>
                                                </li>
                                                <li className="breadcrumb-item active">Product Family</li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-body">
                                    <div className="d-flex flex-wrap justify-content-between">
                                        <h5 className="card-title mr-2">Product Family</h5>
                                        <button
                                            className="btn btn-success"
                                            onClick={() => setShowAddProductFamily(true)}
                                        >
                                            Add Product Family
                                        </button>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="table-responsive manage-user-table">
                                                <TableContainer
                                                    columns={columns}
                                                    data={data}
                                                    fetchData={getProductFamilyData}
                                                    loading={loading}
                                                    rawData={productFamilyData}
                                                    clickFunc={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </React.Fragment >
    )
}

export default ManageProductFamily