import React, { useEffect, useRef, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import Select from 'react-select';
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from 'react-hook-form';
import { usePlatformApi } from '../../_common/hooks/api/PlatformApiHook';
import { useAppLoader } from '../../_common/hooks/common/appLoaderHook';
import { toast } from 'react-hot-toast';
import { handleBlur, handleFocus, handleKeyPressDecimalNumber, handleKeyPressNumber, handleKeyUpDown, handleWheel } from '../../_common/functions';
import moment from 'moment';
import DatePicker from 'react-datepicker';

interface FormValues {
	contractStartDate: string;
	contractTerm: string;
	nextStep: string;
	closePlan: string;
	paymentTerm: string;
	autoRenewal: any;
	customerTerm: any;
	mostLikely: any;
	poc: any;
}

export const yesNoOptions = [
	{ label: "Yes", value: "1" },
	{ label: "No", value: "0" },
]

const validationSchema = Yup.object().shape({
	contractStartDate: Yup.string().required("Contract Start is required"),
	contractTerm: Yup.string().required("Contract Term is required"),
	nextStep: Yup.string().required("Nest Step is required"),
	closePlan: Yup.string().required("Close Plan is required"),
	paymentTerm: Yup.string().required("Payment term is required"),
	autoRenewal: Yup.object().required("Auto renewal is required"),
	customerTerm: Yup.object().required("Customer term is required"),
	mostLikely: Yup.object().required("Most likely is required"),
	poc: Yup.object().required("Contract manager is required"),
});

interface IncomingProps {
	show: boolean;
	close: () => void;
	selectedContracts: any[];
}

const MergeContractModal = ({ show, close, selectedContracts }: IncomingProps) => {

	const platformApi = usePlatformApi();
	const datePickerRef = useRef<any>()

	const { showLoader, hideLoader } = useAppLoader();
	const [contractManagerOptions, setContractManagerOption] = useState<any>(null)

	const { control, formState: { errors }, handleSubmit, setValue, reset, watch } = useForm<FormValues>({
		resolver: yupResolver(validationSchema),
		defaultValues: {
			contractStartDate: "",
			contractTerm: "",
			nextStep: "",
			closePlan: "",
			paymentTerm: "",
			autoRenewal: "",
			customerTerm: "",
			mostLikely: "",
			poc: "",
		},
	});

	const getContractManagerList = () => {
		showLoader();
		platformApi.getAllArrUsers({},
			(message: string, resp: any) => {
				// console.log(resp);
				let tempArr = resp.data.list.data
				if (tempArr && tempArr.length > 0) {
					setContractManagerOption(tempArr.map((item: any) => {
						item.label = item.name;
						item.value = item.id;
						return item
					}));
				}
				hideLoader();
			}, (message: string) => {
				hideLoader();
			})
	}

	const onSubmit = (data: any) => {
		// console.log(data);
		let params: any = {
			"contactid1": selectedContracts[0]?.id,
			"contactid2": selectedContracts[1]?.id,
			"StartDate": data?.contractStartDate ? moment(data.contractStartDate).format("MM/DD/YY") : "",
			"ContractTerm": data?.contractTerm,
			"nextsteps": data?.nextStep,
			"closeplan": data?.closePlan,
			"paymentterm": data?.paymentTerm,
			"autorenewal": data?.autoRenewal?.value,
			"cuatomerterm": data?.customerTerm?.value,
			"mostLikely": data?.mostLikely?.value,
			"poc": data?.poc?.value
		}
		// console.log(params)
		showLoader()
		platformApi.mergeContract(params, (message: string, resp: any) => {
			// console.log(message, resp)
			toast.success(message)
			reset()
			close()
			hideLoader()
		}, (message: string) => {
			// console.log(message)
			toast.error(message)
			hideLoader()
		})
	}

	const handleClose = () => {
		close();
		reset();
	};

	useEffect(() => {
		if (show) {
			getContractManagerList()
		}
	}, [show])

	useEffect(() => {
		if (errors?.contractStartDate) {
			datePickerRef.current.setOpen(true);
		}
	}, [errors])

	return (
		<React.Fragment>
			<Modal
				className='salesforce-modal modal-lndscp'
				show={show}
			// onHide={handleClose}
			>
				<div className="modal-header custom-modal-header">
					<button
						type="button"
						className="close"
						data-dismiss="modal"
						aria-label="Close"
						onClick={handleClose}
					>
						<span aria-hidden="true">×</span>
					</button>
				</div>
				<div className="row">
					<form onSubmit={handleSubmit(onSubmit)} className="w-100" >
						<div className="col-12">
							<div className="card">
								<div className="card-body">
									<div className="d-flex flex-wrap">
										<h5 className="card-title mr-2">Contract Information</h5>
									</div>
									<div className="row">
										<div className="form-group col-6">
											<label htmlFor="contractStartDate">Contract Start Date <span className='text-danger'>*</span></label>
											<Controller
												name={"contractStartDate"}
												control={control}
												render={({ field: { value, onChange, onBlur, name, ref } }) => (
													<>
														{/* <input
																type="date"
																className={"form-control " + (errors?.contractStartDate ? ' border-danger' : '')}
																id="contractStartDate"
																name={name}
																value={value}
																onChange={onChange}
																onBlur={onBlur}
																ref={ref}
																min={new Date().toISOString().split('T')[0]}
																placeholder="Enter contract start date"
															/> */}
														<DatePicker
															selected={value ? new Date(value) : null}
															onChange={onChange}
															dateFormat="MM/dd/yy"
															className={"form-control " + (errors?.contractStartDate ? ' border-danger' : '')}
															id="contractStartDate"
															name={name}
															onBlur={onBlur}
															ref={datePickerRef}
															value={value}
															minDate={new Date()}
															placeholderText="Enter contract start date"
														/>
													</>
												)}
											/>
											{errors && errors.contractStartDate &&
												<span className='text-danger'>Contract Start Date is required</span>
											}
										</div>
										<div className="form-group col-6">
											<label htmlFor="contractTerm">Contract Term (month) <span className='text-danger'>*</span></label>
											<Controller
												name={"contractTerm"}
												control={control}
												render={({ field: { value, onChange, onBlur, name, ref } }) => (
													<>
														<input
															type="number"
															className={"form-control " + (errors?.contractTerm ? ' border-danger' : '')}
															id="contractTerm"
															name={name}
															value={value}
															onChange={onChange}
															onBlur={(e) => { onBlur(); handleBlur(e) }}
															onFocus={(e) => handleFocus(e)}
															onKeyDown={handleKeyUpDown}
															onWheel={handleWheel}
															step={"1"}
															min={0}
															ref={ref}
															placeholder="Enter contract term"
															onKeyPress={(event: any) => handleKeyPressNumber(event)}
														/>
													</>
												)}
											/>
											{errors && errors.contractTerm &&
												<span className='text-danger'>{errors?.contractTerm?.message}</span>
											}
										</div>
										<div className="form-group col-6">
											<label htmlFor="nextStep">Next Step <span className='text-danger'>*</span></label>
											<Controller
												name={"nextStep"}
												control={control}
												render={({ field: { value, onChange, onBlur, name, ref } }) => (
													<>
														<input
															type="text"
															className={"form-control " + (errors?.nextStep ? ' border-danger' : '')}
															id="nextStep"
															name={name}
															onChange={onChange}
															value={value}
															onBlur={onBlur}
															ref={ref}
															placeholder="Enter next step"
														/>
													</>
												)}
											/>
											{errors && errors.nextStep &&
												<span className='text-danger'>{errors?.nextStep?.message}</span>
											}
										</div>
										<div className="form-group col-6">
											<label htmlFor="nextStep">Close Plan <span className='text-danger'>*</span></label>
											<Controller
												name={"closePlan"}
												control={control}
												render={({ field: { value, onChange, onBlur, name, ref } }) => (
													<>
														<input
															type="text"
															className={"form-control " + (errors?.closePlan ? ' border-danger' : '')}
															id="closePlan"
															name={name}
															onChange={onChange}
															value={value}
															onBlur={onBlur}
															ref={ref}
															placeholder="Enter close plan"
														/>
													</>
												)}
											/>
											{errors && errors.closePlan &&
												<span className='text-danger'>{errors?.closePlan?.message}</span>
											}
										</div>
										<div className="form-group col-6">
											<label htmlFor="paymentTerm">Payment Term (month) <span className='text-danger'>*</span></label>
											<Controller
												name={"paymentTerm"}
												control={control}
												render={({ field: { value, onChange, onBlur, name, ref } }) => (
													<>
														<input
															type="number"
															className={"form-control " + (errors?.paymentTerm ? ' border-danger' : '')}
															id="paymentTerm"
															name={name}
															value={value}
															onChange={onChange}
															onBlur={(e) => { onBlur(); handleBlur(e) }}
															onFocus={(e) => handleFocus(e)}
															onKeyDown={handleKeyUpDown}
															onWheel={handleWheel}
															step={"1"}
															min={0}
															ref={ref}
															placeholder="Enter payment term"
															onKeyPress={(event: any) => handleKeyPressNumber(event)}
														/>
													</>
												)}
											/>
											{errors && errors.paymentTerm &&
												<span className='text-danger'>{errors?.paymentTerm?.message}</span>
											}
										</div><div className="form-group col-6">
											<label htmlFor="autoRenewal">Auto renewal <span className='text-danger'>*</span></label>
											<Controller
												name={"autoRenewal"}
												control={control}
												render={({ field: { value, onChange, onBlur, name, ref } }) => (
													<>
														<Select
															className={"select-box " + (errors?.autoRenewal ? ' border-danger' : '')}
															options={yesNoOptions ? yesNoOptions : []}
															placeholder={"Select auto renewal"}
															onChange={onChange}
															value={value}
															defaultValue={value}
															ref={ref}
														/>
													</>
												)}
											/>
											{errors && errors.autoRenewal &&
												<span className='text-danger'>Auto renewal is required</span>
											}
										</div>
										<div className="form-group col-6">
											<label htmlFor="customerTerm">Customer term <span className='text-danger'>*</span></label>
											<Controller
												name={"customerTerm"}
												control={control}
												render={({ field: { value, onChange, onBlur, name, ref } }) => (
													<>
														<Select
															className={"select-box " + (errors?.customerTerm ? ' border-danger' : '')}
															options={yesNoOptions ? yesNoOptions : []}
															placeholder={"Select customer term"}
															onChange={onChange}
															value={value}
															defaultValue={value}
															ref={ref}
														/>
													</>
												)}
											/>
											{errors && errors.customerTerm &&
												<span className='text-danger'>Customer term is required</span>
											}
										</div>
										<div className="form-group col-6">
											<label htmlFor="mostLikely">Most likely <span className='text-danger'>*</span></label>
											<Controller
												name={"mostLikely"}
												control={control}
												render={({ field: { value, onChange, onBlur, name, ref } }) => (
													<>
														<Select
															className={"select-box " + (errors?.mostLikely ? ' border-danger' : '')}
															options={yesNoOptions ? yesNoOptions : []}
															placeholder={"Select most likely"}
															onChange={onChange}
															value={value}
															defaultValue={value}
															ref={ref}
														/>
													</>
												)}
											/>
											{errors && errors.mostLikely &&
												<span className='text-danger'>Most likely is required</span>
											}
										</div>
										<div className="form-group col-6">
											<label htmlFor="poc">Contract manager
												<span className='text-danger'>*</span>
											</label>
											<Controller
												name={"poc"}
												control={control}
												render={({ field: { value, onChange, onBlur, name, ref } }) => (
													<>
														<Select
															className={"select-box" + (errors?.poc ? ' border-danger' : '')}
															id="poc"
															options={contractManagerOptions ? contractManagerOptions : []}
															placeholder={"Select contract manager"}
															onChange={onChange}
															value={value}
															defaultValue={value}
														/>
													</>
												)}
											/>
											{errors && errors.poc &&
												<span className='text-danger'>Contract manager is required</span>
											}
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="col-12">
							<div className='align-items-center d-flex gap-10 justify-content-center'>
								<button type='submit' className='btn btn-primary'>Save</button>
								<button
									type='button'
									className='btn btn-outline-danger'
									onClick={handleClose}
								>
									Cancel
								</button>
							</div>
						</div>
					</form>
				</div>
			</Modal>
		</React.Fragment>
	)
}

export default MergeContractModal