import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { toast } from 'react-hot-toast'
import { Link } from 'react-router-dom'
import Select from 'react-select'
import Header from '../components/commonComponents/Header'
import LeftPanel from '../components/commonComponents/LeftPanel'
import { usePlatformApi } from '../_common/hooks/api/PlatformApiHook'
import { useAppLoader } from '../_common/hooks/common/appLoaderHook'
import { URLS } from '../_config'

const SalesforceMaping = () => {

    const platformApi = usePlatformApi();

    const { control, formState: { errors }, handleSubmit, reset, setValue } = useForm()

    const { showLoader, hideLoader } = useAppLoader();
    const [platformObjectList, setPlatformObjectList] = useState<any>(null)
    const [arrFieldList, setArrFieldList] = useState<any>([])
    const [fieldMapData, setFieldMapData] = useState<any>(null)
    const [crmOptions, setCrmOptions] = useState<any>([])
    const [reqFields, setReqFields] = useState<any>([])
    const [moduleSelect, setModuleSelect] = useState<any>('')

    const getPlatformObject = () => {
        showLoader();
        platformApi.getPlatformObject({},
            (message: string, resp: any) => {
                // console.log({ resp })
                setPlatformObjectList(resp.data)
                hideLoader()
            }, (message: string) => {
                hideLoader()
            })
    }

    const getPlatformObjectFields = (data: any) => {
        showLoader();
        platformApi.getPlatformObjectField({ module: data },
            (message: string, resp: any) => {
                // console.log({ resp })
                setArrFieldList(resp.data.dataARobjectField)
                setFieldMapData(resp.data.userplatformobjectfieldmapdata)
                setReqFields(resp.data.reqField)
                if (Object.keys(resp.data.sfObjectField).length > 0) {
                    setCrmOptions(Object.entries(resp.data.sfObjectField).map(([key, value]) => ({
                        label: value,
                        value: key
                    })))
                }
                hideLoader()
            }, (message: string) => {
                hideLoader()
            })
    }

    // console.log({ crmOptions, errors })
    const onSubmit = (data: any) => {
        // console.log({ data })
        let params: any = {}
        arrFieldList.forEach((item: any, index: number) => {
            params[item] = data?.[item]?.value
        })
        // console.log(params)
        showLoader();
        platformApi.savePlatformObjectField({
            "module": moduleSelect,
            "platform_id": 1,
            "field_map": params
        },
            (message: string, resp: any) => {
                // console.log({ resp })
                toast.success(message)
                reset()
                hideLoader()
            }, (message: string) => {
                toast.error(message)
                hideLoader()
            })

    }

    useEffect(() => {
        getPlatformObject()
    }, [])

    useEffect(() => {
        if (moduleSelect && moduleSelect != '') {
            getPlatformObjectFields(moduleSelect)
        }
    }, [moduleSelect])

    useEffect(() => {
        // console.log(arrFieldList.length > 0 && crmOptions.length > 0 )
        if (arrFieldList.length > 0 && crmOptions.length > 0 && (fieldMapData != null)) {
            // console.log("in if")
            arrFieldList.forEach((item: any, index: number) => {
                let option = null
                crmOptions?.map((_option: any) => {
                    if (_option.value == fieldMapData[item]) {
                        option = _option
                    }
                })
                setValue(item, option);
                // console.log(item, option);
            })
        }

    }, [crmOptions, arrFieldList, fieldMapData])


    return (
        <React.Fragment>
            <div id="layout-wrapper">
                <Header />
                <LeftPanel />
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-flex align-items-center justify-content-between title-mob-fix">
                                        <h4 className="mb-0 font-size-18">Manage Salesforce Maping </h4>
                                        <div className="page-title-right">
                                            <ol className="breadcrumb m-0">
                                                <li className="breadcrumb-item">
                                                    <Link to={URLS.CONNECTS_EXTERNAL_APPLICATION}>Connects External Application</Link>
                                                </li>
                                                <li className="breadcrumb-item active">
                                                    Manage Salesforce Maping
                                                </li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="fl-mod-select">
                                                <div className="row">
                                                    <div className="col-lg-6 col-md-6 col-6">
                                                        <div className="fl-logo-top">
                                                            <div className="arr-log mb-2">
                                                                <img src="assets/images/logo-light.png" />
                                                            </div>
                                                            <strong>ARR Modules</strong>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-6">
                                                        <div className="fl-logo-top">
                                                            <div className="fl-sale-force mb-2">
                                                                <img src="assets/images/sales-force.png" />
                                                            </div>
                                                            <strong>CRM Modules</strong>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mod-login mt-3">
                                                <div className="mod-arr-top-part">
                                                    {/* <div className="custom-control custom-switch " dir="ltr">
                                                        <input
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            id="customSwitch1"
                                                        />
                                                        <label
                                                            className="custom-control-label"
                                                            htmlFor="customSwitch1"
                                                        />
                                                    </div> */}
                                                    {platformObjectList && platformObjectList.length > 0 ?
                                                        platformObjectList.map((item: any, index: number) => (
                                                            <React.Fragment key={index}>
                                                                <button
                                                                    type="button"
                                                                    className="btn"
                                                                    onClick={() => setModuleSelect(item?.Name)}
                                                                >
                                                                    {item?.Name}
                                                                </button>
                                                                <span className={index == platformObjectList.length - 1 ? "d-none" : "d-block"} />
                                                            </React.Fragment>
                                                        ))
                                                        :
                                                        null
                                                    }
                                                    {/* <button type="button" className="btn manag-bt">
                                                        <i className="bx bx-plus" /> Manage Mapping
                                                    </button> */}
                                                </div>
                                                {arrFieldList && arrFieldList.length > 0 && reqFields.length > 0 ?
                                                    <div className="mod-field-sec mod-login">
                                                        <form onSubmit={handleSubmit(onSubmit)}>
                                                            <div className="row">
                                                                <div className="col-lg-6 col-sm-6 col-6">
                                                                    <strong>ARR Field</strong>
                                                                </div>
                                                                <div className="col-lg-6 col-sm-6 col-6">
                                                                    <strong>CRM Field</strong>
                                                                </div>
                                                                {arrFieldList && arrFieldList.length > 0 ?
                                                                    arrFieldList.map((item: any, index: number) => (
                                                                        <React.Fragment
                                                                            key={index}
                                                                        >
                                                                            <div
                                                                                className="col-lg-6 col-sm-6 col-6 mb-2"
                                                                            >
                                                                                <span>{item}</span>
                                                                            </div>
                                                                            <div
                                                                                className="col-lg-6 col-sm-6 col-6 mb-2"
                                                                            >
                                                                                <Controller
                                                                                    name={item}
                                                                                    control={control}
                                                                                    rules={reqFields.includes(item) ? { required: 'required' } : {}}
                                                                                    render={({ field: { value, onChange, onBlur, name } }) => (
                                                                                        <Select
                                                                                            className={"select-box " + (errors?.[item] ? ' border-danger' : '')}
                                                                                            options={crmOptions ? crmOptions : []}
                                                                                            placeholder={"Select your field"}
                                                                                            onChange={value => { onChange(value) }}
                                                                                            // defaultValue={
                                                                                            //     fieldMapData != null ?
                                                                                            //         crmOptions.filter((option: any) =>
                                                                                            //             option.value === fieldMapData[item])
                                                                                            //         :
                                                                                            //         value
                                                                                            // }
                                                                                            value={value}
                                                                                        />
                                                                                    )}
                                                                                />
                                                                                {errors && errors?.[item] &&
                                                                                    <span className='text-danger'>{"Please select"}</span>
                                                                                }
                                                                            </div>
                                                                        </React.Fragment>
                                                                    ))
                                                                    :
                                                                    null
                                                                }
                                                                <div className="col-12 mt-3">
                                                                    <button type="submit" className="btn-primary w-100 btn">
                                                                        Submit
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                    :
                                                    null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default SalesforceMaping