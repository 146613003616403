import React, { useEffect, useMemo, useState } from 'react';
import { usePlatformApi } from '../../_common/hooks/api/PlatformApiHook';
import { useAppLoader } from '../../_common/hooks/common/appLoaderHook';
import ViewButtonCell from '../actionsBtns/ViewButtonCell';
import TableContainer from '../commonComponents/TableContainer';
import { AiOutlineEdit } from 'react-icons/ai';
import moment from 'moment';
import { formatNumber } from '../../_common/functions';

interface IncomingProps {
    AccountId?: any;
    selectedContractRow: any;
    setSelectedContractRow: Function;
    selectedContract: string;
    setSelectedContract: Function;
    setShowContractModal: Function;
    setContractId: Function;
    setContractIsEdit: Function;
    showContractModal: boolean;
    showMergeContractModal: boolean;
    contractStatus?: string;
}

const ContractDataList = ({ AccountId, selectedContractRow, setSelectedContractRow, selectedContract, setSelectedContract, setShowContractModal, setContractId, setContractIsEdit, showContractModal, showMergeContractModal, contractStatus = '' }: IncomingProps) => {

    const platformApi = usePlatformApi();
    const { showLoader, hideLoader } = useAppLoader();

    const [contractData, setContractData] = useState<any>(null)
    const [data, setData] = useState<any>([])
    const [loading, setLoading] = useState<boolean>(false)

    const handleRowClick = (rowId: number, value: string, check: boolean) => {
        // console.log(rowId, value, check)
        // setSelectedContractRow(rowId === selectedContractRow ? null : rowId);
        // if (check) {
        //     setSelectedContract(value);
        // } else {
        //     setSelectedContract('')
        // }
        if (check) {
            setSelectedContractRow((prevSelectedContractRows: any) => [...prevSelectedContractRows, rowId]);
            setSelectedContract((prevSelectedContractId: any) => [...prevSelectedContractId, value]);
        } else {
            setSelectedContractRow((prevSelectedContractRows: any[]) => prevSelectedContractRows.filter(id => id !== rowId));
            setSelectedContract((prevSelectedContractId: any[]) => prevSelectedContractId.filter(cntctId => cntctId !== value));
        }
    };
    // useEffect(() => {console.log(selectedQuoteId)},[selectedQuoteId])

    const getContractData = (page = 1) => {
        showLoader();
        setLoading(true);
        let params: any = {
            page: page,
            AccountId: AccountId
        };
        if (contractStatus && contractStatus != "") {
            params["Status"] = contractStatus
        }
        platformApi.getAllContract(params,
            (message: string, resp: any) => {
                // console.log(resp);
                setLoading(false);
                setContractData(resp.data.list);
                setData(resp.data.list.data);
                hideLoader();
            }, (message: string) => {
                setLoading(false);
                hideLoader();
            })
    }

    const EditBtn = ({ column, value }: any) => {
        const handleClick = () => {
            setShowContractModal(true)
            setContractId(value)
            setContractIsEdit(true)
        }
        return (
            <AiOutlineEdit
                onClick={handleClick}
                className="cursor-pointer mr-2"
                data-toggle="tooltip" data-placement="top" title="Edit"
            />
        )
    }

    const columns = useMemo(
        () => [
            {
                Header: 'Actions',
                accessor: "ContractId",
                Cell: ({ column, value, row }: any) => (
                    <>
                        <ViewButtonCell column={column} value={value} link={'contract-details'} />
                        {
                            row.original.Status && !["Activated", "Expired"].includes(row.original.Status) ?
                                <EditBtn column={column} value={value} />
                                :
                                null
                        }
                        <input
                            className='mr-2'
                            type="checkbox"
                            checked={selectedContractRow.includes(row.id)}
                            onChange={(e) => { handleRowClick(row.id, row.original, e.target.checked) }}
                        // checked={selectedContractRow === row.id}
                        // onChange={(e: any) => { handleRowClick(row.id, row.original.id, e.target.checked) }}
                        />
                    </>
                )
            },
            {
                Header: "Contract Number",
                accessor: "ContractNumber",
                className: "nowrap",
            },
            {
                Header: "Contract Term (months)",
                accessor: "ContractTerm",
            },
            {
                Header: "Start Date",
                accessor: "StartDate",
                Cell: ({ column, value, row }: any) => (
                    <>
                        {value ? moment(value).format("MM/DD/YY") : ""}
                    </>
                )
            },
            {
                Header: "End Date",
                accessor: "EndDate",
                Cell: ({ column, value, row }: any) => (
                    <>
                        {value ? moment(value).format("MM/DD/YY") : ""}
                    </>
                )
            },
            {
                Header: "Contracted Amount ($)",
                accessor: "ContractValue",
                Cell: ({ column, value, row }: any) =>
                    <>
                        {value ? formatNumber(value) : "0.00"}
                    </>,
            },
            {
                Header: "ARR ($)",
                accessor: "arr",
                className: "nowrap",
                Cell: ({ column, value, row }: any) =>
                    <>
                        {value ? formatNumber(value) : "0.00"}
                    </>,
            },
            {
                Header: "MRR ($)",
                accessor: "mrr",
                className: "nowrap",
                Cell: ({ column, value, row }: any) =>
                    <>
                        {value ? formatNumber(value) : "0.00"}
                    </>,
            },
            {
                Header: "Renewal Amount ($)",
                accessor: "renewAmt",
                Cell: ({ column, value, row }: any) =>
                    <>
                        {value ? formatNumber(value) : "0.00"}
                    </>,
            },
            {
                Header: "Uplift ($)",
                accessor: "Upliftval",
                Cell: ({ column, value, row }: any) =>
                    <>
                        {row.original.isRenewed == '1' && value ? formatNumber(value) : "0.00"}
                    </>,
            },

            {
                Header: "Cross Sell ($)",
                accessor: "Crosssellval",
                Cell: ({ column, value, row }: any) =>
                    <>
                        {row.original.isRenewed == '1' && value ? formatNumber(value) : "0.00"}
                    </>,
            },
            {
                Header: "UpSell ($)",
                accessor: "Upsellval",
                Cell: ({ column, value, row }: any) =>
                    <>
                        {row.original.isRenewed == '1' && value ? formatNumber(value) : "0.00"}
                    </>,
            },
            {
                Header: "Contraction ($)",
                accessor: "Contractionval",
                Cell: ({ column, value, row }: any) =>
                    <>
                        {row.original.isRenewed == '1' && value ? formatNumber(value) : "0.00"}
                    </>,
            },
            {
                Header: "Renewal Type",
                accessor: "autorenewal",
                Cell: ({ column, value, row }: any) =>
                    <>
                        {value && value == 1 ? "Auto" : "0.00"}
                    </>,
            },
            {
                Header: "Status",
                accessor: "newstatus",
            },
            {
                Header: "Sub motion",
                accessor: "submotion",
                Cell: ({ column, value, row }: any) =>
                    <>
                        {row.original.isRenewed == '1' && value ? value : ""}
                    </>,
            },
        ],
        [selectedContractRow]
    )

    useEffect(() => {
        if (AccountId && !showContractModal && !showMergeContractModal) {
            getContractData();
        }
    }, [AccountId, showContractModal, showMergeContractModal])

    useEffect(() => {
        // if (contractStatus && contractStatus != "") {
        getContractData();
        // }
    }, [contractStatus])

    return (
        <React.Fragment>
            {/* <div className="card-body">
                <div className="d-flex flex-wrap">
                    <h5 className="card-title mr-2">Contract</h5>
                </div> */}
            <div className="table-responsive">
                <TableContainer
                    columns={columns}
                    data={data}
                    fetchData={getContractData}
                    loading={loading}
                    rawData={contractData}
                    clickFunc={true}
                />
            </div>
            {/* </div> */}
        </React.Fragment>
    )
}

export default ContractDataList