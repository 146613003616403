import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import Select from 'react-select';
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from 'react-hook-form';
import { usePlatformApi } from '../../_common/hooks/api/PlatformApiHook';
import { useAppLoader } from '../../_common/hooks/common/appLoaderHook';
import { toast } from 'react-hot-toast';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { handleBlur, handleFocus, handleKeyPressDecimalNumber, handleKeyUpDown, handleWheel } from '../../_common/functions';

interface FormValues {
	productName: string;
	listPrice: string;
	// active: boolean;
}

const validationSchema = Yup.object().shape({
	productName: Yup.object().required("Product Name is required"),
	listPrice: Yup.string().required("List Price is required")
});

interface IncomingProps {
	show: boolean;
	close: () => void;
	Pricebook2Id?: any
	PricebookEntryId?: any
	isEdit: boolean;
	callbackFunction: any;
}

const AddPricbookEntriesModal = ({ show, close, Pricebook2Id, PricebookEntryId, isEdit = false, callbackFunction }: IncomingProps) => {

	const platformApi = usePlatformApi();

	const { showLoader, hideLoader } = useAppLoader();
	const [productOptions, setProductOptions] = useState<any>([])
	const [priceBookEntriesData, setPriceBookEntriesData] = useState<any>(null)
	const [loading, setLoading] = useState<boolean>(false)

	const { control, formState: { errors }, handleSubmit, setValue, reset } = useForm<FormValues>({
		resolver: yupResolver(validationSchema),
		defaultValues: {
			productName: "",
			listPrice: "",
			// active: false
		},
	});

	const getProductData = () => {
		showLoader();
		platformApi.getAllProduct({},
			(message: string, resp: any) => {
				// console.log(resp);
				const datas = resp?.data?.list?.data
				if (datas && datas.length > 0) {
					setProductOptions(datas.map((item: any) => {
						item.label = item.Name;
						item.value = item.ProductId;
						return item;
					}))
				}
				hideLoader();
			}, (message: string) => {
				hideLoader();
			})
	}

	const getPriceBookEntries = () => {
		showLoader();
		setLoading(true)
		platformApi.getAllPriceBookEntities({
			PricebookEntryId: PricebookEntryId
		},
			(message: string, resp: any) => {
				// console.log(resp);
				setPriceBookEntriesData(resp.data.list.data[0]);
				setLoading(false)
				hideLoader();
			}, (message: string) => {
				setLoading(false)
				hideLoader();
			})
	}

	const onSubmit = (data: any) => {
		// console.log(data);
		let params: any = {
			// "IsActive": data?.active,
			"Pricebook2Id": Pricebook2Id,
			"Product2Id": data?.productName?.value,
			"UnitPrice": data?.listPrice,
		}
		if (isEdit) {
			if (priceBookEntriesData && priceBookEntriesData?.id) {
				params["id"] = priceBookEntriesData?.id
			}
		}
		// console.log(params)
		showLoader()
		platformApi.managePriceBookEntities(params, (message: string, resp: any) => {
			// console.log(message, resp)
			toast.success(message)
			reset()
			close()
			callbackFunction()
			hideLoader()
		}, (message: string) => {
			// console.log(message)
			toast.error(message)
			hideLoader()
		})
	}

	const handleClose = () => {
		setPriceBookEntriesData(null)
		close()
		reset()
	}

	useEffect(() => {
		getProductData()
	}, [])

	useEffect(() => {
		if (PricebookEntryId) {
			getPriceBookEntries()
		}
	}, [PricebookEntryId])

	useEffect(() => {
		if (priceBookEntriesData) {
			setValue('productName', productOptions?.find((item: any) => item.value == priceBookEntriesData?.Product2Id))
			setValue('listPrice', priceBookEntriesData?.UnitPrice)
			// setValue('active', priceBookEntriesData?.IsActive == '1' ? true : false)
		}
	}, [priceBookEntriesData])

	return (
		<React.Fragment>
			<Modal
				className='salesforce-modal modal-lndscp'
				show={show}
			// onHide={handleClose}
			>
				<div className="modal-header custom-modal-header">
					<button
						type="button"
						className="close"
						data-dismiss="modal"
						aria-label="Close"
						onClick={handleClose}
					>
						<span aria-hidden="true">×</span>
					</button>
				</div>
				<Modal.Body>
					{loading ?
						<div>
							Loading......
						</div>
						:
						<div className="row">
							<form onSubmit={handleSubmit(onSubmit)} className="w-100" >
								<div className="col-12">
									<div className="card">
										<div className="card-body">
											<div className="d-flex flex-wrap">
												<h5 className="card-title mr-2">Price Book Information</h5>
											</div>
											<div className="row">
												<div className="form-group col-6">
													<label htmlFor="productName">Product name <span className='text-danger'>*</span></label>
													<Controller
														name={"productName"}
														control={control}
														render={({ field: { value, onChange, onBlur, name, ref } }) => (
															<>
																<Select
																	className={"select-box " + (errors?.productName ? ' border-danger' : '')}
																	options={productOptions ? productOptions : []}
																	placeholder={"Select product"}
																	onChange={onChange}
																	value={value}
																	defaultValue={value}
																	ref={ref}
																/>
															</>
														)}
													/>
													{errors && errors.productName &&
														<span className='text-danger'>Product Name is required</span>
													}
												</div>
												<div className="form-group col-6">
													<label htmlFor="listPrice">List Price ($) <span className='text-danger'>*</span></label>
													<Controller
														name={"listPrice"}
														control={control}
														render={({ field: { value, onChange, onBlur, name, ref } }) => (
															<>
																<input
																	type="number"
																	className={"form-control " + (errors?.listPrice ? ' border-danger' : '')}
																	id="listPrice"
																	name={name}
																	value={value}
																	onChange={onChange}
																	onBlur={(e) => { onBlur(); handleBlur(e) }}
																	onFocus={(e) => handleFocus(e)}
																	onKeyDown={handleKeyUpDown}
																	onWheel={handleWheel}
																	step={"0.01"}
																	min={0}
																	ref={ref}
																	placeholder="Enter list price"
																	onKeyPress={(event: any) => handleKeyPressDecimalNumber(event)}
																/>
															</>
														)}
													/>
													{errors && errors.listPrice &&
														<span className='text-danger'>{errors?.listPrice?.message}</span>
													}
												</div>
												{/* <div className="align-items-center col-6 d-flex form-group gap-10">
														<label htmlFor="active" className='mb-0'>Active</label>
														<Controller
															name={"active"}
															control={control}
															render={({ field: { value, onChange, onBlur, name } }) => (
																<>
																	<input
																		type="checkbox"
																		className={(errors?.active ? ' border-danger' : '')}
																		id="active"
																		name={name}
																		onChange={onChange}
																		// value={value}
																		onBlur={onBlur}
																		defaultChecked={priceBookEntriesData?.IsActive == '1' ? true : false}
																		placeholder="Enter active"
																	/>
																</>
															)}
														/>
														{errors && errors.active &&
															<span className='text-danger'>{errors?.active?.message}</span>
														}
													</div> */}
											</div>
										</div>
									</div>
								</div>

								<div className="col-12">
									<div className='align-items-center d-flex gap-10 justify-content-center'>
										<button type='submit' className='btn btn-primary'>Save</button>
										<button
											type='button'
											className='btn btn-outline-danger'
											onClick={handleClose}
										>
											Cancel
										</button>
									</div>
								</div>
							</form>
						</div>
					}
				</Modal.Body>
			</Modal>
		</React.Fragment>
	)
}

export default AddPricbookEntriesModal