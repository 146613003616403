import React, { useEffect, useState } from 'react'
import { usePlatformApi } from '../../_common/hooks/api/PlatformApiHook';
import { useAppLoader } from '../../_common/hooks/common/appLoaderHook';
import { useNavigate } from 'react-router-dom';

interface IncomingProps {
  filterDetails: any;
  doFilter: boolean;
  setDoFilter: any;
  toUpside?: boolean;
}

const CommonBoxDetails = ({ filterDetails, doFilter, setDoFilter, toUpside = false }: IncomingProps) => {

  const platformApi = usePlatformApi();
  const navigate = useNavigate();

  const { showLoader, hideLoader } = useAppLoader()
  const [revenueDetails, setRevenueDetails] = useState<any>(null)

  const getRevenueDetails = () => {
    showLoader();
    platformApi.getRevenueDetails(filterDetails,
      (message: string, resp: any) => {
        if (resp?.data?.list) {
          setRevenueDetails(resp?.data?.list)
        }
        hideLoader();
        setDoFilter(false)
      }, (message: string) => {
        hideLoader();
        setDoFilter(false)
      })
  }

  const gotToUpsidePage = (quarter: any, type: any) => {
    if (filterDetails && Object.keys(filterDetails).length > 0) {
      navigate(`/upside?quarter=${quarter}&type=${type}&year=${filterDetails?.year}&rep=${filterDetails?.rep}&region=${filterDetails?.region}&Status=${filterDetails?.Status}`)
    } else {
      navigate(`/upside?quarter=${quarter}&type=${type}`)
    }
  }

  const gotToContractPage = (quarter: any, type: any) => {
    if (filterDetails && Object.keys(filterDetails).length > 0) {
      navigate(`/contract-page?quarter=${quarter}&type=${type}&year=${filterDetails?.year}&rep=${filterDetails?.rep}&region=${filterDetails?.region}`)
    } else {
      navigate(`/contract-page?quarter=${quarter}&type=${type}`)
    }
  }

  useEffect(() => {
    getRevenueDetails()
  }, [])

  useEffect(() => {
    if (doFilter) {
      getRevenueDetails()
    }
  }, [doFilter])

  return (
    <div className="row dashboard-top-panel">
      <div className="col-lg-3 col-md-6 col-12">
        <div className="card mini-stats-wid card-border-bottom">
          <div className="card-body" onClick={() => gotToContractPage('FY', 'arr_forcasted')} style={{ cursor: "pointer" }}>
            <div className="d-flex flex-wrap">
              <div className="mr-3">
                <p className="text-muted mb-2">ARR / Forecasted</p>
                <h5 className="mb-2">${revenueDetails?.currentfydata?.forcasted?.arr}</h5>
                <p className="text-muted mb-2">ARR No:
                  {" "}<strong className='text-dark'>{revenueDetails?.currentfydata?.forcasted?.arrno}</strong>
                </p>
              </div>
              <div className="avatar-sm ml-auto">
                <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                  <i className="mdi mdi-file-document-outline" />
                </div>
              </div>
            </div>
            <ul className="dash-para-fix">
              <li className="mb-1">MRR: <strong>${revenueDetails?.currentfydata?.forcasted?.mrr}</strong>{" "}</li>
              <li className="mb-1">NRR: <strong>{revenueDetails?.currentfydata?.forcasted?.nrr}%</strong>{" "}</li>
              <li className="mb-1">GRR: <strong>{revenueDetails?.currentfydata?.forcasted?.orr}%</strong>{" "}</li>
              <li>Best: <strong>${revenueDetails?.currentfydata?.forcasted?.best}</strong></li>
              <li className="mb-1">Most Likely:{" "}
                <strong>${revenueDetails?.currentfydata?.forcasted?.mostlikely}</strong>
              </li>
              <li className="mb-1">Base ARR:{" "}
                <strong>${revenueDetails?.currentfydata?.forcasted?.baseARR}</strong>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-md-6 col-12">
        <div className="card blog-stats-wid card-border-bottom">
          <div className="card-body" onClick={() => { toUpside ? gotToUpsidePage('FY', 'expansion_forcasted') : gotToContractPage('FY', 'expansion_forcasted') }} style={{ cursor: "pointer" }}>
            <div className="d-flex flex-wrap">
              <div className="mr-3">
                <p className="text-muted mb-2">Expansion / Forecast</p>
                <h5 className="mb-0">${revenueDetails?.currentfydata?.forcasted?.expansion} / ${revenueDetails?.currentfydata?.forcasted?.expansion_forecasted}</h5>
                <p className="text-muted mt-2">Expansion No:
                  {" "}<strong className='text-dark'>{revenueDetails?.currentfydata?.forcasted?.expansionno}</strong>
                </p>
              </div>
              <div className="avatar-sm ml-auto">
                <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                  <i className="mdi mdi-chart-bell-curve-cumulative" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-md-6 col-12">
        <div className="card blog-stats-wid card-border-bottom">
          <div className="card-body" onClick={() => gotToContractPage('FY', 'renewed')} style={{ cursor: "pointer" }}>
            <div className="d-flex flex-wrap">
              <div className="mr-3">
                <p className="text-muted mb-2">Renewed</p>
                <h5 className="mb-0">${revenueDetails?.currentfydata?.forcasted?.renewed}</h5>
                <p className="text-muted mt-2">Renewed No:
                  {" "}<strong className='text-dark'>{revenueDetails?.currentfydata?.forcasted?.renewedno}</strong>
                </p>
              </div>
              <div className="avatar-sm ml-auto">
                <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                  <i className="mdi mdi-autorenew" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-md-6 col-12">
        <div className="card blog-stats-wid card-border-bottom">
          <div className="card-body" onClick={() => gotToContractPage('FY', 'churned_forecasted')} style={{ cursor: "pointer" }}>
            <div className="d-flex flex-wrap">
              <div className="mr-3">
                <p className="text-muted mb-2">Churned / Forecasted</p>
                <h5 className="mb-0">${revenueDetails?.currentfydata?.forcasted?.churned} / ${revenueDetails?.currentfydata?.forcasted?.churned_forecasted}</h5>
                <p className="text-muted mt-2">Churned No:
                  {" "}<strong className='text-dark'>{revenueDetails?.currentfydata?.forcasted?.churnedno}</strong>
                </p>
              </div>
              <div className="avatar-sm ml-auto">
                <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                  <i className="mdi mdi-file-multiple-outline" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CommonBoxDetails