import React, { useEffect, useMemo, useState } from 'react'
import { usePlatformApi } from '../../_common/hooks/api/PlatformApiHook'
import { useAppLoader } from '../../_common/hooks/common/appLoaderHook';
import TableContainer from '../commonComponents/TableContainer'
import DeleteButtonCell from '../actionsBtns/DeleteButtonCell';
import { AiOutlineEdit } from 'react-icons/ai';

interface IncomingProps {
    AccountId: string;
    show: boolean;
    setShowCustomersModal: any;
}

const CustomerTeamListing = ({ AccountId, show, setShowCustomersModal }: IncomingProps) => {

    const platformApi = usePlatformApi();
    const { showLoader, hideLoader } = useAppLoader();

    const [customerTeamData, setCustomerTeamData] = useState<any>(null)
    const [data, setData] = useState<any>([])
    const [loading, setLoading] = useState<boolean>(false)

    const getCustomerTeamData = (page = 1) => {
        showLoader();
        setLoading(true);
        platformApi.getAllCustomerContact({
            page: page,
            ContractId: AccountId
        },
            (message: string, resp: any) => {
                // console.log(resp);
                setLoading(false);
                setCustomerTeamData(resp.data.list);
                setData(resp.data.list.data);
                hideLoader();
            }, (message: string) => {
                setLoading(false);
                hideLoader();
            })
    }

    const deleteCustomerTeam = (id: any) => {
        return new Promise((resolve: any, reject: any) => {
            platformApi.contactCustomerDestroy({
                id
            }, (message: string, resp: any) => {
                resolve(message)
            }, (message: string) => {
                reject(message);
            });
        })
    }

    const columns = useMemo(
        () => [
            {
                Header: 'Actions',
                accessor: "ContactId",
                Cell: ({ column, value, row }: any) =>
                    <>
                        <DeleteButtonCell
                            column={column}
                            value={row.original.id}
                            deleteFunc={deleteCustomerTeam}
                            callbackFunc={[getCustomerTeamData]}
                            type={"Customer"}
                        />
                        {/* <AiOutlineEdit
                            onClick={() => setShowCustomersModal(true)}
                            className="cursor-pointer mr-2"
                            data-toggle="tooltip" data-placement="top" title="Edit"
                        /> */}
                    </>,
            },
            {
                Header: "Name",
                accessor: "name",
                className: "nowrap",
            },
            {
                Header: "Department",
                accessor: "department",
                className: "nowrap",
            },
            {
                Header: "Phone",
                accessor: "phone",
            },
            {
                Header: "Email",
                accessor: "email",
            },
        ],
        []
    )

    useEffect(() => {
        getCustomerTeamData();
    }, [show])


    return (
        <React.Fragment>
            <div className="table-responsive">
                <TableContainer
                    columns={columns}
                    data={data}
                    fetchData={getCustomerTeamData}
                    loading={loading}
                    rawData={customerTeamData}
                    small={true}
                    cstmClassName={"table-scroll-overflow"}
                />
            </div>
        </React.Fragment>
    )
}

export default CustomerTeamListing