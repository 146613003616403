import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Controller, useForm } from 'react-hook-form';
import { useAppLoader } from '../../_common/hooks/common/appLoaderHook';
import { toast } from 'react-hot-toast';
import { useCommonApi } from '../../_common/hooks/api/common/commonApiHook';


interface FormValues {
	enableZendesk: boolean,
	zendesk_url: string,
	zendesk_user_name: string,
	zendesk_password: string
}

const validationSchema = Yup.object().shape({
	zendesk_url: Yup.string().required("Url is required"),
	zendesk_user_name: Yup.string().required("User name is required"),
	zendesk_password: Yup.string().required("Password is required"),
});

interface IncomingProps {
	show: boolean;
	close: () => void;
}

const ZendeskConnectModal = ({ show, close }: IncomingProps) => {

	const commonApi = useCommonApi();
	const { showLoader, hideLoader } = useAppLoader();

	const [zendeskData, setZendeskData] = useState<any>(null)

	const { control, formState: { errors }, handleSubmit, setValue, reset } = useForm<FormValues>({
		resolver: yupResolver(validationSchema),
		defaultValues: {
			enableZendesk: false,
			zendesk_url: "",
			zendesk_user_name: "",
			zendesk_password: ""
		},
	});

	const getZendeskData = () => {
		showLoader();
		commonApi.getZendesk({},
			(message: string, resp: any) => {
				// console.log(resp);
				setZendeskData(resp.data.list)
				hideLoader();
			}, (message: string) => {
				hideLoader();
			})
	}

	const onSubmit = (data: any) => {
		// console.log({ data })
		let params: any = {
			zendesk_url: data?.zendesk_url,
			zendesk_user_name: data?.zendesk_user_name,
			zendesk_password: data?.zendesk_password,
			enableZendesk: data?.enableZendesk && data?.enableZendesk === true ? "1" : "0"
		}
		// console.log(params)
		showLoader()
		commonApi.addZendesk(params, (message: string, resp: any) => {
			// console.log(message, resp)
			toast.success(message)
			close()
			hideLoader()
		}, (message: string) => {
			// console.log(message)
			toast.error(message)
			hideLoader()
		})
	}

	useEffect(() => {
		if (show) {
			getZendeskData()
		}
	}, [show])

	useEffect(() => {
		// console.log(zendeskData)
		if (zendeskData) {
			setValue('zendesk_url', zendeskData?.zendesk_url)
			setValue('zendesk_user_name', zendeskData?.zendesk_user_name)
			setValue('zendesk_password', zendeskData?.zendesk_password)
			setValue('enableZendesk', zendeskData?.enableZendesk && zendeskData?.enableZendesk === "1" ? true : false)
		}
	}, [zendeskData])

	return (
		<React.Fragment>
			<Modal
				className='salesforce-modal modal-lndscp'
				show={show}
			// onHide={() => { close(); reset() }}
			>
				<div className="modal-header custom-modal-header">
					<button
						type="button"
						className="close"
						data-dismiss="modal"
						aria-label="Close"
						onClick={() => { close(); reset() }}
					>
						<span aria-hidden="true">×</span>
					</button>
				</div>
				<Modal.Body>
					<div className="row">
						<form onSubmit={handleSubmit(onSubmit)} className="w-100" >
							<div className="col-12">
								<div className="card">
									<div className="card-body">
										<div className="d-flex flex-wrap">
											<h5 className="card-title mr-2">Zendesk Information</h5>
										</div>
										<div className="row">
											<div className="form-group col-6">
												<label htmlFor="zendesk_url">Zendesk Url
													<span className='text-danger'>*</span>
												</label>
												<Controller
													name={"zendesk_url"}
													control={control}
													render={({ field: { value, onChange, onBlur, name, ref } }) => (
														<>
															<input
																type="text"
																className={"form-control " + (errors?.zendesk_url ? ' border-danger' : '')}
																id="zendesk_url"
																name={name}
																onChange={onChange}
																value={value}
																onBlur={onBlur}
																ref={ref}
																placeholder="Enter zendesk url"
															/>
														</>
													)}
												/>
												{errors && errors.zendesk_url &&
													<span className='text-danger'>{errors?.zendesk_url?.message}</span>
												}
											</div>
											<div className="form-group col-6">
												<label htmlFor="zendesk_user_name">Zendesk User Name <span className='text-danger'>*</span></label>
												<Controller
													name={"zendesk_user_name"}
													control={control}
													render={({ field: { value, onChange, onBlur, name, ref } }) => (
														<>
															<input
																type="text"
																className={"form-control " + (errors?.zendesk_user_name ? ' border-danger' : '')}
																id="zendesk_user_name"
																name={name}
																value={value}
																onChange={onChange}
																onBlur={onBlur}
																ref={ref}
																placeholder="Enter zendex user name"
															/>
														</>
													)}
												/>
												{errors && errors.zendesk_user_name &&
													<span className='text-danger'>{errors?.zendesk_user_name?.message}</span>
												}
											</div>
											<div className="form-group col-6">
												<label htmlFor="zendesk_password">Zendesk Password <span className='text-danger'>*</span></label>
												<Controller
													name={"zendesk_password"}
													control={control}
													render={({ field: { value, onChange, onBlur, name, ref } }) => (
														<>
															<input
																type="password"
																className={"form-control " + (errors?.zendesk_password ? ' border-danger' : '')}
																id="zendesk_password"
																name={name}
																value={value}
																onChange={onChange}
																onBlur={onBlur}
																ref={ref}
																autoComplete="new-password"
																placeholder="Enter zendesk password"
															/>
														</>
													)}
												/>
												{errors && errors.zendesk_password &&
													<span className='text-danger'>{errors?.zendesk_password?.message}</span>
												}
											</div>
											<div className="form-group col-6">
												<label htmlFor="enableZendesk">Status <span className='text-danger'>*</span></label>
												<Controller
													name={"enableZendesk"}
													control={control}
													render={({ field: { value, onChange, onBlur, name, ref } }) => (
														<div className='cstm-switch'>
															<input
																type="checkbox"
																id={"enableZendesk"}
																className={"form-control"}
																name={name}
																onChange={onChange}
																value={value === true ? "1" : "0"}
																onBlur={onBlur}
																defaultChecked={zendeskData?.enableZendesk === "1"}
																checked={value === true}
															/>
															<label htmlFor="enableZendesk" data-on-label="on" data-off-label="off"></label>
														</div>
													)}
												/>
												{errors && errors.enableZendesk &&
													<span className='text-danger'>{errors?.enableZendesk?.message}</span>
												}
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="col-12">
								<div className='align-items-center d-flex gap-10 justify-content-center'>
									<button type='submit' className='btn btn-primary'>Save</button>
									<button
										type='button'
										className='btn btn-outline-danger'
										onClick={() => { close(); reset() }}
									>
										Cancel
									</button>
								</div>
							</div>
						</form>
					</div>
				</Modal.Body>
			</Modal>
		</React.Fragment>
	)
}

export default ZendeskConnectModal